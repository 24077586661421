// eslint-disable-next-line no-use-before-define
import React from 'react';

import { Edit, DeleteOutline, DragIndicator, ErrorOutline } from '@mui/icons-material';
import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import { Draggable } from '@hello-pangea/dnd';
import type { DraggableProvided } from '@hello-pangea/dnd';

import { OAR } from './DragAndDropUtils';

export interface OrgansAtRiskRowProps {
  oar: OAR;
  constraints: string[];
  onEdit: () => void;
  onDelete: () => void;
  siteGroupId: number;
  careplanId: number;
  index: number;
  variations: boolean;
}

const OrgansAtRiskRow = ({ oar, constraints, onEdit, index, variations, onDelete }: OrgansAtRiskRowProps) => {
  return (
    <Draggable draggableId={oar.organ} index={index}>
      {(provided: DraggableProvided) => (
        <Card
          sx={{ marginTop: '8px' }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <Stack padding={1} gap={1} justifyContent={'space-between'} alignItems={'center'} direction="row">
            <Stack direction="row" gap={1} width="35%" alignItems="center">
              <DragIndicator color="primary" />
              <Typography variant="body1">{oar.organ}</Typography>
              {variations && <ErrorOutline color="warning" fontSize="small" />}
            </Stack>

            <Box width="25%">
              {constraints.map(
                (constraint, index): JSX.Element => (
                  <Typography variant="body2" key={index}>
                    {constraint}
                  </Typography>
                ),
              )}
            </Box>

            <Stack direction="row">
              <IconButton data-testid={`${oar.organ}-edit`} color="primary" onClick={onEdit}>
                <Edit />
              </IconButton>
              <IconButton data-testid={`${oar.organ}-delete`} onClick={onDelete}>
                <DeleteOutline color="error" />
              </IconButton>
            </Stack>
          </Stack>
        </Card>
      )}
    </Draggable>
  );
};

export default OrgansAtRiskRow;
