import React, { useContext, useState } from 'react';
import { useFormikContext, Field, getIn } from 'formik';
import { ChildSectionWrapper } from 'shared-components/components/FormFields/common';
import { Region } from 'shared-components/enums';
import { ROTextField, ROToggleButtons, ROHelperText } from 'shared-components/components/FormFields';

import { IntakePageProps, FormikValues } from '../Interface';
import {
  VALUE_REQUIRED,
  POSITIVE_NEGATIVE_UNKNOWN_OPTIONS,
  YES_NO_OPTIONS,
  YES_NO_UNKNOWN_OPTIONS,
  GRADE_OPTIONS,
  SURGERY_OPTIONS,
  EXTENT_LVI_OPTIONS,
  RECONSTRUCTION_TIMELINE_OPTIONS,
  RECONSTRUCTION_IMMEDIATE_SURGERY_OPTIONS,
  RECONSTRUCTION_DELAYED_SURGERY_OPTIONS,
  surgeryTooltip,
  oncoplasticSurgeryTooltip,
  radialMarginStatusTooltip,
  lymphovascularInvasionTooltip,
  reconstructionTooltip,
  EcogMovedTooltip,
} from '../helpers';
import { ClinexTooltip, isFieldLowCertainty, LOW_CERTAINTY } from '../../Clinex/helpers';
import { ClinexContext } from '../../Clinex/context';
import { styled } from '@mui/system';
import { TooltipModal } from 'shared-components/components/FormFields';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import ClinexFieldWrapper from '../../Clinex/ClinexFieldWrapper';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;
const region = REACT_APP_REGION;

const StyledQuestionCircleIcon = styled(HelpOutlineIcon)`
  cursor: pointer;
`;

export const BreastIntakeV2 = ({ updateIntakeHandler }: IntakePageProps): JSX.Element => {
  const { values, touched } = useFormikContext<FormikValues>();
  const clinexContexts = useContext(ClinexContext);
  const { clinexData } = clinexContexts;
  const [showSurgeryModal, setShowSurgeryModal] = useState<boolean>(false);
  const [showOncoplasticSurgeryModal, setShowOncoplasticSurgeryModal] = useState<boolean>(false);
  const [showLymphovascularInvasionModal, setShowLymphovascularInvasionModal] = useState<boolean>(false);
  const [showRadialMarginStatusModal, setShowRadialMarginStatusModal] = useState<boolean>(false);
  const [showReconstructionTimelineModal, setShowReconstructionTimelineModal] = useState<boolean>(false);

  if (values.intake?.breast) {
    const {
      grade,
      er,
      pgr,
      her2,
      surgery,
      mastectomy,
      reconstruction,
      oncoplasticSurgery,
      reconstructionTimeline,
      reconstructionType,
      expandersInSitu,
      lymphovascularInvasion,
      lymphovascularInvasionExtent,
      radialMarginStatus,
      neoadjuvantSystemicTherapy,
      exerciseDiscussedWithPatient,
      excerciseReferral,
    } = values.intake.breast;
    // eslint-disable-next-line

    const [nstAdditionalInfo, setNstAdditionalInfo] = useState(values?.intake?.breast?.nstAdditionalInfo || '');

    return (
      <>
        <EcogMovedTooltip />
        <ClinexFieldWrapper field="grade">
          <ClinexTooltip clinexData={clinexData} clinexField={'grade'} formValues={values.intake.breast}>
            <Field
              name="grade"
              component={ROToggleButtons}
              id="grade"
              fieldlabel="Grade"
              options={GRADE_OPTIONS}
              value={grade}
              handleChange={(value: string): void => {
                updateIntakeHandler({ grade: value });
              }}
              error={getIn(touched, 'grade') && !grade}
              warning={isFieldLowCertainty(clinexData, 'grade', values)}
              required
              helperText={!grade ? VALUE_REQUIRED : LOW_CERTAINTY}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="er">
          <ClinexTooltip clinexData={clinexData} clinexField={'er'} formValues={values.intake.breast}>
            <Field
              name="er"
              component={ROToggleButtons}
              id="er"
              fieldlabel="ER"
              options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
              value={er}
              handleChange={(value: string): void => {
                updateIntakeHandler({ er: value });
              }}
              error={getIn(touched, 'er') && !er}
              required
              clinexRow
              helperText={!er ? VALUE_REQUIRED : LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'er', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="pgr">
          <ClinexTooltip clinexData={clinexData} clinexField={'pgr'} formValues={values.intake.breast}>
            <Field
              name="pgr"
              component={ROToggleButtons}
              id="pgr"
              fieldlabel="PgR"
              options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
              value={pgr}
              handleChange={(value: string): void => {
                updateIntakeHandler({ pgr: value });
              }}
              error={getIn(touched, 'pgr') && !pgr}
              required
              helperText={!pgr ? VALUE_REQUIRED : LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'pgr', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="her2">
          <ClinexTooltip clinexData={clinexData} clinexField={'her2'} formValues={values.intake.breast}>
            <Field
              name="her2"
              component={ROToggleButtons}
              id="her2"
              fieldlabel="Her2"
              options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
              value={her2}
              handleChange={(value: string): void => {
                updateIntakeHandler({ her2: value });
              }}
              error={getIn(touched, 'her2') && !her2}
              required
              helperText={!her2 ? VALUE_REQUIRED : LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'her2', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>

        {region === Region.UK && (
          <>
            <ClinexFieldWrapper field="mastectomy">
              <ClinexTooltip clinexData={clinexData} clinexField={'mastectomy'} formValues={values.intake.breast}>
                <Field
                  name="mastectomy"
                  component={ROToggleButtons}
                  id="mastectomy"
                  fieldlabel="Mastectomy"
                  options={YES_NO_UNKNOWN_OPTIONS}
                  value={mastectomy}
                  error={getIn(touched, 'mastectomy') && !mastectomy}
                  onChange={(value: string): void => {
                    updateIntakeHandler({ mastectomy: value });
                  }}
                  message={LOW_CERTAINTY}
                  warning={isFieldLowCertainty(clinexData, 'mastectomy', values)}
                />
              </ClinexTooltip>
            </ClinexFieldWrapper>

            <ClinexFieldWrapper field="reconstruction">
              <Field
                name="reconstruction"
                component={ROToggleButtons}
                id="reconstruction"
                fieldlabel={'Reconstruction'}
                options={YES_NO_UNKNOWN_OPTIONS}
                value={reconstruction}
                error={getIn(touched, 'reconstruction') && !reconstruction}
                handleChange={(value: string): void => {
                  updateIntakeHandler({ reconstruction: value });
                }}
                helperText={LOW_CERTAINTY}
                warning={isFieldLowCertainty(clinexData, 'reconstruction', values)}
              />
            </ClinexFieldWrapper>
          </>
        )}
        {region !== Region.UK && (
          <>
            <ClinexFieldWrapper field="surgery">
              <ClinexTooltip clinexData={clinexData} clinexField={'surgery'} formValues={values.intake.breast}>
                <Field
                  name="surgery"
                  component={ROToggleButtons}
                  id="surgery"
                  fieldlabel="Surgery"
                  options={SURGERY_OPTIONS}
                  value={surgery}
                  handleChange={(value: string): void => {
                    // Clear sub field values as necessary
                    const updateFields = { surgery: value };
                    let extraFields = {};
                    if (value !== 'Mastectomy') {
                      extraFields = { reconstructionTimeline: null };
                    }
                    if (value !== 'Breast conserving') {
                      extraFields = { ...extraFields, oncoplasticSurgery: null };
                    }
                    updateIntakeHandler({ ...updateFields, ...extraFields });
                  }}
                  helperText={LOW_CERTAINTY}
                  warning={isFieldLowCertainty(clinexData, 'surgery', values)}
                  tooltip={
                    <StyledQuestionCircleIcon fontSize="small" color="info" onClick={() => setShowSurgeryModal(true)} />
                  }
                />
              </ClinexTooltip>
            </ClinexFieldWrapper>
            <TooltipModal open={showSurgeryModal} tooltip={surgeryTooltip} setOpen={setShowSurgeryModal} />
            {surgery === 'Breast conserving' && (
              <ChildSectionWrapper>
                <Field
                  name="oncoplasticSurgery"
                  component={ROToggleButtons}
                  id="oncoplasticSurgery"
                  fieldlabel="Oncoplastic Surgery"
                  options={YES_NO_UNKNOWN_OPTIONS}
                  value={oncoplasticSurgery}
                  handleChange={(value: string): void => {
                    updateIntakeHandler({ oncoplasticSurgery: value });
                  }}
                  tooltip={
                    <StyledQuestionCircleIcon
                      fontSize="small"
                      color="info"
                      onClick={() => setShowOncoplasticSurgeryModal(true)}
                    />
                  }
                />
                <TooltipModal
                  open={showOncoplasticSurgeryModal}
                  tooltip={oncoplasticSurgeryTooltip}
                  setOpen={setShowOncoplasticSurgeryModal}
                />
              </ChildSectionWrapper>
            )}
            {surgery === 'Mastectomy' && (
              <>
                <ChildSectionWrapper>
                  <ClinexFieldWrapper field="reconstructionTimeline">
                    <ClinexTooltip clinexData={clinexData} clinexField={'reconstructionTimeline'} formValues={values}>
                      <Field
                        name="reconstructionTimeline"
                        component={ROToggleButtons}
                        id="reconstructionTimeline"
                        fieldlabel="Reconstruction"
                        options={RECONSTRUCTION_TIMELINE_OPTIONS}
                        value={reconstructionTimeline}
                        handleChange={(value: string): void => {
                          updateIntakeHandler({
                            reconstructionTimeline: value,
                            reconstructionType: '',
                            expandersInSitu: '',
                          });
                        }}
                        tooltip={
                          <StyledQuestionCircleIcon
                            fontSize="small"
                            color="info"
                            onClick={() => setShowReconstructionTimelineModal(true)}
                          />
                        }
                      />
                    </ClinexTooltip>
                  </ClinexFieldWrapper>
                  <TooltipModal
                    open={showReconstructionTimelineModal}
                    tooltip={reconstructionTooltip}
                    setOpen={setShowReconstructionTimelineModal}
                  />
                </ChildSectionWrapper>

                {reconstructionTimeline === 'Immediate' && (
                  <>
                    <ChildSectionWrapper>
                      <Field
                        name="reconstructionType"
                        component={ROToggleButtons}
                        id="reconstructionType"
                        fieldlabel="Type"
                        options={RECONSTRUCTION_IMMEDIATE_SURGERY_OPTIONS}
                        value={reconstructionType}
                        handleChange={(value: string): void => {
                          updateIntakeHandler({ reconstructionType: value });
                        }}
                      />
                    </ChildSectionWrapper>
                  </>
                )}

                {reconstructionTimeline === 'Delayed' && (
                  <>
                    <ChildSectionWrapper>
                      <Field
                        name="expandersInSitu"
                        component={ROToggleButtons}
                        id="expandersInSitu"
                        fieldlabel="Expanders in situ"
                        options={RECONSTRUCTION_DELAYED_SURGERY_OPTIONS}
                        value={expandersInSitu}
                        handleChange={(value: string): void => {
                          updateIntakeHandler({ expandersInSitu: value });
                        }}
                      />
                    </ChildSectionWrapper>
                  </>
                )}
              </>
            )}
            <ClinexFieldWrapper field="lymphovascular_invasion">
              <ClinexTooltip clinexData={clinexData} clinexField={'lymphovascular_invasion'} formValues={values}>
                <Field
                  name="lymphovascularInvasion"
                  component={ROToggleButtons}
                  id="lymphovascularInvasion"
                  fieldlabel="Lymphovascular Invasion"
                  options={YES_NO_UNKNOWN_OPTIONS}
                  value={lymphovascularInvasion}
                  handleChange={(value: string): void => {
                    // Clear lymphoma invasion extent value if lymphoma invasion value is set to 'No' or 'Unknown'
                    if (value !== 'Yes') {
                      updateIntakeHandler({ lymphovascularInvasion: value, lymphovascularInvasionExtent: null });
                    } else {
                      updateIntakeHandler({ lymphovascularInvasion: value });
                    }
                  }}
                  helperText={LOW_CERTAINTY}
                  warning={isFieldLowCertainty(clinexData, 'lymphovascular_invasion', values)}
                  tooltip={
                    <StyledQuestionCircleIcon
                      fontSize="small"
                      color="info"
                      onClick={() => setShowLymphovascularInvasionModal(true)}
                    />
                  }
                />
              </ClinexTooltip>
            </ClinexFieldWrapper>
            <TooltipModal
              open={showLymphovascularInvasionModal}
              tooltip={lymphovascularInvasionTooltip}
              setOpen={setShowLymphovascularInvasionModal}
            />
            {lymphovascularInvasion === 'Yes' && (
              <ChildSectionWrapper>
                <Field
                  name="lymphovascularInvasionExtent"
                  component={ROToggleButtons}
                  id="lymphovascularInvasionExtent"
                  fieldlabel="Extent of LVI"
                  options={EXTENT_LVI_OPTIONS}
                  value={lymphovascularInvasionExtent}
                  handleChange={(value: string): void => {
                    updateIntakeHandler({ lymphovascularInvasionExtent: value });
                  }}
                  helperText={LOW_CERTAINTY}
                  warning={isFieldLowCertainty(clinexData, 'lymphovascular_invasion_extent', values)}
                />
              </ChildSectionWrapper>
            )}
            <Field
              name="radialMarginStatus"
              component={ROToggleButtons}
              id="radialMarginStatus"
              fieldlabel="Radial Margin Status"
              options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
              value={radialMarginStatus}
              handleChange={(value: string): void => {
                updateIntakeHandler({ radialMarginStatus: value });
              }}
              tooltip={
                <StyledQuestionCircleIcon
                  fontSize="small"
                  color="info"
                  onClick={() => setShowRadialMarginStatusModal(true)}
                />
              }
            />
            <TooltipModal
              open={showRadialMarginStatusModal}
              tooltip={radialMarginStatusTooltip}
              setOpen={setShowRadialMarginStatusModal}
            />
            <Field
              name="neoadjuvantSystemicTherapy"
              component={ROToggleButtons}
              id="neoadjuvantSystemicTherapy"
              fieldlabel="Neoadjuvant Systemic Therapy"
              options={YES_NO_OPTIONS}
              value={neoadjuvantSystemicTherapy}
              handleChange={(value: string): void => {
                // Clear additional info value if this value is set to 'No'
                if (value !== 'Yes') {
                  updateIntakeHandler({ neoadjuvantSystemicTherapy: value, nstAdditionalInfo: null });
                } else {
                  updateIntakeHandler({ neoadjuvantSystemicTherapy: value });
                }
              }}
            />
            {neoadjuvantSystemicTherapy === 'Yes' && (
              <ChildSectionWrapper>
                <Field
                  name="nstAdditionalInfo"
                  component={ROTextField}
                  id="nstAdditionalInfo"
                  fieldlabel="Additional Info"
                  value={nstAdditionalInfo}
                  onChange={(event: React.FocusEvent<HTMLInputElement>): void => {
                    setNstAdditionalInfo(event.target.value);
                  }}
                  onBlur={() => {
                    updateIntakeHandler({ nstAdditionalInfo });
                  }}
                  multiline
                  maxRows={4}
                />
              </ChildSectionWrapper>
            )}
            <Field
              name="exerciseDiscussedWithPatient"
              component={ROToggleButtons}
              id="exerciseDiscussedWithPatient"
              fieldlabel="Exercise Discussed with Patient"
              options={YES_NO_OPTIONS}
              value={exerciseDiscussedWithPatient}
              handleChange={(value: string): void => {
                updateIntakeHandler({ exerciseDiscussedWithPatient: value });
              }}
            />
            <Field
              name="exerciseReferral"
              component={ROToggleButtons}
              id="excercise"
              fieldlabel="Referred for Exercise"
              options={YES_NO_OPTIONS}
              value={excerciseReferral}
              handleChange={(value: string): void => {
                updateIntakeHandler({ excerciseReferral: value });
              }}
            />
          </>
        )}
      </>
    );
  }
  return <> </>;
};
