// eslint-disable-next-line no-use-before-define
import React, { Component, useEffect } from 'react';
import './UkNavBar.scss';
import { NavLink as RRNavLink, RouteComponentProps, Redirect, withRouter } from 'react-router-dom';
import { HorizonLogoNavigator } from 'shared-components/components';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import { AppBar, Box, Drawer, IconButton, List, ListItem, Toolbar } from '@mui/material';
import { Stack } from '@mui/system';
import { Apps as AppsIcon, Menu as MenuIcon } from '@mui/icons-material';
import { theme } from 'theme';

interface UserQuery {
  loading: boolean;
  error: string;
  data: {
    user: {
      email: string;
      firstName: string;
      lastName: string;
      isPso: boolean;
      isRo: boolean;
      isSuperuser: boolean;
      isShowcaseUser: boolean;
    };
  };
}

interface UserType {
  user: {
    email: string;
    firstName: string;
    lastName: string;
    isPso: boolean;
    isRo: boolean;
    isSuperuser: boolean;
    isShowcaseUser: boolean;
  };
}

const GET_USER_QUERY = gql`
  {
    user {
      id
      email
      firstName
      lastName
      isPso
      isRo
      isSuperuser
      isShowcaseUser
    }
  }
`;

interface State {
  isOpen: boolean;
  roles: any;
}

const roleRoutes = [
  {
    path: '/search',
    text: 'Patient Search',
    roles: ['PSO', 'superUser'],
  },
  {
    path: '/appointments',
    text: 'Appointments',
    roles: ['PSO', 'superUser'],
  },
  {
    path: '/radiation',
    text: 'RO Portal',
    roles: ['ROUser'],
  },
];

class UKNavBar extends Component<RouteComponentProps, State> {
  public constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isOpen: false,
      roles: {
        PSO: ['^\\/appointments$', '^\\/search$', '^\\/registration\\/[0-9]+', '^\\/error$'],
        ROUser: ['^\\/radiation$'],
      },
    };
  }

  private toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  private renderDefaultHeader = (isLoadingError: boolean): JSX.Element => {
    const placeholderText: string = isLoadingError ? 'Error' : 'Loading';
    return (
      <div id="navbar-container">
        <HorizonLogoNavigator linkToSearch={false} />
        {<div>{placeholderText}</div>}
      </div>
    );
  };

  public render(): JSX.Element {
    const errorDiv = (
      <div id="navbar-container">
        <HorizonLogoNavigator linkToSearch={false} />
        <Redirect to="/error" />
      </div>
    );
    return (
      <Query<UserType> query={GET_USER_QUERY} fetchPolicy="network-only">
        {({ loading, error, data }): JSX.Element => {
          useEffect(() => {
            if (error) {
              this.renderDefaultHeader(true);
            }
          }, [error]);

          if (loading) return this.renderDefaultHeader(false);
          if (!data) return errorDiv;
          const { isPso, isSuperuser, isRo, isShowcaseUser } = data?.user;
          const roles = [];
          if (isSuperuser) {
            roles.push('superUser');
          }
          if (isPso) {
            roles.push('PSO');
          }
          if (isRo || isShowcaseUser) {
            roles.push('ROUser');
          }
          if (roles.length === 0) {
            return errorDiv;
          }
          const path = this.props.location.pathname;

          if (roles.indexOf('superUser') === -1) {
            let auth = true;
            const allowedPaths = [];
            for (const x in roles) {
              const roleName = roles[x];
              const paths = this.state.roles[roleName];
              for (const i in paths) {
                const currPath = paths[i];
                if (path.match(currPath)) {
                  allowedPaths.push(path);
                  auth = true;
                }
              }
            }

            if (auth && (path === '/' || allowedPaths.length === 0)) {
              if (roles.length === 1) {
                if (isRo || isShowcaseUser) {
                  return <Redirect to="/radiation" />;
                } else if (isPso) {
                  return <Redirect to="/search" />;
                }
              }
              return <Redirect to="/app-selector" />;
            }
            if (!auth) {
              return errorDiv;
            }
          }

          const drawer = (
            <Box onClick={this.toggle} sx={{ textAlign: 'center' }}>
              <List>
                {roles.length > 1 && (
                  <ListItem>
                    <RRNavLink to="/app-selector" className="mb-2">
                      <AppsIcon htmlColor={theme.palette.grey[600]} />
                    </RRNavLink>
                  </ListItem>
                )}
                {roles.map((role: any, roleIndex: number) => {
                  return roleRoutes.map((route: any, routeIndex: number) => {
                    if (route.roles.indexOf(role) > -1) {
                      return (
                        <ListItem key={`roles-${roleIndex}-route-${routeIndex}`}>
                          <RRNavLink to={route.path}>{route.text}</RRNavLink>
                        </ListItem>
                      );
                    }
                  });
                })}
              </List>
            </Box>
          );

          return (
            <>
              <AppBar position="static">
                <Toolbar disableGutters>
                  <Stack direction="row" spacing={2} alignItems={'flex-end'}>
                    <HorizonLogoNavigator linkToSearch={isPso} />
                    {(isPso || isSuperuser) && (
                      <>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          edge="start"
                          onClick={this.toggle}
                          sx={{ mr: 2, display: { sm: 'none' } }}>
                          <MenuIcon />
                        </IconButton>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, gap: '24px' }}>
                          {roles.length > 1 && (
                            <RRNavLink to="/app-selector" className="mb-2">
                              <AppsIcon htmlColor={theme.palette.grey[600]} />
                            </RRNavLink>
                          )}
                          {roles.map((role: any, roleIndex: number) => {
                            return roleRoutes.map((route: any, routeIndex: number) => {
                              if (route.roles.indexOf(role) > -1) {
                                return (
                                  <Box
                                    key={`roles-${roleIndex}-route-${routeIndex}`}
                                    sx={{ '& a': { color: 'white', fontSize: '18px' } }}>
                                    <RRNavLink to={route.path}>{route.text}</RRNavLink>
                                  </Box>
                                );
                              }
                            });
                          })}
                        </Box>
                      </>
                    )}
                  </Stack>
                </Toolbar>
              </AppBar>
              <nav>
                <Drawer
                  variant="temporary"
                  open={this.state.isOpen}
                  onClose={this.toggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '200px' },
                  }}>
                  {drawer}
                </Drawer>
              </nav>
            </>
          );
        }}
      </Query>
    );
  }
}

const routedComponent = withRouter(UKNavBar);
export default routedComponent;
