import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartColumn from '../Charts/ChartColumn';
import SubChartBar from '../Charts/SubCharts/SubChartBar';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import { DataFunctions } from '../../Utils/dataFunctions';

const MachineUtilisation = ({
  data,
  subChartData,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  ...rest
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight, dateRange),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartColumn({
      selectedPoint: rest.filterOverride,
      leftSeriesTitle: 'Average daily treatments per linac',
      rightSeriesTitle: '',
      leftSeriesFields: ['Date', 'Daily Treatments'],
      rightSeriesFields: ['Date', 'Target'],
      aggregation: dateAggregationMap[dateRange!],
      dateRange: dateRange,
      tooltipSuffix: '',
      ...rest,
    }).components?.[0] ?? {},
    SubChartBar({
      seeMore: seeMoreBottomLeft,
      title: 'Machines',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      dataLabelTitle: 'Average daily treatments',
      dataLength: DataFunctions.aggregateData(subChartData, 'linac').length - 1,
      yAxisMax: DataFunctions.getMaxAverageDailyTreatments(subChartData) * 2,
      precision: 1,
      numLabels: 1,
      ...rest,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
  dateRange: string | undefined,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['Date', 'Daily Treatments', 'Target'],
        data: DataFunctions.aggregateAverageDailyTreatmentsPerLinac(data, dateAggregationMap[dateRange!]),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateAverageDailyTreatmentsPerLinacUnique(
          subChartData,
          seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT,
        ),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(subChartData, 'technique', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default MachineUtilisation;
