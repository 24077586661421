// eslint-disable-next-line no-use-before-define
import React, { useEffect, useContext } from 'react';
import { useFormikContext, FormikProps } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { useRouteMatch, useLocation } from 'react-router-dom';

import { intakePageMapping } from './DiagnosisPages/Intake/IntakePage';
import { CREATE_DIAGNOSIS_INTAKE, GET_DIAGNOSIS_INTAKE } from './DiagnosisPages/Intake/Queries';
import { codeToIntakePageMapping } from './DiagnosisPages/Utils';
import { CarePlanActionType } from './Interface';
import ROPatientCarePlanContext from './ROPatientCarePlanContext';
import { GET_INTAKE_CODE_MAPPING } from './Queries';
import { LOAD_TREATMENTINFO } from './TreatmentInfoPage/Queries';
import { GET_DIAGNOSIS_FROM_CAREPLAN } from './SidePanel/SidePanelQueries';
import {
  GET_ORDERED_DIAGNOSIS_LIST,
  GET_ICD10_CATEGORY_LIST,
  GET_CLINEX_CODES,
  GET_DIAGNOSIS,
} from './DiagnosisPages/Queries';
import { LOAD_TREATMENT_LIST_DATA } from './TreatmentInfoPage/Queries';
import { LIST_OPTIONS } from './TreatmentInfoPage/TreatmentInfoPage';
import ROPatientCarePlanPageRouter from './Router';

const CareplanApollo = (): JSX.Element => {
  const match = useRouteMatch<any>();
  const location = useLocation();
  const { values, setFieldValue }: FormikProps<any> = useFormikContext();
  const [createIntake, { called: createIntakeCalled }] = useMutation(CREATE_DIAGNOSIS_INTAKE);
  const { diagnosis, intake, intakePage } = values;
  const { dispatch } = useContext(ROPatientCarePlanContext);
  const { id: patientId, careplanId } = match.params;

  useQuery(GET_ORDERED_DIAGNOSIS_LIST, {
    variables: { isPrimaryDiagnosis: location.pathname.includes('primary'), patientId },
    skip: careplanId === 'create',
    fetchPolicy: 'cache-and-network',
  });

  useQuery(GET_ICD10_CATEGORY_LIST, {
    variables: { isPrimaryDiagnosis: location.pathname.includes('primary') },
    fetchPolicy: 'cache-and-network',
  });

  useQuery(GET_CLINEX_CODES, {
    variables: { patientId: patientId },
    fetchPolicy: 'cache-and-network',
  });

  useQuery(LOAD_TREATMENT_LIST_DATA, {
    variables: {
      listCategory: [
        LIST_OPTIONS.CHEMO_WHERE,
        LIST_OPTIONS.INTENT,
        LIST_OPTIONS.TREATING_DEPARTMENT,
        LIST_OPTIONS.CHEMO_SEQUENCING,
        LIST_OPTIONS.PREVIOUS_MDT,
        LIST_OPTIONS.BRACHY_WHERE,
        LIST_OPTIONS.BRACHY_SEQUENCING,
      ],
      patientId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: dxFromCpData } = useQuery(GET_DIAGNOSIS_FROM_CAREPLAN, {
    fetchPolicy: 'cache-and-network',
    variables: { careplanId: careplanId },
    skip: !careplanId || careplanId === 'create',
  });

  const splitPathname = location.pathname.split('/');
  const diagnosisIndex = splitPathname.indexOf('diagnosis');
  const diagnosisIdFromPath = diagnosisIndex > 0 ? splitPathname[diagnosisIndex + 1] : null;

  const diagnosisId =
    diagnosisIdFromPath && diagnosisIdFromPath !== 'create'
      ? diagnosisIdFromPath
      : dxFromCpData
      ? dxFromCpData?.careplan?.diagnosis?.id
      : null;

  useQuery(GET_DIAGNOSIS, {
    variables: { diagnosisId },
    fetchPolicy: 'cache-and-network',
    skip: !diagnosisId || diagnosisId === 'create',
    onCompleted: (data: any) => setFieldValue('diagnosis', data?.diagnosis),
  });

  useQuery(LOAD_TREATMENTINFO, {
    variables: { id: careplanId },
    skip: careplanId === 'create',
    onCompleted: (data: any) => setFieldValue('treatmentInfo', data?.careplan),
  });

  useQuery(GET_DIAGNOSIS_INTAKE, {
    variables: { diagnosisId: diagnosis?.id },
    skip: !diagnosis?.id || diagnosis?.id === 'create',
    fetchPolicy: 'cache-and-network',
    onCompleted: (data: any) => setFieldValue('intake', data?.intake),
  });

  const { data: intakeCodeMapping } = useQuery(GET_INTAKE_CODE_MAPPING, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (
      diagnosis &&
      intake &&
      diagnosis.id &&
      intakePage &&
      // @ts-ignore
      !intake[intakePageMapping[intakePage]?.key] &&
      !createIntakeCalled
    ) {
      // @ts-ignore
      const { createVariable } = intakePageMapping[intakePage];
      createIntake({
        variables: { diagnosisId: diagnosis.id, careplanId: careplanId, intakeData: createVariable },
      }).then((res: any) => {
        setFieldValue('intake', { ...res.data.createIntake.intake, id: diagnosis.id });
      });
    }
  }, [values.intake, values.diagnosis]);

  useEffect(() => {
    if (intakeCodeMapping?.intakeCodeMapping?.length > 0 && diagnosis) {
      const codeToStream = intakeCodeMapping.intakeCodeMapping.reduce((current: any, candidate: any): any => {
        const name = candidate.intake.name;
        const code = candidate.diagnosisCode.diagnosisCode;
        current[code] = name;
        return current;
      }, {});
      dispatch({ type: CarePlanActionType.SET_INTAKE_CODE_MAPPING, payload: { ...codeToStream } });
      const primaryDiagnosis = diagnosis?.isPrimaryDiagnosis ? diagnosis : diagnosis?.relatedPrimaryDiagnosis;
      const intakeNameMapped = codeToIntakePageMapping(
        primaryDiagnosis?.diagnosisCode ? primaryDiagnosis?.diagnosisCode?.diagnosisCode : '',
        {
          ...codeToStream,
        },
      );
      setFieldValue('intakePage', intakeNameMapped);
    }
  }, [diagnosis, intakeCodeMapping]);

  return <ROPatientCarePlanPageRouter />;
};

export default CareplanApollo;
