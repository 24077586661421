// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { useFormikContext, Field, getIn } from 'formik';

import { ROAutocomplete, ROToggleButtons } from 'shared-components/components/FormFields';
import {
  INVOLVED_UNINVOLVED_OPTIONS,
  POSITIVE_NEGATIVE_UNKNOWN_OPTIONS,
  SMOKING_HISTORY_OPTIONS,
  YES_NO_OPTIONS,
  PNI_TYPE_OPTIONS,
} from './helpers';
import { useRouteMatch } from 'react-router';
import { SEARCH_DROPDOWN_PLACEHOLDER } from 'shared-components/components/FormFields/common';
import { IntakePageProps, FormikValues } from './Interface';
import { useQuery } from '@apollo/client';
import { LOAD_CAREPLAN_LIST_DATA } from '../../ListData/ListQueries';
import { filterListdata } from '../../ListData/ListData';
import { LoadingSpinner } from 'shared-components/components';
import { useErrorModalContext } from 'op-contexts';
import { getOptionByValue } from '../Utils';
import { ChildSectionWrapper } from 'shared-components/components/FormFields/common';
import { ROTextField, RODatePicker } from 'shared-components/components/FormFields';
import dayjs from 'dayjs';
import { Divider, InputAdornment, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import { SelectOptionType } from 'shared-components/components/FormFields';

const ROPatientDiagnosisIntakeHNPage = ({ updateIntakeHandler, setDateError }: IntakePageProps): JSX.Element => {
  const { values, touched, setFieldValue } = useFormikContext<FormikValues>();
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;
  const [tStageOptions, setTStageOptions] = useState<SelectOptionType[]>([]);
  const [nStageOptions, setNStageOptions] = useState<SelectOptionType[]>([]);
  const [mStageOptions, setMStageOptions] = useState<SelectOptionType[]>([]);

  const { loading: tnmOptionsLoading } = useQuery(LOAD_CAREPLAN_LIST_DATA, {
    variables: { listCategory: 'headNeckIntakeTNM', patientId },
    onCompleted: (data: any): void => {
      const sortedListData = filterListdata(data, 'headNeckIntakeTNM');
      const tStageListData = sortedListData.filter((option: SelectOptionType) => option.value.startsWith('T'));
      const nStageListData = sortedListData.filter((option: SelectOptionType) => option.value.startsWith('N'));
      const mStageListData = sortedListData.filter((option: SelectOptionType) => option.value.startsWith('M'));
      setTStageOptions(tStageListData.map((data: SelectOptionType) => ({ label: data.label, value: data.value })));
      setNStageOptions(nStageListData.map((data: SelectOptionType) => ({ label: data.label, value: data.value })));
      setMStageOptions(mStageListData.map((data: SelectOptionType) => ({ label: data.label, value: data.value })));
    },
  });

  if (tnmOptionsLoading)
    return <LoadingSpinner loadingText={'Loading Intake'} subtitle={'Please wait while we set things up for you'} />;

  if (values.intake?.headAndNeck) {
    const {
      p16,
      smokingHistory,
      stageTCode,
      stageNCode,
      stageMCode,
      cigarettesPerDay,
      yearsSmoked,
      packYears,
      dateOfSurgery,
      extracapsularExtension,
      margins,
      marginsMeasurement,
      periNeuralInvasion,
      pniType,
      patientDiscussedAtMdt,
      treatmentConsistentMdt,
    } = values.intake.headAndNeck;

    const hasSmokingHistory = ['Current', 'Previous'].some((value) => smokingHistory?.includes(value));

    const updateSmokingHistory = (cigarettesPerDay: number | null, yearsSmoked: number | null) => {
      if (cigarettesPerDay && yearsSmoked) {
        // packYears = (cigarettesPerDay / 20) * yearsSmoked
        // Rounded to max 1 decimal point
        const updatedPackYears = Math.round(Number((cigarettesPerDay / 20) * yearsSmoked) * 10) / 10;
        updateIntakeHandler({
          cigarettesPerDay: cigarettesPerDay,
          yearsSmoked: yearsSmoked,
          packYears: updatedPackYears,
        });
      } else {
        updateIntakeHandler({
          cigarettesPerDay: cigarettesPerDay,
          yearsSmoked: yearsSmoked,
          packYears: null,
        });
      }
    };

    return (
      <>
        <Field
          name="p16"
          component={ROToggleButtons}
          id="P16"
          fieldlabel="P16"
          options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
          value={p16}
          handleChange={(value: string): void => {
            // Clear TNM values if p16 value is set to 'Negative' or 'Unknown'
            if (value !== 'Positive') {
              updateIntakeHandler({ p16: value, stageTCode: null, stageNCode: null, stageMCode: null });
            } else {
              updateIntakeHandler({ p16: value });
            }
          }}
          error={getIn(touched, 'p16') && !p16}
          required
        />
        {p16 === 'Positive' && (
          <>
            <Typography variant="subtitle1">AJCC V7</Typography>
            <ChildSectionWrapper>
              <Field
                name="stageTCode"
                component={ROAutocomplete}
                id="stageTCode"
                fieldlabel="T Stage"
                placeholder={SEARCH_DROPDOWN_PLACEHOLDER}
                options={tStageOptions}
                value={getOptionByValue(tStageOptions, stageTCode || null)}
                onChange={(selectedOption: SelectOptionType): void => {
                  updateIntakeHandler({ stageTCode: (selectedOption as SelectOptionType).value });
                }}
              />
              <Field
                name="stageNCode"
                component={ROAutocomplete}
                id="stageNCode"
                fieldlabel="N Stage"
                placeholder={SEARCH_DROPDOWN_PLACEHOLDER}
                options={nStageOptions}
                value={getOptionByValue(nStageOptions, stageNCode || null)}
                onChange={(selectedOption: SelectOptionType): void => {
                  updateIntakeHandler({ stageNCode: (selectedOption as SelectOptionType).value });
                }}
              />
              <Field
                name="stageMCode"
                component={ROAutocomplete}
                id="stageMCode"
                fieldlabel="M Stage"
                placeholder={SEARCH_DROPDOWN_PLACEHOLDER}
                options={mStageOptions}
                value={getOptionByValue(mStageOptions, stageMCode || null)}
                onChange={(selectedOption: SelectOptionType): void => {
                  updateIntakeHandler({ stageMCode: (selectedOption as SelectOptionType).value });
                }}
              />
            </ChildSectionWrapper>
          </>
        )}
        <Field
          name="smokingHistory"
          component={ROToggleButtons}
          id="smokingHistory"
          fieldlabel="Smoking History"
          options={SMOKING_HISTORY_OPTIONS}
          value={smokingHistory}
          handleChange={(value: string): void => {
            // Clear smoking history child fields if set to 'Never Smoked' or 'Unknown'
            if (value === 'Never Smoked' || value === 'Unknown') {
              updateIntakeHandler({
                smokingHistory: value,
                cigarettesPerDay: null,
                yearsSmoked: null,
                packYears: null,
              });
              return;
            }
            updateIntakeHandler({ smokingHistory: value });
          }}
          error={getIn(touched, 'smokingHistory') && !smokingHistory}
          required
        />
        {hasSmokingHistory && (
          <ChildSectionWrapper>
            <Field
              name="cigarettesPerDay"
              component={ROTextField}
              id="cigarettesPerDay"
              fieldlabel="No. of Cigarettes Per Day"
              value={cigarettesPerDay ? Number(cigarettesPerDay).toString() : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setFieldValue('intake.headAndNeck.cigarettesPerDay', Number(event.target.value));
              }}
              onBlur={(): void => {
                updateSmokingHistory(cigarettesPerDay, yearsSmoked);
              }}
              InputProps={{ endAdornment: <InputAdornment position="start">Per Day</InputAdornment> }}
              fullWidth={false}
            />
            <Field
              name="yearsSmoked"
              component={ROTextField}
              id="yearsSmoked"
              fieldlabel="Over How Many Year(s)"
              value={yearsSmoked ? Number(yearsSmoked).toString() : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setFieldValue('intake.headAndNeck.yearsSmoked', Number(event.target.value));
              }}
              onBlur={(): void => {
                updateSmokingHistory(cigarettesPerDay, yearsSmoked);
              }}
              InputProps={{ endAdornment: <InputAdornment position="start">{'Year(s)'}</InputAdornment> }}
              fullWidth={false}
            />
            <Field
              name="packYears"
              component={ROTextField}
              id="packYears"
              fieldlabel="Pack Years"
              value={packYears?.toString()}
              disabled
              fullWidth={false}
            />
          </ChildSectionWrapper>
        )}
        <Divider sx={{ margin: '32px 0' }} />
        <RODatePicker
          id="dateOfSurgery"
          fieldlabel="Date of Surgery"
          disableFuture
          value={dateOfSurgery ? dayjs(dateOfSurgery) : null}
          onChange={(date: Dayjs | null, context: any) => {
            if (context.validationError) {
              setDateError(context.validationError);
              if (context.validationError === 'maxDate') {
                updateIntakeHandler({ dateOfSurgery: date ? date.format('YYYY-MM-DD').toString() : '' });
              }
            } else {
              const dateString = date ? date.format('YYYY-MM-DD').toString() : '';
              updateIntakeHandler({ dateOfSurgery: date ? dateString : '' });
            }
          }}
          onError={setDateError}
        />
        <Field
          name="extracapsularExtension"
          component={ROToggleButtons}
          id="extracapsularExtension"
          fieldlabel="Extracapsular Extension (ECE)"
          options={YES_NO_OPTIONS}
          value={extracapsularExtension}
          handleChange={(value: string): void => {
            updateIntakeHandler({ extracapsularExtension: value });
          }}
        />
        <Field
          name="margins"
          component={ROToggleButtons}
          id="margins"
          fieldlabel="Margins"
          options={INVOLVED_UNINVOLVED_OPTIONS}
          value={margins}
          handleChange={(value: string): void => {
            // Clear margins measurement if margins is not 'Involved'
            if (value !== 'Involved') {
              updateIntakeHandler({ margins: value, marginsMeasurement: null });
              return;
            }
            updateIntakeHandler({ margins: value });
          }}
        />
        {margins === 'Involved' && (
          <ChildSectionWrapper>
            <Field
              name="marginsMeasurement"
              component={ROTextField}
              id="marginsMeasurement"
              fieldlabel="Measurement"
              value={marginsMeasurement ? Number(marginsMeasurement).toString() : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                updateIntakeHandler({ marginsMeasurement: Number(event.target.value) });
              }}
              InputProps={{ endAdornment: <InputAdornment position="start">mm</InputAdornment> }}
              fullWidth={false}
            />
          </ChildSectionWrapper>
        )}
        <Field
          name="periNeuralInvasion"
          component={ROToggleButtons}
          id="periNeuralInvasion"
          fieldlabel="Peri Neural Invasion - Skin H&N"
          options={YES_NO_OPTIONS}
          value={periNeuralInvasion}
          handleChange={(value: string): void => {
            // Clear PNI type if 'No' selected
            if (value !== 'Yes') {
              updateIntakeHandler({ periNeuralInvasion: value, pniType: null });
              return;
            }
            updateIntakeHandler({ periNeuralInvasion: value });
          }}
        />
        {periNeuralInvasion === 'Yes' && (
          <ChildSectionWrapper>
            <Field
              name="pniType"
              component={ROAutocomplete}
              id="pniType"
              fieldlabel="PNI Type"
              placeholder={SEARCH_DROPDOWN_PLACEHOLDER}
              options={PNI_TYPE_OPTIONS}
              value={getOptionByValue(PNI_TYPE_OPTIONS, pniType || null)}
              onChange={(selectedOption: SelectOptionType): void => {
                updateIntakeHandler({ pniType: (selectedOption as SelectOptionType).value });
              }}
            />
          </ChildSectionWrapper>
        )}
        <Divider sx={{ margin: '32px 0' }} />
        <Field
          name="patientDiscussedAtMdt"
          component={ROToggleButtons}
          id="patientDiscussedAtMdt"
          fieldlabel="Patient discussed at MDT"
          options={YES_NO_OPTIONS}
          value={patientDiscussedAtMdt}
          handleChange={(value: string): void => {
            updateIntakeHandler({ patientDiscussedAtMdt: value });
          }}
        />
        <Field
          name="treatmentConsistentMdt"
          component={ROToggleButtons}
          id="treatmentConsistentMdt"
          fieldlabel="Treatment consistent with MDT recommendations"
          options={YES_NO_OPTIONS}
          value={treatmentConsistentMdt}
          handleChange={(value: string): void => {
            updateIntakeHandler({ treatmentConsistentMdt: value });
          }}
        />
      </>
    );
  }
  return <> </>;
};

export default ROPatientDiagnosisIntakeHNPage;
