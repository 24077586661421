// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import './NavigationDropDown.scss';

import NavigationOutsideAlerter from './NavigationOutsideAlerter';

import { Profile } from 'shared-components/images';
import { gql } from '@apollo/client';
import { theme } from 'theme';
import {
  NoEncryptionOutlined as NoEncryptionOutlinedIcon,
  ListAlt as ListAltIcon,
  ExitToApp as ExitToAppIcon,
  SmsOutlined as SmsOutlinedIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

interface Props extends RouteComponentProps, WithApolloClient<{}> {
  firstName: string;
  lastName: string;
}

interface State {
  openDropDown: boolean;
}

interface MenuLinkItem {
  title: string;
  url: string;
}

enum NavigationDropDownMenuType {
  CHANGE_PASSWORD = 'Change password',
  FEEDBACK = 'Feedback',
  TERMS_CONDITION = 'Terms and Conditions',
  LOGOUT = 'Log out',
}

const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      errors
    }
  }
`;

class NavigationDropDown extends Component<Props, State> {
  private menuLinks: MenuLinkItem[] = [
    { title: 'Change password', url: '/px/changePassword' },
    { title: 'Feedback', url: '/px/feedback' },
    { title: NavigationDropDownMenuType.TERMS_CONDITION, url: '/px/termsAndConditions' },
    { title: 'Log out', url: '/auth/logout' },
  ];

  public constructor(props: Props) {
    super(props);

    this.state = {
      openDropDown: false,
    };
  }

  public render(): JSX.Element {
    return (
      <div className="px-nav-drop-down-container">
        <Profile
          className={classNames('icon', { 'nav-drop-down-menu-open': this.state.openDropDown })}
          id="opx-nav-profile-menu"
          onClick={this.openDropDown}
        />
        {this.renderMenuList()}
      </div>
    );
  }

  private renderMenuList = (): JSX.Element => {
    if (this.state.openDropDown) {
      return (
        <Fragment>
          <NavigationOutsideAlerter clickOutside={this.closeDropDown}>
            <div className="nav-drop-down-menu">
              <div className="nav-drop-down-profile">
                <div className="nav-drop-down-profile-name">{this.renderPatientName()}</div>
                <div className="nav-drop-down-close" onClick={this.closeDropDown}>
                  <CloseIcon color="primary" className="icon" />
                </div>
              </div>
              <div>{this.generateMenuLinks()}</div>
            </div>
          </NavigationOutsideAlerter>
          <div
            className="nav-drop-down-mobile-overlay"
            onClick={() => {
              // Need this empty onclick for Safari to work correctly
            }}
          />
        </Fragment>
      );
    }

    return <Fragment />;
  };

  private renderPatientName = (): string => {
    const { firstName, lastName } = this.props;
    return `${firstName} ${lastName}`;
  };

  private logout = (): void => {
    this.props.client
      ?.mutate({
        mutation: LOGOUT_MUTATION,
      })
      .then(() => {
        this.props.history.push({ pathname: '/login' });
      });
  };

  private generateMenuLinks = (): JSX.Element[] => {
    return this.menuLinks.map((menuLink: MenuLinkItem, index: number) => {
      return (
        <div
          key={index}
          id={`menu-title-${menuLink.title.replace(/\s+/g, '-').toLowerCase()}`}
          className="nav-drop-down-link"
          onClick={() => {
            if (menuLink.title === 'Feedback') {
              // eslint-disable-next-line no-restricted-globals
              this.props.history.push({ pathname: menuLink.url, state: { from: location.pathname } });
            } else if (menuLink.title === NavigationDropDownMenuType.TERMS_CONDITION) {
              window.open(menuLink.url);
            } else {
              if (menuLink.title === 'Log out') {
                this.logout();
              } else {
                // eslint-disable-next-line no-restricted-globals
                this.props.history.push({ pathname: menuLink.url, state: { from: location.pathname } });
              }
            }
          }}>
          {this.selectIcon(menuLink.title)}
          <span className="nav-drop-down-link-text">{menuLink.title}</span>
        </div>
      );
    });
  };

  private selectIcon = (menuItem: string): JSX.Element => {
    switch (menuItem) {
      case NavigationDropDownMenuType.CHANGE_PASSWORD:
        return <NoEncryptionOutlinedIcon className="icon" htmlColor={theme.palette.grey[600]} />;
      case NavigationDropDownMenuType.FEEDBACK:
        return <SmsOutlinedIcon htmlColor={theme.palette.grey[600]} className="icon" />;
      case NavigationDropDownMenuType.TERMS_CONDITION:
        return <ListAltIcon htmlColor={theme.palette.grey[600]} className="icon" />;
      default:
        return <ExitToAppIcon htmlColor={theme.palette.grey[600]} className="icon" />;
    }
  };

  private openDropDown = (): void => {
    this.setState({ openDropDown: true });
  };

  private closeDropDown = (): void => {
    this.setState({ openDropDown: false });
  };
}

const routedComponent = withRouter(withApollo<Props>(NavigationDropDown));
export default routedComponent;
