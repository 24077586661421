// eslint-disable-next-line no-use-before-define
import { useQuery } from '@apollo/client';
import { Field, FieldAttributes, FieldHookConfig, useField } from 'formik';
import React, { useMemo, useEffect, useState } from 'react';
import { FormRow } from 'shared-components/components';
import { TextAreaField } from 'shared-components/components/FormikComponents';
import { addressLabelForRegion, addressPlaceholdersForRegion } from 'shared-components/enums';
import { Address, AddressLabel, ListData, SelectOptions } from 'shared-components/interfaces';
import SelectField from '../SelectField';
import AddressAutocompleteField from './AddressAutocompleteField';
import { AUSTRALIA_STATES_LIST, COUNTRY_LIST } from './AddressQueries';
import { Logger } from 'shared-components/utils';
import CircularProgress from '@mui/material/CircularProgress';

// Need to redefine this here as mapListData is an op-utils and shared components are for op and px
const mapListData = (listData: ListData[]): SelectOptions[] =>
  listData?.map((data: ListData) => ({ label: data.name, value: data.name }));

interface AddressFieldIProps extends FieldAttributes<any> {
  name: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  updateField?: (field: string, value: string) => void;
}

interface AddressSectionFieldIProps {
  values: Address;
  updateAddress?: (address: Address) => void;
  includeMandatoryLabels?: boolean;
  locationBiasing?: boolean;
}

const AddressField = (props: AddressFieldIProps): JSX.Element => {
  const { label, name, updateField, required = false, placeholder = '', ...otherProps } = props;
  return (
    <FormRow fieldLabel={`${label}${required ? '*' : ''}`} fieldName={name} labelClass={'label-form-row'}>
      <Field
        {...otherProps}
        placeholder={placeholder || `Enter ${label.toLowerCase()}`}
        htmlFor={name}
        name={name}
        component={TextAreaField}
        updateMutation={(value: string) => {
          updateField && updateField(name, value);
        }}
        alternateStyle
      />
    </FormRow>
  );
};

const AddressSelect = (props: AddressFieldIProps): JSX.Element => {
  const { label, name, options, updateField, required = false, placeholder = '', ...otherProps } = props;
  return (
    <FormRow fieldLabel={`${label}${required ? '*' : ''}`} fieldName={name} labelClass={'label-form-row'}>
      <Field
        {...otherProps}
        placeholder={placeholder || `Enter ${label.toLowerCase()}`}
        htmlFor={name}
        name={name}
        fullWidth
        options={options}
        component={SelectField}
        updateMutation={updateField}
      />
    </FormRow>
  );
};

const AddressSectionField = (props: AddressSectionFieldIProps & FieldHookConfig<string>): JSX.Element => {
  const { name, values, updateAddress, includeMandatoryLabels, locationBiasing = true } = props;
  const labels: AddressLabel = addressLabelForRegion();
  const placeholders: AddressLabel = addressPlaceholdersForRegion();
  const countriesToClearState = ['Australia'];

  const { data: australianStatesData, loading: australianStatesDataIsLoading } = useQuery(AUSTRALIA_STATES_LIST);
  const australianStates = useMemo(() => australianStatesData?.data, [australianStatesData?.data]);

  const { data: countriesData, loading: countriesDataIsLoading } = useQuery(COUNTRY_LIST);
  const countries = useMemo(() => countriesData?.data, [countriesData?.data]);

  // const disableNonAutocompleteFields = useMemo(() => (!values.line1 ? true : false), [values.line1]);
  const disableNonAutocompleteFields = false;

  const [, , stateHelpers] = useField(`${name}.state`);
  const [, , countryHelpers] = useField(`${name}.country`);

  const [googleLoaded, setGoogleLoaded] = useState(false);
  const logger = new Logger('AddressSectionField');

  // sentry issue OP-PROD-2E6 - can't find variable: google
  // wait for google API to load before enabling Address lookup field
  // check every 2 seconds, 3 times, then fail and resort to manual address input
  useEffect(() => {
    if (typeof google === 'undefined') {
      let count = 0;

      const i_id = setInterval(() => {
        count++;
        logger.info(`waiting for google API ${count * 2} seconds`);
        if (typeof google !== 'undefined') {
          // Successfully loaded google api
          setGoogleLoaded(true);
          clearInterval(i_id);
        }
        if (count >= 3) {
          logger.info('Failed to load google api');
          setGoogleLoaded(false);
          clearInterval(i_id);
        }
      }, 2000);
    } else {
      setGoogleLoaded(true);
    }
  }, []);

  const handleUpdateIndividualFieldAddress = (field: string, value: string) => {
    const modifiedField = field.split('.').pop() as string;
    let currentAddressValues = {
      ...values,
      [modifiedField]: value,
    };

    if (modifiedField === 'country') {
      if (countriesToClearState.includes(value)) {
        currentAddressValues = {
          ...currentAddressValues,
          state: '',
        };
      }
    }

    updateAddress && updateAddress(currentAddressValues);
  };

  const handleUpdateAddress = (address: Address): void => {
    updateAddress && updateAddress(address);
  };

  if (australianStatesDataIsLoading || countriesDataIsLoading) return <CircularProgress color="primary" />;

  if (!countriesData) return <></>;

  return (
    <>
      {/* Line 1 with autocomplete if google is defined */}
      {googleLoaded ? (
        <FormRow
          fieldLabel={`${labels.line1}${includeMandatoryLabels ? '*' : ''}`}
          fieldName={`${name}.line1`}
          labelClass={'label-form-row'}>
          <AddressAutocompleteField
            name={name}
            placeholder={placeholders.line1}
            value={values.line1}
            updateAutoFilledAddress={handleUpdateAddress}
            locationBiasing={locationBiasing}
            updateAddressField={(value: string) => handleUpdateIndividualFieldAddress(`${name}.line1`, value)}
          />
        </FormRow>
      ) : (
        <AddressField
          name={`${name}.line1`}
          label={labels.line1}
          placeholder="Address line 1"
          updateField={handleUpdateIndividualFieldAddress}
          disabled={disableNonAutocompleteFields}
        />
      )}

      {/* Line 2 */}
      <AddressField
        name={`${name}.line2`}
        label={labels.line2}
        placeholder={placeholders.line2}
        updateField={handleUpdateIndividualFieldAddress}
        disabled={disableNonAutocompleteFields}
      />

      {/* City */}
      <AddressField
        name={`${name}.city`}
        label={labels.city}
        placeholder={placeholders.city}
        required={includeMandatoryLabels}
        updateField={handleUpdateIndividualFieldAddress}
        disabled={disableNonAutocompleteFields}
      />

      {/* State */}
      {countriesToClearState.includes(values?.country) ? (
        <AddressSelect
          name={`${name}.state`}
          label={labels.state}
          placeholder={placeholders.state}
          required={includeMandatoryLabels}
          disabled={disableNonAutocompleteFields}
          options={mapListData(australianStates)}
          updateField={(value: string | null) => {
            stateHelpers.setValue(value);
            handleUpdateIndividualFieldAddress(`${name}.state`, value || '');
          }}
        />
      ) : (
        <AddressField
          name={`${name}.state`}
          label={labels.state}
          placeholder={placeholders.state}
          required={includeMandatoryLabels}
          updateField={handleUpdateIndividualFieldAddress}
          disabled={disableNonAutocompleteFields}
        />
      )}

      {/* Postcode */}
      <AddressField
        name={`${name}.postcode`}
        label={labels.postcode}
        placeholder={placeholders.postcode}
        required={includeMandatoryLabels}
        updateField={handleUpdateIndividualFieldAddress}
        disabled={disableNonAutocompleteFields}
      />

      {/* Country */}
      <AddressSelect
        id={`${name}.country`}
        name={`${name}.country`}
        label={labels.country}
        placeholder={placeholders.country}
        required={includeMandatoryLabels}
        disabled={disableNonAutocompleteFields}
        options={mapListData(countries)}
        updateField={(value: string | null) => {
          countryHelpers.setValue(value);
          handleUpdateIndividualFieldAddress(`${name}.country`, value || '');
        }}
      />
    </>
  );
};

export default AddressSectionField;
