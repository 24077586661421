// eslint-disable-next-line no-use-before-define
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { styled } from '@mui/system';
import {
  BaseAutocomplete,
  BaseSelect,
  ROConstraintField,
  ROTwoValueTextField,
} from 'shared-components/components/FormFields';
import { useMutation, useQuery } from '@apollo/client';
import { decimalFormatter } from 'op-utils';
import {
  ADD_OAR,
  OAR_QUERY,
  UPDATE_OAR,
  RESET_OAR,
  GET_ORGAN_LIST,
  ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION,
} from './Queries';
import {
  SITE_GROUP_TEMPLATE_VALUE_VARIATION,
  SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
  GET_CAREPLAN,
} from '../../Queries';
import { ConstraintData } from '../Interface';
import { validateField } from 'op-pages/RO/Careplan/ValidationEngine';
import { TenantContext } from 'op-contexts';
import { StandardDialog } from 'shared-components/components';
import { Button, Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import { SelectOptionType } from 'shared-components/components/FormFields';

const Table = styled('table')`
  width: 100%;
  border-collapse: separate !important;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-top: 24px;
`;

const TableHeader = styled('th')`
  padding: 16px;
  text-align: start;
  background: ${(props) => props.theme.palette.grey[100]};
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  font-weight: normal;
`;

const TableBodyData = styled('td')`
  padding: 16px;
  line-height: 20px;
`;

const LESS_THAN_OR_EQUAL = '≤';
export interface OrganAtRiskConstraintInputType {
  firstValue: string | null;
  firstValueUnit: string | null;
  operator: string;
  secondValue: string | null;
  secondValueUnit: string | null;
}

let pressedContinue = false;
const isFieldValid = (field: any) => {
  return pressedContinue ? validateField(field) : true;
};

const sanitizeConstraintData = (constraint: any): ConstraintData => {
  return {
    ...constraint,
    firstValue: parseFloat(constraint.firstValue.toString()),
    secondValue: constraint.secondValue.map((value: any) => parseFloat(value.toString())),
  };
};

// TODO Added this interface to remove tslint warnings, some of unclear type
interface oarPopUpProps {
  isVisible: boolean;
  hidePopup: () => void;
  popup: () => void;
  siteGroupId: number;
  careplanId: number;
  organsInUse: any; //type not immediately clear for this one
  isEdit?: boolean;
  reload?: () => void;
  index?: number;
  data?: any; //type not immediately clear for this one
}

const OrgansAtRiskPopup = (props: oarPopUpProps): JSX.Element => {
  const theme = useTheme();
  const [, updateState] = useState();
  // @ts-ignore allow empty object
  const forceUpdate = useCallback(() => updateState({}), []);
  const { doseUnit } = useContext(TenantContext);
  useEffect(() => {
    pressedContinue = false;
    forceUpdate();
  }, []);
  const stateFromData = (data: any): any => ({
    siteGroupId: data.siteGroupId,
    index: props.index,
    organ: data.organ,
    volumeData:
      data.volumeConstraintData && data.volumeConstraintData.length
        ? data.volumeConstraintData.map((item: any): any => ({
            firstValue: decimalFormatter(item.firstValue, 4),
            firstValueUnit: item.firstValueUnit,
            operator: item.operator,
            secondValue: item.secondValue,
            secondValueUnit: item.secondValueUnit,
            isVolumeData: true,
          }))
        : [
            {
              firstValue: '',
              firstValueUnit: doseUnit,
              operator: LESS_THAN_OR_EQUAL,
              secondValue: [],
              secondValueUnit: '%',
            },
          ],
    dData:
      data.dConstraintData && data.dConstraintData.length
        ? data.dConstraintData.map((item: any): any => ({
            dosetoPrefixOperator: item.dosetoPrefixOperator || null,
            firstValue: decimalFormatter(item.firstValue, 4),
            firstValueUnit: item.firstValueUnit,
            operator: item.operator,
            secondValue: item.secondValue,
            secondValueUnit: item.secondValueUnit,
            isVolumeData: false,
          }))
        : [
            {
              dosetoPrefixOperator: '',
              firstValue: '',
              firstValueUnit: '%',
              operator: LESS_THAN_OR_EQUAL,
              secondValue: [],
              secondValueUnit: doseUnit,
            },
          ],
    maxValue: data.maxValue || [],
    maxValueUnit: data.maxValueUnit !== null ? data.maxValueUnit : doseUnit,
    meanValueUnit: data.meanValueUnit !== null ? data.meanValueUnit : doseUnit,
    meanValue: data.meanValue || [],
    validated: true,
    modalShow: true,
    alara: data.alara,
    isVisible: true,
    isEdit: true,
  });
  const defaultState = {
    siteGroupId: props.data && Object.keys(props.data).length && props.data.id ? props.data.id : props.siteGroupId,
    index: props.index,
    organ: '',
    maxValue: [''],
    meanValue: [''],
    maxValueUnit: doseUnit,
    meanValueUnit: doseUnit,
    volumeData: [
      {
        firstValue: '',
        firstValueUnit: doseUnit,
        operator: LESS_THAN_OR_EQUAL,
        secondValue: [],
        secondValueUnit: '%',
      },
    ],
    dData: [
      {
        firstValue: '',
        firstValueUnit: '%',
        operator: LESS_THAN_OR_EQUAL,
        secondValue: [],
        secondValueUnit: doseUnit,
      },
    ],
    validated: false,
    modalShow: true,
    alara: false,
    isVisible: true,
    isEdit: false,
  };
  const [state, setState] = useState(props?.data ? stateFromData(props.data) : defaultState);

  const [validated] = useState(false);
  const [location] = useState('');
  const [addOAR] = useMutation(ADD_OAR, {
    refetchQueries: [
      { query: OAR_QUERY, variables: { siteGroupId: props.siteGroupId } },
      { query: ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION, variables: { siteGroupId: props.siteGroupId } },
      {
        query: SITE_GROUP_TEMPLATE_VALUE_VARIATION,
        variables: { siteGroupId: props.siteGroupId },
      },

      {
        query: SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
        variables: { siteGroupId: props.siteGroupId, volumingPage: true },
      },
      { query: GET_CAREPLAN, variables: { id: props.careplanId } },
    ],
  });
  const [resetOAR] = useMutation(RESET_OAR, {
    refetchQueries: [
      { query: OAR_QUERY, variables: { siteGroupId: props.siteGroupId } },
      { query: ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION, variables: { siteGroupId: props.siteGroupId } },
      {
        query: SITE_GROUP_TEMPLATE_VALUE_VARIATION,
        variables: { siteGroupId: props.siteGroupId },
      },
      { query: GET_CAREPLAN, variables: { id: props.careplanId } },
      {
        query: SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
        variables: { siteGroupId: props.siteGroupId, volumingPage: true },
      },
    ],
    onCompleted: (): void => {
      props.reload && props.reload(); // update to remove eslint warnings, was always called previously even if undefined
    },
  });

  useEffect(() => {
    if (props.data) {
      setState({ state, ...stateFromData(props.data) });
    }
  }, [props.data]);

  const [updateOAR] = useMutation(UPDATE_OAR, {
    refetchQueries: [
      { query: OAR_QUERY, variables: { siteGroupId: props.siteGroupId } },
      { query: ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION, variables: { siteGroupId: props.siteGroupId } },
      {
        query: SITE_GROUP_TEMPLATE_VALUE_VARIATION,
        variables: { siteGroupId: props.siteGroupId },
      },
      { query: GET_CAREPLAN, variables: { id: props.careplanId } },
      {
        query: SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
        variables: { siteGroupId: props.siteGroupId, volumingPage: true },
      },
    ],
  });
  const { loading, data } = useQuery(GET_ORGAN_LIST);
  if (loading) return <div>Loading....</div>;
  const organList = data.oarOrganList.map((item: any): { label: string; value: string } => ({
    label: item.organ,
    value: item.organ,
  }));

  const handleModalClose = (): void => {
    props.hidePopup();
    return setState({ ...state, isVisible: false });
  };

  const onAddOAR = (): void => {
    if (!Array.isArray(state.meanValue) || (state.meanValue.length === 1 && state.meanValue[0] === ''))
      state.meanValue = null;
    if (!Array.isArray(state.maxValue) || (state.maxValue.length === 1 && state.maxValue[0] === ''))
      state.maxValue = null;
    state.volumeData = state.volumeData.filter(
      (vd: any): boolean => vd.firstValue || (vd.secondValue && vd.secondValue.length),
    );
    state.dData = state.dData.filter((dd: any): boolean => dd.firstValue || (dd.secondValue && dd.secondValue.length));
    addOAR({
      variables: {
        siteGroupId: props.siteGroupId,
        organ: state.organ,
        volumeConstraintData: state.volumeData.map((value: any): ConstraintData => sanitizeConstraintData(value)),
        dConstraintData: state.dData.map((value: any): ConstraintData => sanitizeConstraintData(value)),
        maxValue: parseFloat(state.maxValue) || null,
        maxValueUnit: state.maxValueUnit || doseUnit,
        meanValue: parseFloat(state.meanValue) || null,
        meanValueUnit: state.meanValueUnit || doseUnit,
        alara: state.alara,
      },
    });
    props.hidePopup();
  };

  const onEditOAR = (): void => {
    if (!Array.isArray(state.meanValue) || (state.meanValue.length <= 1 && state.meanValue[0] === ''))
      state.meanValue = null;
    if (!Array.isArray(state.maxValue) || (state.maxValue.length <= 1 && state.maxValue[0] === ''))
      state.maxValue = null;
    state.volumeData = state.volumeData.filter(
      (vd: any): boolean => vd.firstValue || (vd.secondValue && vd.secondValue.length),
    );
    state.dData = state.dData.filter((dd: any): boolean => dd.firstValue || (dd.secondValue && dd.secondValue.length));
    updateOAR({
      variables: {
        siteGroupId: props.siteGroupId,
        index: props.index,
        organ: state.organ,
        volumeConstraintData: state.volumeData.map((value: any): ConstraintData => sanitizeConstraintData(value)),
        dConstraintData: state.dData.map((value: any): ConstraintData => sanitizeConstraintData(value)),
        maxValue: parseFloat(state.maxValue) || null,
        maxValueUnit: state.maxValueUnit || null,
        meanValue: parseFloat(state.meanValue) || null,
        meanValueUnit: state.meanValueUnit || null,
        alara: state.alara,
      },
    });
    props.hidePopup();
  };

  const addConstraintVolumeData = (): void => {
    const volumeData = state.volumeData;
    volumeData.push({
      firstValue: null,
      firstValueUnit: doseUnit,
      operator: LESS_THAN_OR_EQUAL,
      secondValue: null,
      secondValueUnit: '%',
    });
    setState({ ...state, volumeData: volumeData });
  };

  const addConstraintDData = (): void => {
    const dData = state.dData;
    dData.push({
      dosetoPrefixOperator: '',
      firstValue: null,
      firstValueUnit: '%',
      operator: LESS_THAN_OR_EQUAL,
      secondValue: null,
      secondValueUnit: doseUnit,
    });
    setState({ ...state, dData });
  };

  const removeConstraintVolumeData = (index: any): void => {
    const volumeData = state.volumeData;
    volumeData.splice(index, 1);
    setState({ ...state, volumeData: volumeData });
  };

  const removeConstraintdData = (index: any): void => {
    const dData = state.dData;
    dData.splice(index, 1);
    setState({ ...state, dData: dData });
  };

  const getConstraintPreviewData = (): string[] => {
    const transformVolumeData = (data: any, prefix: string) => {
      const dataArr: string[] = [];
      data.forEach((item: any) => {
        if (item.firstValue && item.secondValue && item.secondValue[0]) {
          const constraintString = `${item?.dosetoPrefixOperator ? item?.dosetoPrefixOperator : prefix}${
            item.firstValue
          }${item.firstValueUnit} ${item.operator} ${item.secondValue.join('/')}${item.secondValueUnit}`;
          dataArr.push(constraintString);
        }
      });
      return dataArr;
    };

    const transformMeanMaxData = (prefix: string) => {
      const type = prefix.toLowerCase();
      if (state[`${type}Value`] && state[`${type}Value`][0]) {
        return [`${prefix} ≤ ${state[`${type}Value`][0]} ${state[`${type}ValueUnit`]}`];
      }
      return [];
    };
    const vData = transformVolumeData(state.volumeData, 'V');
    const dData = transformVolumeData(state.dData, 'D');
    const meanData = transformMeanMaxData('Mean');
    const maxData = transformMeanMaxData('Max');

    return [...vData, ...dData, ...meanData, ...maxData];
  };

  const cleanedOptions = props.organsInUse.map((item: any): any => {
    if (item.toLowerCase() !== `${state.organ.toLowerCase()}`) return item.toLowerCase();
    return undefined;
  });
  const unusedOrganList = organList.filter((item: any): boolean => !cleanedOptions.includes(item.value.toLowerCase()));

  const constraintPreviewData = getConstraintPreviewData();
  const notSpecified = 'Not specified';

  const valueUnitOptions = [
    { value: doseUnit, label: doseUnit },
    { value: '%', label: '%' },
  ];
  // free text organ is allowed so if the organ doesn't exist in the list then it's a free text organ
  const organ =
    state.organ &&
    (organList.find((value: any): boolean => value.value.toLowerCase() === state.organ.toLowerCase()) || state.organ);
  const ModalBody = (
    <div id="oar-modal">
      <div className="organ-select">
        <Stack direction="row">
          <Typography sx={{ width: '200px' }} variant="subtitle1">
            Organ*
          </Typography>
          <BaseAutocomplete
            id="organName"
            options={unusedOrganList}
            value={organ}
            getOptionLabel={(option: SelectOptionType | string): string => {
              if (typeof option === 'string') {
                return option;
              }
              if (option?.label.startsWith('Use "')) {
                return option.label.slice(5, -1);
              }
              return option.label;
            }}
            filterOptions={(options: any, params: any): any => {
              const { inputValue } = params;
              const filtered = options.filter((option: any): boolean => {
                if (!params.inputValue) return true;
                return option.value.toLowerCase().includes(params.inputValue.toLowerCase());
              });
              if (filtered.length === 0) {
                filtered.push({ label: `Use "${inputValue}"`, value: inputValue });
              }
              return filtered;
            }}
            onChange={(option: SelectOptionType | string): void => {
              const value = typeof option === 'string' ? option : option?.value;
              if (value.startsWith('Use "')) {
                setState({ ...state, organ: value.slice(5, -1) });
                return;
              }
              setState({
                ...state,
                organ: value || '',
              });
            }}
            inputProps={{
              error: !isFieldValid(state.organ),
            }}
            sx={{ width: '506px' }}
            freeSolo
          />
        </Stack>
      </div>
      <Divider sx={{ margin: '12px 0' }} />
      <ROConstraintField
        id="volumeData"
        label="Volume"
        value={state.volumeData}
        onChange={(index: number, value: any): void => {
          state.volumeData[index] = value || null;
          setState({ ...state });
        }}
        validated={validated}
        invalid={!location}
        readOnly={state.alara}
        addConstraint={addConstraintVolumeData}
        removeConstraint={removeConstraintVolumeData}
        isVolumeData={true}
      />
      <Divider sx={{ margin: '12px 0' }} />
      <ROConstraintField
        id="dData"
        label="D"
        value={state.dData}
        onChange={(index: number, value: any): void => {
          state.dData[index] = value || null;
          setState({ ...state });
        }}
        validated={validated}
        invalid={!location}
        readOnly={state.alara}
        addConstraint={addConstraintDData}
        removeConstraint={removeConstraintdData}
        isVolumeData={false}
      />
      <Divider sx={{ margin: '12px 0' }} />
      <Stack direction="row" gap={1} alignItems="center">
        <Typography sx={{ width: '172px' }} variant="subtitle1">
          Max
        </Typography>
        <Typography sx={{ width: '10px' }} variant="body1">
          ≤
        </Typography>
        <ROTwoValueTextField
          id="oar-max"
          value={state.maxValue}
          readOnly={state.alara}
          onChange={(value: any[]): void => {
            setState({ ...state, maxValue: value });
          }}
        />
        <BaseSelect
          id="maxValueUnit"
          options={valueUnitOptions}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            const value = event?.target?.value;
            if (!state.alara) setState({ ...state, maxValueUnit: value });
          }}
          value={state.maxValueUnit}
          disabled={state.alara}
          sx={{ width: '120px' }}
        />
      </Stack>
      <Divider sx={{ margin: '12px 0' }} />
      <Stack direction="row" gap={1} alignItems="center">
        <Typography sx={{ width: '172px' }} variant="subtitle1">
          Mean
        </Typography>
        <Typography sx={{ width: '10px' }} variant="body1">
          ≤
        </Typography>
        <ROTwoValueTextField
          id="oar-mean"
          value={state.meanValue}
          readOnly={state.alara}
          onChange={(value: any[]): void => {
            setState({ ...state, meanValue: value });
          }}
        />
        <BaseSelect
          id="meanValueUnit"
          options={valueUnitOptions}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            const value = event?.target?.value;
            if (!state.alara) setState({ ...state, meanValueUnit: value });
          }}
          value={state.meanValueUnit}
          disabled={state.alara}
          sx={{ width: '120px' }}
        />
      </Stack>
      <Divider sx={{ margin: '12px 0' }} />
      <Grid container>
        <Grid item xs={7}>
          <Stack direction="row" alignItems="center" height="40px">
            <Typography sx={{ width: '200px' }} variant="subtitle1">
              ALARA
            </Typography>
            <Button
              id="alara"
              sx={{
                color: state.alara ? theme.palette.primary.main : theme.palette.grey[600],
                borderColor: state.alara ? theme.palette.primary.main : theme.palette.grey[600],
                borderRadius: '8px',
                backgroundColour: 'white',
                border: '2px solid',
                outlineColor: theme.palette.grey[300],
                width: '152px',
              }}
              onClick={(): void => {
                setState({ ...defaultState, organ: state.organ, alara: !state.alara });
              }}>
              ALARA
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Divider sx={{ margin: '12px 0' }} />
      <Table data-test-id="organs-at-risk-popup-table">
        <thead>
          <tr>
            <TableHeader>
              <Typography variant="subtitle1">Organ (Preview)</Typography>
            </TableHeader>
            <TableHeader>
              <Typography variant="subtitle1">Constraint (Preview)</Typography>
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableBodyData>{state.organ || notSpecified}</TableBodyData>
            <TableBodyData>
              {(constraintPreviewData.length &&
                constraintPreviewData.map((item: string) => <div key={item}>{item}</div>)) ||
                notSpecified}
            </TableBodyData>
          </tr>
        </tbody>
      </Table>
    </div>
  );
  return (
    <StandardDialog
      open={props.isVisible}
      onClose={handleModalClose}
      maxWidth="md"
      fullWidth
      title={`${props.isEdit ? 'Edit' : 'Add'} Organ At Risk Constraint`}
      onSubmit={(): void => {
        pressedContinue = true;
        forceUpdate();
        if (!isFieldValid(state.organ)) return;
        props.isEdit ? onEditOAR() : onAddOAR();
      }}
      submitText="Confirm"
      onSecondarySubmit={(): void => {
        if (props.data) {
          setState({ ...stateFromData(props.data) });
          resetOAR({
            variables: {
              siteGroupId: props.siteGroupId,
              organ: state.organ,
            },
          });
        } else {
          setState({ ...defaultState, organ: state.organ });
        }
      }}
      secondarySubmitText="Reset Values">
      {ModalBody}
    </StandardDialog>
  );
};

export default OrgansAtRiskPopup;
