import { Region } from 'shared-components/enums';
import { BaseConfigInterface } from './AppConfig/types';
import auConfig from './AppConfig/auConfig';
import usConfig from './AppConfig/usConfig';
import demoConfig from './AppConfig/demoConfig';
import ukConfig from './AppConfig/ukConfig';
import anzConfig from './AppConfig/anzConfig';

interface AppConfigInterface {
  [region: string]: BaseConfigInterface;
}

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

export const AppConfig: AppConfigInterface = {
  [Region.AU]: auConfig,
  [Region.UK]: ukConfig,
  [Region.US]: usConfig,
  [Region.DEMO]: demoConfig,
  [Region.ANZ]: anzConfig,
};

export const CurrentAppConfig: BaseConfigInterface = AppConfig[REACT_APP_REGION || Region.AU];
