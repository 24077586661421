// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApolloSaveStatus } from 'op-types';
import { Query } from '@apollo/client/react/components';
import { LoadingSpinner } from 'shared-components/components';
import { ListData } from 'shared-components/interfaces';
import withHealthAssessment, { WithHealthAssessment } from '../../HealthAssessment';

import HASocialAssessment from './HASocialAssessment';
import { HASocialAssessmentItem } from 'op-classes';

// eslint-disable-next-line
const HEALTH_ASSESMENT_QUERY = gql`
  query HealthAssessment($id: ID!) {
    healthAssessment(id: $id) {
      id
      patient {
        id
        fullName
        dob
      }
      alcoholBool
      alcoholFrequency
      smokingBool
      smokingHistory
      smokingFrequency
      smokingStopRawMonth
      smokingStopRawYear
      exerciseBool
      exerciseFrequency
      accommodationAssistance
      accommodationAssistanceReason
      transportationAssistance
      transportationAssistanceReason
    }
    alcoholFrequencyRefData: listData(category: "alcohol_frequency") {
      id
      name
    }
    smokingFrequencyRefData: listData(category: "smoking_frequency") {
      id
      name
    }
    exerciseFrequencyRefData: listData(category: "exercise_frequency") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
`;

interface HealthAssessmentQueryData {
  healthAssessment: {
    id: string;
    patient: {
      fullName: string;
      dob: string;
    };
    alcoholBool: boolean;
    alcoholFrequency: string;
    smokingBool: boolean;
    smokingHistory: string;
    smokingFrequency: string;
    smokingStopRawMonth: string;
    smokingStopRawYear: string;
    exerciseBool: boolean;
    exerciseFrequency: string;
    accommodationAssistance: boolean;
    accommodationAssistanceReason: string;
    transportationAssistance: boolean;
    transportationAssistanceReason: string;
  };
  alcoholFrequencyRefData: ListData[];
  smokingFrequencyRefData: ListData[];
  exerciseFrequencyRefData: ListData[];
  user: {
    id: string;
    isPso: boolean;
  };
}

interface Props extends WithHealthAssessment, RouteComponentProps<{ patientId: string; haId: string }> {
  client: any;
  updateSaveStatus: ApolloSaveStatus;
  validateOnLoad: boolean;
}

class HASocialAssessmentApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
      validateOnLoad,
    } = this.props;
    return (
      <Fragment>
        <Query<HealthAssessmentQueryData> query={HEALTH_ASSESMENT_QUERY} variables={{ id: patientId }}>
          {({ loading, data }): JSX.Element => {
            if (loading) {
              return <LoadingSpinner relativeSpinner={true} />;
            }

            if (data && data.healthAssessment) {
              const haSocialAssessment = new HASocialAssessmentItem();
              haSocialAssessment.loadData(data.healthAssessment);
              return (
                <HASocialAssessment
                  patientId={patientId}
                  autosave={this.autosave}
                  haSocialAssessment={haSocialAssessment}
                  validateOnLoad={validateOnLoad}
                  alcoholFrequencyRefData={data.alcoholFrequencyRefData}
                  smokingFrequencyRefData={data.smokingFrequencyRefData}
                  exerciseFrequencyRefData={data.exerciseFrequencyRefData}
                  isPso={data.user && data.user.isPso}
                />
              );
            }
            return <div>Error!</div>;
          }}
        </Query>
      </Fragment>
    );
  }
  private autosave = (updateObject: object): Promise<void> => {
    const { mutateGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Mutate the graph
    return mutateGraph(
      client,
      updateObject,
      () => {
        getSaveStatus(client).then((savingStatus) => {
          updateSaveStatus(savingStatus);
        });
      },
      () => {},
      () => {},
      async () => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);
      },
    );
  };
}

const routedComponent = withRouter(HASocialAssessmentApollo);
const haComponent = withHealthAssessment(routedComponent);
export default haComponent;
