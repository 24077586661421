// eslint-disable-next-line no-use-before-define
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { ROSidePanel } from 'op-components';
import { GET_DIAGNOSIS, CLINEX_DIAGNOSIS_DATA } from './Queries';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useWindowSize } from 'shared-components/utils/CustomHooks';

import './DiagnosisSidePanel.scss';
import DocumentViewer from './Clinex/DocumentViewer';
import { ClinexContext } from './Clinex/context';

const ROPatientDiagnosisSidePanel = (): JSX.Element | null => {
  const { pathname } = useLocation();
  const [clinexLoading, setClinexLoading] = useState(true);
  const params = pathname.split('/');
  const diagnosisId = params[7] || 'create';
  const pagesClinexShows = ['intake', 'tnmstaging'];
  const diagnosisSelection = !params.some((el: string): boolean => pagesClinexShows.includes(el));
  const {
    setClinexData,
    setClinexDocuments,
    setUuid,
    clinexDocuments,
    highlights,
    showClinexDocument,
    setShowClinexDocument,
    intakeFieldsLoaded,
  } = useContext(ClinexContext);
  const { data: clinexData, refetch: refetchClinex } = useQuery(CLINEX_DIAGNOSIS_DATA, {
    variables: { diagnosisId },
    skip: !diagnosisId || diagnosisId === 'create' || diagnosisSelection,
    fetchPolicy: 'network-only',
  });
  const {
    loading,
    data,
    refetch: refetchDiagnosis,
  } = useQuery(GET_DIAGNOSIS, {
    variables: { diagnosisId },
    skip: !diagnosisId || diagnosisId === 'create',
  });

  const clinexPayload = clinexData?.clinexDiagnosisDataFull?.diagnosisData;
  const parsedClinex = clinexPayload && JSON.parse(clinexPayload);
  const windowSize = useWindowSize();
  const isSmallDevice = windowSize.width < 1900;

  useEffect(() => {
    if (diagnosisId && diagnosisId !== 'create') {
      refetchDiagnosis().then(({ data }) => {
        if (data?.diagnosis?.isPrimaryDiagnosis === false && data.diagnosis.relatedPrimaryDiagnosis?.id) {
          refetchClinex({ diagnosisId: data.diagnosis.relatedPrimaryDiagnosis.id });
        }
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (parsedClinex?.response_uuid) {
      setUuid(parsedClinex.response_uuid);
    }
    if (parsedClinex?.data) {
      // Array of all fields that are not null or 'tumour_stream'
      const filteredParsed = Object.keys(parsedClinex.data).filter(
        (key) => parsedClinex.data[key] && key !== 'tumour_stream',
      );
      // Reduce array of non-null fields to object of field and value
      const filteredReduced =
        filteredParsed.length &&
        filteredParsed.reduce((dataObject: any, field: string): any => {
          dataObject = { ...dataObject, [field]: parsedClinex.data[field] };
          return dataObject;
        }, {});

      if (filteredReduced && parsedClinex.documents) {
        setClinexData(filteredReduced);
        setClinexDocuments(parsedClinex.documents);
        setClinexLoading(false);
        if (pathname.includes('intake') && !intakeFieldsLoaded) {
          setShowClinexDocument(false);
        } else setShowClinexDocument(true);
      }
    } else {
      setShowClinexDocument(false);
      setClinexData();
    }
  }, [clinexData, pathname, intakeFieldsLoaded]);

  if (loading || !data) return <Fragment />;

  return (
    <div
      className={classnames('side-panel-diag', {
        'hide-side-panel-diag': isSmallDevice,
        clinex: showClinexDocument,
      })}>
      <ROSidePanel isClinex={showClinexDocument && !diagnosisSelection}>
        {!diagnosisSelection && showClinexDocument && (
          <DocumentViewer documents={clinexDocuments} highlights={highlights} loading={clinexLoading} />
        )}
      </ROSidePanel>
    </div>
  );
};

export default ROPatientDiagnosisSidePanel;
