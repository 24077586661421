import React from 'react';
import { Stack } from '@mui/material';
import { ROHelperText } from 'shared-components/components/FormFields';

export const POSITIVE_NEGATIVE_UNKNOWN_OPTIONS = [
  { value: 'Positive', label: 'Positive' },
  { value: 'Negative', label: 'Negative' },
  { value: 'Unknown', label: 'Unknown' },
];
export const SMOKING_HISTORY_OPTIONS = [
  { value: 'Current Smoker', label: 'Current Smoker' },
  { value: 'Previous Smoker', label: 'Previous Smoker' },
  { value: 'Never Smoked', label: 'Never Smoked' },
  { value: 'Unknown', label: 'Unknown' },
];

export const YES_NO_OPTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const INVOLVED_UNINVOLVED_OPTIONS = [
  { value: 'Involved', label: 'Involved' },
  { value: 'Uninvolved', label: 'Uninvolved' },
];

export const PNI_TYPE_OPTIONS = [
  { value: 'Minor', label: 'Minor' },
  { value: 'Major (0.5mm diameter) or named nerve', label: 'Major (0.5mm diameter) or named nerve' },
  { value: 'Intracranial', label: 'Intracranial' },
];

export const YES_NO_UNKNOWN_OPTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Unknown', label: 'Unknown' },
];

export const YES_NO_NOTAPPLICABLE_OPTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Not Applicable', label: 'Not Applicable' },
];

export const GRADE_OPTIONS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: 'Unknown', label: 'Unknown' },
];

export const WEIGHT_LOSS_OPTIONS = [
  { value: '>10%', label: '>10%' },
  { value: '<10%', label: '<10%' },
  { value: 'Unkown', label: 'Unknown' },
];

export const LYMPHOMA_STAGE_OPTIONS = [
  {
    label: '1 - Involvement of a single lymph node region* or lymphoid structure',
    value: 'Involvement of a single lymph node region* or lymphoid structure',
  },
  {
    label:
      '2 - Involvement of two or more lymph node regions,* or localised involvement of one extranodal site and one or lymph node regions, all on the same side of the diaphragm',
    value:
      'Involvement of two or more lymph node regions,* or localised involvement of one extranodal site and one or lymph node regions, all on the same side of the diaphragm',
  },
  {
    label: '3 - Involvement of lymph node regions* or structures on both sides of the diaphragm',
    value: 'Involvement of lymph node regions* or structures on both sides of the diaphragm',
  },
  {
    label:
      '4 - Diffuse or disseminated involvement of one or more extralymphatic organs, OR isolated extralymphatic organ involvement without adjacent regional lymph node involvement, but with disease in distant site(s), OR any involvement of the liver, bone marrow, pleura or CSF',
    value:
      'Diffuse or disseminated involvement of one or more extralymphatic organs, OR isolated extralymphatic organ involvement without adjacent regional lymph node involvement, but with disease in distant site(s), OR any involvement of the liver, bone marrow, pleura or CSF',
  },
  {
    label: 'Unknown',
    value: 'Unknown',
  },
];

export const A_B_SYMPTOMS_OPTIONS = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'Unknown', label: 'Unknown' },
];

export const PSMA_OPTIONS = [
  { value: 'Positive', label: 'Positive' },
  { value: 'Negative', label: 'Negative' },
  { value: 'Not Done', label: 'Not Done' },
];

export const POSITIVE_SITE_OPTIONS = [
  { value: 'Local', label: 'Local' },
  { value: 'Pelvic Nodal', label: 'Pelvic Nodal' },
  { value: 'Distant', label: 'Distant' },
];

export const EXTRANODAL_OPTIONS = [
  { value: 'Yes - E', label: 'Yes - E' },
  { value: 'Not Applicable', label: 'Not Applicable' },
  { value: 'Unknown', label: 'Unknown' },
];
export const BULK_DISEASE_OPTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Unknown', label: 'Unknown' },
];
// TODO EVE-10791: add all options for Intake pages into Carepath list data
export const SURGERY_OPTIONS = [
  { value: 'Breast conserving', label: 'Breast conserving' },
  { value: 'Mastectomy', label: 'Mastectomy' },
  { value: 'Biopsy only', label: 'Biopsy only' },
  { value: 'None', label: 'None' },
];

export const EXTENT_LVI_OPTIONS = [
  { value: 'Extensive', label: 'Extensive' },
  { value: 'Focal', label: 'Focal' },
  { value: 'Unknown', label: 'Unknown' },
];

export const RECONSTRUCTION_TIMELINE_OPTIONS = [
  { value: 'Immediate', label: 'Immediate' },
  { value: 'Delayed', label: 'Delayed' },
  { value: 'None', label: 'None' },
];

export const RECONSTRUCTION_IMMEDIATE_SURGERY_OPTIONS = [
  { value: 'Autologous', label: 'Autologous' },
  { value: 'Implant only', label: 'Implant only' },
];

export const RECONSTRUCTION_DELAYED_SURGERY_OPTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const VALUE_REQUIRED = 'This field is required';

export const stageToolTipText = (
  <div>
    <b>1</b> - Involvement of a single lymph node region* or lymphoid structure <br />
    <b>2</b> - Involvement of two or more lymph node regions,* or localised involvement of one extranodal site and one
    or lymph node regions, all on the same side of the diaphragm <br />
    <b>3</b> - Involvement of lymph node regions* or structures on both sides of the diaphragm <br />
    <b>4</b> - Diffuse or disseminated involvement of one or more extralymphatic organs, OR isolated extralymphatic
    organ involvement without adjacent regional lymph node involvement, but with disease in distant site(s), OR any
    involvement of the liver, bone marrow, pleura or CSF
    <br />
    <br />
    *Lymph node regions include: right cervical (including cervical, supraclavicular, occipital, and preauricular lymph
    nodes), left cervical, right axillary, left axillary, right infraclavicular, left infraclavicular, mediastinal,
    hilar, periaortimesentery, right pelvic, left pelvic, right inguinal femoral, and left inguinal femoral
  </div>
);

export const absymptomsTooltip = (
  <div>
    <b>A</b> - Absence of constitutional symptoms <br />
    <b>B</b> - Constitutional symptoms: fever (&gt;38°C), drenching sweats, weight loss (10% body weight over 6 months)
  </div>
);

export const extranodalTooltip = (
  <div>
    Involvement of a single, extranodal site contiguous or proximal to a known nodal site (Stages I to III; additional
    extranodal involvement is stage IV)
  </div>
);

export const bulkDiseaseTooltip = (
  <div>
    One or more site of disease of &gt;10cm diameter, or mediastinal widening to &gt;1/3 of the chest width on chest
    X-ray
  </div>
);

export const GLEASON_LEVEL_OPTIONS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

export const getIntakeISUPGrade = (gleasonOne: string, gleasonTwo: string): string => {
  const gleason = +gleasonOne + +gleasonTwo;
  let intakeISUPGrade = '1';
  if (gleason === 7 && +gleasonOne < +gleasonTwo) {
    intakeISUPGrade = '2';
  } else if (gleason === 7 && +gleasonOne > +gleasonTwo) {
    intakeISUPGrade = '3';
  } else if (gleason === 8) {
    intakeISUPGrade = '4';
  } else if (gleason >= 9) {
    intakeISUPGrade = '5';
  }
  return intakeISUPGrade;
};

export const surgeryTooltip = {
  header: 'Surgery',
  body: '<b>Breast conserving surgery includes:</b> Partial Mastectomy, Lumpectomy, Mammoplasty, MLE, Breast Conservation and Quadrantectomy.',
};

export const oncoplasticSurgeryTooltip = {
  header: 'Oncoplastic Surgery',
  body: 'Removal of the breast cancer using plastic surgical techniques to reconstruct the defect produced when the cancer is removed. <br/>Examples include: Mammoplasty, Mastopexy, Fat grafting.',
};

export const radialMarginStatusTooltip = {
  header: 'Radial Margin Status',
  body: 'Radial margin is the distance measure perpendicularly from the tumour to the margin of specimen.</br></br><b>Positive margin:</b> Tumour at the inked margin</br><b>Negative margin:</b> No ink on the tumour</br></br>Do not include deep and superficial.',
};

export const lymphovascularInvasionTooltip = {
  header: 'Lymphovascular Invasion',
  body: 'Tumour cells present within a definite endothelial-lined space (either lymphatic or blood vessels) in the area surrounding invasive carcinoma and includes dermal lymphovascular invasion.',
};

export const reconstructionTooltip = {
  header: 'Reconstruction',
  body: '<b>Immediate:</b> Breast reconstruction done at the same surgery as the mastectomy</br><b>Delayed:</b> Breast reconstruction at any time other than the same surgery as the mastectomy</br><b>None:</b> Breast reconstruction has not been completed</br>',
};

export const EcogMovedTooltip = () => (
  <Stack margin="-10px 0 10px 330px">
    {/* This jank field is here as we need this helper text but don't want the field to be
bordered in blue */}
    <ROHelperText id="ecogTooltip" info helperText="ECOG has been moved to the Treatment Information page." />
  </Stack>
);
