import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartArea from '../Charts/ChartArea';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import { DataFunctions } from '../../Utils/dataFunctions';
import SubChartStackedBarPercent from '../Charts/SubCharts/SubChartStackedBarPercent';

const CarepathUtilisation = ({
  data,
  subChartData,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  ...rest
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight, dateRange),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartArea({
      selectedPoint: rest.filterOverride,
      leftSeriesTitle: 'Careplan adherence',
      rightSeriesTitle: 'Peer review',
      leftSeriesFields: ['Date', 'Adherence'],
      rightSeriesFields: ['Date', 'Peer Review'],
      extraSeriesFields: ['Date', 'Non-Adherent'],
      aggregation: dateAggregationMap[dateRange!],
      dateRange: dateRange,
      ...rest,
    }).components?.[0] ?? {},
    SubChartStackedBarPercent({
      seeMore: seeMoreBottomLeft,
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      dataLength: DataFunctions.aggregateData(subChartData, 'tumourStream').length - 1,
      ...rest,
    }).components?.[0] ?? {},
    SubChartStackedBarPercent({
      seeMore: seeMoreBottomRight,
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      dataLength: DataFunctions.aggregateData(subChartData, 'createdBy').length - 1,
      ...rest,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
  dateRange: string | undefined,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['Date', 'Adherence', 'Non-Adherent', 'Peer Review'],
        data: DataFunctions.aggregateAdherenceValues(data, dateAggregationMap[dateRange!]),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['Field', 'Adherence', 'Non-Adherent'],
        data: DataFunctions.aggregateAdherenceByField(
          subChartData,
          'tumourStream',
          seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT,
        ),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['Field', 'Adherence', 'Non-Adherent'],
        data: DataFunctions.aggregateAdherenceByField(
          subChartData,
          'createdBy',
          seeMoreBottomRight ? undefined : SUB_CHART_LIMIT,
        ),
      },
    },
  ],
});

export default CarepathUtilisation;
