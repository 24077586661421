export const CAREPLAN_PAGES = {
  CREATE: '',
  DIAGNOSIS_A: 'primary',
  DIAGNOSIS_B: 'newPrimary',
  STAGING: 'tnmstaging',
  INTAKE: 'intake',
  TREATMENT_INFO: 'treatmentInfo',
  TEMPLATE_SELECTION: 'templateSelection',
  SITESETUP: 'linkSites',
  SIMULATION: 'simulation',
  PRESCRIPTION: 'prescription',
  VOLUMING: 'voluming',
  SUBMISSION: 'submission',
  CPOT: 'cpot',
  SPECIAL_PHYSICS_CONSULT: 'specialPhysicsConsult',
};

export const NO_TEMPLATES_MESSAGE = 'No careplan template/manual site selected.';
export const VARIATION_MESSAGE = 'Variation from careplan template';
export const PEER_REVIEW_MESSAGE = 'Variation from careplan template and peer review triggered';
export const SUBMISSION_MESSAGE = 'This careplan has been submitted.';
export const CPOT_SUBMISSION_MESSAGE =
  'This careplan has been resubmitted, please ensure that you alert the treatment team of this change.';
export const UNSUBMITTED_MESSAGE = 'You have unsubmitted changes, please resubmit.';
export const CPOT_MESSAGE = 'You are making changes to a commenced treatment. Please remember to submit.';
export const CLINEX_BETA_TESTING_MESSAGE =
  'This is a beta version of Clinex during the calibration phase. Please review the values populated';
