import React, { useState, ReactNode, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  LinearProgress,
  Chip,
  Avatar,
} from '@mui/material';
import SigmaBoost from './SigmaBoost.png';
import SigmaTechnique from './SigmaTechnique.png';
import SigmaDose from './SigmaDose.png';
import SigmaTreatmentSites from './SigmaTreatmentSites.png';
import SigmaDoseperFractions from './SigmaDoseperfraction.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useTheme } from '@mui/system';
interface SigmaExplanationModalProps {
  open: boolean;
  toggle: (value: boolean) => void;
  templateName: string;
}
interface SiteValue {
  value: string;
  score: number;
  image?: ReactNode;
  display: string;
}

interface Site {
  site: string;
  phase: string;
  technique: SiteValue;
  dose: SiteValue;
  dosePerfractions: SiteValue;
}
const SITES = [
  {
    site: 'Chestwall + SCF + Axilla + IMC',
    phase: 'Phase 1',
    technique: {
      value: 'VMAT/IMRT',
      score: 90,
      image: <Box component="img" src={SigmaTechnique} />,
      display: 'Technique: VMAT/IMRT',
    },
    dose: { value: '5000 cGy', score: 20, image: <Box component="img" src={SigmaDose} />, display: 'Dose: 5000 cGy' },
    dosePerfractions: {
      value: '20 cGy',
      score: 90,
      image: <Box component="img" src={SigmaDoseperFractions} />,
      display: 'Fractions: 20 cGy',
    },
  },
];
const BOOST: SiteValue = {
  value: 'No',
  score: 90,
  image: <Box component="img" src={SigmaBoost} />,
  display: 'Include boost: No',
};
const TREATMENT_SITES: SiteValue = {
  value: 'Chestwall + SCF + Axilla + IMC',
  score: 95,
  image: <Box component="img" src={SigmaTreatmentSites} />,
  display: 'Treatment sites: Chestwall + SCF + Axilla + IMC',
};

const ExplainationBlock = ({
  title,
  value,
  score,
  setShowDetailData,
}: {
  title: string;
  value: string;
  score: number;
  setShowDetailData: (value: boolean) => void;
}): JSX.Element => {
  const theme = useTheme();
  return (
    <Paper
      elevation={3}
      sx={{ padding: 2, width: '100%', cursor: 'pointer', ':hover': { backgroundColor: theme.palette.action.hover } }}
      onClick={() => {
        setShowDetailData(true);
      }}>
      <Stack sx={{ width: '100%' }}>
        <Typography variant="body2">{title}:</Typography>
        <Typography variant="body2">{value}</Typography>
        <LinearProgress value={Number(score)} variant="determinate" color="primary" />
      </Stack>
    </Paper>
  );
};

const BoostAndTreatmentSites = ({
  boostSite,
  treatmentSites,
  setShowDetailData,
  setSelectedSiteValue,
}: {
  boostSite: SiteValue;
  treatmentSites: SiteValue;
  setShowDetailData: (value: boolean) => void;
  setSelectedSiteValue: (value: SiteValue) => void;
}): JSX.Element => {
  return (
    <Stack gap={1} sx={{ paddingBottom: '20px', paddingTop: '20px' }}>
      <Stack direction="row" gap={1}>
        <Avatar sx={{ width: '20px', height: '20px', fontSize: 12 }}>1</Avatar>
        <Typography variant="subtitle2">Boost and treatment Sites</Typography>
      </Stack>
      <Stack direction="row" gap={1} alignItems="center">
        <ExplainationBlock
          title="Include boost"
          value={boostSite.value}
          score={boostSite.score}
          setShowDetailData={(value) => {
            setShowDetailData(value);
            setSelectedSiteValue(boostSite);
          }}
        />
        <ArrowRightIcon />
        <ExplainationBlock
          title="Treatment sites"
          value={treatmentSites.value}
          score={treatmentSites.score}
          setShowDetailData={(value) => {
            setShowDetailData(value);
            setSelectedSiteValue(treatmentSites);
          }}
        />
      </Stack>
    </Stack>
  );
};

const TechniqueDoseFractions = ({
  technique,
  dose,
  fractions,
  setShowDetailData,
  setSelectedSiteValue,
}: {
  technique: SiteValue;
  dose: SiteValue;
  fractions: SiteValue;
  setShowDetailData: (value: boolean) => void;
  setSelectedSiteValue: (value: SiteValue) => void;
}): JSX.Element => {
  return (
    <Stack direction="row" gap={1} sx={{ width: '100%' }} alignItems="center">
      <ExplainationBlock
        title="Technique"
        value={technique.value}
        score={technique.score}
        setShowDetailData={(value) => {
          setShowDetailData(value);
          setSelectedSiteValue(technique);
        }}
      />
      <ArrowRightIcon />
      <ExplainationBlock
        title="Dose"
        value={dose.value}
        score={dose.score}
        setShowDetailData={(value) => {
          setShowDetailData(value);
          setSelectedSiteValue(dose);
        }}
      />
      <ArrowRightIcon />
      <ExplainationBlock
        title="Fractions"
        value={fractions.value}
        score={fractions.score}
        setShowDetailData={(value) => {
          setShowDetailData(value);
          setSelectedSiteValue(fractions);
        }}
      />
    </Stack>
  );
};

const PhaseAndSites = ({
  phase,
  sites,
  setShowDetailData,
  setSelectedSiteValue,
}: {
  phase: string;
  sites: Site[];
  setShowDetailData: (value: boolean) => void;
  setSelectedSiteValue: (value: SiteValue) => void;
}): JSX.Element => {
  return (
    <Grid container width="100%" rowSpacing={1} columnSpacing={2}>
      {sites.map((site, index) => (
        <>
          <Grid item sm={2}>
            {index === 0 && <Chip label={phase} />}
          </Grid>
          <Grid item sm={2}>
            <Typography variant="subtitle2">{site.site}</Typography>
          </Grid>
          <Grid item sm={8}>
            <TechniqueDoseFractions
              technique={site.technique}
              dose={site.dose}
              fractions={site.dosePerfractions}
              setShowDetailData={setShowDetailData}
              setSelectedSiteValue={setSelectedSiteValue}
            />
          </Grid>
        </>
      ))}
    </Grid>
  );
};

const PhaseAndSitesList = ({
  sites,
  setShowDetailData,
  setSelectedSiteValue,
}: {
  sites: Site[];
  setShowDetailData: (value: boolean) => void;
  setSelectedSiteValue: (value: SiteValue) => void;
}): JSX.Element => {
  const phaseSiteMap = sites.reduce((acc, site) => {
    if (!acc[site.phase]) {
      acc[site.phase] = [];
    }
    acc[site.phase].push(site);
    return acc;
  }, {} as { [key: string]: Site[] });

  return (
    <Stack direction="column" gap={2} sx={{ paddingBottom: '20px' }}>
      <Stack direction="row" gap={1}>
        <Avatar sx={{ width: '20px', height: '20px', fontSize: 12 }}>2</Avatar>
        <Typography variant="subtitle2">Technique, dose and fractions per treatment site</Typography>
      </Stack>
      <Stack direction="column" gap={2} sx={{ paddingBottom: '20px' }}>
        {Object.entries(phaseSiteMap).map(([phaseKey, sites]) => (
          <PhaseAndSites
            phase={phaseKey}
            sites={sites}
            setShowDetailData={setShowDetailData}
            setSelectedSiteValue={setSelectedSiteValue}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const SigmaExplanationDialogContent = ({
  templateName,
  setShowDetailData,
  setSelectedSiteValue,
}: {
  templateName: string;
  setShowDetailData: (value: boolean) => void;
  setSelectedSiteValue: (value: SiteValue) => void;
}): JSX.Element => {
  return (
    <>
      <Typography variant="h6" sx={{ paddingBottom: '20px' }}>
        Careplan Template: {templateName}
      </Typography>
      <Typography variant="body2" sx={{ paddingBottom: '20px' }}>
        Below you can see how this Sigma recommendation breaks down into its components, each representing a Sigma model
        prediction that is explainable. You can click on the cards to view a detailed explanation for each prediction.
        The predicted probabilities, shown as purple bars, are used to calculate the final Sigma score for the
        recommended template.
      </Typography>
      <BoostAndTreatmentSites
        boostSite={BOOST}
        treatmentSites={TREATMENT_SITES}
        setShowDetailData={setShowDetailData}
        setSelectedSiteValue={setSelectedSiteValue}
      />
      <PhaseAndSitesList
        sites={SITES}
        setShowDetailData={setShowDetailData}
        setSelectedSiteValue={setSelectedSiteValue}
      />
    </>
  );
};

const SigmaExplanationDetailContent = ({
  templateName,
  setShowDetailData,
  selectedSiteValue,
}: {
  templateName: string;
  setShowDetailData: (value: boolean) => void;
  selectedSiteValue: SiteValue | null;
}): JSX.Element => {
  return (
    <>
      <Typography variant="h6" sx={{ paddingBottom: '20px' }}>
        Careplan Template: {templateName}
      </Typography>
      <Typography variant="body2" sx={{ paddingBottom: '20px' }}>
        The chart below explains the Sigma model predction <strong>{selectedSiteValue?.display}</strong> using Shapley
        values. Shapely values are a game theoretic approach to explaining the output of non-linear machine learning
        models as used by Sigma. The waterfall chart shows the impract of the most important features on the predicted
        value f(x), starting from an expected value E(f(x)), which would be predicted if none of the features were
        known.
      </Typography>
      <Button
        startIcon={<NavigateBeforeIcon color="primary" />}
        onClick={() => {
          setShowDetailData(false);
        }}
        color="primary"
        variant="text">
        <Typography color="primary">GO BACK</Typography>
      </Button>
      {selectedSiteValue?.image}
    </>
  );
};

const SigmaExplanationModal = ({ open, toggle, templateName }: SigmaExplanationModalProps): ReactNode => {
  const [showDetailData, setShowDetailData] = useState(false);
  const [selectedSiteValue, setSelectedSiteValue] = useState<SiteValue | null>(null);
  const fixedTemapleName = 'Chestwall + SCF + Axilla + IMC 5000/25';
  useEffect(() => {
    if (!open) {
      setSelectedSiteValue(null);
      setShowDetailData(false);
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={() => {
        toggle(false);
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      maxWidth="md">
      <DialogTitle id="modal-title">Sigma explanation</DialogTitle>
      <DialogContent>
        {!showDetailData ? (
          <SigmaExplanationDialogContent
            templateName={fixedTemapleName}
            setShowDetailData={(value: boolean) => {
              setShowDetailData(value);
            }}
            setSelectedSiteValue={(value: SiteValue) => {
              setSelectedSiteValue(value);
            }}
          />
        ) : (
          <SigmaExplanationDetailContent
            templateName={fixedTemapleName}
            setShowDetailData={(value: boolean) => {
              setShowDetailData(value);
            }}
            selectedSiteValue={selectedSiteValue}
          />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            toggle(false);
          }}
          variant="text"
          color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SigmaExplanationModal;
