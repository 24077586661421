import React, { useEffect } from 'react';
import { StandardDialog } from 'shared-components/components';
import { useMutation } from '@apollo/client';
import { LOGOUT_MUTATION } from 'op-graphql/Logout';
import { navigateToExternalURL } from 'shared-components/utils';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { Logger } from 'shared-components/utils';
import { useErrorModalContext } from 'op-contexts/ErrorModalContext/ErrorModalContext';

const logger = new Logger('ROErrorDialog');

const isPractitionerPage = () => {
  // Determines if the current is only viewable by practitioners
  return (
    window.location.pathname.startsWith('/radiation') ||
    window.location.pathname.startsWith('/navigator') ||
    window.location.pathname.startsWith('/medonc') ||
    window.location.pathname.endsWith('/patient/create')
  );
};

const ErrorModal = () => {
  const { error } = useErrorModalContext();
  //const client = useApolloClient();
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);

  const handleLogout = async () => {
    //await client.clearStore();
    await logoutMutation();
    navigateToExternalURL('/sso/logout');
  };

  if (!error) return null;

  const getTitle = () => {
    if (error === 'Account') return 'Account Error';
    return 'Something went wrong';
  };

  const getBody = () => {
    if (!isPractitionerPage()) return CurrentAppConfig.ErrorPopup.Patient;
    if (error === 'Account') return CurrentAppConfig.ErrorPopup.Practitioner;
    if (error === 'Practitioner') return CurrentAppConfig.ErrorPopup.Practitioner;

    return CurrentAppConfig.ErrorPopup.Generic;
  };

  const getFunction = () => {
    if (error === 'Account') return handleLogout;
    return () => window.location.reload();
  };

  const getSubmitText = () => {
    if (error === 'Account') return 'Logout';
    return 'Refresh Page';
  };

  return (
    <StandardDialog open title={getTitle()} submitText={getSubmitText()} onSubmit={getFunction()}>
      <div style={{ lineHeight: 1.2 }} dangerouslySetInnerHTML={{ __html: getBody() }}></div>
    </StandardDialog>
  );
};

export default ErrorModal;
