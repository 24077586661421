import { useState } from 'react';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { StandardDialog } from 'shared-components/components';
import { Breakpoint } from '@mui/system';

interface PropsType {
  open: boolean;
  setOpen: (value: boolean) => void;
  tooltip?: TooltipInterface;
  maxWidth?: Breakpoint;
}

export function TooltipModal(props: PropsType): JSX.Element {
  const { open, setOpen, tooltip, maxWidth } = props;

  return (
    <StandardDialog open={open} onClose={() => setOpen(false)} title={tooltip?.header || ''} maxWidth={maxWidth}>
      <div dangerouslySetInnerHTML={{ __html: tooltip?.body || '' }}></div>
    </StandardDialog>
  );
}

interface TooltipInterface {
  header: string;
  body: string;
}

interface ToolTipProps {
  tooltip?: TooltipInterface;
  maxWidth?: Breakpoint;
}

export function FormRowToolTip(props: ToolTipProps): JSX.Element {
  const { tooltip, maxWidth } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <HelpOutlineIcon sx={{ cursor: 'pointer' }} color="info" onClick={() => setOpen(true)} />
      {open && <TooltipModal open={open} tooltip={tooltip} setOpen={setOpen} maxWidth={maxWidth} />}
    </>
  );
}
