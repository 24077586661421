import { useState, useEffect, useRef, useCallback } from 'react';
import { Typography, Stack, Tooltip } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

const NavBar = ({
  openSections,
  toggleSection,
  navToPage,
  currentPage,
  theme,
}: {
  openSections: any;
  toggleSection: any;
  navToPage: any;
  currentPage: any;
  theme: any;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Stack
      height="100%"
      width="240px"
      min-width="240px"
      borderRight="rgba(0, 0, 0, 0.2) 1px solid"
      sx={{ backgroundColor: '#FAFAFA' }}>
      <button
        onClick={() => navToPage('index')}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          backgroundColor: isHovered ? '#efefef' : '#FAFAFA',
          border: 'none',
          fontSize: '16px',
          marginBottom: '10px',
          cursor: 'pointer',
          textAlign: 'left',
          width: '100%',
          padding: '20px 0 12px 15px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        }}>
        <Stack direction="row" alignItems="center" marginBottom={1}>
          <ArrowForward
            style={{
              marginRight: '15px',
              transform: 'rotate(180deg)',
              opacity: '54%',
              color: theme.palette.primary.dark,
            }}
          />
          <Stack>
            <Typography>Back</Typography>
            <Typography variant="subtitle2" color={theme.palette.text.secondary} sx={{ fontWeight: 400 }}>
              Insights Dashboard
            </Typography>
          </Stack>
        </Stack>
      </button>
      <Stack>
        <Stack>
          <Typography
            variant="subtitle1"
            onClick={() => toggleSection('Activity')}
            display="flex"
            marginTop="10px"
            paddingLeft="10px"
            alignItems="center"
            sx={{ cursor: 'pointer' }}>
            <ExpandMore style={{ transform: `rotate(${openSections.Activity ? 0 : '-90deg'})` }} />
            Activity
          </Typography>
          {openSections.Activity && (
            <NavList navToPage={navToPage} currentPage={currentPage} navItems={activityNavItemsMapping} />
          )}
        </Stack>
        <Stack>
          <Typography
            variant="subtitle1"
            onClick={() => toggleSection('Quality')}
            display="flex"
            marginTop="10px"
            paddingLeft="10px"
            alignItems="center"
            sx={{ cursor: 'pointer' }}>
            <ExpandMore style={{ transform: `rotate(${openSections.Quality ? 0 : '-90deg'})` }} />
            Quality
          </Typography>
          {openSections.Quality && (
            <NavList navToPage={navToPage} currentPage={currentPage} navItems={qualityNavItemsMapping} />
          )}
        </Stack>
        <Typography
          variant="subtitle1"
          onClick={() => toggleSection('Access')}
          display="flex"
          marginTop="10px"
          paddingLeft="10px"
          alignItems="center"
          sx={{ cursor: 'pointer' }}>
          <ExpandMore style={{ transform: `rotate(${openSections.Access ? 0 : '-90deg'})` }} />
          Access
        </Typography>
        {openSections.Access && (
          <NavList navToPage={navToPage} currentPage={currentPage} navItems={accessNavItemsMapping} />
        )}
        <Stack>
          <Typography
            variant="subtitle1"
            onClick={() => toggleSection('Efficiency')}
            display="flex"
            marginTop="10px"
            paddingLeft="10px"
            alignItems="center"
            sx={{ cursor: 'pointer' }}>
            <ExpandMore style={{ transform: `rotate(${openSections.Efficiency ? 0 : '-90deg'})` }} />
            Efficiency
          </Typography>
          {openSections.Efficiency && (
            <NavList navToPage={navToPage} currentPage={currentPage} navItems={efficiencyNavItemsMapping} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const NavList = ({
  navToPage,
  currentPage,
  navItems,
}: {
  navToPage: (page: string) => void;
  currentPage: string;
  navItems: { [key: string]: { name: string; enabled: boolean } };
}) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  return (
    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
      {Object.entries(navItems).map(([label, value]) => {
        const isActive = currentPage === label;
        const isDisabled = !value.enabled;
        const isHovered = hoveredItem === label && !isDisabled;
        const showTooltip = hoveredItem === label && isDisabled;

        const styles = {
          padding: '12px 0',
          backgroundColor: isActive ? '#CDC6FE' : isHovered ? '#efefef' : 'transparent',
          borderRadius: isActive ? '4px' : 'none',
          borderLeft: isActive ? '4px solid #081E43' : 'none',
          cursor: isDisabled ? 'default' : 'pointer',
          color: isDisabled ? 'rgba(8, 30, 67, 0.38)' : 'inherit',
          fontSize: '16px',
          fontWeight: '400',
          paddingLeft: '15px',
        };

        return (
          <Tooltip
            title="Coming soon! Preview it now on the insights dashboard page"
            open={showTooltip}
            placement="right"
            arrow
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -30],
                  },
                },
              ],
            }}>
            <li
              key={label}
              onClick={() => !isDisabled && navToPage(label)}
              onMouseEnter={() => setHoveredItem(label)}
              onMouseLeave={() => setHoveredItem(null)}
              style={styles}>
              {value.name}
            </li>
          </Tooltip>
        );
      })}
    </ul>
  );
};

const accessNavItemsMapping: { [key: string]: { name: string; enabled: boolean } } = {
  referrers: { name: 'Top 10 Referrers', enabled: CurrentAppConfig.Insights?.Dashboards.totalReferrals ?? false },
  diagnosis: { name: 'Diagnosis', enabled: CurrentAppConfig.Insights?.Dashboards.totalDiagnosis ?? false },
  activity: { name: 'Clinical Activity', enabled: CurrentAppConfig.Insights?.Dashboards.totalPlans ?? false },
};

const efficiencyNavItemsMapping: { [key: string]: { name: string; enabled: boolean } } = {
  machines: { name: 'Machine Utilization', enabled: CurrentAppConfig.Insights?.Dashboards.linacs ?? false },
  'wait-times': { name: 'Patient Wait Times', enabled: CurrentAppConfig.Insights?.Dashboards.simToTreatment ?? false },
  productivity: {
    name: 'Planning Productivity',
    enabled: CurrentAppConfig.Insights?.Dashboards.averageDailyPlans ?? false,
  },
};

const activityNavItemsMapping: { [key: string]: { name: string; enabled: boolean } } = {
  consultations: {
    name: 'Total Consultations',
    enabled: CurrentAppConfig.Insights?.Dashboards.totalConsultations ?? false,
  },
  simulations: { name: 'Total Simulations', enabled: CurrentAppConfig.Insights?.Dashboards.totalSimulations ?? false },
  treatments: { name: 'Total Treatments', enabled: CurrentAppConfig.Insights?.Dashboards.totalTreatments ?? false },
};

const qualityNavItemsMapping: { [key: string]: { name: string; enabled: boolean } } = {
  carepathway: {
    name: 'Carepathway Utilization',
    enabled: CurrentAppConfig.Insights?.Dashboards.careplanAdherence ?? false,
  },
  techniques: { name: 'Technique Utilization', enabled: CurrentAppConfig.Insights?.Dashboards.techniques ?? false },
  fractions: {
    name: 'Fractionation Schedule',
    enabled: CurrentAppConfig.Insights?.Dashboards.averageFractions ?? false,
  },
};

export default NavBar;
