import React, { Fragment, useState, useEffect, useContext } from 'react';

import { useQuery } from '@apollo/client';
import './OrgansAtRisk.scss';
import OrgansAtRiskPopup from './OrgansAtRiskPopup';
import OrgansAtRiskRow from './OrgansAtRiskRow';
import { OAR_QUERY, ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION } from './Queries';
import { VARIATION_MESSAGE } from '../../Constants';
import { TenantContext } from 'op-contexts';
import { AddCircleOutline } from '@mui/icons-material';
import lodash from 'lodash';
import { Typography, useTheme, Button } from '@mui/material';
import { decimalFormatter } from 'op-utils';

const OAR_VARIATION_MESSAGE = 'OAR(s) have varied from template';
const WarningIcon = (): JSX.Element => {
  return <i className="icon icon-form-warning"></i>;
};
const OrganWarning = (): JSX.Element => {
  return (
    <tr>
      <td className="ro-oar-warning">
        <WarningIcon />
        {VARIATION_MESSAGE}
      </td>
    </tr>
  );
};
const OrgansAtRiskModule = (props: any): JSX.Element => {
  const theme = useTheme();

  const [state, setState] = useState({ modalShow: false, oarData: null, editOarIndex: -1 });
  const cpotTriggered = props?.cpotTriggered;
  const { doseUnit } = useContext(TenantContext);

  const { loading, data } = useQuery(OAR_QUERY, {
    fetchPolicy: 'network-only',
    variables: { siteGroupId: props.siteGroupId },
  });

  useEffect(() => {
    if (state.editOarIndex !== -1 && data && data.roOrganAtRisk) {
      setState({ ...state, oarData: data.roOrganAtRisk[state.editOarIndex] });
    }
  }, [data]);

  const { data: templateOars } = useQuery(ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION, {
    variables: { siteGroupId: props.siteGroupId },
    skip: loading || !data || cpotTriggered,
  });

  const oarChange = (): boolean => {
    if (templateOars && data && templateOars.organAtRiskTemplateValuesVariation && data.roOrganAtRisk) {
      // Get the organ names
      const templateOrgans = templateOars.organAtRiskTemplateValuesVariation
        .map((organ: any) => {
          return organ.organ;
        })
        .filter(Boolean);
      const groupOrgans = data.roOrganAtRisk.map((organ: any) => {
        return organ.organ;
      });
      if (!templateOrgans.length) return false;
      if (groupOrgans.length === templateOrgans.length) {
        // Check the same organ are in the list
        for (let i = 0; i < templateOrgans.length; i++) {
          if (!groupOrgans.includes(templateOrgans[i])) {
            return true;
          }
        }
      } else {
        // OAR list not the same length
        return true;
      }
    }
    return false;
  };

  const setOrganDefaultValue = (
    organ: any,
    prop: string,
    defaultValue: any,
    // ideally compareValues should be of type string[] | null[]
    // due to typescript errors relating to the Array.includes function compiling the first
    // parameter for the search field to be `never`, we have changed it to any[]
    compareValues: any[] = [],
  ): void => {
    if (!organ.hasOwnProperty(prop)) {
      organ[prop] = defaultValue;
      return;
    }
    if (compareValues.includes(organ?.[prop])) organ[prop] = defaultValue;
  };

  const organValueVariation = (groupOrgan: any): boolean => {
    if (templateOars && data && templateOars.organAtRiskTemplateValuesVariation && groupOrgan) {
      const templateId = templateOars.organAtRiskTemplateValuesVariation.filter((item: any) =>
        Boolean(item.templateId),
      );
      if (templateId.length) {
        return true;
      }
      const templateOrgan = lodash.omit(
        lodash.clone(
          templateOars.organAtRiskTemplateValuesVariation.find(
            // eslint-disable-next-line eqeqeq
            (item: any) => item.organ == groupOrgan.organ,
          ),
        ),
        ['templateId'],
      );

      if (templateOrgan) {
        setOrganDefaultValue(templateOrgan, 'alara', false, []);
        setOrganDefaultValue(groupOrgan, 'alara', false, []);
        setOrganDefaultValue(templateOrgan, 'meanValue', [], [null]);
        setOrganDefaultValue(groupOrgan, 'meanValue', [], [null]);
        setOrganDefaultValue(templateOrgan, 'meanValueUnit', doseUnit, [null]);
        setOrganDefaultValue(groupOrgan, 'meanValueUnit', doseUnit, [null]);
        setOrganDefaultValue(templateOrgan, 'maxValue', [], [null]);
        setOrganDefaultValue(groupOrgan, 'maxValue', [], [null]);
        setOrganDefaultValue(templateOrgan, 'maxValueUnit', doseUnit, [null]);
        setOrganDefaultValue(groupOrgan, 'maxValueUnit', doseUnit, [null]);
        setOrganDefaultValue(templateOrgan, 'volumeConstraintData', [], [null]);
        setOrganDefaultValue(groupOrgan, 'volumeConstraintData', [], [null]);
        setOrganDefaultValue(templateOrgan, 'dConstraintData', [], [null]);
        setOrganDefaultValue(groupOrgan, 'dConstraintData', [], [null]);

        return JSON.stringify(templateOrgan) !== JSON.stringify(groupOrgan);
      }
    }
    return false;
  };

  const organRiskPopup = (): void => setState({ ...state, modalShow: true });

  const hidePopup = (): void => setState({ ...state, oarData: null, editOarIndex: -1, modalShow: false });

  const reloadPopup = () => {
    setState({ ...state, oarData: data.roOrganAtRisk[state.editOarIndex] });
  };
  const addAdditional = (organsInUse: any): JSX.Element => {
    return (
      <OrgansAtRiskPopup
        isVisible={state.modalShow}
        hidePopup={hidePopup}
        popup={organRiskPopup}
        siteGroupId={props.siteGroupId}
        careplanId={props.careplanId}
        organsInUse={organsInUse}
      />
    );
  };
  const editOARModal = (organsInUse: any, index: number): JSX.Element => {
    return (
      <OrgansAtRiskPopup
        isVisible={state.modalShow}
        hidePopup={hidePopup}
        popup={organRiskPopup}
        isEdit={true}
        reload={reloadPopup}
        siteGroupId={props.siteGroupId}
        careplanId={props.careplanId}
        index={index}
        data={state.oarData}
        organsInUse={organsInUse}
      />
    );
  };

  const tableHeadings = ['Organ', 'Constraint', 'Action', ''];
  if (loading) return <div>Loading...</div>;
  if (!data) return <div>Error loading...</div>;
  const organsInUse = data.roOrganAtRisk.map((item: any) => item.organ);
  const editPopup = (index: number): void =>
    setState({ modalShow: true, oarData: data.roOrganAtRisk[index], editOarIndex: index });

  return (
    <Fragment>
      {oarChange() && (
        <div className="ro-oar-warning">
          <WarningIcon />
          {OAR_VARIATION_MESSAGE}
        </div>
      )}
      <div className={'vertical-column-oar-flex'}>
        {Boolean(data.roOrganAtRisk.length) && (
          <table className={'oar-table-block'} data-test-id={'voluming-oar-table'}>
            <thead className={'oar-table-heading'}>
              <tr className={'oar-table-heading-item'}>
                {tableHeadings.map(
                  (item): JSX.Element => (
                    <th key={item}>
                      <Typography variant="subtitle1">{item}</Typography>
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {data.roOrganAtRisk.map((item: any, index: number): JSX.Element => {
                let constraints: string[] = [];
                if (item.volumeConstraintData)
                  constraints = [
                    ...item.volumeConstraintData.map(
                      (vcd: any): string =>
                        `V${decimalFormatter(vcd.firstValue, 4) || ' '}${vcd.firstValueUnit} ${vcd.operator} ${
                          (vcd.secondValue && vcd.secondValue.join('/')) || ' '
                        }${vcd.secondValueUnit}`,
                    ),
                  ];
                if (item.dConstraintData)
                  constraints = [
                    ...constraints,
                    ...item.dConstraintData.map(
                      (dcd: any): string =>
                        `${dcd.dosetoPrefixOperator || 'D'}${decimalFormatter(dcd.firstValue, 4) || ' '}${
                          dcd.firstValueUnit
                        } ${dcd.operator} ${(dcd.secondValue && dcd.secondValue.join('/')) || ' '}${
                          dcd.secondValueUnit
                        }`,
                    ),
                  ];
                if (item.maxValue && item.maxValue.length) {
                  constraints = [
                    ...constraints,
                    `Max ≤ ${(item.maxValue && item.maxValue.join('/')) || ' '}${item.maxValueUnit}`,
                  ];
                }
                if (item.meanValue && item.meanValue.length) {
                  constraints = [
                    ...constraints,
                    `Mean ≤ ${(item.meanValue && item.meanValue.join('/')) || ' '}${item.meanValueUnit}`,
                  ];
                }
                if (item.alara) constraints = ['ALARA'];
                return (
                  <Fragment key={`fragment-${index}`}>
                    <OrgansAtRiskRow
                      key={`${item.organ}-${index}`}
                      itemId={item.id}
                      isVisible={state.modalShow}
                      editPopup={(): void => editPopup(index)}
                      hidePopup={hidePopup}
                      popup={organRiskPopup}
                      organName={item.organ}
                      {...item}
                      constraints={constraints}
                      organsInUse={organsInUse}
                      siteGroupId={props.siteGroupId}
                      careplanId={props.careplanId}
                      index={index}
                    />
                    {organValueVariation({ ...item }) && <OrganWarning key={`warning-${index}`} />}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        )}
        {state.modalShow &&
          (state.oarData ? editOARModal(organsInUse, state.editOarIndex) : addAdditional(organsInUse))}
        <div className="oar-table-block oar-padding">
          <Button
            onClick={(): void => {
              organRiskPopup();
            }}
            data-testid="add-additional-oar"
            variant="outlined"
            startIcon={<AddCircleOutline color="primary" />}>
            Add additional OAR
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default OrgansAtRiskModule;
