// eslint-disable-next-line no-use-before-define
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { ExtendLock } from 'op-components/index';
import { PatientCoverage } from 'op-interfaces/PatientInterfaces';
import { OPUser, Patient } from 'op-interfaces/index';
import { GET_APOLLO_CACHE, WithRegistrationForm } from 'op-pages/OP/RegistrationForm/RegistrationForm';
import { Component, Fragment, useEffect } from 'react';

import { RouteComponentProps } from 'react-router';
import { LoadingSpinner } from 'shared-components/components';
import { SavingStatus } from 'shared-components/enums';
import { GraphUpdate, ListData } from 'shared-interfaces/index';
import withROCreatePatient from '../withROCreatePatient';
import RORegInsurance from './RORegInsurance';

const PATIENT_QUERY = gql`
  query patientQuery($id: ID) {
    patient(id: $id) {
      id
      ida
      dob
      payor
      coverageRelationship
      policyNumber
      preAuthNumber
      preAuthNotes
      fullName
    }
    user {
      id
      isPso
    }
    insurerRefData: quarantiniDepartments(unknown: true) {
      id
      name
    }
    relationshipRefData: listData(category: "coverageRelationship") {
      id
      name
    }
  }
`;

interface Props
  extends WithApolloClient<{}>,
    WithRegistrationForm,
    RouteComponentProps<{
      id?: string;
    }> {
  isActive?: boolean;
}

interface State {
  activeTab: string;
  saveStatus: SavingStatus;
  pageViewed: boolean;
}

interface PatientAndUserDetailsQueryData {
  patient: PatientCoverage;
  insurerRefData: ListData[];
  relationshipRefData: ListData[];
  user: OPUser;
}

class RORegInsuranceApollo extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: 'clinicList',
      saveStatus: SavingStatus.SAVED,
      pageViewed: false,
    };
  }

  public render(): JSX.Element {
    const match: any = this.props.match;
    const patientId: string = match.params.id;
    const variables = {
      id: patientId,
    };

    return (
      <Query<PatientAndUserDetailsQueryData>
        query={PATIENT_QUERY}
        variables={variables}
        fetchPolicy="cache-and-network">
        {({ loading, data }): JSX.Element => {
          if (loading) return <LoadingSpinner />;
          if (data && data.patient && data.user) {
            return (
              <Fragment>
                <ExtendLock accessPatientId={data.patient.id} />
                <RORegInsurance
                  autosave={this.autosave}
                  patient={data.patient}
                  user={data.user}
                  insurerRefData={data.insurerRefData}
                  relationshipRefData={data.relationshipRefData}
                />
              </Fragment>
            );
          }
          return <div></div>;
        }}
      </Query>
    );
  }

  private autosave = async (patient: Patient, updateItems: [GraphUpdate], forceFetch?: object[]): Promise<void> => {
    const patientMutationObj: any = this.props.getPatientMutation(patient, updateItems, forceFetch);
    const { match } = this.props;
    const { id } = match.params;
    patientMutationObj['refetchQueries'] = [
      {
        query: PATIENT_QUERY,
        variables: { id },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
      },
    ];
    patientMutationObj['awaitRefetchQueries'] = true;
    const client = this.props.client;

    // Get the pending save count and increment it by 1
    const apolloCache = await this.getApolloCache();
    let currentPendingSaveCount = apolloCache.currentPendingSaveCount + 1;
    let saveErrorCount = apolloCache.saveErrorCount;
    client &&
      client.writeQuery({
        query: gql`
          query {
            pendingSaveCount
          }
        `,
        data: {
          pendingSaveCount: currentPendingSaveCount,
        },
      });
    this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });

    // Save the updated data. Once done deincrement the pending save count. If any erros saving increment the saveErrorCount
    client
      ?.mutate(patientMutationObj)
      .then((result): void => {
        if (result.data.updatePatient.errors) {
          saveErrorCount++;
        }
      })
      .catch((): void => {
        this.props.showSavingErrorModal(false, this.props.history.push);
        saveErrorCount++;
      })
      .finally(async (): Promise<void> => {
        // Reload the save count incase it was set elsewhere during the run of this function
        const apolloCache = await this.getApolloCache();
        currentPendingSaveCount = apolloCache.currentPendingSaveCount - 1;
        client &&
          client.writeQuery({
            query: gql`
              query {
                pendingSaveCount
                saveErrorCount
              }
            `,
            data: {
              pendingSaveCount: currentPendingSaveCount,
              saveErrorCount: saveErrorCount,
            },
          });
        this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });
      });
  };

  /*private autosaved = async (
    patient: PatientCoverage,
    key: string,
    value: string,
    type: string = 'String',
  ): Promise<void> => {
    let client = this.props.client;
    let update = {
      key,
      value,
      type,
    };

    var updates = [update];
    // Get the pending save count and increment it by 1
    let apolloCache = await this.getApolloCache();
    let currentPendingSaveCount = apolloCache.currentPendingSaveCount + 1;
    let saveErrorCount = apolloCache.saveErrorCount;

    client.writeData({ data: { pendingSaveCount: currentPendingSaveCount } });
    this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });

    // Save the updated data. Once done deincrement the pending save count. If any erros saving increment the saveErrorCount
    client
      .mutate(this.props.getPatientMutation(patient, updates))
      .then((result: { data: UpdatePatientResult }): void => {
        if (result.data.updatePatient.errors) {
          saveErrorCount++;
        }
      })
      .catch((): void => {
        this.props.showSavingErrorModal(false, this.props.history.push);
        saveErrorCount++;
      })
      .finally(
        async (): Promise<void> => {
          // Reload the save count incase it was set elsewhere during the run of this function
          let apolloCache = await this.getApolloCache();
          currentPendingSaveCount = apolloCache.currentPendingSaveCount - 1;
          client.writeData({ data: { pendingSaveCount: currentPendingSaveCount, saveErrorCount: saveErrorCount } });
          this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });
        },
      );
  };*/

  private getApolloCache = async (): Promise<{
    currentPendingSaveCount: number;
    saveErrorCount: number;
    registrationPagesViewed: string[];
  }> => {
    const { client } = this.props;
    if (!client) throw new Error('Client not found');
    try {
      const apolloCache = await client.query({ query: GET_APOLLO_CACHE });
      const currentPendingSaveCount = apolloCache.data.pendingSaveCount;
      const saveErrorCount = apolloCache.data.saveErrorCount;
      const registrationPagesViewed = apolloCache.data.registrationPagesViewed;
      return {
        currentPendingSaveCount: currentPendingSaveCount,
        saveErrorCount: saveErrorCount,
        registrationPagesViewed: registrationPagesViewed,
      };
    } catch (error) {
      throw error;
    }
  };
}

const apolloComponent = withApollo<Props>(RORegInsuranceApollo);
export default withROCreatePatient(apolloComponent);
