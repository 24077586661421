// eslint-disable-next-line no-use-before-define
import React, { useState, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useFormikContext, FormikProps } from 'formik';
import classNames from 'classnames';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { CREATE_CAREPLAN, UPDATE_CAREPLAN_DIAGNOSIS, GET_CAREPLAN } from '../Queries';
import {
  GET_ICD10_CATEGORY_LIST,
  GET_ORDERED_DIAGNOSIS_LIST,
  GET_DIAGNOSIS,
  UPDATE_DIAGNOSIS,
  GET_CLINEX_CODES,
  LOG_CLINEX_MUTATION,
} from './Queries';
import { Region } from 'shared-components/enums';
import { BaseAutocomplete } from 'shared-components/components/FormFields';
import {
  DiagnosisCodeType,
  DiagnosisType,
  DEFAULT_DIAGNOSIS_CODE_TYPE,
  DiagnosisCategoryList,
  DiagnosisRouterParams,
} from './Interface';
import { logPage } from 'shared-components/utils';
import ROPatientCarePlanPageFooter from '../Footer';
import { CAREPLAN_PAGES } from '../Constants';
import { LoadingSpinner } from 'shared-components/components';
import CareplanContainer from '../Container';
import { CareplanBanners } from '../Banner/Banner';
import { intakePageMapping } from './Intake/IntakePage';
import { CREATE_DIAGNOSIS_INTAKE } from './Intake/Queries';
import useDiagnosisWorkflow from './useDiagnosisWorkflow';
import { SelectOptionType } from 'shared-components/components/FormFields';
import { Stack, Tab, Tabs, Grid, useTheme, Button, Typography } from '@mui/material';
import { CheckCircle, Search } from '@mui/icons-material';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;
const NEW_PRIMARY = 'newPrimary';
const NEW_METASTASIS = 'newMetastasis';
interface State {
  selectedDiagCode: string;
  selectedCategoryIdx: number;
  selectedTumourSteam: string;
}

const CLINEX = 0;
const FREQUENT = 1;
const ICD10 = 2;

const ROPatientDiagnosisICD10Page = (): JSX.Element => {
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const startTime = useRef<number>(new Date().getTime());
  const match = useRouteMatch<DiagnosisRouterParams>();
  const { id: patientId, careplanId, page, operation, diagnosisId, oncologyType } = match.params;
  const { values, setFieldValue }: FormikProps<any> = useFormikContext();
  const [updatingDxCode, setUpdatingDxCode] = useState(false);
  const [selectedTab, setSelectedTab] = useState(FREQUENT);
  const [createIntake, { loading: createIntakeLoading, called: createIntakeCalled }] =
    useMutation(CREATE_DIAGNOSIS_INTAKE);
  const { hasExistingPrimaryDiagnosis, createRelatedDiagnosisWithContext } = useDiagnosisWorkflow();
  const { data: careplan } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });
  const [setDiagnosis] = useMutation(UPDATE_DIAGNOSIS, {
    refetchQueries: [
      { query: GET_DIAGNOSIS, variables: { diagnosisId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setUpdatingDxCode(false);
    },
  });
  const [createCareplan] = useMutation(CREATE_CAREPLAN);
  const [saveCareplanDiagnosis] = useMutation(UPDATE_CAREPLAN_DIAGNOSIS, {
    onCompleted: ({ data }: any) => {
      // update the Id iun the url
      history.push(`/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${data.diagnosis}/${page}`);
    },
  });
  const [hasClinex, setHasClinex] = useState(false);
  const [clinexSuggestions, setClinexSuggestions] = useState([]);
  const [clinexUuid, setClinexUuid] = useState<string>();
  const [state, setState] = useState<State>({
    selectedDiagCode: '',
    selectedCategoryIdx: 0,
    selectedTumourSteam: values.selectedTumourStream,
  });
  const [selectedOption, setSelectedOption] = useState<SelectOptionType | undefined>(undefined);
  const [logEvent] = useMutation(LOG_CLINEX_MUTATION);
  const [searchOptions, setSearchOptions] = useState<DiagnosisCodeType[]>([]);
  const [frequentlyUsed, setFrequentlyUsed] = useState<DiagnosisCodeType[]>([]);
  const [diagonsisCategoryList, setDiagnosisCategoryList] = useState<DiagnosisCategoryList[]>([]);
  const { loading, data: diagnosisData } = useQuery(GET_DIAGNOSIS, {
    variables: { diagnosisId: diagnosisId },
    fetchPolicy: 'cache-and-network',
  });

  const { loading: loadingDiagnosisList, data: diagnosisListData } = useQuery(GET_ORDERED_DIAGNOSIS_LIST, {
    variables: { isPrimaryDiagnosis: location.pathname.includes('primary'), patientId },
    onCompleted: (data): void => {
      setFrequentlyUsed(data.diagnosisCodeList);
    },
    fetchPolicy: 'cache-and-network',
  });

  useQuery(GET_ICD10_CATEGORY_LIST, {
    variables: { isPrimaryDiagnosis: operation === NEW_PRIMARY },
    onCompleted: (data: any): void => {
      if (data?.diagnosisCategoryList) {
        const dataCopy = { ...data };
        const reducedSortOptions = dataCopy.diagnosisCategoryList.reduce((acc: any, curr: any): any => {
          if (curr?.diagnosisList.length) {
            const newDiagList = curr.diagnosisList.map((diagItem: any) => {
              const newDiagItem = {
                ...diagItem,
                tumourStream: {
                  name: curr?.tumourStream,
                },
              };
              return newDiagItem;
            });
            acc = [...acc, ...newDiagList];
            return acc;
          } else return [];
        }, []);
        setSearchOptions(reducedSortOptions);
        const sortedDiagnosisList = [...dataCopy.diagnosisCategoryList].sort((a: any, b: any) =>
          a.tumourStream.localeCompare(b.tumourStream),
        );
        setDiagnosisCategoryList(sortedDiagnosisList);
      }

      if (data?.diagnosisCategoryList[0]) {
        setState({ ...state, selectedTumourSteam: data.diagnosisCategoryList[0].tumourStream });
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading: loadingClinex, data: clinexData } = useQuery(GET_CLINEX_CODES, {
    variables: { patientId: patientId },
    onCompleted: (data: any) => {
      const suggestions = data.clinexDiagnosisCode.diagnosisList;
      const uuid = data.clinexDiagnosisCode.clinexUuid;
      setClinexUuid(uuid);
      setClinexSuggestions(suggestions);
      const clinexSuggestionsToggle = suggestions?.length > 0 && region !== Region.UK;
      if (suggestions?.length > 0 && region !== Region.UK && operation !== NEW_METASTASIS) {
        setHasClinex(clinexSuggestionsToggle);
        setState({ ...state });
        setSelectedTab(CLINEX);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const moveToNextPage = (careplanID: any) => {
    // Reset tab to default on page change
    setSelectedTab(FREQUENT);
    if (
      operation === NEW_PRIMARY &&
      (diagnosis.isPrimaryDiagnosis ||
        (diagnosis.relatedPrimaryDiagnosis && diagnosis.relatedPrimaryDiagnosis.id && diagnosis.diagnosisCode))
    ) {
      return history.push(
        `/${oncologyType}/patient/${patientId}/careplan/${careplanID}/diagnosis/${diagnosisId}/${CAREPLAN_PAGES.STAGING}`,
      );
    }
    if (!hasExistingPrimaryDiagnosis()) {
      // need to check if the related is created then don't create it again
      if (diagnosis.relatedPrimaryDiagnosis && diagnosis.relatedPrimaryDiagnosis.id) {
        return history.push(
          `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/primary/newPrimary`,
        );
      }
      createRelatedDiagnosisWithContext((newDiagnosisId: string) => {
        // set this value in the formik as the page is not reloaded in the related primary page
        setFieldValue('diagnosis', {
          ...values.diagnosis,
          relatedPrimaryDiagnosis: {
            ...values.diagnosis?.relatedPrimaryDiagnosis,
            id: newDiagnosisId,
          },
        });
        return history.push(
          `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/primary/newPrimary`,
        );
      });
    } else {
      return history.push(
        `/${oncologyType}/patient/${patientId}/careplan/${careplanID}/diagnosis/${diagnosisId}/primary`,
      );
    }
  };
  if (
    (loading && !values?.diagnosis) ||
    (loadingClinex && !clinexData?.clinexDiagnosisCode?.diagnosisList) ||
    loadingDiagnosisList ||
    !diagnosisListData
  ) {
    return (
      <LoadingSpinner
        loadingText={'Loading Diagnosis Selection'}
        subtitle={'Please wait while we set things up for you'}
      />
    );
  }

  const updateCareplanDiagnosis = (currentDiangosis: DiagnosisType, relatedDiagnosis: DiagnosisType) => {
    // Secondary flow
    if ([NEW_PRIMARY, NEW_METASTASIS].includes(page)) {
      if (currentDiangosis.isPrimaryDiagnosis) {
        saveCareplanDiagnosis({
          variables: {
            careplanId,
            diagnosisId: currentDiangosis.id,
          },
        });
      }
      if (!currentDiangosis.isPrimaryDiagnosis) {
        saveCareplanDiagnosis({
          variables: {
            careplanId,
            diagnosisId: currentDiangosis.id,
            relatedPrimaryDiagnosisId: relatedDiagnosis.id,
          },
        });
      }
    }
  };

  const diagnosis = values?.diagnosis;
  const currentDiagnosis =
    (operation === NEW_PRIMARY && diagnosis?.isPrimaryDiagnosis) ||
    (operation === NEW_METASTASIS && !diagnosis?.isPrimaryDiagnosis)
      ? diagnosis
      : diagnosis?.relatedPrimaryDiagnosis;
  const searchLabel = operation === NEW_PRIMARY ? 'primary' : 'metastasis';

  const handleSelectDiagCode = (diagnosisCode: DiagnosisCodeType, clearSelectedOption = true): void => {
    if (operation === NEW_PRIMARY) {
      const newPrimaryDiagnosis = {
        diagnosisCode: diagnosisCode.diagnosisCode,
        diagnosisDescription: diagnosisCode.diagnosisDescription,
        tumourStream: {
          name: diagnosisCode.tumourStream.name,
        },
      };
      if (values.diagnosis.isPrimaryDiagnosis) {
        setFieldValue('diagnosis', {
          ...values.diagnosis,
          diagnosisCode: { ...values.diagnosis?.diagnosisCode, ...newPrimaryDiagnosis },
        });
      } else {
        setFieldValue('diagnosis', {
          ...values.diagnosis,
          relatedPrimaryDiagnosis: {
            ...values.diagnosis?.relatedPrimaryDiagnosis,
            diagnosisCode: {
              ...values.diagnosis?.relatedPrimaryDiagnosis?.diagnosisCode,
              ...newPrimaryDiagnosis,
            },
          },
        });
      }
    }
    if (operation === NEW_METASTASIS) {
      const newMetastasisDiagnosis = {
        diagnosisCode: diagnosisCode.diagnosisCode,
        description: diagnosisCode.diagnosisDescription,
      };
      setFieldValue('diagnosis', {
        ...values.diagnosis,
        diagnosisCode: {
          ...values.diagnosis?.diagnosisCode,
          ...newMetastasisDiagnosis,
        },
      });
    }

    if (!diagnosisCode.id) return;
    setState({ ...state, selectedDiagCode: diagnosisCode.id });
    let stageType = 'clinical';
    if (diagnosisCode.tumourStream.name === 'Breast') {
      stageType = 'pathological';
    }
    if (
      (diagnosis.isPrimaryDiagnosis && operation === NEW_PRIMARY) ||
      (!diagnosis.isPrimaryDiagnosis && operation === NEW_METASTASIS)
    ) {
      setUpdatingDxCode(true);
      setDiagnosis({ variables: { id: diagnosis.id, diagnosisCodeId: diagnosisCode.id, stageType: stageType } });
    } else {
      const relatedID = diagnosis.relatedPrimaryDiagnosis ? diagnosis.relatedPrimaryDiagnosis.id : '';
      if (relatedID !== '' && !diagnosis.isPrimaryDiagnosis) {
        setUpdatingDxCode(true);
        updateCareplanDiagnosis(diagnosis.id, relatedID);
        setDiagnosis({
          variables: {
            id: relatedID,
            diagnosisCodeId: diagnosisCode.id,
            stageType: stageType,
          },
        });
      } else if (diagnosis.relatedPrimaryDiagnosis) {
        setUpdatingDxCode(true);
        setDiagnosis({
          variables: {
            id: diagnosis.relatedPrimaryDiagnosis.id,
            diagnosisCodeId: diagnosisCode.id,
            stageType: stageType,
          },
        });
      }
    }
    if (clearSelectedOption) setSelectedOption(undefined);
  };
  const checkCurrentDiagnosisCode = (): boolean => {
    // Return true if the current diagnosis code is not selected or is updating (i.e. mutation is in progress)
    // Check that Create Intake mutation is not in progress
    return (
      (currentDiagnosis &&
        (typeof currentDiagnosis.diagnosisCode === 'undefined' || currentDiagnosis.diagnosisCode === null)) ||
      updatingDxCode ||
      createIntakeLoading
    );
  };

  const renderNoListSearchResults = (): JSX.Element => {
    return (
      <div>
        <span className="icd10-cate-item">No data available</span>
      </div>
    );
  };

  const renderListContents = (data: DiagnosisCategoryList[]): JSX.Element | JSX.Element[] | undefined => {
    if (data && data.length > 0) {
      return renderListSearchResults(data);
    } else {
      return renderNoListSearchResults();
    }
  };

  const renderDiagnosisGrid = (data: DiagnosisCodeType[], bestMatch: boolean): JSX.Element[] => {
    if (data && data.length > 0) {
      const searchResults = data.map((code: DiagnosisCodeType): JSX.Element => {
        const active = currentDiagnosis?.diagnosisCode?.diagnosisCode === code.diagnosisCode;
        return (
          <div style={{ display: 'flex' }} key={code.id}>
            <Button
              className={classNames('code-list-item-icd10', {
                'best-match': bestMatch && code.certainty === 'high',
              })}
              data-testid="item-description"
              value={code.id}
              onClick={(): void => {
                currentDiagnosis && handleSelectDiagCode(code);
                setState({ ...state, selectedDiagCode: code.id });
              }}
              style={{
                width: '100%',
                border: active ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[300]}`,
              }}>
              <Stack width={1} height={1} paddingLeft={2} justifyContent="top">
                <Stack direction="row" alignItems="center" justifyContent="left">
                  {active && <CheckCircle color="primary" style={{ fontSize: '32px' }} sx={{ paddingRight: '8px' }} />}
                  <Typography variant="h6" data-testid="item-code">
                    {code.diagnosisCode} {code.tumourStream.name}
                  </Typography>
                </Stack>
                <Typography variant="body1">{code.diagnosisDescription.trim()}</Typography>
              </Stack>
            </Button>
          </div>
        );
      });
      return searchResults;
    }
    return [];
  };

  const renderListSearchResults = (data: DiagnosisCategoryList[]): JSX.Element[] | undefined => {
    if (data && data.length > 0) {
      const searchResults = data.map((cat: DiagnosisCategoryList, idx: number): JSX.Element => {
        const active = idx === state.selectedCategoryIdx;
        return (
          <Stack
            key={`${cat.tumourStream}_${idx}`}
            className={classNames('icd10-cate-item', { active })}
            onClick={(): void => {
              setState({ ...state, selectedCategoryIdx: idx, selectedTumourSteam: cat.tumourStream });
            }}>
            <Typography
              variant="body1"
              className={classNames({ active })}
              style={{
                borderLeft: active ? `4px solid ${theme.palette.primary.main}` : 'none',
                borderRadius: `${idx === 0 ? '4px 0 0 0' : '0'}`,
              }}>
              {cat.tumourStream}
            </Typography>
          </Stack>
        );
      });
      return searchResults;
    }
    return;
  };

  const renderICD10List = (data: DiagnosisCategoryList[]): JSX.Element[][] | undefined => {
    if (data && data.length > 0) {
      const searchResults = data.map((cat: DiagnosisCategoryList): JSX.Element[] => {
        if (state.selectedTumourSteam !== cat.tumourStream) return [];
        return cat.diagnosisList.map((code: DiagnosisCodeType): JSX.Element => {
          const active = state.selectedDiagCode === code.id;
          return (
            <button
              key={code.id}
              className={classNames('code-list-item', {
                active,
              })}
              style={{
                borderLeft: active ? `4px solid ${theme.palette.primary.main}` : 'none',
              }}
              value={code.id}
              onClick={(): void => {
                handleSelectDiagCode({ ...code, tumourStream: { name: cat.tumourStream } });
              }}>
              <Typography variant="body1" sx={{ lineHeight: '16px' }}>
                {code.diagnosisCode}-{cat.tumourStream}: {code.diagnosisDescription}
              </Typography>
            </button>
          );
        });
      });
      return searchResults;
    }
    return;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '15px',
    gridAutoRows: '1fr',
    gridRowGap: '5px',
    maxWidth: '100%',
  };

  return (
    <div className="main-container-wrapper">
      <CareplanBanners data={careplan} />
      <CareplanContainer>
        <div className="page-header">
          <Typography variant="h5">{`${
            operation === NEW_PRIMARY ? 'Primary' : 'Metastasis'
          } Diagnosis Selection`}</Typography>
        </div>
        <div className="create-diagnosis-icd10-list">
          <Stack id="icd10-cate-list-conainer">
            <div
              className={classNames({
                primary: page === NEW_PRIMARY,
              })}>
              <div
                className="create-diagnosis-icd10-list-header"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '16px',
                  paddingBottom: '16px',
                }}>
                <Tabs className="freq-icd10-menu" value={selectedTab} onChange={handleTabChange}>
                  {hasClinex && operation !== NEW_METASTASIS && (
                    <Tab label="Suggested diagnosis" className="suggested" value={0} />
                  )}
                  <Tab label="Frequently used" className="freq" value={1} />
                  <Tab label="ICD 10" className="icd10" value={2} />
                </Tabs>
                <BaseAutocomplete
                  id="icd10Select"
                  value={selectedOption}
                  fullWidth
                  disableClearable
                  onChange={(option: SelectOptionType | string) => {
                    const value = typeof option === 'string' ? option : option?.value;
                    const selectedOption = typeof option === 'string' ? { label: option, value: option } : option;
                    setSelectedOption(selectedOption);
                    if (value) {
                      const selectedId = value;
                      const code: DiagnosisCodeType = searchOptions.find(
                        ({ id }) => id === selectedId,
                      ) as DiagnosisCodeType;
                      handleSelectDiagCode(code, false);
                    } else {
                      handleSelectDiagCode(DEFAULT_DIAGNOSIS_CODE_TYPE, false);
                    }
                  }}
                  options={searchOptions.map(
                    (option: any): SelectOptionType => ({
                      value: option.id.toString(),
                      label: option.diagnosisCode + ' - ' + option.diagnosisDescription,
                    }),
                  )}
                  placeholder={`Search ${searchLabel} diagnoses…`}
                  sx={{
                    width: '400px',
                  }}
                  InputProps={{
                    startAdornment: <Search color="primary" sx={{ marginLeft: '8px' }} />,
                  }}
                />
              </div>
              <Stack id="icd10-list-content">
                {hasClinex && selectedTab === CLINEX && (
                  <Stack>
                    <Typography variant="body1" marginBottom={3}>
                      The following diagnosis codes are suggested by Clinex based on the patient's pathology and imaging
                      reports.
                    </Typography>
                    <div className="code-list-container">
                      <div className="code-list" data-testid="top-9-list">
                        <div style={gridStyle}>{renderDiagnosisGrid(clinexSuggestions, true)}</div>
                      </div>
                    </div>
                  </Stack>
                )}
                {selectedTab === FREQUENT && (
                  <Stack>
                    <div className="code-list-container">
                      <div className="code-list" data-testid="top-9-list">
                        <div style={gridStyle}>{renderDiagnosisGrid(frequentlyUsed.slice(0, 9), false)}</div>
                      </div>
                    </div>
                  </Stack>
                )}
                {selectedTab === ICD10 && (
                  <Stack className="icd10-list">
                    <div className="code-list-container" style={{ maxHeight: '600px' }}>
                      <div className="code-list">
                        <Grid container sx={{ border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '5px' }}>
                          <Grid item md={3} xs={12} className="icd10-cate-list flex-column" id="icd10-cate-list">
                            {renderListContents(diagonsisCategoryList)}
                          </Grid>
                          <Grid item md={9} xs={12} className="px-0" sx={{ overflowY: 'auto', maxHeight: '600px' }}>
                            {renderICD10List(diagonsisCategoryList)}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Stack>
                )}
              </Stack>
            </div>
          </Stack>
        </div>
      </CareplanContainer>
      <ROPatientCarePlanPageFooter
        onBack={(): void => {
          // Previous page is primary if we are in newPrimary or metastasis if we are in newMetastasis
          if (operation === NEW_PRIMARY) {
            history.push(
              `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/primary`,
            );
          } else {
            history.push(
              `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/metastasis`,
            );
          }
        }}
        backDisabled={diagnosisData && !diagnosisData.diagnosis.draft}
        onNext={(): void => {
          let cpId = careplanId;
          if (careplanId === 'create') {
            // TODO: Fix this logic
            createCareplan({ variables: { patientId, diagnosisId } })
              .then((result): void => {
                cpId = result.data.createCareplan.careplan.id;
              })
              .then(() => {
                // Next page is staging for primary work flow
                // In secondary workflow the next page is primary selection
                moveToNextPage(cpId);
              });
          }
          if (
            diagnosis &&
            values.intake &&
            diagnosis.id &&
            values.intakePage &&
            !values.intake[intakePageMapping[values.intakePage as keyof typeof intakePageMapping]?.key] &&
            !createIntakeCalled
          ) {
            const { createVariable } = intakePageMapping[values.intakePage as keyof typeof intakePageMapping];
            createIntake({
              variables: { diagnosisId: diagnosis.id, careplanId: careplanId, intakeData: createVariable },
            }).then((res: any) => {
              setFieldValue('intake', res.data.createIntake.intake);
            });
          }

          const eventData = {
            page: page,
            diagnosisId: diagnosisId,
            isPrimaryDiagnosis: diagnosis?.isPrimaryDiagnosis,
            diagnosisCode:
              page === 'primary' && !diagnosis?.isPrimaryDiagnosis
                ? diagnosis?.relatedPrimaryDiagnosis?.diagnosisCode?.diagnosisCode
                : diagnosis?.diagnosisCode?.diagnosisCode,
            tumourStream: diagnosis.diagnosisCode?.tumourStream?.name,
            operation: operation,
            patientId: patientId,
            careplanId: cpId === 'create' ? '' : cpId,
            clinexResponseUuid: clinexUuid,
          };
          logPage(startTime?.current, eventData, logEvent);
          moveToNextPage(cpId);
        }}
        nextDisabled={checkCurrentDiagnosisCode()}
      />
    </div>
  );
};

export default ROPatientDiagnosisICD10Page;
