import Highcharts from 'highcharts';
import { syncConfig, creditsConfig, BottomChartParams, subExportingConfig, loadSeeMoreButton } from '../DefaultConfig';
import { SUB_CHART_LIMIT } from '../../DashboardConfigs/DefaultConfig';

const SubChartBar = ({
  title,
  connector,
  cell,
  filterOverride,
  seeMore,
  precision = 0,
  theme,
  yAxisMax = 100,
  toggleSeeMore,
  dataLength,
  dataTotal,
  numLabels = 2,
  dataLabelTitle = 'Count',
  dataLabelTitleWidth = 50,
}: BottomChartParams): Dashboards.Board.Options => {
  const positionText = (
    chart: Highcharts.Chart & { labelHeader1?: Highcharts.SVGElement; labelHeader2?: Highcharts.SVGElement },
  ) => {
    const xPosition1Offset = numLabels === 2 ? 68 : 130;

    const xPosition1 = chart.chartWidth - dataLabelTitleWidth - xPosition1Offset;
    const xPosition2 = chart.chartWidth - dataLabelTitleWidth - 20;

    if (chart.labelHeader1 && chart.labelHeader2) {
      chart.labelHeader1.attr({ x: xPosition1 });
      chart.labelHeader2.attr({ x: xPosition2 });
    } else {
      if (!chart.labelHeader1) {
        chart.labelHeader1 = chart.renderer
          .text(dataLabelTitle, xPosition1, 60)
          .css({
            color: theme.palette.text.primary,
            fontSize: '12px',
            fontFamily: 'Poppins, sans-serif',
          })
          .add();
      }

      if (numLabels === 2) {
        chart.labelHeader2 = chart.renderer
          .text('% of total', xPosition2, 60)
          .css({
            color: theme.palette.text.secondary,
            fontSize: '12px',
            fontFamily: 'Poppins, sans-serif',
          })
          .add();
      }
    }
  };

  const dataLabels = [
    {
      enabled: true,
      formatter: function () {
        return (
          '<span style="font-weight: 400; font-family: Poppins, sans-serif">' + this.y?.toFixed(precision) + '</span>'
        );
      },
      align: 'right',
      verticalAlign: 'middle',
      alignTo: 'plotEdges',
      x: numLabels === 2 ? 50 : 40,
      y: -1,
      crop: false,
      overflow: 'allow',
    },
    {
      enabled: true,
      formatter: function () {
        const percentage = (this.y !== null && this.y !== undefined ? (this.y / dataTotal!) * 100 : 0).toFixed(0);
        return (
          '<span style="color: #696969; font-weight: 400; font-family: Poppins, sans-serif">' +
          percentage +
          '%' +
          '</span>'
        );
      },
      align: 'right',
      verticalAlign: 'middle',
      alignTo: 'plotEdges',
      x: 110,
      y: -1,
      crop: false,
      overflow: 'allow',
    },
  ];

  if (numLabels !== 2) {
    dataLabels.pop();
  }

  return {
    components: [
      {
        cell: cell,
        connector: { id: connector },
        sync: syncConfig,
        type: 'Highcharts',
        chartOptions: {
          exporting: subExportingConfig,
          chart: {
            type: 'bar',
            spacing: numLabels === 2 ? [20, 130, 70, 20] : [20, 60, 70, 20],
            height: 320,
            events: {
              load() {
                if (dataLength! > SUB_CHART_LIMIT) {
                  loadSeeMoreButton(this, toggleSeeMore, seeMore, cell);
                }

                positionText(this);

                this.redraw = function () {
                  Highcharts.Chart.prototype.redraw.call(this);
                  positionText(this);
                };
              },
            },
          },
          title: {
            text: title,
            align: 'left',
          },
          subtitle: {
            text: filterOverride,
            align: 'left',
            style: {
              color: 'rgba(8, 30, 67, 0.60)',
              fontSize: '14px',
            },
          },
          xAxis: {
            type: 'category',
            scrollbar: {
              enabled: true,
            },
            ...(numLabels === 2
              ? {
                  labels: {
                    align: 'left',
                    x: 8,
                  },
                }
              : {}),
          },
          yAxis: {
            reversed: true,
            visible: false,
            max: yAxisMax,
            scrollbar: {
              enabled: true,
            },
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          credits: creditsConfig,
          plotOptions: {
            bar: {
              borderRadius: 8,
              pointWidth: 9,
            },
            series: {
              cursor: 'pointer',
              dataLabels: dataLabels as Highcharts.PlotSeriesDataLabelsOptions,
            },
          },
        },
      },
    ],
  };
};

export default SubChartBar;
