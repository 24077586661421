import React, { useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { SavingStatus } from 'shared-components/enums';

import { ExtendLock } from 'op-components';
import { LoadingSpinner } from 'shared-components/components';

import RegistrationConflicts from './RegistrationConflicts';
import { useErrorModalContext } from 'op-contexts';
import ModalResolveConflicts from 'shared-components/components/Modals/ModalResolveConflicts/ModalResolveConflicts';

const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($patientId: ID!) {
    patient(id: $patientId) {
      id
      lock {
        lockedBy
        readOnly
        lockedByName
      }
      firstName
      middleName
      lastName
      ida
      gender
      genderIdentification
      genderIdentificationOther
      dob
      namePrefix
      preferredName
      dobRawDay
      dobRawMonth
      dobRawYear
      primaryPhone
      secondaryPhone
      email
      residentialAddressLine1
      residentialAddressLine2
      residentialAddressCity
      residentialAddressState
      residentialAddressPostcode
      residentialAddressCountry
      postalAddressSameAsResidential
      postalAddressLine1
      postalAddressLine2
      postalAddressCity
      postalAddressState
      postalAddressPostcode
      postalAddressCountry
      address {
        id
        formattedAddress
      }
      attachments {
        id
        filename
        documentType
        filesize
        submitted
        url
        encounterDate
      }
      emergencyContact {
        id
        firstName
        lastName
        relationship
        mobilePhoneNumber
        homePhoneNumber
        email
        authorisedForEnquiries
        supportPerson
      }
      nextOfKinContact {
        id
        firstName
        lastName
        relationship
        mobilePhoneNumber
        homePhoneNumber
        email
        authorisedForEnquiries
        supportPerson
      }
      altContactProvided
      referringFirstName
      referringLastName
      referringPractice
      referringIsInMosaiq
      gpFirstName
      gpLastName
      gpPractice
      gpIsInMosaiq
      referrersOtherSpecialists
      healthMedicareDvaOption
      healthMedicareNumber
      healthMedicareIrn
      healthMedicareExpiry
      healthDvaExpiry
      healthDvaNumber
      healthDvaType
      healthPrivateHealthInsurance
      healthPrivateHealthFundName
      healthPrivateHealthFundNumber
      healthPensionCard
      healthPensionCardNumber
      healthPensionCardExpiry
      countryOfBirth
      languageAtHome
      occupation
      heritage
      maritalStatus
      interpreterRequired
      informationNoticeContent
      informationNoticeAccepted
      additionalComments
      diffMqData
    }

    titleRefData: listData(category: "nameTitle") {
      id
      name
    }
    relationshipsRefData: listData(category: "relationships") {
      id
      name
    }
    heritageRefData: listData(category: "heritage") {
      id
      name
    }
    maritalStatusRefData: listData(category: "maritalStatus") {
      id
      name
    }
    countryOfBirthRefData: listData(category: "countryOfBirth") {
      id
      name
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    genderIdentificationRefData: listData(category: "genderIdentification") {
      id
      name
    }
    languageSpokenRefData: listData(category: "languages") {
      id
      name
    }
    medicareTypeRefData: listData(category: "healthFund") {
      id
      name
      appKey
    }
    dvaTypeRefData: listData(category: "dvaCardType") {
      id
      name
      appKey
    }
    ausStateRefData: listData(category: "australiaStates") {
      id
      name
    }
    user {
      id
      isPso
    }
    profile(id: $patientId) {
      id
      patientFeatures(id: $patientId) {
        doctorReferralLookupEnabled
      }
    }
  }
`;

const UPDATE_REGO_DATA_CONFLICTS = gql`
  mutation UpdateRegistrationDataConflicts($patientId: ID!, $diffSelectedKey: String!, $diffSelectedValue: String!) {
    updateRegistrationDataConflicts(
      patientId: $patientId
      diffSelectedKey: $diffSelectedKey
      diffSelectedValue: $diffSelectedValue
    ) {
      patient {
        id
        diffMqData
      }
    }
  }
`;

const RESOLVE_PATIENT_CONFLICTS = gql`
  mutation resolveDataConflicts($patientId: ID!) {
    resolvePatientConflicts(patientId: $patientId) {
      patient {
        id
        diffMqData
      }
    }
  }
`;

interface Props {
  exitForm: any;
}

const RegistrationConflictsApollo = ({ exitForm }: Props): JSX.Element => {
  const [saveStatus, setSaveStatus] = useState('');
  const [resolveConflictsModalOpen, setResolveConflictsModalOpen] = useState(false);
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();

  const { data, loading } = useQuery(PATIENT_AND_USER_DETAILS_QUERY, {
    variables: { patientId },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any): any => {
      return data;
    },
  });

  const patientAndUserData = useMemo(() => data?.patient ?? {}, [data]);

  const [updateRegoDataConflicts] = useMutation(UPDATE_REGO_DATA_CONFLICTS, {
    onError: (): void => {
      setSaveStatus(SavingStatus.FAILED);
    },
    onCompleted: (data: any): any => {
      setSaveStatus(SavingStatus.SAVED);
      return data;
    },
  });

  const [resolvePatientConflicts] = useMutation(RESOLVE_PATIENT_CONFLICTS, {
    onCompleted: (): void => {
      history.push({ pathname: `/patient/${patientId}/registration/summary` });
    },
  });

  return (
    <>
      {loading && <LoadingSpinner />}
      {data && (
        <>
          <ExtendLock accessPatientId={patientAndUserData.id} />
          <RegistrationConflicts
            patient={patientAndUserData}
            handleShowSaveExitConfirm={() => exitForm(true)}
            refData={{
              titleRefData: data.titleRefData,
              relationshipsRefData: data.relationshipsRefData,
              heritageRefData: data.heritageRefData,
              genderRefData: data.genderRefData,
              genderIdentificationRefData: data.genderIdentificationData,
              maritalStatusRefData: data.maritalStatusRefData,
              countryOfBirthRefData: data.countryOfBirthRefData,
              languageSpokenRefData: data.languageSpokenRefData,
              medicareTypeRefData: data.medicareTypeRefData,
              ausStateRefData: data.ausStateRefData,
            }}
            updatePatient={updateRegoDataConflicts}
            resolvePatientConflicts={() => setResolveConflictsModalOpen(true)}
            saveStatus={saveStatus}
          />
        </>
      )}

      <ModalResolveConflicts
        isOpen={resolveConflictsModalOpen}
        dismissFunction={() => setResolveConflictsModalOpen(false)}
        resolveConflictsPage={() => resolvePatientConflicts({ variables: { patientId: patientAndUserData.id } })}
      />
    </>
  );
};

export default RegistrationConflictsApollo;
