// eslint-disable-next-line no-use-before-define
import { gql, useMutation, useQuery } from '@apollo/client';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { RegistrationContext } from 'op-contexts';
import { useContext, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LoadingSpinner } from 'shared-components/components';
import { Logger, navigateToExternalURL } from 'shared-components/utils';
import PatientNavigation from './PatientNavigation';
import { FormContext } from './context';

const logger = new Logger('NewPatientNavigationApollo');

export const PATIENT_FORM_DETAILS_QUERY = gql`
  query PatientFormsDetails($id: ID!, $patientId: ID!) {
    patient(id: $id) {
      id
      resolvedNamePrefix
      firstName
      lastName
      dob
      lastVisitedSection
      address {
        id
        formattedAddress
      }
      userProfile {
        id
        systemState
      }
      regFormStatus
      emrInstance
      emr
    }
    healthAssessment(id: $id) {
      id
      status
    }
    distressThermometerInitial(patientId: $patientId) {
      id
      status
      version
    }
    distressThermometerEot(patientId: $patientId) {
      id
      status
      version
    }
    covidScreening(id: $id) {
      id
      status
    }
    profile {
      id
      toForm
      showDistressThermometer
      showRegistration
      showHealthAssessment
      registrationAccessType
      overallStatus
      patientFeatures {
        distressThermometerEotEnabled
        healthAssessmentEnabled
        covidEnabled
      }
    }
    featureOptions {
      id
      name
      description
      active
    }
  }
`;

const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      errors
    }
  }
`;

const GET_PX_TOKEN_MUTATION = gql`
  mutation GetPXInvite($patientId: ID!) {
    getPxInvite(patientId: $patientId) {
      inviteLink
    }
  }
`;

export interface ProfileNavigation {
  registrationAccessType: string;
  showDistressThermometer: boolean;
  showRegistration: boolean;
  showHealthAssessment: boolean;
  overallStatus: string;
  patientFeatures: {
    distressThermometerEnabled: boolean;
    distressThermometerEotEnabled: boolean;
    healthAssessmentEnabled: boolean;
    covidEnabled: boolean;
  };
  toForm: string;
}

export interface FormStatus {
  id: string;
  status: string;
}

interface Props extends WithApolloClient<{}>, RouteComponentProps<{ patientId: string }> {}

interface LogoutMutationResponse {
  logout: {
    errors?: string[];
  };
}

interface PXTokenMutationResponse {
  getPxInvite: {
    inviteLink: string;
  };
}

const PatientNavigationApollo = (props: Props): JSX.Element => {
  const {
    match: {
      params: { patientId },
    },
    client,
    history,
  }: any = props;
  const { setFormStatusDetails } = useContext(FormContext);
  const { setHaSummaryVisited, setRegistrationSummaryVisited, setAddressPlaceId } = useContext(RegistrationContext);
  const [pxLoading, setPxLoading] = useState(false);

  const [getPxTokenMutation] = useMutation(GET_PX_TOKEN_MUTATION, {
    variables: { patientId },
    onCompleted: (data: PXTokenMutationResponse) => {
      const pxlink = data.getPxInvite.inviteLink;
      logger.info('Redirecting to ', pxlink);
      setPxLoading(false);
      navigateToExternalURL(pxlink);
    },
    onError: () => {
      logger.error('Could not generate PX invite. Patient ID: ', patientId);
      setPxLoading(false);
      logout(data.profile.registrationAccessType);
    },
  });

  const getPXInvite = (): void => {
    setPxLoading(true);
    getPxTokenMutation();
  };

  const logout = (registrationAccessType: string, isDtEot?: boolean): void => {
    client
      .mutate({
        mutation: LOGOUT_MUTATION,
        variables: {},
      })
      .then((response: { data: LogoutMutationResponse }): void => {
        if (response.data.logout.errors != null) {
          logger.error('logout', 'Unable to logout', response.data.logout.errors);
          return;
        }

        // Reset context for new users logging in on same device
        setHaSummaryVisited(false);
        setRegistrationSummaryVisited(false);
        setAddressPlaceId('');

        if (registrationAccessType === 'atHome') {
          history.push({ pathname: '/endSession', state: { isDtEot: isDtEot } });
        } else {
          history.replace('/patient');
        }
      });
  };

  const { loading, data } = useQuery(PATIENT_FORM_DETAILS_QUERY, {
    variables: { id: patientId, patientId },
    fetchPolicy: 'network-only',
    onCompleted: (): void => setFormStatusDetails(data),
  });

  // It doesn't seem the onCompleted above fires reliably, so this helps
  data && setFormStatusDetails(data);

  if (loading || pxLoading) {
    return <LoadingSpinner relativeSpinner={true} />;
  }

  if (data && data.patient && data.profile) {
    const {
      patient,
      profile,
      healthAssessment,
      distressThermometerInitial,
      distressThermometerEot,
      covidScreening,
      featureOptions,
    } = data;
    return (
      <PatientNavigation
        patient={patient}
        profile={profile}
        haStatus={healthAssessment.status}
        covidStatus={covidScreening.status}
        dtForm={distressThermometerInitial}
        dtEotForm={distressThermometerEot}
        featureOptions={featureOptions}
        logout={logout}
        getPXInvite={getPXInvite}
      />
    );
  }

  return <></>;
};

export default withRouter(withApollo<Props>(PatientNavigationApollo));
