import { AuthorizedICDTreatingDepartments, CareplanFilter, CareplanStatusFilter, RoDashboardTabs } from 'op-enums';
import { BaseConfigInterface } from './types';
import { BOOLEAN_OPTIONS, UK_CIED_OPTIONS } from '../TreatmentInfoPage/Interface';

const { CROMWELL } = AuthorizedICDTreatingDepartments;

const uk_config: BaseConfigInterface = {
  SpecialPhysicsConsultVisible: false,
  RequestTemplateChangeActive: false,
  ErrorPopup: {
    Generic:
      "We encountered an error. Please refresh the page. If the issue persists, please contact GenesisCare IT support: <a href='mailto:genesiscare.itservicedesk@genesiscare.co.uk'>genesiscare.itservicedesk@genesiscare.co.uk</a>",
    Practitioner:
      "Your account setup appears to be incomplete or missing information. Please contact GenesisCare IT support: <a href='mailto:genesiscare.itservicedesk@genesiscare.co.uk'>genesiscare.itservicedesk@genesiscare.co.uk</a>",
    Patient: 'We encountered an error. Please refresh the page. If the issue persists, please contact your clinic.',
  },
  ReferringPhysicians: {
    oncologist: 'Responsible Physician',
    generalPractitioner: 'Primary Physician (GP)',
    referringSurgeon: 'Referring Clinician',
    referringSurgeon2: 'Referring Other',
    referringSurgeon3: 'Referring Other',
  },
  DefaultValue: {
    cied: '',
    patientStatus: 'outPatient',
    previousRadioTreatment: '',
    treatmentRORequired: '',
    tumourName: 'tumour ',
  },
  Clinex: {
    betaTesting: false,
  },
  Intake: {
    disabled: true,
    isProstateDisabled: true,
    breastMandatoryFields: ['grade', 'er', 'pgr', 'her2'],
    lungMandatoryFields: ['weightLoss', 'smokingHistory', 'egfr', 'alk', 'pdl1'],
    headNeckMandatoryFields: ['P16', 'smokingHistory'],
    lymphomaMandatoryFields: ['stage', 'aOrBSymptoms', 'extranodal', 'bulkDisease'],
    breastFormVersion: 1,
  },
  PatientSummary: {
    isVisible: true,
    labsEnabled: false,
    RODocumentUpload: true,
    documentFilterField: 'description',
  },
  OutcomesPage: {
    isVisible: false,
    DateFormat: 'DD-MMM-YYYY',
    DateTimeFormat: 'DD MMM YYYY h:mma',
  },
  OTRPage: {
    isVisible: false,
    DateFormat: 'DD-MMM-YYYY',
    DateTimeFormat: 'DD MMM YYYY h:mma',
  },
  DisplayButton: {
    displaySimButton: false,
  },
  DisplayModal: {
    submission: true,
  },
  SimulationPage: {
    SpacerGelWarning: true,
    imagingText: 'Add imaging request',
    imagingCountTitle: 'Imaging',
    disableExtentDefined: true,
    disablePositionSetup: true,
    immobilisationLabel: 'Immobilisation',
  },
  TreatmentInfoPage: {
    mandatoryFields: ['intent', 'treatingDepartment', 'readyForCareDate', 'cied', 'previousRadioTreatment'],
    ReadyForCareDate: true,
    AdditionalBookingInfo: true,
    MDT: true,
    DelayCategory: true,
    PreviousTreatmentRetreatment: true,
    PreviousTreatmentRetreatmentType: true,
    AuthorizedICDTreatingDepartments: [CROMWELL],
    Hormones: true,
    Brachytherapy: true,
    ccip: false,
  },
  PrescriptionPage: {
    MicrodosimetryTld: false,
    WeeklyPhysicsCheck: false,
    BasicDoseCalculation: false,
  },
  SubmissionPage: {
    PlanAimSubmissionType: 'Plan Aim',
    CPOTEnabled: true,
  },
  VolumingPage: {
    UsePrioritizedOARs: false,
  },
  RadiationDashboard: {
    dateTimeFormat: 'DD/MM/YYYY hh:mm A',
    active: RoDashboardTabs.CLINIC_LIST,
    careplanDashboard: {
      careplanStatusFilters: [
        { id: CareplanStatusFilter.DRAFT, name: CareplanStatusFilter.DRAFT, checked: true },
        { id: CareplanStatusFilter.PLAN_AIM, name: CareplanStatusFilter.PLAN_AIM, checked: true },
        { id: CareplanStatusFilter.PRESCRIPTION, name: CareplanStatusFilter.PRESCRIPTION, checked: true },
      ],
      careplanFilters: [{ id: CareplanFilter.UNSUBMITTED, name: CareplanFilter.UNSUBMITTED, checked: false }],
      isPatientSummaryLinkEnabled: true,
    },
    showFilters: false,
  },
  QuestionLabel: {
    CIED: 'Cardiac Device',
    RoRequired: 'Clinical Oncologist required',
    GoldSeeds: 'Fiducials',
    phase: 'Phase',
  },
  QuestionOptions: {
    CIED: UK_CIED_OPTIONS,
    previousRadioTreatment: BOOLEAN_OPTIONS,
  },
  QuestionValidationChildren: {
    CIED: ['ciedPatientDependancy', 'ciedLinacRequired'],
    Staging: {
      Primary: {
        RO: ['laterality', 'diagnosisDate'],
        MO: ['laterality'],
      },
      Metastasis: {
        RO: ['laterality', 'diagnosisDate'],
        MO: [],
      },
    },
  },
  ResetMutationVariableDefaults: {
    CIED: {
      // Yes, there is a spelling mistake with Dependency
      ciedPatientDependancy: '',
      ciedLinacRequired: '',
    },
    CiedProtocolRequired: {},
  },
  GetMutationVariableDefaults: {
    CIED: {
      // Yes, there is a spelling mistake with Dependency
      ciedPatientDependancy: '',
      ciedLinacRequired: '',
    },
    CiedProtocolRequired: {},
  },
  DoseSiteSummary: {
    enabled: false,
    DateFormat: 'DD/MM/YYYY',
  },
  SubmitDiagnosisOnlyAndExit: false,
  CardioReferralField: false,
  Tooltips: {
    hasPriority: false,
    hasClinicalPathologicalStaging: false,
  },
  DefaultTimezone: 'Europe/London',
  DateFormat: 'DD MM YYYY',
  CareplanVoidTimeRestriction: 8,
  CareplanVoidDescription: 'Documentation will be voided and a clinic note will be added into Mosaiq.',
  EmailMaxLength: 40,
  Outage: {
    link: 'https://genesiscare.sharepoint.com/sites/HorizonPortalUK/SitePages/Lumonus-Transition.aspx',
    showBanner: true,
    message: 'Horizon will be offline from 7PM Friday, March 15th to Sunday, March 17th, 2024, GMT',
  },
  Notes: {
    EnableSubmit: true,
    DateTimeFormat: 'DD/MM/YYYY hh:mm A',
  },
  DefaultLocale: 'en-gb',
  SupportEmail: 'itsupport@genesiscare.com.au',
  ShowResetDemoDataMenuLink: false,
  MenuItems: {
    manageProtocols: false,
  },
};

export default uk_config;
