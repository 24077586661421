import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartColumn from '../Charts/ChartColumn';
import SubChartTable from '../Charts/SubCharts/SubChartTable';
import SubChartBar from '../Charts/SubCharts/SubChartBar';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import { DataFunctions } from '../../Utils/dataFunctions';

const FractionationSchedule = ({
  data,
  subChartData,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  ...rest
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight, dateRange),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartColumn({
      selectedPoint: rest.filterOverride,
      aggregation: dateAggregationMap[dateRange!],
      dateRange: dateRange,
      leftSeriesTitle: 'Average Fractions (#)',
      rightSeriesTitle: '',
      leftSeriesFields: ['submittedAt', 'Average Fractions'],
      rightSeriesFields: ['submittedAt', 'Average Fraction Dose'],
      tooltipSuffix: '',
      ...rest,
    }).components?.[0] ?? {},
    SubChartBar({
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      seeMore: seeMoreBottomLeft,
      yAxisMax: DataFunctions.aggregateDataMax(subChartData, 'tumourStream') * 2,
      dataLength: DataFunctions.aggregateData(subChartData, 'tumourStream').length - 1,
      dataTotal: DataFunctions.aggregateDataSum(subChartData, 'tumourStream'),
      ...rest,
    }).components?.[0] ?? {},
    SubChartTable({
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      seeMore: seeMoreBottomRight,
      dataLength: DataFunctions.aggregatePhysicianFractions(subChartData).length - 1,
      ...rest,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
  dateRange: string | undefined,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['submittedAt', 'Average Fractions', 'Average Fraction Dose'],
        data: DataFunctions.aggregateAverageFractions(data, dateAggregationMap[dateRange!]),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(
          subChartData,
          'tumourStream',
          seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT,
        ),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregatePhysicianFractions(subChartData, seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default FractionationSchedule;
