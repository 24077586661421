// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { NetworkStatus, gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';

import { HAMedicalHistoryOtherItem } from 'op-classes';
import { ApolloSaveStatus } from 'op-types';

import { LoadingSpinner } from 'shared-components/components';
import { ListData, Dictionary } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';

import HAMedicalHistoryOther from './HAMedicalHistoryOther';
import {
  HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT,
  HEALTH_ASSESSMENT_WELLBEING_ISSUE_FRAGMENT,
} from 'op-graphql/fragments';
import withHealthAssessment, { WithHealthAssessment } from '../../HealthAssessment';

const logger = new Logger('HAMedicalHistoryOther');

const HA_MEDICAL_OTHER = gql`
  query HealthAssessment($id: ID!) {
    healthAssessment(id: $id) {
      id
      mobilityAid
      mobilityAids {
        id
        ...HealthAssessmentMobilityAid
      }
      wellbeingIssue
      wellbeingIssues {
        id
        ...HealthAssessmentWellbeingIssue
      }
      activeInjury
      activeInjuryReason
      inPain
      inPainReason
      fallen
      fallenReason
      pregnant
      advancedCareDirective
      advancedCareAdditionalInformation
    }
    mobilityAidRefData: listData(category: "mobilityAids") {
      id
      name
    }
    wellbeingIssueRefData: listData(category: "wellbeingIssues") {
      id
      name
    }
  }

  ${HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT}
  ${HEALTH_ASSESSMENT_WELLBEING_ISSUE_FRAGMENT}
`;

interface MedicalHistoryOtherQueryData {
  healthAssessment: HAMedicalHistoryOtherItem;
  mobilityAidRefData: ListData[];
  wellbeingIssueRefData: ListData[];
}

interface Props extends WithHealthAssessment, RouteComponentProps<{ patientId: string; haId: string }> {
  client: any;
  updateSaveStatus: ApolloSaveStatus;
  validateOnLoad: boolean;
}

class HAMedicalHistoryOtherApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
      validateOnLoad,
    } = this.props;

    return (
      <Query<MedicalHistoryOtherQueryData> query={HA_MEDICAL_OTHER} variables={{ id: patientId }}>
        {({ loading, data, refetch, networkStatus }): JSX.Element => {
          // Don't rerender whole component on refetch, otherwise will flash loading and scroll to top
          if (loading && networkStatus !== NetworkStatus.refetch) return <LoadingSpinner relativeSpinner={true} />;

          if (data && data.healthAssessment) {
            const haMedicalOther = new HAMedicalHistoryOtherItem().loadData(data.healthAssessment);
            return (
              <HAMedicalHistoryOther
                medicalHistoryOther={haMedicalOther}
                mobilityAidRefData={data.mobilityAidRefData}
                wellbeingIssueRefData={data.wellbeingIssueRefData}
                autosave={this.autosave}
                performCRUD={this.performOperation}
                refetch={refetch}
                validateOnLoad={validateOnLoad}
                patientId={patientId}
              />
            );
          }

          return <div>Error!</div>;
        }}
      </Query>
    );
  }

  private autosave = (updateObject: object, replaceId?: string, refetch?: any): Promise<void> => {
    const { mutateGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Mutate the graph
    return mutateGraph(
      client,
      updateObject,
      () => {
        getSaveStatus(client).then((savingStatus) => {
          updateSaveStatus(savingStatus);
        });
      },
      () => {},
      () => {},
      async () => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);

        if (refetch !== undefined) {
          try {
            refetch();
          } catch (error) {
            logger.debug('Refetch failed', error);
          }
        }
      },
      replaceId,
    );
  };

  private performOperation = (
    haId: string,
    operation: string,
    objectType: string,
    itemId?: string,
    refetch?: any,
  ): void => {
    const { mutateCRUDGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Build the update object
    const updateObject: Dictionary = {
      haId,
      objectType,
      operation,
    };

    if (itemId !== undefined && itemId !== '') {
      updateObject.id = itemId;
    }

    // Call the mutation to update the CRUD
    mutateCRUDGraph(
      client,
      updateObject,
      (): void => {
        getSaveStatus(client).then((savingStatus): void => {
          updateSaveStatus(savingStatus);
        });
      },
      (): void => {},
      (): void => {},
      async (): Promise<void> => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);
        if (refetch !== undefined) {
          try {
            refetch();
          } catch (error) {
            logger.debug('Refetch failed', error);
          }
        }
      },
    );
  };
}

const routedComponent = withRouter(HAMedicalHistoryOtherApollo);
const haComponent = withHealthAssessment(routedComponent);
export default haComponent;
