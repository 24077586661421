// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { FormContainer, ModalConsultationDiscussion, ModalDeleteForm, ModalSubmitForm } from 'op-components';
import { generatePath, useParams } from 'react-router';
import { FormRow, LoadingSpinner } from 'shared-components/components';
import { usePreventUnload } from 'shared-components/utils/CustomHooks';
import { SelectFieldBase, TextAreaField, TextAreaFieldGroup } from 'shared-components/components/FormikComponents';
import CheckboxField from 'shared-components/components/UIFormComponents/CheckboxField';
import {
  ALL_MANAGEMENT_PLAN_QUERY,
  SUBMITTED_MANAGEMENT_PLAN_QUERY,
  CREATE_GOAL_OF_CARE,
  DELETE_GOAL_OF_CARE,
  DELETE_MANAGEMENT_PLAN,
  MANAGEMENT_PLAN_QUERY,
  SUBMIT_MANAGEMENT_PLAN,
  UPDATE_GOAL_OF_CARE,
  UPDATE_MANAGEMENT_PLAN,
} from '../graphql/ManagementPlanQueries';
import { formRoutes, managementDashboardLink } from '../routes';
import {
  HeaderTitle,
  sortScoreOptions,
  GreyLine,
  SectionTitle,
  SectionTitleWrapper,
  SectionWrapper,
  InfoWrapper,
  InfoIcon,
  LEAVE_PAGE_WARNING,
} from '../common';
import { mapListData } from '../../../../utils/helpers';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';

interface Props {
  patient: any;
  listData: any;
}

interface FormikProps {
  values: any;
}

const ConsultationPage = ({ patient, listData }: Props): JSX.Element => {
  const history = useHistory();
  const { patientId, formId } = useParams<{ patientId: string; formId: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showDT, setShowDT] = useState(false);
  const [showConsultationDiscussionModal, setShowConsultationDiscussionModal] = useState(false);
  const [preventUnload, setPreventUnload] = useState(true);

  const { loading: formLoading, data: formData } = useQuery(MANAGEMENT_PLAN_QUERY, {
    variables: { id: formId },
  });

  const initialValues = formData?.managementPlan;

  useEffect(() => {
    setShowDT(Boolean(initialValues?.distressScorePdf));
  }, [initialValues]);

  useEffect(() => {
    if (showDeleteModal || showSubmitModal) {
      setPreventUnload(false);
    }
  }, [showDeleteModal, showSubmitModal]);

  const [updateManagementPlan] = useMutation(UPDATE_MANAGEMENT_PLAN, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: MANAGEMENT_PLAN_QUERY, variables: { id: formId } }],
  });
  const [submitManagementPlan] = useMutation(SUBMIT_MANAGEMENT_PLAN, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ALL_MANAGEMENT_PLAN_QUERY, variables: { patientId } },
      { query: SUBMITTED_MANAGEMENT_PLAN_QUERY, variables: { patientId } },
    ],
  });
  const [deleteManagementPlan] = useMutation(DELETE_MANAGEMENT_PLAN, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ALL_MANAGEMENT_PLAN_QUERY, variables: { patientId } }],
  });

  const [createGoalMutation] = useMutation(CREATE_GOAL_OF_CARE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: MANAGEMENT_PLAN_QUERY, variables: { id: formId } }],
  });
  const [deleteGoalMutation] = useMutation(DELETE_GOAL_OF_CARE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: MANAGEMENT_PLAN_QUERY, variables: { id: formId } }],
  });
  const [updateGoalMutation] = useMutation(UPDATE_GOAL_OF_CARE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: MANAGEMENT_PLAN_QUERY, variables: { id: formId } }],
  });

  const updateMutation = (field: string, value: string | number | boolean) => {
    // Clear out the values for check in notes and check in status if the distress score changes to below a 7
    if (field === 'distressScrore' && !highRiskDt(value)) {
      updateManagementPlan({ variables: { id: formId, checkInNotes: '', checkInStatus: null } });
    }
    updateManagementPlan({ variables: { id: formId, [field]: value } });
  };
  const createGoalOfCare = () => createGoalMutation({ variables: { formId } });
  const updateGoalOfCare = (goalId: string, goal: string) => {
    return updateGoalMutation({ variables: { formId, goalId, goal } });
  };
  const deleteGoalOfCare = (goalId: string) => deleteGoalMutation({ variables: { formId, goalId } });

  const hasBeenSubmitted = Boolean(initialValues?.isReview);

  const highRiskDt = (score: any): boolean => {
    return score >= 7;
  };

  const formStandardValues = {
    updatedBy: '', // TEMP
    patient: patient,
    saveAndExitLink: generatePath(managementDashboardLink, { patientId }),
    setDeleteModalIsOpen: setShowDeleteModal, // TEMP
    deleteModal: () => {},
    submitForm: () => setShowSubmitModal(true),
    createAmendmentAndRedirect: () => {}, // TEMP
    handleValidation: () => {},
    pageNumber: 1,
    sectionTitle: formRoutes.consultation.sectionTitle,
    continueLink: '',
  };

  usePreventUnload(preventUnload);

  return (
    <>
      <FormContainer
        isLocked={false}
        {...formStandardValues}
        handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
          void event;
        }}
        sidePanelWidth={'756px'}
        showMxPlanSidePanel={true}
        showDT={showDT}>
        {formLoading && <LoadingSpinner />}
        {formData && (
          <div style={{ height: '100%', width: '100%', padding: '8px' }}>
            <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
              {({ values }: FormikProps) => (
                <Form>
                  <HeaderTitle data-test-id="consultation-page-header">Consultation</HeaderTitle>
                  <FormRow fieldLabel="Timepoint" fieldName="timepoint">
                    <Field
                      name="timepoint"
                      component={SelectFieldBase}
                      options={mapListData(listData?.timepointOptions)}
                      placeholder="Please choose..."
                      updateMutation={(value: string) => updateMutation('timepoint', value)}
                    />
                  </FormRow>
                  {/* Only want to show distress thermometer related questions if there has been a new DT submission since the last mx plan submission  */}
                  {values.distressScorePdf && (
                    <>
                      <SectionTitleWrapper>
                        <SectionTitle className="new-superscript">DISTRESS SCREENING</SectionTitle>
                        <GreyLine />
                      </SectionTitleWrapper>
                      <FormRow fieldLabel="Reviewed distress thermometer" fieldName="reviewedDt">
                        <Field
                          name="reviewedDt"
                          component={CheckboxField}
                          label="Yes"
                          handleMutation={(value: boolean) => updateMutation('reviewedDt', value)}
                        />
                      </FormRow>
                      <SectionWrapper>
                        <FormRow fieldLabel="Distress score" fieldName="distressScore" labelClass="pl-3">
                          <Field
                            name="distressScore"
                            component={SelectFieldBase}
                            options={mapListData(sortScoreOptions(listData?.scoreOptions))}
                            placeholder="Please select..."
                            updateMutation={(value: string) => updateMutation('distressScore', Number(value))}
                          />
                        </FormRow>
                        {/* Nurse only needs to check in with patient if distress score is >= 7 */}
                        {highRiskDt(values.distressScore) && (
                          <>
                            <FormRow
                              fieldLabel="Score >= 7 check-in status"
                              fieldName="checkInStatus"
                              labelClass="pl-3">
                              <Field
                                name="checkInStatus"
                                component={SelectFieldBase}
                                options={mapListData(listData?.checkInStatusOptions)}
                                placeholder="Please select..."
                                updateMutation={(value: string) => updateMutation('checkInStatus', value)}
                              />
                              <InfoWrapper>
                                <InfoIcon />
                                {'Score >= 7 requires a check-in with patient prior to next appointment'}
                              </InfoWrapper>
                            </FormRow>
                            <FormRow fieldLabel="Check-in notes" fieldName="checkInNotes" labelClass="pl-3">
                              <Field
                                name="checkInNotes"
                                component={TextAreaField}
                                placeholder="Please provide details i.e. date of check in"
                                updateMutation={(value: string) => updateMutation('checkInNotes', value)}
                                multiline
                                maxLength={2048}
                              />
                            </FormRow>
                          </>
                        )}
                      </SectionWrapper>
                    </>
                  )}
                  <SectionTitleWrapper>
                    <SectionTitle>CONSULTATION</SectionTitle>
                    <GreyLine />
                  </SectionTitleWrapper>
                  <FormRow
                    fieldLabel="Patient concerns/needs"
                    fieldName="patientConcernsNeeds"
                    tooltipElement={
                      <HelpOutlineIcon color="primary" onClick={() => setShowConsultationDiscussionModal(true)} />
                    }>
                    <Field
                      name="patientConcernsNeeds"
                      placeholder="Please provide more details"
                      component={TextAreaField}
                      updateMutation={(value: string) => updateMutation('patientConcernsNeeds', value)}
                      multiline
                      maxLength={2048}
                    />
                  </FormRow>
                  <SectionWrapper>
                    <FormRow fieldLabel="Goals of care" fieldName="goalOfCare">
                      <TextAreaFieldGroup
                        name="goalOfCare"
                        parentField="Parent Field"
                        values={values?.goalOfCare}
                        deleteMutation={deleteGoalOfCare}
                        createMutation={() => createGoalOfCare()}
                        updateMutation={(variables: any) =>
                          updateGoalOfCare(variables?.variables?.id, variables?.variables?.goal)
                        }
                        placeholder="Describe goal"
                        addButtonText="Add another goal of care"
                        helperMessageType="info"
                        textFieldValue="goal"
                        textFieldName="goalOfCare"
                        isManagementForm={true}
                      />
                    </FormRow>
                  </SectionWrapper>
                  <FormRow fieldLabel="Plan of care" fieldName="planOfCare">
                    <Field
                      name="planOfCare"
                      component={TextAreaField}
                      placeholder="Please provide details on how you address goals or concerns identified"
                      updateMutation={(value: string) => updateMutation('planOfCare', value)}
                      multiline
                      maxLength={2048}
                    />
                  </FormRow>
                  <FormRow fieldLabel="Resources provided" fieldName="resourcesProvided">
                    <Field
                      name="resourcesProvided"
                      component={TextAreaField}
                      placeholder="Please provide more details"
                      updateMutation={(value: string) => updateMutation('resourcesProvided', value)}
                      multiline
                      maxLength={2048}
                    />
                  </FormRow>
                  {hasBeenSubmitted && (
                    <FormRow fieldLabel="Review comments" fieldName="reviewComments">
                      <Field
                        name="reviewComments"
                        component={TextAreaField}
                        updateMutation={(value: string) => updateMutation('reviewComments', value)}
                        multiline
                        maxLength={2048}
                      />
                    </FormRow>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        )}
      </FormContainer>
      {showConsultationDiscussionModal && (
        <ModalConsultationDiscussion
          isOpen={showConsultationDiscussionModal}
          title="Consultation discussion guide"
          setIsOpen={setShowConsultationDiscussionModal}
        />
      )}
      {showDeleteModal && (
        <ModalDeleteForm
          isOpen={showDeleteModal}
          title="Discard Consultation"
          setIsOpen={setShowDeleteModal}
          deleteFormMutation={() =>
            deleteManagementPlan({ variables: { formId } }).then(() =>
              history.push(generatePath(managementDashboardLink, { patientId })),
            )
          }
        />
      )}
      {showSubmitModal && (
        <ModalSubmitForm
          isOpen={showSubmitModal}
          setIsOpen={setShowSubmitModal}
          submitFormMutation={() => {
            submitManagementPlan({ variables: { formId } }).then(() =>
              history.push(generatePath(managementDashboardLink, { patientId })),
            );
          }}
        />
      )}
      <Prompt when={preventUnload} message={LEAVE_PAGE_WARNING} />
    </>
  );
};

export default ConsultationPage;
