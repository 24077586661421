// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';

import withHealthAssessment, { WithHealthAssessment } from '../../HealthAssessment';

import HAPatientInformation from './HAPatientInformation';

import { Logger } from 'shared-components/utils';
import { LoadingSpinner } from 'shared-components/components';
import { HAPatientInformationItem } from 'op-classes';

import { ApolloSaveStatus } from 'op-types';

const logger = new Logger('HAPatientInformationApollo');

const HEALTH_ASSESMENT_QUERY = gql`
  query HealthAssessment($id: ID!) {
    healthAssessment(id: $id) {
      id
      patient {
        id
        fullName
        dob
      }
      height
      weight
    }
    user {
      id
      isPso
    }
  }
`;

interface HealthAssessmentQueryData {
  healthAssessment: {
    id: string;
    patient: {
      fullName: string;
      dob: string;
    };
    height: number;
    weight: number;
  };
  user: {
    id: string;
    isPso: boolean;
  };
}

interface Props extends WithHealthAssessment, RouteComponentProps<{ patientId: string; haId: string }> {
  client: any;
  updateSaveStatus: ApolloSaveStatus;
  validateOnLoad: boolean;
}

class HAPatientInformationApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
      validateOnLoad,
    } = this.props;

    return (
      <Fragment>
        <Query<HealthAssessmentQueryData> query={HEALTH_ASSESMENT_QUERY} variables={{ id: patientId }}>
          {({ loading, data }): JSX.Element => {
            if (loading) {
              return <LoadingSpinner relativeSpinner={true} />;
            }

            if (data && data.healthAssessment) {
              const haPatientInfo = new HAPatientInformationItem();
              haPatientInfo.loadData(data.healthAssessment);

              logger.debug('render', 'Query Data ', JSON.stringify(data));
              logger.debug('render', 'HA Patient Info: ', JSON.stringify(haPatientInfo));

              return (
                <HAPatientInformation
                  haPatientInfo={haPatientInfo}
                  patientId={patientId}
                  autosave={this.autosave}
                  validateOnLoad={validateOnLoad}
                  isPso={data.user && data.user.isPso}
                />
              );
            }
            return <div></div>;
          }}
        </Query>
      </Fragment>
    );
  }

  private autosave = (updateObject: object): Promise<void> => {
    const { mutateGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Mutate the graph
    return mutateGraph(
      client,
      updateObject,
      () => {
        getSaveStatus(client).then((savingStatus) => {
          updateSaveStatus(savingStatus);
        });
      },
      () => {},
      () => {},
      async () => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);
      },
    );
  };
}

const routedComponent = withRouter(HAPatientInformationApollo);
const haComponent = withHealthAssessment(routedComponent);
export default haComponent;
