import {
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  handlePointClick,
  TopChartParams,
  setHeight,
  selectPoint,
  xAxisConfig,
  columnTooltipConfig,
} from './DefaultConfig';
import { InsightsDateAggregation } from 'oncology_portal/src/enums';
import Highcharts from 'highcharts';

const ChartColumn = ({
  onPointClick,
  selectedPoint,
  selectedIndex,
  seeMoreUpdated,
  leftSeriesTitle,
  rightSeriesTitle,
  leftSeriesFields,
  rightSeriesFields,
  aggregation,
  dateRange,
  endDate,
  tooltipSuffix = 'plans',
  rightSeriesEnabled = false,
  tooltipPrecision = 0,
}: TopChartParams): Dashboards.Board.Options => {
  const columnAssignment = [
    {
      seriesId: 'series-0',
      data: leftSeriesFields,
    },
  ];

  if (rightSeriesTitle) {
    columnAssignment.push({
      seriesId: 'series-1',
      data: rightSeriesFields,
    });
  }

  const series: Highcharts.SeriesOptionsType[] = [
    {
      id: 'series-0',
      type: 'column',
      name: leftSeriesTitle,
      yAxis: 0,
      ...(aggregation === InsightsDateAggregation.DAYS && {
        pointPlacement: 'on',
        pointRange: 24 * 60 * 60 * 1000,
      }),
    },
  ];

  if (rightSeriesTitle) {
    series.push({
      id: 'series-1',
      type: 'spline',
      name: rightSeriesTitle,
      marker: {
        enabled: false,
      },
      yAxis: rightSeriesEnabled ? 1 : 0,
    });
  }

  return {
    components: [
      {
        cell: 'top-chart',
        connector: {
          id: 'top-chart-data',
          columnAssignment: columnAssignment,
        },
        sync: syncConfig,
        type: 'Highcharts',
        chartOptions: {
          exporting: exportingConfig,
          chart: {
            type: 'column',
            animation: false,
            spacing: chartSpacingConfig,
            events: {
              load() {
                setHeight(this);
              },
              redraw() {
                selectPoint(this, selectedPoint, selectedIndex);
              },
            },
          },
          title: { text: '' },
          xAxis: xAxisConfig(aggregation!, dateRange!, endDate!),
          yAxis: [
            { title: { text: '' } },
            {
              title: { text: '' },
              opposite: true,
            },
          ],
          credits: creditsConfig,
          tooltip: columnTooltipConfig(aggregation!, tooltipSuffix, tooltipPrecision),
          series: series,
          plotOptions: {
            column: {
              borderRadius: 8,
            },
            series: {
              cursor: 'pointer',
              animation: selectedPoint === '' && !seeMoreUpdated,
              states: {
                select: {
                  enabled: false,
                },
              },
              point: {
                events: {
                  click: function () {
                    handlePointClick(this, onPointClick);
                  },
                },
              },
            },
          },
        },
      },
    ],
  };
};

export default ChartColumn;
