import { gql } from '@apollo/client';

export const DOCTOR_APPOINTMENT_QUERY = gql`
  query doctorAppointmentByDateWithPractioners($startDate: DateTime, $endDate: DateTime) {
    doctorAppointmentsByDateWithPractitioners(startDate: $startDate, endDate: $endDate) {
      id
      relatedPractitioners {
        practitioner {
          id
          firstName
          lastName
        }
        relationshipType
      }
      practitioner {
        id
        firstName
        lastName
      }
      appointment {
        id
        patient {
          id
          fullName
          ida
          dob
          gender
          address {
            id
            formattedAddress
          }
          photoUrl
        }
        location {
          id
          name
        }
        startTime
        duration
        comment
        activity
        statusAbbreviation
        description
        department {
          id
          alias
        }
      }
    }
  }
`;
