// eslint-disable-next-line no-use-before-define
import React from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { useQuery } from '@apollo/client';

import withDistressThermometer, { WithDistressThermometer } from '../DistressThermometer';
import DTRating from './DTRating';

import { DTRatingItem } from 'op-classes';

import { Logger } from 'shared-components/utils';
import { LoadingSpinner } from 'shared-components/components';

const logger = new Logger('DTRatingApollo');

export const DT_RATING_QUERY = gql`
  query DTRating($patientId: ID!, $id: ID) {
    distressThermometer(patientId: $patientId, id: $id) {
      id
      distressRating
      version
    }
  }
`;

interface Props
  extends WithDistressThermometer,
    WithApolloClient<{}>,
    RouteComponentProps<{ patientId: string; distressId: string }> {}
const DTRatingApollo = (props: Props): JSX.Element => {
  const {
    match: {
      params: { patientId, distressId },
    },
    client,
    getDTMutation,
  }: any = props;
  const { loading, data } = useQuery(DT_RATING_QUERY, { variables: { patientId, id: distressId } });

  const autosave = (updateObject: object): void => {
    // Call the client to mutate the distress thermometer
    client.mutate(getDTMutation(updateObject)).catch((): void => {
      logger.debug('autosave', '*** FAILED TO SAVE ****', JSON.stringify(updateObject));
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (data && data.distressThermometer) {
    const dtRating = new DTRatingItem().loadData(data.distressThermometer);
    return <DTRating autosave={autosave} dtRating={dtRating} patientId={patientId} />;
  }

  return <></>;
};

const apolloComponent = withApollo<Props>(DTRatingApollo);
const routedComponent = withRouter(apolloComponent);
const dtComponent = withDistressThermometer(routedComponent);
export default dtComponent;
