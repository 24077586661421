import { AddressLabel } from 'shared-components/interfaces';

export enum HeaderBarType {
  WHITE = 'WHITE',
  GREEN = 'GREEN',
}

export enum LoginPageMode {
  DEFAULT = 'DEFAULT',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

export enum SavingStatus {
  FAILED = 'Save failed',
  SAVED = 'Saved',
  SAVING = 'Saving',
}

export enum SubmitButtonType {
  SUBMITTING = 'Submitting...',
  SUBMITTED = 'Submitted',
  SUBMIT_AND_EXIT = 'Submit and exit',
  SUBMIT_AND_CONTINUE = 'Submit and continue',
}

// Human friendly name for apollo network status
// See for list https://www.apollographql.com/docs/react/api/react-apollo/#optionsvariables
export enum ApolloNetworkStatus {
  LOADING = 1,
  SET_VARIABLES = 2,
  FETCH_MORE = 3,
  REFETCH = 4,
  POLL = 6,
  READY = 7,
  ERROR = 8,
}

export enum RoCarePlanPages {
  DX_LANDING_PG = '1',
  DX_NEW_DIAGNOSIS_PG = '1a',
  DX_SELECT_DIAGNOSIS_PG = '1b',
  DX_STAGING_PG = '2',
  DX_INTAKE_PG = '3',
  CP_TREATMENT_INFO_PG = '4',
  CP_CAREPLAN_SELECTION_PG = '5',
  CP_SITESETUP_PG = '6',
  TM_SIMULATION_PG = '7',
  TM_PRESCRIPTION_PG = '8',
  TM_VOLUMING_PG = '9',
  TM_SUBMISSION_PG = '10',
  TM_SUBMISSION_PG_US = '11',
  TM_SPECIAL_PHYSICS_CONSULT = '10',
}

export enum AustralianStates {
  VIC = 'VIC',
  NSW = 'NSW',
  WA = 'WA',
  QLD = 'QLD',
  SA = 'SA',
}

export enum Region {
  AU = 'AU',
  UK = 'UK',
  US = 'US',
  DEMO = 'DEMO',
  ANZ = 'ANZ',
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const addressLabelForRegion = (manualOverride: Partial<AddressLabel> = {}): AddressLabel => {
  const region = import.meta.env.REACT_APP_REGION;

  let labels: AddressLabel = {
    country: 'Country/Region',
    line1: 'Address line 1',
    line2: 'Address line 2',
    city: 'City/Suburb',
    state: 'State',
    postcode: 'Postcode',
  };

  if (region === Region.UK) {
    labels = {
      ...labels,
      city: 'Town',
      state: 'County',
    };
  } else if (region === Region.US) {
    labels = {
      ...labels,
      city: 'City',
      postcode: 'ZIP',
    };
  } else if (region === Region.AU) {
    labels = {
      ...labels,
      country: 'Country',
    };
  }

  if (manualOverride) {
    labels = {
      ...labels,
      ...manualOverride,
    };
  }

  return labels;
};

export const addressPlaceholdersForRegion = (): AddressLabel => {
  const region = import.meta.env.REACT_APP_REGION;

  let placeholders: AddressLabel = {
    country: '',
    line1: 'Lookup address',
    line2: '',
    city: '',
    state: '',
    postcode: '',
  };

  if (region === Region.AU) {
    placeholders = {
      ...placeholders,
      line2: 'Enter apartment, unit, suite, or floor #',
    };
  }

  return placeholders;
};

export const PAT_ID_1_REFRESH_TIME = 5000;

export const LARGE_DISPLAY_SIZE = 1920;

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;
