import React from 'react';
import { PatientTooltip as IPatientTooltip } from 'op-interfaces';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { Link } from 'react-router-dom';
import PatientTooltip from '../../../pages/RO/Dashboard/PatientTooltip';
import { isDemo } from 'op-utils';
import { patientGenderStockImage } from 'op-utils/PatientHelper';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';

interface Props {
  recentPatients: IPatientTooltip[];
  isRo: boolean;
}

const StyledPatientLink = styled(Link)`
  width: 100%;
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledImageDiv = styled('div')`
  float: left;
  margin-left: 16px;
  padding-top: 4px;
`;

const RecentPatients = (props: Props): JSX.Element | null => {
  const { recentPatients, isRo } = props;
  const theme = useTheme();

  const heading = isRo ? (
    <Stack
      width={1}
      sx={{
        backgroundColor: theme.palette.action.hover,
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: theme.palette.grey[300],
      }}>
      <Typography lineHeight={'28px'} paddingLeft={2} variant="caption" data-test-id="last-5-patients">
        LAST 5 PATIENTS
      </Typography>
    </Stack>
  ) : (
    <></>
  );

  if (!recentPatients?.length) {
    return isRo ? (
      <>
        {heading}
        <Typography
          paddingLeft={2}
          paddingTop={1}
          whiteSpace="break-spaces"
          variant="body2"
          color={theme.palette.text.secondary}
          data-test-id="no-recent-patients">
          No recent patients available because you have not visited any patient summaries.
        </Typography>
      </>
    ) : null;
  }
  return (
    <>
      {heading}
      {recentPatients.map((recentPatient: IPatientTooltip, index: number): JSX.Element => {
        const patientDemoImage = isDemo ? patientGenderStockImage(recentPatient.gender) : null;
        return (
          <PatientTooltip key={index} patient={recentPatient}>
            <Box width={1} paddingTop={1}>
              <StyledPatientLink to={`/radiation/patient/${recentPatient.id}/summary`}>
                <Stack direction="row" key={recentPatient.id} id={`patient-${recentPatient.id}`} gap="8px">
                  <StyledImageDiv>
                    <PhotoWidget url={patientDemoImage} patientId={recentPatient.id} enableOverlay={true} size={'sm'} />
                  </StyledImageDiv>
                  <Stack direction="column">
                    <Typography variant="subtitle1" data-test-id={`patient-${index}-full-name`}>
                      {recentPatient.fullName}
                    </Typography>
                    <Typography variant="body2" data-test-id={`patient-${index}-ida`}>
                      ID: {recentPatient.ida}
                    </Typography>
                    <Typography variant="body2" data-test-id={`patient-${index}-emr`}>
                      EMR: {recentPatient.emrInstance}
                    </Typography>
                  </Stack>
                </Stack>
              </StyledPatientLink>
            </Box>
          </PatientTooltip>
        );
      })}
    </>
  );
};
export default RecentPatients;
