import Highcharts from 'highcharts';
import { syncConfig, creditsConfig, BottomChartParams, loadSeeMoreButton, subExportingConfig } from '../DefaultConfig';
import { SUB_CHART_LIMIT } from '../../DashboardConfigs/DefaultConfig';

const columnSeriesMapping = (leftSeriesFields: string[]) => {
  const trimmedFields = leftSeriesFields.slice(1);
  return trimmedFields.map((field, index) => ({
    seriesId: 'series-' + index,
    data: ['CreatedBy', field],
  }));
};

const seriesMapping = (leftSeriesFields: string[]) => {
  const trimmedFields = leftSeriesFields.slice(1);
  return trimmedFields.map((field, index) => ({
    id: 'series-' + index,
    type: 'bar',
    name: field,
    color: seriesColorMapping[field as keyof typeof seriesColorMapping] || 'rgba(158, 158, 158)',
    fillOpacity: 0.75,
  })) as Highcharts.SeriesOptionsType[];
};

const seriesColorMapping: { [key: string]: string } = {
  'IMRT/VMAT': 'rgba(170, 161, 253)',
  Stereotactic: 'rgba(62, 167, 178)',
  Conformal: 'rgba(62, 112, 178)',
  Brachy: 'rgba(255, 189, 91)',
  Complex: 'rgba(251, 106, 118)',
};

const SubChartStackedBarPercentTotal = ({
  title,
  connector,
  cell,
  filterOverride,
  seeMore,
  theme,
  toggleSeeMore,
  dataLength,
  seriesFields,
  dataTotal,
}: BottomChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: cell,
      connector: {
        id: connector,
        columnAssignment: columnSeriesMapping(seriesFields!),
      },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: subExportingConfig,
        chart: {
          type: 'bar',
          spacing: [20, 130, 70, 20],
          height: 320,
          styledMode: false,
          events: {
            load() {
              const chart = this as Highcharts.Chart & {
                labelHeader1?: Highcharts.SVGElement;
                labelHeader2?: Highcharts.SVGElement;
              };

              if (dataLength! > SUB_CHART_LIMIT) {
                loadSeeMoreButton(this, toggleSeeMore, seeMore, cell);
              }

              const positionText = () => {
                // 'Count' label position
                const xPosition1 = chart.chartWidth - 118;

                // '% of total' label position
                const xPosition2 = chart.chartWidth - 70;

                if (chart.labelHeader1 && chart.labelHeader2) {
                  chart.labelHeader1.attr({ x: xPosition1 });
                  chart.labelHeader2.attr({ x: xPosition2 });
                } else {
                  chart.labelHeader1 = chart.renderer
                    .text('Count', xPosition1, 55)
                    .css({
                      color: theme.palette.text.primary,
                      fontSize: '12px',
                      fontFamily: 'Poppins, sans-serif',
                    })
                    .add();

                  chart.labelHeader2 = chart.renderer
                    .text('% of total', xPosition2, 55)
                    .css({
                      color: theme.palette.text.secondary,
                      fontSize: '12px',
                      fontFamily: 'Poppins, sans-serif',
                    })
                    .add();
                }
              };

              positionText();

              chart.redraw = function () {
                Highcharts.Chart.prototype.redraw.call(this);
                positionText();
              };
            },
          },
        },
        title: {
          text: title,
          align: 'left',
          style: {
            color: theme.palette.text.primary,
            fontSize: '20px',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '500',
          },
        },
        subtitle: {
          text: filterOverride,
          align: 'left',
          style: {
            color: 'rgba(8, 30, 67, 0.60)',
            fontSize: '14px',
          },
        },
        xAxis: {
          type: 'category',
          scrollbar: {
            enabled: true,
          },
        },
        yAxis: {
          reversed: true,
          visible: false,
          max: 150,
          scrollbar: {
            enabled: true,
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
        },
        credits: creditsConfig,
        series: seriesMapping(seriesFields!),
        plotOptions: {
          bar: {
            borderRadius: 8,
            pointWidth: 9,
            stacking: 'percent',
          },
          series: {
            cursor: 'pointer',
            dataLabels: [
              {
                enabled: true,
                formatter: function () {
                  return '<span style="font-weight: 400; font-family: Poppins, sans-serif">' + this.total + '</span>';
                },
                align: 'right',
                verticalAlign: 'middle',
                alignTo: 'plotEdges',
                x: 50,
                y: -1,
                crop: false,
                overflow: 'allow',
              },
              {
                enabled: true,
                formatter: function () {
                  const percentage = (
                    this.y !== null && this.y !== undefined ? (this.y / dataTotal!) * 100 : 0
                  ).toFixed(0);
                  return (
                    '<span style="color: #696969; font-weight: 400; font-family: Poppins, sans-serif">' +
                    percentage +
                    '%' +
                    '</span>'
                  );
                },
                align: 'right',
                verticalAlign: 'middle',
                alignTo: 'plotEdges',
                x: 110,
                y: -1,
                crop: false,
                overflow: 'allow',
              },
            ],
          },
        },
      },
    },
  ],
});

export default SubChartStackedBarPercentTotal;
