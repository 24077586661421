import validate from 'validate.js';
import { FormDataModel } from 'shared-components/interfaces';
import { ChangePasswordError } from './ChangePasswordInterface';

// Interface that defines the data model for the Change Password Form
export interface ChangePasswordFormModel extends FormDataModel {
  formData: {
    [key: string]: string | boolean;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  };
}

interface ChangePasswordErrorList {
  error: string;
  details: string;
}

export default class ChangePasswordFormViewModel {
  public ERROR_PASSWORD_INVALID = 'Please enter a valid password';
  public ERROR_PASSWORD_TOO_SHORT = 'Your password is too short, please enter a password at least 8 characters';
  public ERROR_PASSWORD_TOO_COMMON =
    'Please ensure your password does not contain names, your email or the company name';
  public ERROR_PASSWORD_TOO_WEAK = 'Your password is too easy to guess, please enter a more complex password';
  public ERROR_PASSWORDS_ENTERED_DO_NOT_MATCH = 'The passwords you entered do not match. Please re-enter passwords';
  public EXPIRED_TOKEN_ERROR = 'Token has expired';
  public TNC_ERROR = 'You must agree to the Terms & Conditions to continue';

  private MIN_PASSWORD_LENGTH = 8;

  public changePasswordFormModel: ChangePasswordFormModel;
  public errors: ChangePasswordError;

  private validationRules = {
    oldPassword: {
      format: {
        pattern: '[\\S]+',
        message: this.ERROR_PASSWORD_INVALID,
      },
    },
    confirmPassword: {
      format: {
        pattern: '[\\S]{8,}',
        message: this.ERROR_PASSWORD_TOO_SHORT,
      },
      equality: {
        attribute: 'newPassword',
        message: this.ERROR_PASSWORDS_ENTERED_DO_NOT_MATCH,
        comparator: function (v1: string, v2: string) {
          return v1 === v2;
        },
      },
    },
    newPassword: {
      format: {
        pattern: '[\\S]{8,}',
        message: this.ERROR_PASSWORD_TOO_SHORT,
      },
    },
  };

  // Configuration options for the validator
  private validationOptions = {
    fullMessages: false,
    format: 'flat',
  };

  public constructor() {
    this.changePasswordFormModel = {
      formData: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
    };

    this.errors = {};
  }

  /**
   * Function that updates the form data stored within this object
   * Once updated, it executes a request to Apollo to ensure the database is synced.
   * @param key - Property of ChangePasswordFormModel form data object
   * @param newValue - Value to be assigned to the key
   */
  public validateField = (
    key: string,
    newValue: string,
    errorHandler?: (message: string) => void,
    successHandler?: () => void,
  ): void => {
    let updatedKey = key;

    // Create the data to be validated
    const validationObject: { [updatedKey: string]: string } = {};

    // Update the key to match the form data
    switch (key) {
      case 'old-password':
        updatedKey = 'oldPassword';
        break;
      case 'new-password':
        updatedKey = 'newPassword';
        break;
      case 'confirm-password':
        updatedKey = 'confirmPassword';
        validationObject.newPassword = this.changePasswordFormModel.formData.newPassword;
        break;
      default:
        break;
    }

    validationObject[updatedKey] = newValue;

    let invalidMessage: string | undefined = 'errors.invalid_message';
    invalidMessage = validate(validationObject, this.validationRules, this.validationOptions);
    this.errors[updatedKey] = undefined;

    // No validation issues
    if (invalidMessage === undefined) {
      // Call the success handler to update states
      if (successHandler) {
        successHandler();
      }
      // Update the form data object with the new value
      this.changePasswordFormModel.formData[updatedKey] = newValue;
    } else {
      this.changePasswordFormModel.formData[updatedKey] = '';

      if (errorHandler) {
        this.errors[updatedKey] = invalidMessage[0];
        errorHandler(invalidMessage[0]);
      }
    }
  };

  public validatePasswordsOnFormSubmission = (forcedReset: boolean): boolean => {
    const formData = this.changePasswordFormModel.formData;
    return (
      (formData.oldPassword.length >= 1 || forcedReset) &&
      formData.newPassword.length >= this.MIN_PASSWORD_LENGTH &&
      formData.newPassword.length >= this.MIN_PASSWORD_LENGTH &&
      formData.newPassword === formData.confirmPassword
    );
  };

  /**
   * This method will convert the data string that is obtained from the mutation and convert it into a change password error so that it can be used to display errors on screen.
   * @param {string[]} errors Optional, where the string array will contain details which will map to a type.
   * @returns {ChangePasswordError[] | undefined} An array of change password errors or undefined.
   */
  public createErrorArray = (errors?: [ChangePasswordErrorList]): void => {
    if (errors && errors.length > 0) {
      errors.forEach((error: ChangePasswordErrorList) => {
        // Convert the error into a JSON object
        try {
          const details = error.details;
          if (details === 'Incorrect password') {
            this.errors.oldPassword = this.ERROR_PASSWORD_INVALID;
          } else if (details === 'Password does not match') {
            this.errors.confirmPassword = this.ERROR_PASSWORDS_ENTERED_DO_NOT_MATCH;
          } else if (details === this.ERROR_PASSWORD_TOO_SHORT) {
            this.errors.newPassword = details;
          } else if (details === this.ERROR_PASSWORD_TOO_COMMON) {
            this.errors.newPassword = details;
          } else if (details === this.ERROR_PASSWORD_TOO_WEAK) {
            this.errors.newPassword = details;
          } else if (details === this.EXPIRED_TOKEN_ERROR) {
            this.errors.resetToken = true;
          }
          // TODO : Will need to add the details mapping for when the password does not meet NIST
        } catch (e) {
          // FIXME: Handle the error or report to sentry
        }
      });
    }
  };
}
