// eslint-disable-next-line no-use-before-define
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import dayjs from 'dayjs';
import { styled } from '@mui/system';
import { useQuery } from '@apollo/client';
import { ListCheckData } from 'shared-components/interfaces';
import { Card, LoadingSpinner } from 'shared-components/components';
import SimpleTable, { DATATYPE } from 'shared-components/components/UIFormComponents/SimpleTable';
import { usePrevious } from 'shared-components/utils/CustomHooks';
import { GET_PATIENT_DOCUMENTS, PREFETCH_PATIENT_DOCUMENTS } from './PatientProfileQueries';
import { PatientDocumentType } from './PatientSummaryInterfaces';
import useInterval from 'shared-components/utils/useInterval';
import { DocumentType } from './Summary/interfaces';
import { Warning } from 'shared-components/images';
import { UserContextType } from '../../../contexts/UserContext/UserContext';
import UserContext from '../../../contexts/UserContext/UserContext';
import { RoleType } from 'op-enums';
import { CurrentAppConfig } from '../Careplan/AppConfig';
import TooltipWarning from 'shared-components/components/TooltipWarning/TooltipWarning';
import { LARGE_DISPLAY_SIZE } from 'shared-components/enums';
import { Button, Stack, Typography } from '@mui/material';
import {
  FindInPageOutlined as FindInPageOutlinedIcon,
  UploadOutlined as UploadOutlinedIcon,
} from '@mui/icons-material';

const StyledDocListCol = styled(Stack)`
  color: ${(props) => props.theme.palette.text.primary};
  height: 100%;
  overflow: auto;
  padding: 0px;
  justify-content: center;
  td {
    font-size: 14px;
  }
  table {
    margin: 0;
  }
`;

const StyledContainer = styled(Card)`
  height: 50%;
  padding: 16px;
  tbody tr:hover {
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
`;

const StyledSimpleTable = styled(SimpleTable)`
  line-height: 40px;
  margin: 25px 5px;
  height: 100%;
  width: 100%;
`;

const UploadIcon = styled(UploadOutlinedIcon)`
  width: 24px;
  height: 24px;
  margin-right: -5px;
`;

const WarningIcon = styled(Warning)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 3px;
`;

const DOC_FILTER_FIELD = CurrentAppConfig.PatientSummary.documentFilterField;
interface ROPatientDocsListProps {
  setDocument: (document: PatientDocumentType | null) => void;
  triggerModal: any;
  isSmallDisplay: boolean;
  currentDocument: DocumentType | null;
  setShowDocumentUploadPage: (showPage: boolean) => void;
  unsubmittedDocuments: boolean;
  disableUpload: boolean;
}

const ROPatientDocList = (props: ROPatientDocsListProps): JSX.Element => {
  const match = useRouteMatch<any>();
  const [docTypeOptions, setDocTypeOptions] = useState<ListCheckData[]>([]);
  const { state } = useContext<UserContextType>(UserContext);
  const { PSO, RO } = RoleType;
  const [documents, setDocuments] = useState<PatientDocumentType[]>([]);
  const docsListRef = useRef<HTMLDivElement>(null);
  const { id: patientId } = match.params;

  const { data, loading, refetch } = useQuery(GET_PATIENT_DOCUMENTS, {
    variables: { patientId: patientId },
    skip: !patientId,
    fetchPolicy: 'network-only',
  });

  const prevPatientId = usePrevious(patientId);

  useQuery(PREFETCH_PATIENT_DOCUMENTS, {
    variables: { patientId: patientId },
    skip: !patientId,
    fetchPolicy: 'network-only',
  });

  const tableHeader = [
    { header: 'Type', key: 'description', sort: false, type: DATATYPE.STRING },
    { header: 'Status', key: 'status', sort: true, type: DATATYPE.STRING },
    { header: 'Date', key: 'dateOfDocument', sort: true, type: DATATYPE.DATE },
  ];
  const heading = 'Documents';

  const sortAlphabetically = (array: Array<any>, parameter: string) => {
    return [...array].sort((a: any, b: any) => {
      if (!a.hasOwnProperty(parameter) || !b.hasOwnProperty(parameter)) return 0;
      const parameter1 = a[parameter].toLowerCase();
      const parameter2 = b[parameter].toLowerCase();
      if (parameter1 > parameter2) {
        return 1;
      }
      if (parameter2 > parameter1) {
        return -1;
      }
      return 0;
    });
  };

  const documentSelectCallback = (id: string | null): void => {
    props.setDocument(id != null ? documents.filter((doc) => doc.id === id)[0] : null);
  };

  const formatData = (inputData: any) => {
    return inputData.map((data: any) => {
      return { ...data, dateOfDocument: dayjs(data['dateOfDocument']).tz('utc').format('L') };
    });
  };

  const getDocumentTypeOptions = (documents: PatientDocumentType[]): ListCheckData[] => {
    const documentTypesList = documents.map((doc: PatientDocumentType) => doc[DOC_FILTER_FIELD]);
    const documentTypes = [...new Set(documentTypesList)].map((value: string) => {
      const hasOption = docTypeOptions && docTypeOptions.filter((option) => option.id === value).length;
      let checked = true;

      if (hasOption && patientId === prevPatientId) {
        checked = docTypeOptions.find((option) => option.id === value)?.checked ?? true;
      }

      // If the document type is not in the state, set checked to true by default except for Document(CAS)
      if (!docTypeOptions.find((option) => option.name === 'Document(CAS)') && value === 'Document(CAS)') {
        checked = false;
      }

      return {
        id: value,
        name: value,
        checked,
      };
    });

    return documentTypes;
  };

  const handleOnRowClick = (id: string) => {
    documentSelectCallback(id);
    props.isSmallDisplay && props.triggerModal();
  };

  const updateDocumentTypeOptions = (documentTypes: string[], checked: boolean): void => {
    const updatedPatientDocsFilter: ListCheckData[] = docTypeOptions.map((item: ListCheckData) => {
      const updatedOption = documentTypes.filter((currentItem) => currentItem === item.id);
      if (updatedOption.length) {
        return { id: updatedOption[0], name: updatedOption[0], checked: checked };
      }

      return item;
    });

    setDocTypeOptions(updatedPatientDocsFilter ? sortAlphabetically(updatedPatientDocsFilter, 'name') : []);
  };

  /* Refresh documents list every 8 seconds */
  useInterval(() => {
    refetch();
  }, 8000);

  /* Update local documents and document type filters when data is refreshed */
  useEffect(() => {
    if (data && data.documents) {
      setDocuments(data.documents);
      const documentTypeOptions = getDocumentTypeOptions(data.documents);
      const sortedDocumentTypes = sortAlphabetically(documentTypeOptions, 'name');
      setDocTypeOptions(sortedDocumentTypes);
    }
  }, [data]);

  /* Set first document on the list to be displayed initially */
  useEffect(() => {
    if (
      documents &&
      documents.length &&
      documents[0].patient.id === patientId &&
      !documents.filter((doc) => doc.id === props.currentDocument?.id).length &&
      window?.innerWidth >= LARGE_DISPLAY_SIZE
    ) {
      documentSelectCallback(documents[0].id);
    } else if (data && !data.documents.length) {
      documentSelectCallback(null);
    }
  }, [documents]);

  /* Update document list based on selected types */
  useEffect(() => {
    const typesList = docTypeOptions
      .filter((option: ListCheckData) => option.checked)
      .map((option: ListCheckData) => option.id);
    const newDocuments =
      data && data.documents
        ? data.documents.filter((doc: PatientDocumentType) => typesList.includes(doc[DOC_FILTER_FIELD]))
        : [];

    setDocuments(newDocuments);
  }, [docTypeOptions]);

  const cleanedData = formatData(documents);
  const formattedData = cleanedData.length ? cleanedData : [{ id: 0 }];

  return (
    <>
      <StyledContainer>
        <Stack justifyContent="space-between" alignItems="center" direction="row" paddingBottom={1}>
          <Typography variant="h6">{heading}</Typography>
          {(state.primaryRole === PSO ||
            (state.primaryRole === RO && CurrentAppConfig.PatientSummary.RODocumentUpload)) && (
            <TooltipWarning
              disabled={!(props.disableUpload || props.unsubmittedDocuments)}
              message={
                props.disableUpload
                  ? 'Syncing patient with Mosaiq, please try again soon...'
                  : props.unsubmittedDocuments
                  ? 'Unsubmitted documents'
                  : ''
              }>
              <Button
                onClick={(): void => {
                  if (!props.disableUpload) {
                    props.setShowDocumentUploadPage(true);
                  }
                }}
                variant="text"
                id="upload-document-block"
                disabled={props.disableUpload}
                startIcon={<UploadIcon color="primary" />}
                endIcon={props.unsubmittedDocuments ? <WarningIcon /> : <></>}>
                Upload document
              </Button>
            </TooltipWarning>
          )}
        </Stack>
        <StyledDocListCol ref={docsListRef}>
          {loading ? <LoadingSpinner loadingText={'Loading Documents'} relativeSpinner={true} /> : null}
          {!loading && data?.documents?.length ? (
            <StyledSimpleTable
              header={heading}
              onRowClick={(id: string) => handleOnRowClick(id)}
              dataProps={tableHeader}
              data={formattedData}
              selectedRow={
                props.currentDocument
                  ? props.currentDocument.id
                  : window.innerWidth >= LARGE_DISPLAY_SIZE
                  ? formattedData[0].id
                  : null
              }
              docTypeOptions={docTypeOptions}
              updateDocumentTypeOptions={updateDocumentTypeOptions}
            />
          ) : null}
          {!loading && !data?.documents?.length && (
            <Stack alignItems="center">
              <FindInPageOutlinedIcon fontSize="large" color="primary" />
              <Typography variant="body1">No document available</Typography>
            </Stack>
          )}
        </StyledDocListCol>
      </StyledContainer>
    </>
  );
};

export default ROPatientDocList;
