// eslint-disable-next-line no-use-before-define
import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import { LoadingSpinner, Card } from 'shared-components/components';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { DocumentType } from './Summary/interfaces';
import { useLazyQuery } from '@apollo/client';
import { GET_PATIENT_DOCUMENT } from './PatientProfileQueries';
import useInterval from 'shared-components/utils/useInterval';
import { Stack, Typography, useTheme } from '@mui/material';
import { FindInPageOutlined as FindInPageOutlinedIcon } from '@mui/icons-material';

interface ROPatientDocProps {
  document: DocumentType | null;
}

const StyledCol = styled(Card)`
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
`;

const Date = styled('span')`
  font-size: ${getRemSize(16)};
`;

const ROPatientDocs = (props: ROPatientDocProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [document, setDocument] = useState<DocumentType | null>(null);
  const [fetchAttempts, setFetchAttempts] = useState(0);
  const fetchDelay = 3000;
  const maxFetchAttempts = 100;
  const theme = useTheme();
  const [getDocument, { data }] = useLazyQuery(GET_PATIENT_DOCUMENT);

  const fetchDocument = () => {
    if (data && data.document?.docUrl) {
      fetch(data.document?.docUrl).then((res) => {
        if (res.ok) {
          if (document?.hash !== data.document?.hash) {
            setDocument(data.document);
          }
          setLoading(false);
          setFetchAttempts(0);
        }
        if (fetchAttempts === maxFetchAttempts) {
          setFetchError(true);
        }
      });
      setFetchAttempts((attempts) => attempts + 1);
    }
  };

  useEffect(() => {
    setFetchAttempts(0);

    if (props.document && props.document.id) {
      getDocument({
        variables: {
          documentId: props.document.id,
        },
      });
      setLoading(true);
      setFetchError(false);
    } else {
      setDocument(null);
      setLoading(false);
    }
  }, [props.document]);

  // Fetch the document before useInterval starts the timer
  useEffect(() => {
    fetchDocument();
  }, [data]);

  useInterval(fetchDocument, fetchAttempts < maxFetchAttempts && loading ? fetchDelay : null);

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <StyledCol $noPadding>
        {!loading && !props.document && (
          <Stack alignItems="center" paddingTop={9}>
            <FindInPageOutlinedIcon fontSize="large" color="primary" />
            <Typography variant="body1">No document available</Typography>
          </Stack>
        )}
        {loading && !fetchError && (
          <LoadingSpinner
            loadingText={'Loading Document Viewer'}
            subtitle={'This may take longer for larger documents'}
            relativeSpinner={true}
          />
        )}
        {!loading && !fetchError && document && (
          <Fragment>
            <Stack padding={2} alignItems="center" justifyContent="space-between" direction="row">
              <Stack direction="row" gap={0.5}>
                <Typography variant="h6" data-test-id="document-preview-header">
                  {props.document?.description || 'No title available'}
                </Typography>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  ({props.document?.status})
                </Typography>
              </Stack>
              <Date>{dayjs(props.document?.dateOfDocument).tz('utc').format('LLLL')}</Date>
            </Stack>
            <iframe
              title="patientPDFView"
              src={document.docUrl.concat('#view=fitH')} // Add view parameter so PDF defaults to fit to width
              width="100%"
              style={{ height: 'calc(100% - 60px)' }}
              onLoad={(): void => {
                setLoading(false);
              }}
              onError={(): void => {
                setFetchError(true);
              }}>
              This browser does not support PDFs.
            </iframe>
          </Fragment>
        )}
      </StyledCol>
    </Stack>
  );
};

export default ROPatientDocs;
