import { InsightsDateAggregation } from 'oncology_portal/src/enums';

export const EditModeConfig = {
  enabled: false,
  contextMenu: {
    enabled: true,
    items: ['editMode'],
  },
};

export const GuiConfig = {
  layouts: [
    {
      rows: [
        {
          cells: [{ id: 'top-chart' }],
        },
        {
          cells: [{ id: 'sub-chart-left' }, { id: 'sub-chart-right' }],
        },
      ],
    },
  ],
};

export interface DashboardConfig {
  theme: any;
  data: any;
  subChartData: any;
  filterOverride: string;
  selectedIndex: number;
  seeMoreUpdated: boolean;
  seeMoreBottomLeft: boolean;
  seeMoreBottomRight: boolean;
  dateRange: string;
  endDate: Date;
  toggleSeeMore: (cell: string) => void;
  onPointClick: (date: string) => void;
}

export const SUB_CHART_LIMIT = 6;

export const dateAggregationMap: Record<string, InsightsDateAggregation> = {
  'Past 30 days': InsightsDateAggregation.DAYS,
  'Past 90 days': InsightsDateAggregation.MONTHS,
  'Past 6 months': InsightsDateAggregation.MONTHS,
  'Past 1 year': InsightsDateAggregation.MONTHS,
  'All time': InsightsDateAggregation.MONTHS,
};
