import React, { createContext, useContext, useState, ReactNode } from 'react';

export type ERROR_TYPE = 'Account' | 'Generic' | 'Practitioner' | null;

interface ErrorContextType {
  error: ERROR_TYPE;
  setError: (error: ERROR_TYPE) => void;
}

const ErrorContext = createContext<ErrorContextType>({
  error: null,
  setError: () => {},
});

// TODO We use this in components instead of directly using useContext. This seems a bit redundant
export const useErrorModalContext = () => {
  const context = useContext(ErrorContext);
  return context;
};

export const ErrorModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [error, setError] = useState<ERROR_TYPE>(null);

  return <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>;
};
