// eslint-disable-next-line no-use-before-define
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import moment from 'moment-timezone';
import { Component } from 'react';
import validate from 'validate.js';
import './RegistrationBasic.scss';
import { PatientBasic } from 'op-interfaces';
import { appendRawToDOBRawString } from 'op-utils';
import { Region, SavingStatus } from 'shared-components/enums';
import { ListData } from 'shared-components/interfaces';
import { NAME_REGEX, UK_NAME_REGEX } from 'shared-components/utils/Regex';
import { ModalSearchResults, RegistrationContainer } from 'op-components';
import {
  CircleInfo,
  DropDownField,
  ErrorInfo,
  FreeTextField,
  MaskField,
  SectionField,
  SegmentedInput,
  BaseDatePicker,
} from 'shared-components/components/FormFields';
import { DocumentNode } from 'graphql';
import { applyRequired } from '../utils';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

/* eslint-disable-next-line */
const region = REACT_APP_REGION;

// These values map to i18n translation keys
const FORM_HEADING = 'Basic details';
const TITLE_PREFIX_FIELD_HEADING = 'Title';
const TITLE_PREFIX_FIELD_PLACEHOLDER = 'Select title';
const PREFERRED_CENTRE_FIELD_HEADING = 'Preferred centre';
const PREFERRED_CENTRE_FIELD_PLACEHOLDER = 'Select preferred centre';
const NHS_OPTIONS_FIELD_HEADING = 'Reason why NHS ID is not provided';
const NHS_OPTIONS_FIELD_PLACEHOLDER = 'Select one option';
const REGISTRATION_REASON_FIELD_HEADING = 'Registration reason';
const REGISTRATION_REASON_FIELD_PLACEHOLDER = 'Select one option';
const REGISTRATION_REASON_TEXT_FIELD_HEADING = 'Registration reason';
const REGISTRATION_REASON_TEXT_FIELD_PLACEHOLDER = 'Enter registration reason';
const FIRST_NAME_FIELD_HEADING = 'First name';
const FIRST_NAME_FIELD_PLACEHOLDER = 'Enter first name';
const MIDDLE_NAME_FIELD_HEADING = 'Middle name';
const MIDDLE_NAME_FIELD_PLACEHOLDER = 'Enter middle name';
const LAST_NAME_FIELD_HEADING = 'Last name';
const LAST_NAME_FIELD_PLACEHOLDER = 'Enter last name';
const PREFERRED_NAME_FIELD_HEADING = 'Preferred name';
const PREFERRED_NAME_FIELD_PLACEHOLDER = 'Enter preferred name';
const DATE_OF_BIRTH_FIELD_HEADING = 'Date of birth';
const DATE_OF_BIRTH_EXAMPLE_TEXT = 'DD MM YYYY e.g. 12 06 1995';

const FIELD_INFO: { [k: string]: any } = {
  DOB_RAW_DAY: {
    key: 'dobRawDay',
    name: 'dobRawDay',
  },
  DOB_RAW_MONTH: {
    key: 'dobRawMonth',
    name: 'dobRawMonth',
  },
  DOB_RAW_YEAR: {
    key: 'dobRawYear',
    name: 'dobRawYear',
  },
  DOB: {
    key: 'dob',
    name: 'dob',
  },
  IDB: {
    key: 'idb',
    name: 'idb',
  },
  GENDER: {
    TITLE: 'Gender',
    NAME: 'gender',
    KEY: 'gender',
    IS_MANDATORY: true,
    MISSING_INPUT_ERROR: 'Please select your gender',
  },
};

const emptySearch = gql`
  {
    ukDedupSearch(search: "") {
      id
      firstName
      lastName
      dob
      residentialAddressLine1
      residentialAddressLine2
      residentialAddressCity
      residentialAddressPostcode
    }
  }
`;

if (region === Region.UK) {
  FIELD_INFO.PREFERRED_CENTRE = {
    TITLE: 'Preferred centre',
    NAME: 'preferred centre',
    KEY: 'centre',
    IS_MANDATORY: true,
    MISSING_INPUT_ERROR: 'Please select a centre',
    INVALID_INPUT_ERROR: 'Please select a centre',
  };
  FIELD_INFO.IDB = {
    TITLE: 'NHS ID',
    NAME: 'idb',
    KEY: 'idb',
    IS_MANDATORY: false,
    INVALID_INPUT_ERROR: 'This is not a valid NHS ID',
  };
  FIELD_INFO.NHS_OPTIONS = {
    TITLE: 'Reason why NHS ID is not provided',
    NAME: 'nhsOptions',
    KEY: 'nhsOptions',
    IS_MANDATORY: false,
    INVALID_INPUT_ERROR: 'Please select a correct reason',
    MISSING_INPUT_ERROR: 'Please select a reason',
  };
  FIELD_INFO.REGISTRATION_REASON = {
    TITLE: 'Registration reason',
    NAME: 'registrationReason',
    KEY: 'registrationReason',
    IS_MANDATORY: true,
    MISSING_INPUT_ERROR: 'Please select a reason',
  };
  FIELD_INFO.REGISTRATION_REASON_TEXT = {
    TITLE: 'Registration Reason',
    NAME: 'registrationReasonText',
    KEY: 'registrationReasonText',
    MISSING_INPUT_ERROR: 'Please enter a reason',
  };
}

const VALIDATION_FIELD_NAMES: { [key: string]: string } = {
  NAME_PREFIX: 'namePrefix',
  FIRST_NAME: 'firstName',
  MIDDLE_NAME: 'middleName',
  LAST_NAME: 'lastName',
  PREFERRED_NAME: 'preferredName',
  DOB_RAW: 'dobRaw',
  IDB: 'idb',
  GENDER: 'gender',
  NHS_OPTIONS: 'nhsOptions',
  PREFERRED_CENTRE: 'primaryCenter',
  REGISTRATION_REASON: 'registrationReason',
  REGISTRATION_REASON_TEXT: 'registrationReasonText',
};

interface State {
  showDialog: boolean;
  viewed: Set<string>;
  searchQuery: DocumentNode;
  patient: PatientBasic;
}

interface SearchResults {
  ukDedupSearch: PatientBasic[];
}

interface Props {
  autosave: (patient: PatientBasic, key: string, value: string) => Promise<void>;
  forceRefetch: () => void;
  patient: PatientBasic;
  nhsOptionsRefData: ListData[];
  genderRefData: ListData[];
  locationsRefData: ListData[];
  titleReferenceData: ListData[];
  registrationReasonRefData: ListData[];
  saveStatus: SavingStatus;
  validateOnLoad: boolean;
  isPso: boolean;
}

validate.validators.patientdob = function (value: string): string | null {
  // If there is no value or the length of the value is none, just return null and do not continue validation.
  if (!value || value.length === 0) {
    return null;
  }
  if (value.length !== 8) {
    return 'Please complete date of birth.';
  }

  if (isNaN(Number(value))) {
    return 'Please enter a valid date';
  }

  const date = moment(value, 'DDMMYYYY');
  if (date.isValid()) {
    if (date.isAfter(moment())) {
      return 'Please enter a date not in the future';
    }
    return null;
  } else {
    return 'Please enter a valid date';
  }
};

class RegistrationBasic extends Component<Props, State> {
  public static defaultProps = {
    locationsRefData: [],
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      showDialog: false,
      viewed: new Set(),
      searchQuery: emptySearch,
      patient: props.patient,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad && props.patient.lock && !props.patient.lock.readOnly) {
      const fields = Object.keys(VALIDATION_FIELD_NAMES).map((keyName: string): string => {
        return VALIDATION_FIELD_NAMES[keyName];
      });
      const viewed = new Set(fields);
      return { viewed: viewed, showDialog: false, searchQuery: emptySearch, patient: props.patient };
    }

    return state;
  }

  public render(): JSX.Element {
    const { genderRefData, isPso, saveStatus, titleReferenceData } = this.props;
    const patient = this.state.patient;
    return (
      <RegistrationContainer patient={patient} genderRefData={genderRefData} isPso={isPso} saveStatus={saveStatus}>
        {this.renderRegistrationBasicDetailsForm(patient, titleReferenceData)}
      </RegistrationContainer>
    );
  }

  private deduplicate(patient: PatientBasic, key: string, value: string): void {
    if (region === Region.UK) {
      let { firstName, lastName, dob } = patient;
      if (key === 'firstName') firstName = value;
      if (key === 'lastName') lastName = value;
      if (key === 'dob') {
        dob = value;
      }

      const searchQuery = gql`
        {
          ukDedupSearch (search: "${firstName} ${lastName}" dob: "${dob}" existingPatient: "${patient.id}") {
            id
            firstName
            lastName
            dob
            ida
            residentialAddressLine1
            residentialAddressLine2
            residentialAddressCity
            residentialAddressPostcode

          }
        }
      `;
      this.setState({ ...this.state, searchQuery });
    }

    this.autosave(patient, key, value);
  }

  /**
   * Autosave a field and it's values to the database
   * @param patient
   * @param key
   * @param value
   */
  private autosave = async (
    patient: PatientBasic,
    key: string,
    value: string,
    validateOnBlur = true,
  ): Promise<void> => {
    patient[key] = value;
    this.setState({ patient });
    await this.props.autosave(patient, key, value).then(() => {
      if (key === 'idb') {
        value = value.replace(/ /g, '').replace(/_/g, '');

        const viewed = this.state.viewed.add('nhsOptions');
        this.setState({ viewed });
      }
      if (validateOnBlur) {
        let modifiedKey = key;
        // Need to modify the key for validation purposes since the dob raw is made up of 3 fields now, but the error is still only displayed in one area. The error will need to be displayed in one location.
        if (key === FIELD_INFO.DOB_RAW_YEAR.key) {
          modifiedKey = 'dobRaw';
        }
        const viewed = this.state.viewed.add(modifiedKey);
        this.setState({ viewed });
      }
    });
  };

  public hideModal(): void {
    this.setState({ ...this.state, searchQuery: emptySearch });
  }

  private isIsBn(value: string | undefined): boolean | undefined {
    if (value === '') {
      return true;
    } else if (typeof value === 'string') {
      const mul = [];
      for (let i = 0; i < 9; i++) {
        // @ts-ignore
        mul.push(parseInt(value[i]) * [i + 1]);
      }
      const sum = mul.reduce((n, a): number => n + a, 0);
      const mod = sum % 11;
      return mod === parseInt(value[9]);
    }
  }

  private isAbove18(date: any): boolean {
    return (
      new Date(parseInt(date.slice(0, 4)) + 18, parseInt(date.slice(5, 7)) - 1, parseInt(date.slice(8, 10))) <=
      new Date()
    );
  }
  /**
   * Renders the registration form
   * @param {Patient['patient']} patient The patient object for the form we are updating
   * @return {JSX.Element}
   */
  private renderRegistrationBasicDetailsForm(patient: PatientBasic, titleReferenceData: ListData[]): JSX.Element {
    const { genderRefData, locationsRefData, nhsOptionsRefData, registrationReasonRefData, forceRefetch, isPso } =
      this.props;
    // Global rules for the validator, must match the patient interface keys
    const globalValidationRules: { [key: string]: object } = {
      namePrefix: {
        presence: {
          allowEmpty: false,
          message: 'Please select title.',
        },
      },
      firstName: {
        presence: {
          allowEmpty: false,
          message: region === Region.UK ? 'Please enter given names' : 'Please complete first name.',
        },
        length: {
          maximum: 30,
          message: 'Please limit character length to 30 including spaces',
        },
        format: {
          pattern: region === Region.UK ? UK_NAME_REGEX : NAME_REGEX,
          message: 'Please enter characters only.',
        },
      },
      middleName: {
        presence: {
          allowEmpty: true,
        },
        length: {
          maximum: 40,
          message: 'Please limit character length to 40 including spaces',
        },
        format: {
          pattern: region === Region.UK ? UK_NAME_REGEX : NAME_REGEX,
          message: 'Please enter characters only.',
        },
      },
      lastName: {
        presence: {
          allowEmpty: false,
          message: 'Please complete last name.',
        },
        length: {
          maximum: 40,
          message: 'Please limit character length to 40 including spaces',
        },
        format: {
          pattern: region === Region.UK ? UK_NAME_REGEX : NAME_REGEX,
          message: 'Please enter characters only.',
        },
      },
      preferredName: {
        presence: {
          allowEmpty: true,
        },
        length: {
          maximum: 40,
          message: 'Please limit character length to 40 including spaces',
        },
        format: {
          pattern: NAME_REGEX,
          message: 'Please enter characters only.',
        },
      },
      dobRaw: {
        presence: {
          allowEmpty: false,
          message: 'Please complete date of birth.',
        },
        patientdob: {},
      },
      gender: {
        presence: {
          allowEmpty: !FIELD_INFO.GENDER.IS_MANDATORY,
          message: FIELD_INFO.GENDER.MISSING_INPUT_ERROR,
        },
        list: {
          listdata: genderRefData,
          message: FIELD_INFO.GENDER.INVALID_INPUT_ERROR,
        },
      },
    };

    if (region === Region.UK) {
      const validIsbn = this.isIsBn(patient.idb);
      let validAge = true;
      if (patient.dob) {
        validAge = this.isAbove18(patient.dob);
      }

      if (!validIsbn) {
        globalValidationRules.idb = {
          format: {
            pattern: "'a^'",
            message: 'This is not a valid NHS number',
          },
        };
      } else if (patient.idbConflict) {
        globalValidationRules.idb = {
          format: {
            pattern: "'a^'",
            message: 'This NHS number is in use on another patient',
          },
        };
      } else {
        globalValidationRules.idb = {
          presence: {
            allowEmpty: true,
          },
        };
      }

      globalValidationRules.dobRaw = {
        presence: {
          allowEmpty: false,
          message: 'Please complete date of birth',
        },
        format: {
          pattern: !validAge ? 'ERROR' : '.*',
          message: 'Patient is under 18',
        },
      };

      globalValidationRules.primaryCenter = {
        presence: {
          allowEmpty: !FIELD_INFO.PREFERRED_CENTRE.IS_MANDATORY,
          message: FIELD_INFO.PREFERRED_CENTRE.MISSING_INPUT_ERROR,
        },
        list: {
          listdata: locationsRefData,
          message: FIELD_INFO.PREFERRED_CENTRE.INVALID_INPUT_ERROR,
        },
      };
      globalValidationRules.nhsOptions = {
        presence: {
          allowEmpty: !!patient.idb,
          message: FIELD_INFO.NHS_OPTIONS.MISSING_INPUT_ERROR,
        },
      };
      globalValidationRules.registrationReason = {
        presence: {
          allowEmpty: !FIELD_INFO.REGISTRATION_REASON.IS_MANDATORY,
          message: FIELD_INFO.REGISTRATION_REASON.MISSING_INPUT_ERROR,
        },
      };
      globalValidationRules.registrationReasonText = {
        presence: {
          allowEmpty: patient.registrationReason !== 'Other',
          message: FIELD_INFO.REGISTRATION_REASON_TEXT.MISSING_INPUT_ERROR,
        },
      };
    }
    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    for (const viewed of this.state.viewed.keys()) {
      specificValidationRules[viewed] = globalValidationRules[viewed];
    }
    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };
    const modifiedPatient = { ...patient };
    modifiedPatient.dobRaw = appendRawToDOBRawString(patient);
    // Run validation on all the fields
    const validationObject = validate(modifiedPatient, specificValidationRules, disableFullMessages);
    const { searchQuery } = this.state;
    const categoryName = 'basic';

    return (
      <div className="form-page">
        {region === Region.UK && patient.firstName && patient.lastName && patient.dob && (
          <Query<SearchResults> query={searchQuery} fetchPolicy="network-only" nextFetchPolicy="network-only">
            {({ data }): JSX.Element | null => {
              return (
                <ModalSearchResults
                  isOpen={Boolean(data && data.ukDedupSearch && data.ukDedupSearch.length > 0)}
                  data={data}
                  dismissFunction={this.hideModal.bind(this)}
                  patient={patient}
                  redirect={'/registration/temppatientid/basic'}
                />
              );
            }}
          </Query>
        )}
        <form className="form-container">
          <div className="form-heading basic-details">{FORM_HEADING}</div>
          <SectionField
            isValid={validationObject && validationObject.namePrefix ? false : true}
            htmlFor="namePrefix"
            title={TITLE_PREFIX_FIELD_HEADING}>
            <DropDownField
              disabled={patient.lock && patient.lock.readOnly}
              inputName="namePrefix"
              placeholder={TITLE_PREFIX_FIELD_PLACEHOLDER}
              defaultValue={patient.namePrefix}
              options={titleReferenceData}
              onChange={(e): Promise<void> => this.autosave(patient, 'namePrefix', e.target.value)}
              errors={validationObject && validationObject.namePrefix ? validationObject.namePrefix : undefined}
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.firstName ? false : true}
            htmlFor="firstName"
            title={applyRequired({
              title: FIRST_NAME_FIELD_HEADING,
              category: categoryName,
              optionalForPSO: isPso,
            })}>
            <FreeTextField
              disabled={patient.lock && patient.lock.readOnly}
              inputName="firstName"
              maxLength={30}
              placeholder={FIRST_NAME_FIELD_PLACEHOLDER}
              defaultValue={patient.firstName}
              onBlur={(e): void => this.deduplicate(patient, 'firstName', e.target.value)}
              errors={validationObject && validationObject.firstName ? validationObject.firstName : undefined}
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.middleName ? false : true}
            htmlFor="middleName"
            hideOptional={region === Region.UK}
            title={MIDDLE_NAME_FIELD_HEADING}
            required={false}>
            <FreeTextField
              disabled={patient.lock && patient.lock.readOnly}
              inputName="middleName"
              maxLength={40}
              placeholder={MIDDLE_NAME_FIELD_PLACEHOLDER}
              defaultValue={patient.middleName}
              onBlur={(e): void => {
                this.autosave(patient, 'middleName', e.target.value);
              }}
              errors={validationObject && validationObject.middleName ? validationObject.middleName : undefined}
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.lastName ? false : true}
            htmlFor="lastName"
            title={applyRequired({
              title: LAST_NAME_FIELD_HEADING,
              category: categoryName,
              optionalForPSO: isPso,
            })}>
            <FreeTextField
              disabled={patient.lock && patient.lock.readOnly}
              inputName="lastName"
              maxLength={40}
              placeholder={LAST_NAME_FIELD_PLACEHOLDER}
              defaultValue={patient.lastName}
              onBlur={(e): void => this.deduplicate(patient, 'lastName', e.target.value)}
              errors={validationObject && validationObject.lastName ? validationObject.lastName : undefined}
            />
          </SectionField>
          {region !== Region.UK && (
            <SectionField
              isValid={validationObject && validationObject.preferredName ? false : true}
              htmlFor="preferredName"
              title={PREFERRED_NAME_FIELD_HEADING}
              required={false}>
              <FreeTextField
                disabled={patient.lock && patient.lock.readOnly}
                inputName="preferredName"
                maxLength={40}
                placeholder={PREFERRED_NAME_FIELD_PLACEHOLDER}
                defaultValue={patient.preferredName}
                onBlur={(e): void => {
                  this.autosave(patient, 'preferredName', e.target.value);
                }}
                errors={validationObject && validationObject.preferredName ? validationObject.preferredName : undefined}
              />
              <CircleInfo messages={['If different than first name']} />
            </SectionField>
          )}

          <SectionField
            isValid={validationObject && validationObject.gender ? false : true}
            htmlFor={FIELD_INFO.GENDER.NAME}
            title={applyRequired({
              title: FIELD_INFO.GENDER.TITLE,
              category: categoryName,
              optionalForPSO: isPso,
            })}>
            <SegmentedInput
              disabled={patient.lock && patient.lock.readOnly}
              options={genderRefData}
              fieldName={FIELD_INFO.GENDER.NAME}
              defaultSelected={patient.gender}
              itemSelected={(selectedItem): void => {
                this.autosave(patient, FIELD_INFO.GENDER.KEY, (selectedItem as ListData).id);
              }}
              errors={validationObject && validationObject.gender ? validationObject.gender : undefined}
            />
          </SectionField>
          {region !== Region.UK ? (
            <SectionField
              isValid={validationObject && validationObject.dobRaw ? false : true}
              htmlFor="dobRaw"
              title={applyRequired({
                title: DATE_OF_BIRTH_FIELD_HEADING,
                category: categoryName,
                optionalForPSO: isPso,
              })}>
              <div className="date-of-birth-container">
                <div className="date-of-birth-input-container">
                  <FreeTextField
                    disabled={patient.lock && patient.lock.readOnly}
                    inputName={FIELD_INFO.DOB_RAW_DAY.name}
                    maxLength={2}
                    inputType="text"
                    defaultValue={modifiedPatient.dobRawDay}
                    displayInputError={validationObject && validationObject.dobRaw}
                    onBlur={(e): void => {
                      this.autosave(modifiedPatient, FIELD_INFO.DOB_RAW_DAY.key, e.target.value);
                    }}
                    placeholder={'DD'}
                  />
                  <FreeTextField
                    disabled={patient.lock && patient.lock.readOnly}
                    inputName={FIELD_INFO.DOB_RAW_MONTH.name}
                    maxLength={2}
                    inputType="text"
                    defaultValue={modifiedPatient.dobRawMonth}
                    displayInputError={validationObject && validationObject.dobRaw}
                    onBlur={(e): void => {
                      this.autosave(modifiedPatient, FIELD_INFO.DOB_RAW_MONTH.key, e.target.value);
                    }}
                    placeholder={'MM'}
                  />
                  <FreeTextField
                    disabled={patient.lock && patient.lock.readOnly}
                    inputName={FIELD_INFO.DOB_RAW_YEAR.name}
                    maxLength={4}
                    inputType="text"
                    defaultValue={modifiedPatient.dobRawYear}
                    displayInputError={validationObject && validationObject.dobRaw}
                    onBlur={(e): void => {
                      this.autosave(modifiedPatient, FIELD_INFO.DOB_RAW_YEAR.key, e.target.value);
                    }}
                    placeholder={'YYYY'}
                  />
                </div>
                <div className="date-of-birth-example-text">{DATE_OF_BIRTH_EXAMPLE_TEXT}</div>
                <ErrorInfo errors={validationObject && validationObject.dobRaw} />
              </div>
            </SectionField>
          ) : (
            <SectionField isValid={!validationObject?.dobRaw} htmlFor="dobRaw" title={DATE_OF_BIRTH_FIELD_HEADING}>
              <BaseDatePicker
                id="dobRaw"
                sx={{ height: '48px' }}
                disabled={modifiedPatient?.lock?.readOnly}
                disableFuture
                openTo="year"
                value={modifiedPatient.dob ? dayjs(modifiedPatient.dob) : null}
                onAccept={(value: Dayjs | null): void => {
                  const date = dayjs(value);
                  if (date.isValid()) {
                    this.deduplicate(modifiedPatient, FIELD_INFO.DOB.key, date.format('YYYY-MM-DD'));
                  } else if (value === null) {
                    this.deduplicate(modifiedPatient, FIELD_INFO.DOB.key, '');
                  }
                }}
                minDate={dayjs().subtract(120, 'year')}
                maxDate={dayjs().subtract(18, 'years')}
                error={!!validationObject?.dobRaw}
                helperText={validationObject?.dobRaw?.[0]}
                onBlur={(e): void => {
                  // On Accept doesn't get triggered with keyboard input, so we need to trigger it on blur
                  const date = dayjs(e.target.value, 'DD/MM/YYYY');
                  if (date.isValid()) {
                    this.deduplicate(modifiedPatient, FIELD_INFO.DOB.key, date.format('YYYY-MM-DD'));
                  } else {
                    this.deduplicate(modifiedPatient, FIELD_INFO.DOB.key, '');
                  }
                }}
              />
            </SectionField>
          )}
          {region === Region.UK && (
            <>
              <SectionField
                isValid={validationObject && validationObject.primaryCenter ? false : true}
                htmlFor="primaryCenter"
                title={PREFERRED_CENTRE_FIELD_HEADING}>
                <DropDownField
                  inputName="primaryCenter"
                  placeholder={PREFERRED_CENTRE_FIELD_PLACEHOLDER}
                  defaultValue={patient.primaryCenter}
                  options={locationsRefData}
                  onChange={(e): Promise<void> => this.autosave(patient, 'primaryCenter', e.target.value)}
                  errors={
                    validationObject && validationObject.primaryCenter ? validationObject.primaryCenter : undefined
                  }
                />
              </SectionField>

              <SectionField
                isValid={validationObject && validationObject.idb ? false : true}
                htmlFor="idb"
                title={FIELD_INFO.IDB.TITLE}>
                <MaskField
                  mask="999 999 9999"
                  disabled={patient.lock && patient.lock.readOnly}
                  inputName="idb"
                  inputType="text"
                  defaultValue={modifiedPatient.idb}
                  displayInputError={validationObject && validationObject.idb}
                  onBlur={(e): void => {
                    const cleanedValue = e.target.value.replace(/ /g, '').replace(/_/g, '');
                    this.autosave(modifiedPatient, 'idb', cleanedValue);
                    setTimeout(forceRefetch, 300);
                  }}
                  errors={validationObject && validationObject.idb ? validationObject.idb : undefined}
                />
              </SectionField>
              <SectionField
                disabled={!patient.idb}
                isValid={validationObject && validationObject.nhsOptions ? false : true}
                htmlFor="nhsOptions"
                title={NHS_OPTIONS_FIELD_HEADING}>
                <DropDownField
                  inputName="nhsOptions"
                  disabled={Boolean(patient.idb)}
                  placeholder={!patient.idb ? NHS_OPTIONS_FIELD_PLACEHOLDER : ''}
                  defaultValue={patient.nhsOptions}
                  options={!patient.idb ? nhsOptionsRefData : []}
                  onChange={(e): Promise<void> => {
                    return this.autosave(patient, 'nhsOptions', e.target.value);
                  }}
                  errors={validationObject && validationObject.nhsOptions ? validationObject.nhsOptions : undefined}
                />
              </SectionField>
              <SectionField
                isValid={validationObject && validationObject.registrationReason ? false : true}
                htmlFor="registrationReason"
                title={REGISTRATION_REASON_FIELD_HEADING}>
                <DropDownField
                  inputName="registrationReason"
                  placeholder={REGISTRATION_REASON_FIELD_PLACEHOLDER}
                  defaultValue={patient.registrationReason}
                  options={registrationReasonRefData}
                  onChange={(e): Promise<void> => this.autosave(patient, 'registrationReason', e.target.value)}
                  errors={
                    validationObject && validationObject.registrationReason
                      ? validationObject.registrationReason
                      : undefined
                  }
                />
              </SectionField>
            </>
          )}
          {region === Region.UK && patient.registrationReason === 'Other' && (
            <SectionField
              isValid={validationObject && validationObject.registrationReasonText ? false : true}
              htmlFor="registrationReasonText"
              title={REGISTRATION_REASON_TEXT_FIELD_HEADING}>
              <FreeTextField
                disabled={patient.lock && patient.lock.readOnly}
                inputName="registrationReasonText"
                maxLength={200}
                placeholder={REGISTRATION_REASON_TEXT_FIELD_PLACEHOLDER}
                defaultValue={patient.registrationReasonText}
                onBlur={(e): void => this.deduplicate(patient, 'registrationReasonText', e.target.value)}
                errors={
                  validationObject && validationObject.registrationReasonText
                    ? validationObject.registrationReasonText
                    : undefined
                }
              />
            </SectionField>
          )}
        </form>
      </div>
    );
  }
}

export default RegistrationBasic;
