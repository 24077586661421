import {
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  handlePointClick,
  TopChartParams,
  setHeight,
  selectPoint,
  columnSeriesMapping,
  seriesMapping,
  xAxisConfig,
  columnTooltipConfig,
} from './DefaultConfig';

const ChartStackedColumn = ({
  onPointClick,
  selectedPoint,
  selectedIndex,
  seeMoreUpdated,
  leftSeriesTitle,
  leftSeriesFields,
  aggregation,
  dateRange,
  endDate,
  tooltipSuffix = 'plans',
  opacity = 1,
  stacking = 'normal',
  seriesColorMapping,
}: TopChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: 'top-chart',
      connector: {
        id: 'top-chart-data',
        columnAssignment: columnSeriesMapping(leftSeriesFields),
      },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: exportingConfig,
        chart: {
          type: 'column',
          animation: false,
          spacing: chartSpacingConfig,
          styledMode: false,
          events: {
            load() {
              setHeight(this);
            },
            redraw() {
              selectPoint(this, selectedPoint, selectedIndex);
            },
          },
        },
        title: { text: '' },
        xAxis: xAxisConfig(aggregation!, dateRange!, endDate!),
        yAxis: {
          title: { text: leftSeriesTitle },
          ...(stacking === 'percent'
            ? {
                max: 100,
                endOnTick: false,
                labels: {
                  format: '{value}%',
                },
              }
            : {}),
        },
        ...(stacking === 'percent'
          ? {
              legend: {
                itemStyle: {
                  fontSize: '12px',
                },
              },
            }
          : {}),
        credits: creditsConfig,
        tooltip: columnTooltipConfig(aggregation!, tooltipSuffix),
        series: seriesMapping(leftSeriesFields, aggregation!, seriesColorMapping!, opacity),
        plotOptions: {
          column: {
            stacking: stacking as Highcharts.OptionsStackingValue,
          },
          series: {
            cursor: 'pointer',
            animation: selectedPoint === '' && !seeMoreUpdated,
            states: {
              select: {
                enabled: false,
              },
            },
            point: {
              events: {
                click: function () {
                  handlePointClick(this, onPointClick);
                },
              },
            },
          },
        },
        time: {
          timezone: 'UTC',
        },
      },
    },
  ],
});

export default ChartStackedColumn;
