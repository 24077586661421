// eslint-disable-next-line no-use-before-define
import React, { Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Patient, LocationListData } from 'op-interfaces';
import RegistrationHomeClinic from './RegistrationHomeClinic';
import { ListData } from 'shared-components/interfaces';
import { GET_LOCATION_PROFILE } from 'op-components/PatientCard/PatientCardQueries';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';

interface Props extends WithApolloClient<{}> {
  patient: Patient;
  horizonCenterId: string;
  homeRego?: boolean;
  stateHandler?: (selection: string | undefined) => void;
  validationObject?: any;
  disabled?: any;
}

const RegistrationHomeClinicApollo = (props: Props): JSX.Element => {
  const { patient, horizonCenterId, homeRego, stateHandler, validationObject, disabled } = props;

  // Map the locations to match the listdata type expected by the dropdown component
  const convertLocationsToListData = (locations: LocationListData[]): any => {
    return locations.map(({ alias: name, ...rest }) => ({ name, ...rest }));
  };

  const { loading, data } = useQuery(GET_LOCATION_PROFILE, {
    variables: { patientId: patient.id },
    skip: !patient.id,
  });

  if (loading) {
    return <Fragment />;
  }

  if (data && data.locationsPsoSystem) {
    const listData: ListData[] = convertLocationsToListData(data.locationsPsoSystem);
    return (
      <RegistrationHomeClinic
        disabled={disabled}
        isHomeRego={homeRego || false}
        patient={patient}
        locationListData={listData}
        horizonCenterId={horizonCenterId}
        stateHandler={stateHandler}
        validationObject={validationObject}
      />
    );
  }
  return <Fragment />;
};

const component = withApollo<Props>(RegistrationHomeClinicApollo);
export default component;
