// eslint-disable-next-line no-use-before-define
import React, { useState, Fragment, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router';
import { withRouter } from 'react-router';
import { styled } from '@mui/system';
import { PatientAttachment } from 'op-interfaces';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import CompactNotes from '../CompactNotes';

import OuterContainer from '../OuterContainer';
import DoseSiteSummary from '../DoseSiteSummary';
import DocList from '../DocList';
import ROPatientDocs from '../Docs';
import { ROPatientContextProvider } from '../context';
import '../PatientSummary.scss';
import ROPatientForms from '../PatientForms';
import { LARGE_DISPLAY_SIZE } from 'shared-components/enums';
import { DocumentUpload } from 'op-components';
import { GET_PATIENT_ATTACHMENTS, SUBMIT_ATTACHMENT } from 'op-components/DocumentUpload/queries';
import { DocumentType } from './interfaces';
import { NavigationContext, FileContextProvider } from 'op-contexts';
import useInterval from 'shared-components/utils/useInterval';
import { PAT_ID_1_REFRESH_TIME } from 'shared-components/enums';
import { Grid, Dialog, DialogTitle, IconButton, DialogContent, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledROPatientDocs = styled(ROPatientDocs)`
  margin-top: 2em;
`;

interface User {
  id: string;
}

interface Attachment extends PatientAttachment {
  uploader: User;
  staging: boolean;
}

const ROPatientSummaryPage = (): JSX.Element => {
  const location = useLocation();
  const windowSize = useWindowSize();
  const [document, setDocument] = useState<DocumentType | null>(null);
  const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);
  const [showDocumentUploadPage, setShowDocumentUploadPage] = useState<boolean>(false);
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;
  const isSmallDisplay = windowSize.width < LARGE_DISPLAY_SIZE;
  const [submitAttachmentMutation] = useMutation(SUBMIT_ATTACHMENT);
  const history = useHistory();

  const { data, refetch } = useQuery(GET_PATIENT_ATTACHMENTS, {
    variables: { id: patientId },
    context: { locallyHandledErrors: ['Patient matching query does not exist.'] },
    onError: (error) => {
      history.push('/radiation');
    },
  });

  // Refresh profile every few seconds until patient has pat_id_1
  useInterval(
    () => {
      refetch();
    },
    data?.patient?.userProfile?.hasPatId1 ? null : PAT_ID_1_REFRESH_TIME,
  );

  const userId = data?.user.id;
  const isRo = data?.user?.isRo;

  // Filter attachments:
  // 1. Those not submitted or submitting + user ID; we only want to show unsubmitted attachments for uploader
  // 2. Non-registration attachments should not be displayed
  const filteredAttachments =
    data?.patient?.attachments?.filter(
      (file: Attachment) =>
        !file.submitted && file.uploader?.id === userId && !file.staging && !file.isRegistrationAttachment,
    ) || [];

  const navigationContext = useContext(NavigationContext);

  useEffect(() => {
    setDocument(null);
  }, [patientId]);

  // When the PSO clicks on Patient Summary, set the entry point to Patient Summary so that after reviewing forms,
  //  then coming back here, then submitting registration, the PSO comes back here instead of review-forms
  useEffect(() => {
    navigationContext.setRegEntryPath(location.pathname);
  }, [location.pathname]);

  if (!data) return <></>;

  const docList = (
    <DocList
      isSmallDisplay={isSmallDisplay}
      triggerModal={() => setShowDocumentModal(true)}
      currentDocument={document}
      setDocument={(document: any): void => {
        setDocument(document);
      }}
      setShowDocumentUploadPage={(showPage: boolean): void => {
        setShowDocumentUploadPage(showPage);
      }}
      unsubmittedDocuments={Boolean(filteredAttachments.length)}
      disableUpload={!data?.patient?.userProfile?.hasPatId1}
    />
  );
  // sx={{ width: '50%', gap: '16px', padding: '0 16px' }}
  const isROSummary = window.location.href.includes('radiation');

  return (
    <FileContextProvider>
      <ROPatientContextProvider>
        <OuterContainer
          rowSpace={showDocumentUploadPage ? '0px' : undefined}
          marginBottom={showDocumentUploadPage ? '64px' : '56px'}
          refreshChartSummary={() => setShowDocumentUploadPage(false)}>
          <Grid container>
            {!showDocumentUploadPage &&
              (isROSummary ? (
                <Grid
                  item
                  xs={isSmallDisplay ? 6 : 3}
                  sx={{ padding: '0 16px', gap: '16px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                  {docList}
                  <DoseSiteSummary />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={isSmallDisplay ? 6 : 3}
                  sx={{ padding: '0 16px', gap: '16px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <ROPatientForms patientId={patientId} isSmallDisplay={isSmallDisplay} />
                  {docList}
                </Grid>
              ))}
            {!isSmallDisplay && !showDocumentUploadPage ? (
              <Grid item xs={6}>
                {/* PDF viewer  */}
                <StyledROPatientDocs document={document} />
              </Grid>
            ) : (
              <></>
            )}
            {isSmallDisplay && !showDocumentUploadPage ? (
              <Dialog
                fullWidth
                sx={{ '& .MuiPaper-root': { minWidth: '90%', height: '100%' } }}
                open={showDocumentModal}
                onClose={() => {
                  setShowDocumentModal(false);
                  setDocument(null);
                }}
                id="document-modal">
                <DialogTitle sx={{ display: 'flex' }}>
                  <IconButton
                    sx={{ marginLeft: 'auto' }}
                    className="btn-close"
                    onClick={() => {
                      setShowDocumentModal(false);
                      setDocument(null);
                    }}>
                    <CloseIcon color="primary" />
                  </IconButton>
                </DialogTitle>
                <DialogContent sx={{ width: '100%' }}>
                  <StyledROPatientDocs document={document} />
                </DialogContent>
              </Dialog>
            ) : (
              <></>
            )}
            {showDocumentUploadPage ? (
              <Grid item xs={12}>
                <DocumentUpload
                  patientId={patientId}
                  userId={userId}
                  currentDocuments={filteredAttachments}
                  onSubmitDocumentUpload={async (): Promise<boolean> => {
                    const success = await submitAttachmentMutation({
                      variables: {
                        patientID: patientId,
                      },
                    }).then((response) => {
                      return response.data.submitAttachment.success;
                    });
                    if (success) setShowDocumentUploadPage(false);
                    return success;
                  }}
                  onCancelDocumentUpload={() => setShowDocumentUploadPage(false)}
                />
              </Grid>
            ) : (
              <></>
            )}
            {!showDocumentUploadPage ? (
              <Grid
                item
                xs={isSmallDisplay ? 6 : 3}
                style={{ padding: isSmallDisplay ? '0 16px 0 0' : '0 16px', height: '100%' }}>
                <CompactNotes />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </OuterContainer>
      </ROPatientContextProvider>
    </FileContextProvider>
  );
};

const routedComponent = withRouter(ROPatientSummaryPage);
export default routedComponent;
