// eslint-disable-next-line no-use-before-define
import React from 'react';
import { CareplanTemplate } from '../../Interface';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import { Typography, Stack, useTheme } from '@mui/material';

interface CareplanButtonProps {
  dataTestId?: string;
  currentTemplate: CareplanTemplate;
  index: number;
  className?: string;
  footer: JSX.Element;
  onClickHandler: () => void;
}

const CareplanButton = (props: CareplanButtonProps): JSX.Element => {
  const { currentTemplate, index, className, footer, onClickHandler } = props;
  const id = `suggested-careplan-item-${index}`;
  const theme = useTheme();
  return (
    <Button
      key={currentTemplate.id}
      className={className}
      data-testid={props?.dataTestId}
      id={id}
      sx={{ ':hover': { backgroundColor: theme.palette.action.hover } }}
      onAnimationEnd={() => {
        const careplanItem = document.getElementById(id) as any;
        careplanItem.disabled = false;
        careplanItem.classList.remove('active');
        (document.getElementById('careplanCheckmark' + index) as any).classList.remove('added');
      }}
      onClick={() => {
        const elem = document.getElementById(id) as any;
        elem.disabled = true;
        const checked = document.getElementById('careplanCheckmark' + index) as any;
        elem.classList.add('active');
        checked.classList.add('added');
        onClickHandler();
      }}>
      <Stack alignSelf="left" gap={1} width={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography data-testid="template-name" variant="subtitle1">
            {currentTemplate.name}
          </Typography>
          <AddCircleOutline color="primary" id={'careplanCheckmark' + index} />
        </Stack>
        {footer}
      </Stack>
    </Button>
  );
};

export default CareplanButton;
