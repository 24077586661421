// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import { MandatoryPatient, OPUser } from 'op-interfaces';
import { LoadingSpinner } from 'shared-components/components';
import HAPSOReview from './HAPSOReview';

export const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    patient(id: $id) {
      id
      address {
        id
        formattedAddress
      }
      firstName
      middleName
      lastName
      ida
      idb
      gender
      dob
      emrInstance
    }
    user {
      id
      isPso
    }
  }
`;

interface Props extends WithApolloClient<{}>, RouteComponentProps<{ patientId: string }> {}

class PatientFormSummaryApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
    } = this.props;

    return (
      <Query<{ patient: MandatoryPatient; user: OPUser }>
        query={PATIENT_AND_USER_DETAILS_QUERY}
        variables={{ id: patientId }}
        fetchPolicy="network-only">
        {({ loading, data }): JSX.Element => {
          if (loading) {
            return <LoadingSpinner relativeSpinner={true} />;
          }
          if (data && data.patient && data.user) {
            return (
              <Fragment>
                <HAPSOReview patient={data.patient} isPso={data.user.isPso} />
              </Fragment>
            );
          }
          return <div></div>;
        }}
      </Query>
    );
  }
}

export default withRouter(PatientFormSummaryApollo);
