import React, { useCallback, useEffect, useState } from 'react';
import OuterContainer from '../PatientSummary/OuterContainer';
import { ROPatientContextProvider } from '../PatientSummary/context';
import { Stack, Typography, Divider, Button } from '@mui/material';
import { ROPatientCarePathDoseSummaryWidget } from '../Careplan/SidePanel/SidePanel';
import { Slide, toast } from 'react-toastify';
import {
  ROAutocomplete,
  RODatePicker,
  ROTextField,
  SelectOptionType,
  ROChipMultiSelect,
} from 'shared-components/components/FormFields';
import { LoadingSpinner } from 'shared-components/components';

import { getOptionByValue } from '../Careplan/DiagnosisPages/Utils';
import { useQuery } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import {
  GET_ON_TREATMENT_REVIEW,
  GET_ON_TREATMENT_REVIEW_LIST,
  SUBMIT_ON_TREATMENT_REVIEW,
  UPDATE_ON_TREATMENT_REVIEW,
} from './Queries';
import { ROSidePanel } from 'op-components/index';
import { LOAD_CAREPLAN_LIST_DATA } from '../Careplan/ListData/ListQueries';
import { filterListdata } from '../Careplan/ListData/ListData';
import Banner from './Banner';
import { OnTreatmentReviewType } from './Interface';
import PDFPreviewModal from './PDFPreviewModal';

const LIST_OPTIONS = {
  IMAGE_REVIEW: 'otvImagingReview',
};

type ValidationStructure = {
  reviewDate: boolean;
  treatmentFractionsReviewed: boolean;
  imagingReview: boolean;
  clinicalAssessment: boolean;
  plan: boolean;
  painLevel: boolean;
  painManagementPlan: boolean;
};

type FormStateType = {
  reviewDate: string | null;
  treatmentFactionsReviewed: string;
  imagingReview: string[];
  clinicalAssessment: string;
  plan: string;
  painLevel: number | null;
  painManagementPlan: string;
  practitioner?: string;
};

const painLevelOptions: SelectOptionType[] = [
  { value: '', label: '' },
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

const getFractionsList = (doseSiteSummary: string): SelectOptionType[] => {
  const doseSiteSummaryData = JSON.parse(doseSiteSummary);
  const doseFractions = Math.ceil(doseSiteSummaryData.plannedDosageRatio / 5);
  // only one fraction to be delivered then return 1 instead of 1-1
  if (doseSiteSummaryData.plannedDosageRatio === 1) {
    return [{ value: '1', label: '1' }];
  }
  // create array of fractions
  return [...Array(doseFractions).keys()].map((val) => ({
    value:
      val * 5 + 5 > doseSiteSummaryData.plannedDosageRatio
        ? `${val * 5 + 1}-${doseSiteSummaryData.plannedDosageRatio}`
        : `${val * 5 + 1}-${val * 5 + 5}`,
    label:
      val * 5 + 5 > doseSiteSummaryData.plannedDosageRatio
        ? `${val * 5 + 1}-${doseSiteSummaryData.plannedDosageRatio}`
        : `${val * 5 + 1}-${val * 5 + 5}`,
  }));
};

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const OnTreatmentReviewForm = (): JSX.Element => {
  const match: { params: { id: string; reviewId: string } } = useRouteMatch();
  const { id: patientId, reviewId } = match.params;
  const [openPDFViewer, setOpenPDFViewer] = useState(false);
  const { loading: listDataLoading, data: listData } = useQuery(LOAD_CAREPLAN_LIST_DATA, {
    fetchPolicy: 'cache-first',
    variables: {
      listCategory: [LIST_OPTIONS.IMAGE_REVIEW],
      patientId,
    },
  });
  const { loading: reviewLoading, data: reviewData } = useQuery(GET_ON_TREATMENT_REVIEW, {
    variables: { id: reviewId },
  });
  const { loading: reviewListLoading, data: reviewListData } = useQuery(GET_ON_TREATMENT_REVIEW_LIST, {
    variables: { patientId },
  });
  const [updateTreatmentReview] = useMutation(UPDATE_ON_TREATMENT_REVIEW, {
    refetchQueries: [{ query: GET_ON_TREATMENT_REVIEW, variables: { id: reviewId } }],
  });
  const [submitTreatmentReview] = useMutation(SUBMIT_ON_TREATMENT_REVIEW, {
    refetchQueries: [
      { query: GET_ON_TREATMENT_REVIEW_LIST, variables: { patientId } },
      { query: GET_ON_TREATMENT_REVIEW, variables: { id: reviewId } },
    ],
  });

  const [formState, setFormState] = useState<FormStateType>({
    reviewDate: '',
    treatmentFactionsReviewed: '',
    imagingReview: [],
    clinicalAssessment: '',
    plan: '',
    painLevel: null,
    painManagementPlan: '',
  });
  const [isInValidField, setIsInValidField] = React.useState<ValidationStructure>({
    reviewDate: true,
    treatmentFractionsReviewed: true,
    imagingReview: true,
    clinicalAssessment: true,
    plan: true,
    painLevel: true,
    painManagementPlan: true,
  });
  const [triggerSubmit, setTriggerSubmit] = React.useState<boolean>(false);
  const history = useHistory();
  const [initialLoad, setInitialLoad] = useState(true); // Ref to track initial load

  useEffect(() => {
    if (reviewData && initialLoad) {
      const { treatmentFractionsReviewed, imageReview, clinicalAssessment, plan, painLevel, painManagementPlan } =
        reviewData.treatmentReview;
      setFormState({
        reviewDate: reviewData.treatmentReview.reviewDate,
        treatmentFactionsReviewed: treatmentFractionsReviewed,
        imagingReview: imageReview ? imageReview.split(',') : [],
        clinicalAssessment: clinicalAssessment,
        plan: plan,
        painLevel: painLevel,
        painManagementPlan: painManagementPlan,
        practitioner: reviewData.treatmentReview.practitioner?.name || '',
      });
      setInitialLoad(false); // Set initial load to false after first load
    }
  }, [reviewData, initialLoad]);
  const updateTreatmentReviewCallback = useCallback(
    debounce((newState: FormStateType) => {
      updateTreatmentReview({
        variables: {
          id: reviewId,
          reviewDate: newState.reviewDate,
          treatmentFractionsReviewed: newState.treatmentFactionsReviewed,
          imageReview: newState.imagingReview.join(','),
          clinicalAssessment: newState.clinicalAssessment,
          plan: newState.plan,
          painLevel: newState.painLevel,
          painManagementPlan: newState.painManagementPlan,
        },
      });
    }, 1000),
    [reviewId, updateTreatmentReview],
  );
  const submitForm = () => {
    submitTreatmentReview({ variables: { id: reviewId } }).then(() => {
      setTimeout(() => {
        toast.dark(
          <>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.primary.contrastText }}>
              On treatment review submitted.
            </Typography>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.primary.contrastText }}>
              You can view the PDF in the Documents page.
            </Typography>
          </>,
          {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            transition: Slide,
            progress: undefined,
            style: { width: 'fit-content' },
          },
        );
      }, 500);
      history.push(`/radiation/patient/${patientId}/on-treatment-review`);
    });
  };
  const triggerValidation = () => {
    const formInValid = [
      isInvalid('reviewDate', formState.reviewDate),
      isInvalid('treatmentFractionsReviewed', formState.treatmentFactionsReviewed),
      isInvalid('imagingReview', formState.imagingReview.join(', ')),
      isInvalid('clinicalAssessment', formState.clinicalAssessment),
      isInvalid('plan', formState.plan),
      isInvalid('painLevel', formState.painLevel),
      isInvalid('painManagementPlan', formState.painManagementPlan),
    ].some((item) => item === true);
    return formInValid;
  };
  const isInvalid = (fieldName: string, value: any) => {
    if (String(value) === '' || value === null) {
      setIsInValidField((prev) => ({ ...prev, [fieldName]: true }));
      return true;
    } else {
      setIsInValidField((prev) => ({ ...prev, [fieldName]: false }));
      return false;
    }
  };
  const handleChangeTreatment = (values: string[]) => {
    handleChange(values, 'treatment');
    isInvalid('responseToTreatment', values);
  };
  const handleChangeImaging = (values: string[]) => {
    handleChange(values, 'imaging');
    isInvalid('imagingReview', values);
  };
  const handleChange = (values: string[], type: 'treatment' | 'imaging') => {
    if (type === 'treatment') {
      setFormState((prevValues) => {
        const newdata = { ...prevValues, responseToTreatment: values };
        updateTreatmentReviewCallback(newdata);
        return newdata;
      });
    } else {
      setFormState((prevValues) => {
        const newdata = { ...prevValues, imagingReview: values };
        updateTreatmentReviewCallback(newdata);
        return newdata;
      });
    }
  };
  if (listDataLoading || reviewLoading) {
    return <LoadingSpinner />;
  }
  const imagingReviewOptions = filterListdata(listData, LIST_OPTIONS.IMAGE_REVIEW).map(
    (item: { value: any }) => item.value,
  );
  // filter the reviews that match the careplan and get the fractions reviewed
  const existingFractions =
    reviewListData?.treatmentReviewList
      .filter((item: OnTreatmentReviewType) => item.careplan === reviewData?.treatmentReview.careplan)
      .map((item: any) => item.treatmentFractionsReviewed) || [];
  const fractionsList = getFractionsList(reviewData?.treatmentReview.dss)
    .map((item) => {
      return {
        ...item,
        disabled:
          reviewData.treatmentReview.treatmentFactionsReviewed === item.value || existingFractions.includes(item.value),
      };
    })
    .map((item) => {
      return { ...item, tooltip: item.disabled ? 'Fractions are already reviewed' : '' };
    });

  return (
    <ROPatientContextProvider>
      <OuterContainer>
        <Stack direction="row" width={1} marginTop="-16px">
          <Stack sx={{ width: '100%' }}>
            <Banner
              review={reviewData.treatmentReview}
              patientId={patientId}
              discardCallback={() => {
                setInitialLoad(true);
              }}
            />
            <Stack style={{ paddingLeft: '24px', paddingTop: '24px', width: '100%', flex: 1, overflowY: 'auto' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }} data-testid="on-treatment-review-form-title">
                {reviewData?.treatmentReview.careplan}
              </Typography>
              <Typography variant="body1">
                <span style={{ color: 'red' }}>*</span> = required field
              </Typography>
              <Stack style={{ paddingTop: '24px', width: '100%' }}>
                <RODatePicker
                  id="reviewDate"
                  fieldlabel="Treatment review date"
                  value={reviewData?.treatmentReview?.reviewDate ? dayjs(reviewData.treatmentReview.reviewDate) : null}
                  onChange={(date) => {
                    const newDate = dayjs(date).format('YYYY-MM-DD');
                    const dateValue = newDate === 'Invalid Date' ? null : newDate;
                    setFormState((prevValues) => {
                      const newdata = { ...prevValues, reviewDate: dateValue };
                      updateTreatmentReviewCallback(newdata);
                      return newdata;
                    });
                    isInvalid('reviewDate', dateValue);
                  }}
                  required
                  requiredFieldColor="red"
                  error={triggerSubmit && isInValidField.reviewDate}
                />
                <ROTextField
                  id="supervisingPhysician"
                  requiredFieldColor="red"
                  fieldlabel="Supervising Physician"
                  required
                  value={formState.practitioner || ''}
                  disabled
                />
                <ROAutocomplete
                  id="treatmentFractionsReviewed"
                  fieldlabel="Treatment fractions reviewed"
                  requiredFieldColor="red"
                  required
                  inputProps={{ error: triggerSubmit && isInValidField.treatmentFractionsReviewed }}
                  options={fractionsList}
                  value={getOptionByValue(
                    fractionsList,
                    formState.treatmentFactionsReviewed ? formState.treatmentFactionsReviewed : '',
                  )}
                  onChange={(option: SelectOptionType | string): void => {
                    const value = typeof option === 'string' ? option : option?.value;
                    setFormState((prevValues) => {
                      const newdata = { ...prevValues, treatmentFactionsReviewed: value };
                      updateTreatmentReviewCallback(newdata);
                      return newdata;
                    });
                    isInvalid('treatmentFractionsReviewed', value);
                  }}
                />
                <ROTextField
                  id="clinicalAssessment"
                  requiredFieldColor="red"
                  fieldlabel="Clinical Assessment (Subjective, Objective)"
                  required
                  error={triggerSubmit && isInValidField.clinicalAssessment}
                  minRows={4}
                  value={formState.clinicalAssessment || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setFormState((prevValues) => {
                      const newdata = { ...prevValues, clinicalAssessment: event.target.value };
                      updateTreatmentReviewCallback(newdata);
                      return newdata;
                    });
                    isInvalid('clinicalAssessment', event.target.value);
                  }}
                  multiline
                  maxRows={8}
                  placeholder="Start typing ..."
                  maxLength={-1}
                />
                <ROTextField
                  id="plan"
                  requiredFieldColor="red"
                  fieldlabel="Plan"
                  required
                  error={triggerSubmit && isInValidField.plan}
                  value={formState.plan || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setFormState((prevValues) => {
                      const newdata = { ...prevValues, plan: event.target.value };
                      updateTreatmentReviewCallback(newdata);
                      return newdata;
                    });
                    isInvalid('plan', event.target.value);
                  }}
                  placeholder="Start typing ..."
                  multiline
                  minRows={1}
                  maxRows={4}
                />
                <ROChipMultiSelect
                  id="imagingReview"
                  requiredFieldColor="red"
                  fieldLabel="Imaging review"
                  selectedValues={formState.imagingReview || []}
                  handleChange={handleChangeImaging}
                  options={imagingReviewOptions}
                  placeholder="Please select"
                  error={triggerSubmit && isInValidField.imagingReview}
                  required={true}
                />
                <ROAutocomplete
                  id="painLevel"
                  fieldlabel="Pain Level"
                  requiredFieldColor="red"
                  required
                  inputProps={{ error: triggerSubmit && isInValidField.painLevel }}
                  options={painLevelOptions}
                  value={getOptionByValue(
                    painLevelOptions,
                    formState.painLevel !== null ? formState.painLevel.toString() : '',
                  )}
                  onChange={(option: SelectOptionType | string): void => {
                    const value = typeof option === 'string' ? option : option?.value;
                    setFormState((prevValues) => {
                      const newdata = { ...prevValues, painLevel: value !== '' ? parseInt(value) : null };
                      updateTreatmentReviewCallback(newdata);
                      return newdata;
                    });
                    isInvalid('treatmentFractionsReviewed', value);
                  }}
                />
                <ROTextField
                  id="painManagementPlan"
                  requiredFieldColor="red"
                  fieldlabel="Pain Management Plan"
                  required
                  error={triggerSubmit && isInValidField.painManagementPlan}
                  value={formState.painManagementPlan || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setFormState((prevValues) => {
                      const newdata = { ...prevValues, painManagementPlan: event.target.value };
                      updateTreatmentReviewCallback(newdata);
                      return newdata;
                    });
                    isInvalid('painManagementPlan', event.target.value);
                  }}
                  placeholder="Start typing ..."
                  multiline
                  minRows={1}
                  maxRows={4}
                />
              </Stack>
            </Stack>
            <Divider sx={{ borderColor: (theme) => theme.palette.primary.main }} />
            <Stack flexDirection="row" justifyContent="flex-end" columnGap={1} padding={2} paddingBottom={0}>
              <Button
                id="footer-button-back"
                data-testid="footer-button-back"
                title="Back"
                variant="text"
                onClick={() => {
                  history.push(`/radiation/patient/${patientId}/on-treatment-review`);
                }}>
                Back
              </Button>
              <Button
                data-testid="footer-button-preview"
                variant="outlined"
                title="Preview"
                onClick={() => {
                  setOpenPDFViewer(true);
                }}>
                Preview PDF
              </Button>
              <Button
                data-testid="footer-button-continue"
                variant="contained"
                disabled={
                  !(reviewData.treatmentReview.currentChanges || reviewData.treatmentReview.lastSubmittedAt === null)
                }
                onClick={() => {
                  setTriggerSubmit(true);
                  if (!triggerValidation()) {
                    submitForm();
                  }
                }}>
                Submit
              </Button>
            </Stack>
          </Stack>
          <ROSidePanel>
            <ROPatientCarePathDoseSummaryWidget selectedCareplan={reviewData.treatmentReview.careplan} />
          </ROSidePanel>
        </Stack>
        <PDFPreviewModal
          open={openPDFViewer}
          onClose={() => setOpenPDFViewer(false)}
          url={`/ro_portal/otv/${reviewId}/pdf`}
        />
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default OnTreatmentReviewForm;
