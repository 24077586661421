// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment, useEffect } from 'react';
import { gql } from '@apollo/client';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import moment from 'moment-timezone';
import { LoadingSpinner } from 'shared-components/components';
import UKAppointmentsDashboard from './UKAppointmentsDashboard';

const GET_USER_QUERY = gql`
  {
    user {
      id
      email
      firstName
      lastName
      isPso
      isSuperuser
      defaultLocation
    }
  }
`;

const DASHBOARD_QUERY = gql`
  query dashboard_query(
    $currentPage: Int
    $status: String
    $startTime: Date
    $locationAlias: String
    $category: String
  ) {
    ukAppointments(page: $currentPage, status: $status, startTime: $startTime, locationAlias: $locationAlias) {
      id
      startTime
      description
      practitioner {
        id
        name
      }
      department {
        id
        name
        alias
      }
      patient {
        id
        ida
        fullName
        regFormStatus
      }
    }
    listData(category: $category) {
      id
      name
      appKey
    }
    configs {
      id
      alias
      name
    }
    ukAppointmentsCount(startTime: $startTime, status: $status, locationAlias: $locationAlias)
  }
`;

interface UserProps extends WithApolloClient<{}> {
  active?: boolean;
}

interface UserState {
  active: boolean;
}

interface Props {
  active?: boolean;
  defaultLocation: string;
}

interface State {
  currentPage: number;
  startTime: any;
  status: string;
  centre: string;
  totalPages: number;
}

class UKAppointmentsDashboardApollo extends Component<UserProps, UserState> {
  public render(): JSX.Element {
    return (
      <Query query={GET_USER_QUERY}>
        {({ loading, data }: any): JSX.Element => {
          if (loading) return <LoadingSpinner />;
          if (data) {
            let defLocation = '';
            if (data.user && data.user.defaultLocation) {
              defLocation = data.user.defaultLocation;
            }
            return (
              <UKAppointmentsUserDashboardApollo defaultLocation={defLocation}></UKAppointmentsUserDashboardApollo>
            );
          }
          return <div></div>;
        }}
      </Query>
    );
  }
}

class UKAppointmentsUserDashboardApollo extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      currentPage: 1,
      startTime: moment().format('YYYY-MM-DD'),
      centre: this.props.defaultLocation,
      status: '',
      totalPages: 1,
    };
  }

  private changePage = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };
  private changeDate = (newDate: any, currentPage: number) => {
    this.setState({ startTime: newDate, currentPage: currentPage });
  };
  private changeStatus = (status: string, currentPage: number) => {
    this.setState({ status: status, currentPage: currentPage });
  };
  private changeCentre = (centre: string, currentPage: number) => {
    this.setState({ centre: centre, currentPage: currentPage });
  };

  public render(): JSX.Element {
    const { currentPage, startTime, centre, status } = this.state;
    const variables = {
      currentPage,
      startTime,
      locationAlias: centre,
      category: 'ukSearchFilters',
      status: status,
    };

    return (
      <Query query={DASHBOARD_QUERY} variables={variables}>
        {({ loading, data }: any): JSX.Element => {
          if (loading) return <LoadingSpinner />;
          if (data) {
            return (
              <Fragment>
                <UKAppointmentsDashboard
                  currentPage={this.state.currentPage}
                  startTime={this.state.startTime}
                  locationAlias={this.state.centre}
                  status={this.state.status}
                  totalPages={this.state.totalPages}
                  onPageChange={this.changePage}
                  onDateChange={this.changeDate}
                  onStatusChange={this.changeStatus}
                  onCentreChange={this.changeCentre}
                  data={data}
                />
              </Fragment>
            );
          }
          return <div></div>;
        }}
      </Query>
    );
  }
}

export default withApollo(UKAppointmentsDashboardApollo);
