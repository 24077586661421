import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartBar from '../Charts/ChartBar';
import SubChartBar from '../Charts/SubCharts/SubChartBar';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import { DataFunctions } from '../../Utils/dataFunctions';

const Top10Referrers = ({
  data,
  subChartData,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  ...rest
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartBar({
      selectedPoint: rest.filterOverride,
      leftSeriesTitle: '',
      rightSeriesTitle: '',
      leftSeriesFields: ['Date', 'Techniques'],
      rightSeriesFields: ['Date', 'Techniques'],
      aggregation: dateAggregationMap[dateRange],
      dateRange: dateRange,
      ...rest,
    }).components?.[0] ?? {},
    SubChartBar({
      seeMore: seeMoreBottomLeft,
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      dataTotal: DataFunctions.aggregateDataSum(subChartData, 'tumourstream'),
      yAxisMax: DataFunctions.aggregateDataMax(subChartData, 'tumourstream') * 2,
      dataLength: DataFunctions.aggregateData(subChartData, 'tumourstream').length - 1,
      ...rest,
    }).components?.[0] ?? {},
    SubChartBar({
      seeMore: seeMoreBottomRight,
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      dataTotal: DataFunctions.aggregateDataSum(subChartData, 'createdBy'),
      yAxisMax: DataFunctions.aggregateDataMax(subChartData, 'createdBy') * 2,
      dataLength: DataFunctions.aggregateData(subChartData, 'createdBy').length - 1,
      ...rest,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: true,
        data: DataFunctions.aggregateData(data, 'referrer', 10, 'New Referrals'),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(
          subChartData,
          'tumourStream',
          seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT,
        ),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(subChartData, 'createdBy', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default Top10Referrers;
