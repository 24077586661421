const MockData = [
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'SCTB',
    submittedAt: '2024-10-27',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'SCTB',
    submittedAt: '2024-11-10',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'XPWP',
    submittedAt: '2024-07-31',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'XPWP',
    submittedAt: '2024-08-25',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'LYUZ',
    submittedAt: '2024-09-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'LYUZ',
    submittedAt: '2024-10-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'AQWP',
    submittedAt: '2024-08-31',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'AQWP',
    submittedAt: '2024-09-19',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'XXYJ',
    submittedAt: '2024-07-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'XXYJ',
    submittedAt: '2024-08-03',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'YCWL',
    submittedAt: '2024-10-07',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'YCWL',
    submittedAt: '2024-10-23',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'RFEW',
    submittedAt: '2024-06-22',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'RFEW',
    submittedAt: '2024-07-08',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'GQBR',
    submittedAt: '2024-10-27',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'GQBR',
    submittedAt: '2024-11-22',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'OGIP',
    submittedAt: '2024-06-23',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'OGIP',
    submittedAt: '2024-06-30',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'VXAS',
    submittedAt: '2024-11-23',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'VXAS',
    submittedAt: '2024-12-22',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'BJCZ',
    submittedAt: '2024-11-08',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'BJCZ',
    submittedAt: '2024-11-24',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'LSFF',
    submittedAt: '2024-10-18',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'LSFF',
    submittedAt: '2024-11-06',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'WXIO',
    submittedAt: '2024-09-29',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'WXIO',
    submittedAt: '2024-10-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'FJAA',
    submittedAt: '2024-11-03',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'FJAA',
    submittedAt: '2024-11-13',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'RAXU',
    submittedAt: '2024-11-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'RAXU',
    submittedAt: '2024-12-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'HFLY',
    submittedAt: '2024-09-14',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'HFLY',
    submittedAt: '2024-09-26',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'KKLY',
    submittedAt: '2024-06-18',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'KKLY',
    submittedAt: '2024-07-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'QJSL',
    submittedAt: '2024-09-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'QJSL',
    submittedAt: '2024-09-24',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'WQLD',
    submittedAt: '2024-10-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'WQLD',
    submittedAt: '2024-10-22',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'PDML',
    submittedAt: '2024-11-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'PDML',
    submittedAt: '2024-12-01',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'SQCB',
    submittedAt: '2024-07-31',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'SQCB',
    submittedAt: '2024-08-24',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'RPAV',
    submittedAt: '2024-11-02',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'RPAV',
    submittedAt: '2024-11-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'BMYB',
    submittedAt: '2024-09-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'BMYB',
    submittedAt: '2024-09-21',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'JOWG',
    submittedAt: '2024-07-14',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'JOWG',
    submittedAt: '2024-08-10',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'XAKR',
    submittedAt: '2024-11-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'XAKR',
    submittedAt: '2024-11-08',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'MCGG',
    submittedAt: '2024-09-11',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'MCGG',
    submittedAt: '2024-09-24',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ETBJ',
    submittedAt: '2024-09-16',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'ETBJ',
    submittedAt: '2024-09-29',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ETSL',
    submittedAt: '2024-10-13',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'ETSL',
    submittedAt: '2024-11-02',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'DWJS',
    submittedAt: '2024-08-30',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'DWJS',
    submittedAt: '2024-09-27',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'TDXJ',
    submittedAt: '2024-06-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'TDXJ',
    submittedAt: '2024-07-05',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'CCIR',
    submittedAt: '2024-08-07',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'CCIR',
    submittedAt: '2024-09-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ZAYC',
    submittedAt: '2024-10-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ZAYC',
    submittedAt: '2024-11-05',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'BEAS',
    submittedAt: '2024-08-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'BEAS',
    submittedAt: '2024-08-29',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'QXWR',
    submittedAt: '2024-11-02',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'QXWR',
    submittedAt: '2024-11-09',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'SUZY',
    submittedAt: '2024-06-30',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'SUZY',
    submittedAt: '2024-07-30',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'CHLP',
    submittedAt: '2024-10-31',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'CHLP',
    submittedAt: '2024-11-04',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'HEQC',
    submittedAt: '2024-06-30',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'HEQC',
    submittedAt: '2024-07-04',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'JJEP',
    submittedAt: '2024-10-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'JJEP',
    submittedAt: '2024-10-29',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'JIKQ',
    submittedAt: '2024-07-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'JIKQ',
    submittedAt: '2024-08-19',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'CJEE',
    submittedAt: '2024-08-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'CJEE',
    submittedAt: '2024-09-20',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'WYQS',
    submittedAt: '2024-10-07',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'WYQS',
    submittedAt: '2024-10-12',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'DYWP',
    submittedAt: '2024-11-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'DYWP',
    submittedAt: '2024-11-26',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'OYFD',
    submittedAt: '2024-06-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'OYFD',
    submittedAt: '2024-07-19',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'CGRB',
    submittedAt: '2024-08-29',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'CGRB',
    submittedAt: '2024-08-31',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'XZKN',
    submittedAt: '2024-06-20',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'XZKN',
    submittedAt: '2024-06-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'TRJU',
    submittedAt: '2024-08-24',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'TRJU',
    submittedAt: '2024-09-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'OOQN',
    submittedAt: '2024-11-11',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'OOQN',
    submittedAt: '2024-11-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'VTFN',
    submittedAt: '2024-06-14',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'VTFN',
    submittedAt: '2024-07-03',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'RJHS',
    submittedAt: '2024-07-30',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'RJHS',
    submittedAt: '2024-08-15',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'QOKM',
    submittedAt: '2024-06-13',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'QOKM',
    submittedAt: '2024-06-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'DJTN',
    submittedAt: '2024-09-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'DJTN',
    submittedAt: '2024-09-23',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'FVGY',
    submittedAt: '2024-10-14',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'FVGY',
    submittedAt: '2024-11-09',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'TFRC',
    submittedAt: '2024-10-06',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'TFRC',
    submittedAt: '2024-10-24',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'VGGI',
    submittedAt: '2024-08-29',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'VGGI',
    submittedAt: '2024-09-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'YNAN',
    submittedAt: '2024-06-03',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'YNAN',
    submittedAt: '2024-06-28',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'IQIC',
    submittedAt: '2024-11-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'IQIC',
    submittedAt: '2024-11-29',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'DIEK',
    submittedAt: '2024-10-20',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'DIEK',
    submittedAt: '2024-11-18',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'XYXJ',
    submittedAt: '2024-08-28',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'XYXJ',
    submittedAt: '2024-09-21',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'YTKF',
    submittedAt: '2024-11-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'YTKF',
    submittedAt: '2024-11-30',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ZOWQ',
    submittedAt: '2024-10-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'ZOWQ',
    submittedAt: '2024-10-31',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'JWGI',
    submittedAt: '2024-11-01',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'JWGI',
    submittedAt: '2024-11-25',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'PJLM',
    submittedAt: '2024-09-02',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'PJLM',
    submittedAt: '2024-09-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'WEMV',
    submittedAt: '2024-08-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'WEMV',
    submittedAt: '2024-08-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'RPTS',
    submittedAt: '2024-08-29',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'RPTS',
    submittedAt: '2024-09-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'UBET',
    submittedAt: '2024-07-13',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'UBET',
    submittedAt: '2024-07-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ENOW',
    submittedAt: '2024-09-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ENOW',
    submittedAt: '2024-10-15',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'UIDH',
    submittedAt: '2024-07-19',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'UIDH',
    submittedAt: '2024-07-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'AERJ',
    submittedAt: '2024-09-11',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'AERJ',
    submittedAt: '2024-10-01',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'OFAQ',
    submittedAt: '2024-09-21',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'OFAQ',
    submittedAt: '2024-09-28',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ODVI',
    submittedAt: '2024-10-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ODVI',
    submittedAt: '2024-11-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'RUDU',
    submittedAt: '2024-11-07',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'RUDU',
    submittedAt: '2024-11-24',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'DVIW',
    submittedAt: '2024-09-28',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'DVIW',
    submittedAt: '2024-10-26',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'EOON',
    submittedAt: '2024-09-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'EOON',
    submittedAt: '2024-10-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'XWOS',
    submittedAt: '2024-08-05',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'XWOS',
    submittedAt: '2024-08-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'NBJT',
    submittedAt: '2024-08-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'NBJT',
    submittedAt: '2024-08-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'WIBA',
    submittedAt: '2024-10-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'WIBA',
    submittedAt: '2024-11-03',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'IQJH',
    submittedAt: '2024-10-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'IQJH',
    submittedAt: '2024-10-18',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'AUWB',
    submittedAt: '2024-08-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'AUWB',
    submittedAt: '2024-08-23',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'JUFZ',
    submittedAt: '2024-07-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'JUFZ',
    submittedAt: '2024-08-04',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'UGLF',
    submittedAt: '2024-10-01',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'UGLF',
    submittedAt: '2024-10-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'PGRP',
    submittedAt: '2024-07-29',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'PGRP',
    submittedAt: '2024-08-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'CUKE',
    submittedAt: '2024-08-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'CUKE',
    submittedAt: '2024-08-24',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'YKVG',
    submittedAt: '2024-10-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'YKVG',
    submittedAt: '2024-11-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'LURT',
    submittedAt: '2024-08-10',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'LURT',
    submittedAt: '2024-08-14',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'EQAT',
    submittedAt: '2024-11-13',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'EQAT',
    submittedAt: '2024-12-06',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'TQZQ',
    submittedAt: '2024-08-31',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'TQZQ',
    submittedAt: '2024-09-26',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'FZMN',
    submittedAt: '2024-10-30',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'FZMN',
    submittedAt: '2024-11-28',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'POOK',
    submittedAt: '2024-10-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'POOK',
    submittedAt: '2024-11-10',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'GSUY',
    submittedAt: '2024-10-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'GSUY',
    submittedAt: '2024-11-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'MFGB',
    submittedAt: '2024-11-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'MFGB',
    submittedAt: '2024-11-16',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'GDPA',
    submittedAt: '2024-10-13',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'GDPA',
    submittedAt: '2024-10-29',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'SVZY',
    submittedAt: '2024-09-07',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'SVZY',
    submittedAt: '2024-09-12',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'EQTB',
    submittedAt: '2024-08-19',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'EQTB',
    submittedAt: '2024-09-02',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'BNKK',
    submittedAt: '2024-10-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'BNKK',
    submittedAt: '2024-11-06',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'AJGU',
    submittedAt: '2024-09-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'AJGU',
    submittedAt: '2024-10-01',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'SMSZ',
    submittedAt: '2024-06-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'SMSZ',
    submittedAt: '2024-07-03',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'JMVU',
    submittedAt: '2024-06-14',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'JMVU',
    submittedAt: '2024-07-06',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ZDMN',
    submittedAt: '2024-11-09',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'ZDMN',
    submittedAt: '2024-11-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'KXAM',
    submittedAt: '2024-10-28',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'KXAM',
    submittedAt: '2024-11-24',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'TNRL',
    submittedAt: '2024-06-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'TNRL',
    submittedAt: '2024-06-05',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'MKGY',
    submittedAt: '2024-08-27',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'MKGY',
    submittedAt: '2024-09-16',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'TEOA',
    submittedAt: '2024-11-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'TEOA',
    submittedAt: '2024-11-18',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ZUZY',
    submittedAt: '2024-10-20',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ZUZY',
    submittedAt: '2024-11-07',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'AAVI',
    submittedAt: '2024-08-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'AAVI',
    submittedAt: '2024-09-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'LDRO',
    submittedAt: '2024-09-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'LDRO',
    submittedAt: '2024-10-12',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'RPSR',
    submittedAt: '2024-08-07',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'RPSR',
    submittedAt: '2024-08-27',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'KSVQ',
    submittedAt: '2024-06-09',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'KSVQ',
    submittedAt: '2024-07-09',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'QLOB',
    submittedAt: '2024-10-01',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'QLOB',
    submittedAt: '2024-10-16',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'WYTA',
    submittedAt: '2024-11-30',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'WYTA',
    submittedAt: '2024-12-10',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'THAS',
    submittedAt: '2024-11-14',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'THAS',
    submittedAt: '2024-11-15',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'IQJQ',
    submittedAt: '2024-08-08',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'IQJQ',
    submittedAt: '2024-08-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'MXZF',
    submittedAt: '2024-06-24',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'MXZF',
    submittedAt: '2024-07-23',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'OLYX',
    submittedAt: '2024-06-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'OLYX',
    submittedAt: '2024-06-23',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ZWQW',
    submittedAt: '2024-06-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ZWQW',
    submittedAt: '2024-07-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'SBSD',
    submittedAt: '2024-09-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'SBSD',
    submittedAt: '2024-09-11',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'VDIJ',
    submittedAt: '2024-11-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'VDIJ',
    submittedAt: '2024-11-29',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'FELC',
    submittedAt: '2024-07-05',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'FELC',
    submittedAt: '2024-08-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ICHL',
    submittedAt: '2024-11-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ICHL',
    submittedAt: '2024-11-23',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'SRWE',
    submittedAt: '2024-10-10',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'SRWE',
    submittedAt: '2024-11-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'CZVU',
    submittedAt: '2024-09-30',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'CZVU',
    submittedAt: '2024-10-05',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'HHFM',
    submittedAt: '2024-09-26',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'HHFM',
    submittedAt: '2024-10-20',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'NOLR',
    submittedAt: '2024-11-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'NOLR',
    submittedAt: '2024-11-13',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'UULI',
    submittedAt: '2024-10-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'UULI',
    submittedAt: '2024-11-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'KSCD',
    submittedAt: '2024-11-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'KSCD',
    submittedAt: '2024-12-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'UUSE',
    submittedAt: '2024-06-24',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'UUSE',
    submittedAt: '2024-07-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'LLAE',
    submittedAt: '2024-09-12',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'LLAE',
    submittedAt: '2024-10-05',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'CJNP',
    submittedAt: '2024-10-01',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'CJNP',
    submittedAt: '2024-10-08',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'FTSV',
    submittedAt: '2024-06-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'FTSV',
    submittedAt: '2024-06-27',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'PVZI',
    submittedAt: '2024-07-10',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'PVZI',
    submittedAt: '2024-07-29',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'CRCC',
    submittedAt: '2024-07-08',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'CRCC',
    submittedAt: '2024-08-07',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'LBQP',
    submittedAt: '2024-11-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'LBQP',
    submittedAt: '2024-11-18',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ZIKM',
    submittedAt: '2024-08-30',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ZIKM',
    submittedAt: '2024-09-01',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'QHWA',
    submittedAt: '2024-09-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'QHWA',
    submittedAt: '2024-09-17',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'WBNZ',
    submittedAt: '2024-11-29',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'WBNZ',
    submittedAt: '2024-12-10',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'YXUH',
    submittedAt: '2024-08-06',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'YXUH',
    submittedAt: '2024-08-23',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'MXOY',
    submittedAt: '2024-09-23',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'MXOY',
    submittedAt: '2024-10-09',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'RMTX',
    submittedAt: '2024-06-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'RMTX',
    submittedAt: '2024-06-28',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'XCBY',
    submittedAt: '2024-09-19',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'XCBY',
    submittedAt: '2024-10-05',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'VNHX',
    submittedAt: '2024-06-19',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'VNHX',
    submittedAt: '2024-06-23',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'BJNS',
    submittedAt: '2024-07-22',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'BJNS',
    submittedAt: '2024-07-31',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'XEXZ',
    submittedAt: '2024-07-22',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'XEXZ',
    submittedAt: '2024-07-28',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'BTZV',
    submittedAt: '2024-10-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'BTZV',
    submittedAt: '2024-10-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'VGID',
    submittedAt: '2024-09-04',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'VGID',
    submittedAt: '2024-09-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ZQVB',
    submittedAt: '2024-08-26',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ZQVB',
    submittedAt: '2024-09-03',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'JKWG',
    submittedAt: '2024-09-03',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'JKWG',
    submittedAt: '2024-09-28',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'WLTH',
    submittedAt: '2024-10-22',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'WLTH',
    submittedAt: '2024-11-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'ZLUI',
    submittedAt: '2024-11-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'ZLUI',
    submittedAt: '2024-12-06',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'EGKC',
    submittedAt: '2024-09-06',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'EGKC',
    submittedAt: '2024-09-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'TKUF',
    submittedAt: '2024-08-16',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'TKUF',
    submittedAt: '2024-09-11',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'UTOF',
    submittedAt: '2024-09-29',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'UTOF',
    submittedAt: '2024-10-12',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'KPER',
    submittedAt: '2024-09-16',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'KPER',
    submittedAt: '2024-10-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'WTJQ',
    submittedAt: '2024-08-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'WTJQ',
    submittedAt: '2024-08-18',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'HTFU',
    submittedAt: '2024-11-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'HTFU',
    submittedAt: '2024-12-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'CPVP',
    submittedAt: '2024-06-21',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'CPVP',
    submittedAt: '2024-07-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'OYFP',
    submittedAt: '2024-07-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'OYFP',
    submittedAt: '2024-07-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'GOYK',
    submittedAt: '2024-06-05',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'GOYK',
    submittedAt: '2024-06-23',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'EEXL',
    submittedAt: '2024-06-16',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'EEXL',
    submittedAt: '2024-07-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'NUTJ',
    submittedAt: '2024-07-01',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'NUTJ',
    submittedAt: '2024-07-11',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'IVTM',
    submittedAt: '2024-09-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'IVTM',
    submittedAt: '2024-09-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'LFFE',
    submittedAt: '2024-09-23',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'LFFE',
    submittedAt: '2024-10-02',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ZCOM',
    submittedAt: '2024-07-09',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ZCOM',
    submittedAt: '2024-07-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'BRHV',
    submittedAt: '2024-10-23',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'BRHV',
    submittedAt: '2024-11-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'AAOF',
    submittedAt: '2024-10-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'AAOF',
    submittedAt: '2024-10-27',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'RLDL',
    submittedAt: '2024-07-24',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'RLDL',
    submittedAt: '2024-08-09',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'QZYN',
    submittedAt: '2024-10-10',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'QZYN',
    submittedAt: '2024-10-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'VAUP',
    submittedAt: '2024-11-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'VAUP',
    submittedAt: '2024-11-28',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'UKNJ',
    submittedAt: '2024-08-07',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'UKNJ',
    submittedAt: '2024-09-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'EXWG',
    submittedAt: '2024-06-20',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'EXWG',
    submittedAt: '2024-06-26',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ENKZ',
    submittedAt: '2024-07-19',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'ENKZ',
    submittedAt: '2024-08-01',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'JFUO',
    submittedAt: '2024-08-09',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'JFUO',
    submittedAt: '2024-08-24',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'DFMH',
    submittedAt: '2024-09-26',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'DFMH',
    submittedAt: '2024-10-06',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'KMAN',
    submittedAt: '2024-07-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'KMAN',
    submittedAt: '2024-07-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'WYZU',
    submittedAt: '2024-08-08',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'WYZU',
    submittedAt: '2024-08-31',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'DHAG',
    submittedAt: '2024-10-07',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'DHAG',
    submittedAt: '2024-11-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'TAUV',
    submittedAt: '2024-06-02',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'TAUV',
    submittedAt: '2024-06-19',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'FUMQ',
    submittedAt: '2024-11-24',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'FUMQ',
    submittedAt: '2024-12-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'PYLA',
    submittedAt: '2024-07-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'PYLA',
    submittedAt: '2024-08-01',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'BBEY',
    submittedAt: '2024-09-10',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'BBEY',
    submittedAt: '2024-09-20',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'JMUM',
    submittedAt: '2024-07-29',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'JMUM',
    submittedAt: '2024-08-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'YBHG',
    submittedAt: '2024-06-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'YBHG',
    submittedAt: '2024-06-21',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'YORM',
    submittedAt: '2024-09-04',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'YORM',
    submittedAt: '2024-09-24',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'GOYQ',
    submittedAt: '2024-11-23',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'GOYQ',
    submittedAt: '2024-11-29',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'WCXX',
    submittedAt: '2024-07-12',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'WCXX',
    submittedAt: '2024-07-25',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'BOOM',
    submittedAt: '2024-08-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'BOOM',
    submittedAt: '2024-09-01',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'VAXJ',
    submittedAt: '2024-07-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'VAXJ',
    submittedAt: '2024-07-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'WZOQ',
    submittedAt: '2024-09-27',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'WZOQ',
    submittedAt: '2024-09-28',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'KPKW',
    submittedAt: '2024-11-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'KPKW',
    submittedAt: '2024-11-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'BTZZ',
    submittedAt: '2024-10-13',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'BTZZ',
    submittedAt: '2024-11-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'KKDX',
    submittedAt: '2024-10-24',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'KKDX',
    submittedAt: '2024-10-29',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'IEQW',
    submittedAt: '2024-07-16',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'IEQW',
    submittedAt: '2024-08-09',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ZFBV',
    submittedAt: '2024-06-11',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ZFBV',
    submittedAt: '2024-06-18',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'RCVR',
    submittedAt: '2024-08-03',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'RCVR',
    submittedAt: '2024-08-30',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'RCGS',
    submittedAt: '2024-06-30',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'RCGS',
    submittedAt: '2024-07-05',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'WHZL',
    submittedAt: '2024-06-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'WHZL',
    submittedAt: '2024-07-11',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'VPBE',
    submittedAt: '2024-07-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'VPBE',
    submittedAt: '2024-07-28',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ZBIC',
    submittedAt: '2024-06-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ZBIC',
    submittedAt: '2024-06-18',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'FJJK',
    submittedAt: '2024-08-18',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'FJJK',
    submittedAt: '2024-08-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'DFAV',
    submittedAt: '2024-08-27',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'DFAV',
    submittedAt: '2024-09-17',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'UNPR',
    submittedAt: '2024-10-13',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'UNPR',
    submittedAt: '2024-10-17',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'UAUS',
    submittedAt: '2024-11-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'UAUS',
    submittedAt: '2024-12-01',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'PPAD',
    submittedAt: '2024-10-31',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'PPAD',
    submittedAt: '2024-11-20',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'CPTQ',
    submittedAt: '2024-11-25',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'CPTQ',
    submittedAt: '2024-12-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'PIBH',
    submittedAt: '2024-09-01',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'PIBH',
    submittedAt: '2024-09-03',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'NAPN',
    submittedAt: '2024-09-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'NAPN',
    submittedAt: '2024-10-18',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'MUKC',
    submittedAt: '2024-10-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'MUKC',
    submittedAt: '2024-10-24',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'CSWJ',
    submittedAt: '2024-08-23',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'CSWJ',
    submittedAt: '2024-09-08',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'EART',
    submittedAt: '2024-09-23',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'EART',
    submittedAt: '2024-10-08',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'OHUR',
    submittedAt: '2024-10-17',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'OHUR',
    submittedAt: '2024-10-18',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'PKWD',
    submittedAt: '2024-10-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'PKWD',
    submittedAt: '2024-10-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'UXWN',
    submittedAt: '2024-07-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'UXWN',
    submittedAt: '2024-07-19',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'NLBJ',
    submittedAt: '2024-09-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'NLBJ',
    submittedAt: '2024-09-17',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'IJGI',
    submittedAt: '2024-09-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'IJGI',
    submittedAt: '2024-09-20',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'BLCB',
    submittedAt: '2024-11-13',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'BLCB',
    submittedAt: '2024-12-09',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'DEWZ',
    submittedAt: '2024-07-12',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'DEWZ',
    submittedAt: '2024-07-19',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'EIMS',
    submittedAt: '2024-08-09',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'EIMS',
    submittedAt: '2024-08-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'PHGQ',
    submittedAt: '2024-07-12',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'PHGQ',
    submittedAt: '2024-07-31',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'CSAZ',
    submittedAt: '2024-08-20',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'CSAZ',
    submittedAt: '2024-08-31',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ABJP',
    submittedAt: '2024-07-02',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ABJP',
    submittedAt: '2024-07-30',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'JYEX',
    submittedAt: '2024-07-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'JYEX',
    submittedAt: '2024-07-22',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'ORFY',
    submittedAt: '2024-07-02',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'ORFY',
    submittedAt: '2024-07-31',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'GKYQ',
    submittedAt: '2024-07-29',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'GKYQ',
    submittedAt: '2024-08-04',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'OXRZ',
    submittedAt: '2024-06-24',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'OXRZ',
    submittedAt: '2024-06-30',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'XJIZ',
    submittedAt: '2024-06-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'XJIZ',
    submittedAt: '2024-07-24',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ZYDE',
    submittedAt: '2024-08-31',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'ZYDE',
    submittedAt: '2024-09-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'EIFL',
    submittedAt: '2024-08-16',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'EIFL',
    submittedAt: '2024-09-11',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ZGMQ',
    submittedAt: '2024-10-21',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ZGMQ',
    submittedAt: '2024-11-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'FOFY',
    submittedAt: '2024-09-13',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'FOFY',
    submittedAt: '2024-09-28',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'EGZS',
    submittedAt: '2024-07-14',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'EGZS',
    submittedAt: '2024-08-08',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'YHHN',
    submittedAt: '2024-09-08',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'YHHN',
    submittedAt: '2024-09-17',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'VUIT',
    submittedAt: '2024-06-21',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'VUIT',
    submittedAt: '2024-07-02',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'PRNH',
    submittedAt: '2024-07-23',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'PRNH',
    submittedAt: '2024-07-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'LKKY',
    submittedAt: '2024-09-28',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'LKKY',
    submittedAt: '2024-10-18',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'IFBG',
    submittedAt: '2024-10-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'IFBG',
    submittedAt: '2024-10-19',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'XGBQ',
    submittedAt: '2024-11-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'XGBQ',
    submittedAt: '2024-11-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'YFNW',
    submittedAt: '2024-09-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'YFNW',
    submittedAt: '2024-10-01',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'QSWP',
    submittedAt: '2024-07-06',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'QSWP',
    submittedAt: '2024-07-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'CWSI',
    submittedAt: '2024-06-30',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'CWSI',
    submittedAt: '2024-07-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'YVQJ',
    submittedAt: '2024-11-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'YVQJ',
    submittedAt: '2024-12-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'KNDV',
    submittedAt: '2024-11-05',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'KNDV',
    submittedAt: '2024-11-06',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'QVEF',
    submittedAt: '2024-11-27',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'QVEF',
    submittedAt: '2024-12-18',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'SEEK',
    submittedAt: '2024-11-25',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'SEEK',
    submittedAt: '2024-12-14',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'DCZH',
    submittedAt: '2024-09-07',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'DCZH',
    submittedAt: '2024-09-28',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'JCTO',
    submittedAt: '2024-06-28',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'JCTO',
    submittedAt: '2024-07-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'UQAH',
    submittedAt: '2024-11-29',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'UQAH',
    submittedAt: '2024-12-20',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'FCZO',
    submittedAt: '2024-11-03',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'FCZO',
    submittedAt: '2024-11-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'JXYS',
    submittedAt: '2024-06-24',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'JXYS',
    submittedAt: '2024-06-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'YGDU',
    submittedAt: '2024-10-02',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'YGDU',
    submittedAt: '2024-10-30',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'COOS',
    submittedAt: '2024-06-15',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'COOS',
    submittedAt: '2024-07-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'QAFM',
    submittedAt: '2024-07-14',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'QAFM',
    submittedAt: '2024-07-29',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'PQEG',
    submittedAt: '2024-11-24',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'PQEG',
    submittedAt: '2024-12-09',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'TRRE',
    submittedAt: '2024-10-16',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'TRRE',
    submittedAt: '2024-10-26',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'HMIG',
    submittedAt: '2024-06-07',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'HMIG',
    submittedAt: '2024-07-02',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'SKNC',
    submittedAt: '2024-11-02',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'SKNC',
    submittedAt: '2024-11-04',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'HJFP',
    submittedAt: '2024-08-15',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'HJFP',
    submittedAt: '2024-08-21',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'EDLC',
    submittedAt: '2024-10-06',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'EDLC',
    submittedAt: '2024-10-09',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'UBRA',
    submittedAt: '2024-07-13',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'UBRA',
    submittedAt: '2024-07-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'VCDU',
    submittedAt: '2024-08-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'VCDU',
    submittedAt: '2024-09-02',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'EUVZ',
    submittedAt: '2024-08-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'EUVZ',
    submittedAt: '2024-09-06',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'SLUD',
    submittedAt: '2024-11-16',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'SLUD',
    submittedAt: '2024-12-06',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'UOWA',
    submittedAt: '2024-10-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'UOWA',
    submittedAt: '2024-10-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'LADT',
    submittedAt: '2024-07-13',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'LADT',
    submittedAt: '2024-07-18',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'RAOY',
    submittedAt: '2024-07-20',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'RAOY',
    submittedAt: '2024-08-08',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'MGMU',
    submittedAt: '2024-09-26',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'MGMU',
    submittedAt: '2024-10-17',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'WQFQ',
    submittedAt: '2024-08-14',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'WQFQ',
    submittedAt: '2024-09-10',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'IBSY',
    submittedAt: '2024-07-06',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'IBSY',
    submittedAt: '2024-07-30',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'HXHY',
    submittedAt: '2024-11-09',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'HXHY',
    submittedAt: '2024-11-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'RPLM',
    submittedAt: '2024-11-13',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'RPLM',
    submittedAt: '2024-11-21',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'RJXL',
    submittedAt: '2024-10-30',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'RJXL',
    submittedAt: '2024-11-12',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'XYHM',
    submittedAt: '2024-07-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'XYHM',
    submittedAt: '2024-08-11',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'TBWL',
    submittedAt: '2024-10-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'TBWL',
    submittedAt: '2024-10-24',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'HGHI',
    submittedAt: '2024-09-16',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'HGHI',
    submittedAt: '2024-10-13',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'XFTJ',
    submittedAt: '2024-07-04',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'XFTJ',
    submittedAt: '2024-07-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'INCJ',
    submittedAt: '2024-08-05',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'INCJ',
    submittedAt: '2024-08-11',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'LYXJ',
    submittedAt: '2024-07-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'LYXJ',
    submittedAt: '2024-08-09',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'JYSO',
    submittedAt: '2024-06-09',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'JYSO',
    submittedAt: '2024-07-01',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'RTJD',
    submittedAt: '2024-09-23',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'RTJD',
    submittedAt: '2024-10-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'GITL',
    submittedAt: '2024-07-13',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'GITL',
    submittedAt: '2024-07-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ROYB',
    submittedAt: '2024-07-09',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ROYB',
    submittedAt: '2024-07-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'CODB',
    submittedAt: '2024-11-13',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'CODB',
    submittedAt: '2024-12-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'CNVX',
    submittedAt: '2024-06-02',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'CNVX',
    submittedAt: '2024-06-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'TGIR',
    submittedAt: '2024-08-22',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'TGIR',
    submittedAt: '2024-09-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'NEOJ',
    submittedAt: '2024-07-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'NEOJ',
    submittedAt: '2024-08-14',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'SRZK',
    submittedAt: '2024-07-30',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'SRZK',
    submittedAt: '2024-08-19',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'JWXO',
    submittedAt: '2024-08-03',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'JWXO',
    submittedAt: '2024-08-21',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'FPSH',
    submittedAt: '2024-07-05',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'FPSH',
    submittedAt: '2024-07-14',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'KXYY',
    submittedAt: '2024-06-13',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'KXYY',
    submittedAt: '2024-07-11',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'OPXG',
    submittedAt: '2024-06-27',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'OPXG',
    submittedAt: '2024-07-11',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'VYFB',
    submittedAt: '2024-06-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'VYFB',
    submittedAt: '2024-06-24',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'YDNV',
    submittedAt: '2024-08-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'YDNV',
    submittedAt: '2024-08-29',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'GSLJ',
    submittedAt: '2024-08-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'GSLJ',
    submittedAt: '2024-08-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'IUQF',
    submittedAt: '2024-09-23',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'IUQF',
    submittedAt: '2024-10-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'BGIU',
    submittedAt: '2024-06-16',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'BGIU',
    submittedAt: '2024-07-01',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'CLXJ',
    submittedAt: '2024-10-10',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'CLXJ',
    submittedAt: '2024-11-03',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'DEGF',
    submittedAt: '2024-06-23',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'DEGF',
    submittedAt: '2024-07-11',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'WLDH',
    submittedAt: '2024-08-28',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'WLDH',
    submittedAt: '2024-09-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'CGJY',
    submittedAt: '2024-07-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'CGJY',
    submittedAt: '2024-07-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ZDZB',
    submittedAt: '2024-11-10',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'ZDZB',
    submittedAt: '2024-11-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'VAQB',
    submittedAt: '2024-08-23',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'VAQB',
    submittedAt: '2024-09-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'HLJA',
    submittedAt: '2024-09-16',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'HLJA',
    submittedAt: '2024-10-07',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'EGVH',
    submittedAt: '2024-07-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'EGVH',
    submittedAt: '2024-08-03',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'EDOA',
    submittedAt: '2024-08-28',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'EDOA',
    submittedAt: '2024-09-26',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'TLLH',
    submittedAt: '2024-09-05',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'TLLH',
    submittedAt: '2024-09-07',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'WSAF',
    submittedAt: '2024-09-16',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'WSAF',
    submittedAt: '2024-09-19',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'HWXB',
    submittedAt: '2024-08-02',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'HWXB',
    submittedAt: '2024-08-14',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ONKA',
    submittedAt: '2024-10-01',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'ONKA',
    submittedAt: '2024-10-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'HSOO',
    submittedAt: '2024-11-02',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'HSOO',
    submittedAt: '2024-11-11',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'XJPZ',
    submittedAt: '2024-08-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'XJPZ',
    submittedAt: '2024-09-01',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'VPER',
    submittedAt: '2024-09-19',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'VPER',
    submittedAt: '2024-10-02',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'KIUJ',
    submittedAt: '2024-07-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'KIUJ',
    submittedAt: '2024-08-10',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'XGIN',
    submittedAt: '2024-11-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'XGIN',
    submittedAt: '2024-12-04',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'RKHB',
    submittedAt: '2024-11-18',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'RKHB',
    submittedAt: '2024-11-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'VHSF',
    submittedAt: '2024-09-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'VHSF',
    submittedAt: '2024-10-04',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'FLID',
    submittedAt: '2024-07-17',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'FLID',
    submittedAt: '2024-08-08',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'BZQG',
    submittedAt: '2024-09-30',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'BZQG',
    submittedAt: '2024-10-01',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'SXGW',
    submittedAt: '2024-10-06',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'SXGW',
    submittedAt: '2024-11-02',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'LEOY',
    submittedAt: '2024-06-27',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'LEOY',
    submittedAt: '2024-07-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'TRTK',
    submittedAt: '2024-08-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'TRTK',
    submittedAt: '2024-09-09',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'UZWN',
    submittedAt: '2024-11-22',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'UZWN',
    submittedAt: '2024-12-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'MKXC',
    submittedAt: '2024-06-10',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'MKXC',
    submittedAt: '2024-06-14',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'QQPN',
    submittedAt: '2024-09-21',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'QQPN',
    submittedAt: '2024-09-28',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'TDYV',
    submittedAt: '2024-09-24',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'TDYV',
    submittedAt: '2024-10-23',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'GPHN',
    submittedAt: '2024-10-25',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'GPHN',
    submittedAt: '2024-11-16',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'OOJV',
    submittedAt: '2024-06-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'OOJV',
    submittedAt: '2024-06-28',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ALRB',
    submittedAt: '2024-11-11',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ALRB',
    submittedAt: '2024-11-27',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'OEOD',
    submittedAt: '2024-07-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'OEOD',
    submittedAt: '2024-08-14',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'GWZK',
    submittedAt: '2024-10-16',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'GWZK',
    submittedAt: '2024-11-06',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'SDQC',
    submittedAt: '2024-08-27',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'SDQC',
    submittedAt: '2024-09-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'PPOW',
    submittedAt: '2024-10-05',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'PPOW',
    submittedAt: '2024-10-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'XKGA',
    submittedAt: '2024-09-24',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'XKGA',
    submittedAt: '2024-10-07',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'XEZB',
    submittedAt: '2024-08-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'XEZB',
    submittedAt: '2024-08-17',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'VGWF',
    submittedAt: '2024-11-13',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'VGWF',
    submittedAt: '2024-12-03',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'SGQI',
    submittedAt: '2024-10-19',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'SGQI',
    submittedAt: '2024-11-15',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'GMPQ',
    submittedAt: '2024-11-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'GMPQ',
    submittedAt: '2024-12-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'DDJA',
    submittedAt: '2024-10-29',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'DDJA',
    submittedAt: '2024-11-11',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'WRPF',
    submittedAt: '2024-11-07',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'WRPF',
    submittedAt: '2024-11-30',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'IJED',
    submittedAt: '2024-07-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'IJED',
    submittedAt: '2024-08-05',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'HCSG',
    submittedAt: '2024-10-23',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'HCSG',
    submittedAt: '2024-10-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'FVZO',
    submittedAt: '2024-07-25',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'FVZO',
    submittedAt: '2024-08-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'YZWB',
    submittedAt: '2024-07-06',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'YZWB',
    submittedAt: '2024-07-28',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'HZLT',
    submittedAt: '2024-07-05',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'HZLT',
    submittedAt: '2024-07-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'BDHE',
    submittedAt: '2024-11-05',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'BDHE',
    submittedAt: '2024-11-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'BUHC',
    submittedAt: '2024-09-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'BUHC',
    submittedAt: '2024-10-01',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'TJEM',
    submittedAt: '2024-06-23',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'TJEM',
    submittedAt: '2024-07-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ISEC',
    submittedAt: '2024-06-05',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ISEC',
    submittedAt: '2024-06-18',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'SAEC',
    submittedAt: '2024-07-01',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'SAEC',
    submittedAt: '2024-07-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'WVNT',
    submittedAt: '2024-09-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'WVNT',
    submittedAt: '2024-10-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'DCMF',
    submittedAt: '2024-10-21',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'DCMF',
    submittedAt: '2024-11-18',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'IWCF',
    submittedAt: '2024-06-19',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'IWCF',
    submittedAt: '2024-07-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'IFOR',
    submittedAt: '2024-11-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'IFOR',
    submittedAt: '2024-11-10',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'JXIW',
    submittedAt: '2024-09-29',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'JXIW',
    submittedAt: '2024-10-12',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'TEZP',
    submittedAt: '2024-09-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'TEZP',
    submittedAt: '2024-09-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'THQR',
    submittedAt: '2024-11-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'THQR',
    submittedAt: '2024-12-13',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'CJPE',
    submittedAt: '2024-10-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'CJPE',
    submittedAt: '2024-11-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'SRQQ',
    submittedAt: '2024-09-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'SRQQ',
    submittedAt: '2024-09-19',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'LZZX',
    submittedAt: '2024-06-21',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'LZZX',
    submittedAt: '2024-07-19',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'NICT',
    submittedAt: '2024-11-08',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'NICT',
    submittedAt: '2024-11-16',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'VUOS',
    submittedAt: '2024-11-06',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'VUOS',
    submittedAt: '2024-12-02',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'MWTV',
    submittedAt: '2024-06-26',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'MWTV',
    submittedAt: '2024-07-03',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'VOTV',
    submittedAt: '2024-07-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'VOTV',
    submittedAt: '2024-08-07',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ALSB',
    submittedAt: '2024-07-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ALSB',
    submittedAt: '2024-08-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ZOWU',
    submittedAt: '2024-10-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ZOWU',
    submittedAt: '2024-10-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'QEFO',
    submittedAt: '2024-08-14',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'QEFO',
    submittedAt: '2024-09-01',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'GDRC',
    submittedAt: '2024-06-22',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'GDRC',
    submittedAt: '2024-07-13',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ISBM',
    submittedAt: '2024-06-18',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ISBM',
    submittedAt: '2024-07-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'RMBI',
    submittedAt: '2024-06-05',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'RMBI',
    submittedAt: '2024-06-17',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'CEGY',
    submittedAt: '2024-07-17',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'CEGY',
    submittedAt: '2024-08-01',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'BTTH',
    submittedAt: '2024-10-12',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'BTTH',
    submittedAt: '2024-11-09',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'WBHX',
    submittedAt: '2024-08-12',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'WBHX',
    submittedAt: '2024-09-04',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'VTGG',
    submittedAt: '2024-11-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'VTGG',
    submittedAt: '2024-11-18',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'JVNE',
    submittedAt: '2024-06-01',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'JVNE',
    submittedAt: '2024-06-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'SUBW',
    submittedAt: '2024-11-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'SUBW',
    submittedAt: '2024-12-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'UQRG',
    submittedAt: '2024-09-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'UQRG',
    submittedAt: '2024-09-15',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'BSJJ',
    submittedAt: '2024-11-03',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'BSJJ',
    submittedAt: '2024-11-20',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'PEQD',
    submittedAt: '2024-10-11',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'PEQD',
    submittedAt: '2024-10-19',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'KFIB',
    submittedAt: '2024-06-01',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'KFIB',
    submittedAt: '2024-06-05',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'LFBQ',
    submittedAt: '2024-09-17',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'LFBQ',
    submittedAt: '2024-10-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'VPOL',
    submittedAt: '2024-11-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'VPOL',
    submittedAt: '2024-11-14',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ZJOV',
    submittedAt: '2024-09-11',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'ZJOV',
    submittedAt: '2024-09-23',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'FFDA',
    submittedAt: '2024-09-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'FFDA',
    submittedAt: '2024-09-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ERCY',
    submittedAt: '2024-09-20',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ERCY',
    submittedAt: '2024-09-29',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'NQHU',
    submittedAt: '2024-06-27',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'NQHU',
    submittedAt: '2024-07-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'KSML',
    submittedAt: '2024-10-27',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'KSML',
    submittedAt: '2024-11-24',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'GFPE',
    submittedAt: '2024-10-05',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'GFPE',
    submittedAt: '2024-10-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'KJCX',
    submittedAt: '2024-06-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'KJCX',
    submittedAt: '2024-07-20',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'LRSO',
    submittedAt: '2024-11-30',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'LRSO',
    submittedAt: '2024-12-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'UZTF',
    submittedAt: '2024-11-27',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'UZTF',
    submittedAt: '2024-12-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'SOYN',
    submittedAt: '2024-09-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'SOYN',
    submittedAt: '2024-09-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'NPGE',
    submittedAt: '2024-06-04',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'NPGE',
    submittedAt: '2024-06-13',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'GWXH',
    submittedAt: '2024-11-19',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'GWXH',
    submittedAt: '2024-12-12',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'ZFYR',
    submittedAt: '2024-08-11',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ZFYR',
    submittedAt: '2024-08-17',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ROHM',
    submittedAt: '2024-08-18',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ROHM',
    submittedAt: '2024-08-19',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'LXHO',
    submittedAt: '2024-11-13',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'LXHO',
    submittedAt: '2024-12-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'POVD',
    submittedAt: '2024-11-24',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'POVD',
    submittedAt: '2024-11-30',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'JBCM',
    submittedAt: '2024-08-31',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'JBCM',
    submittedAt: '2024-09-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'XNAE',
    submittedAt: '2024-08-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'XNAE',
    submittedAt: '2024-08-25',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'HCBV',
    submittedAt: '2024-10-16',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'HCBV',
    submittedAt: '2024-10-26',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'VMHC',
    submittedAt: '2024-06-04',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'VMHC',
    submittedAt: '2024-06-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'DXIQ',
    submittedAt: '2024-06-12',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'DXIQ',
    submittedAt: '2024-07-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'RAUL',
    submittedAt: '2024-07-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'RAUL',
    submittedAt: '2024-07-13',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'GPRY',
    submittedAt: '2024-07-26',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'GPRY',
    submittedAt: '2024-08-05',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'NWJU',
    submittedAt: '2024-10-20',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'NWJU',
    submittedAt: '2024-10-25',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'HZSJ',
    submittedAt: '2024-08-01',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'HZSJ',
    submittedAt: '2024-08-30',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'BZDM',
    submittedAt: '2024-11-12',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'BZDM',
    submittedAt: '2024-11-26',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ERCB',
    submittedAt: '2024-06-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ERCB',
    submittedAt: '2024-06-29',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'IPOT',
    submittedAt: '2024-09-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'IPOT',
    submittedAt: '2024-10-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'WJMQ',
    submittedAt: '2024-06-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'WJMQ',
    submittedAt: '2024-07-07',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'XNYV',
    submittedAt: '2024-09-27',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'XNYV',
    submittedAt: '2024-10-22',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'HMIN',
    submittedAt: '2024-07-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'HMIN',
    submittedAt: '2024-08-07',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'ZQTX',
    submittedAt: '2024-11-13',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ZQTX',
    submittedAt: '2024-11-21',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'FERB',
    submittedAt: '2024-09-29',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'FERB',
    submittedAt: '2024-10-15',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'PZKH',
    submittedAt: '2024-08-04',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'PZKH',
    submittedAt: '2024-08-31',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'BBAR',
    submittedAt: '2024-08-16',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'BBAR',
    submittedAt: '2024-09-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'VLHW',
    submittedAt: '2024-11-12',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'VLHW',
    submittedAt: '2024-12-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'BDRW',
    submittedAt: '2024-08-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'BDRW',
    submittedAt: '2024-08-30',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'SUNP',
    submittedAt: '2024-06-12',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'SUNP',
    submittedAt: '2024-06-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'WHXZ',
    submittedAt: '2024-08-24',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'WHXZ',
    submittedAt: '2024-09-20',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ALCO',
    submittedAt: '2024-11-14',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ALCO',
    submittedAt: '2024-11-18',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'WENP',
    submittedAt: '2024-11-09',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'WENP',
    submittedAt: '2024-11-15',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'EMSX',
    submittedAt: '2024-08-15',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'EMSX',
    submittedAt: '2024-09-06',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'MXZI',
    submittedAt: '2024-07-30',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'MXZI',
    submittedAt: '2024-08-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'BJQM',
    submittedAt: '2024-09-27',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'BJQM',
    submittedAt: '2024-10-01',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'UCCK',
    submittedAt: '2024-08-10',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'UCCK',
    submittedAt: '2024-09-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'FAOX',
    submittedAt: '2024-08-06',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'FAOX',
    submittedAt: '2024-09-01',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'KOXK',
    submittedAt: '2024-11-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'KOXK',
    submittedAt: '2024-11-29',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'VTRV',
    submittedAt: '2024-09-15',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'VTRV',
    submittedAt: '2024-10-03',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'NVMN',
    submittedAt: '2024-09-10',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'NVMN',
    submittedAt: '2024-09-21',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'RXSY',
    submittedAt: '2024-08-24',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'RXSY',
    submittedAt: '2024-09-17',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'CUCY',
    submittedAt: '2024-08-15',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'CUCY',
    submittedAt: '2024-08-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'IUXW',
    submittedAt: '2024-07-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'IUXW',
    submittedAt: '2024-08-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'LTHX',
    submittedAt: '2024-06-08',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'LTHX',
    submittedAt: '2024-06-10',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ZYER',
    submittedAt: '2024-09-19',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'ZYER',
    submittedAt: '2024-09-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'OQJY',
    submittedAt: '2024-08-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'OQJY',
    submittedAt: '2024-09-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'CFBD',
    submittedAt: '2024-10-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'CFBD',
    submittedAt: '2024-10-23',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'CSJR',
    submittedAt: '2024-09-15',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'CSJR',
    submittedAt: '2024-10-05',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'LUPY',
    submittedAt: '2024-11-27',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'LUPY',
    submittedAt: '2024-12-03',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'YDCF',
    submittedAt: '2024-08-09',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'YDCF',
    submittedAt: '2024-08-14',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'FING',
    submittedAt: '2024-06-11',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'FING',
    submittedAt: '2024-07-04',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ACEL',
    submittedAt: '2024-08-25',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'ACEL',
    submittedAt: '2024-09-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'EJRF',
    submittedAt: '2024-10-22',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'EJRF',
    submittedAt: '2024-11-01',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'FUNQ',
    submittedAt: '2024-07-13',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'FUNQ',
    submittedAt: '2024-08-07',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'YNWJ',
    submittedAt: '2024-11-10',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'YNWJ',
    submittedAt: '2024-11-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'WRBK',
    submittedAt: '2024-07-16',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'WRBK',
    submittedAt: '2024-08-03',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'ICRL',
    submittedAt: '2024-11-18',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'ICRL',
    submittedAt: '2024-11-24',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'VHUW',
    submittedAt: '2024-07-25',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'VHUW',
    submittedAt: '2024-07-31',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'YUBO',
    submittedAt: '2024-10-11',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'YUBO',
    submittedAt: '2024-10-26',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'ZFIY',
    submittedAt: '2024-08-30',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'ZFIY',
    submittedAt: '2024-09-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'MSVP',
    submittedAt: '2024-09-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'MSVP',
    submittedAt: '2024-09-25',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'ZLGK',
    submittedAt: '2024-09-29',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'ZLGK',
    submittedAt: '2024-10-04',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'LEMP',
    submittedAt: '2024-07-20',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'LEMP',
    submittedAt: '2024-08-13',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'SQVV',
    submittedAt: '2024-07-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'SQVV',
    submittedAt: '2024-08-04',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'INOO',
    submittedAt: '2024-08-30',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'INOO',
    submittedAt: '2024-08-31',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'GLXG',
    submittedAt: '2024-06-04',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'GLXG',
    submittedAt: '2024-06-14',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'RLIB',
    submittedAt: '2024-11-03',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'RLIB',
    submittedAt: '2024-11-06',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'PRRN',
    submittedAt: '2024-10-31',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'PRRN',
    submittedAt: '2024-11-05',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'IUZD',
    submittedAt: '2024-06-02',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'IUZD',
    submittedAt: '2024-06-12',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'ZJZM',
    submittedAt: '2024-06-06',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'ZJZM',
    submittedAt: '2024-06-18',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'VFBL',
    submittedAt: '2024-07-29',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'VFBL',
    submittedAt: '2024-08-08',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'KWCK',
    submittedAt: '2024-09-12',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'KWCK',
    submittedAt: '2024-10-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'LJPC',
    submittedAt: '2024-08-22',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'LJPC',
    submittedAt: '2024-09-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'KQXO',
    submittedAt: '2024-08-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'KQXO',
    submittedAt: '2024-09-14',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'NRFA',
    submittedAt: '2024-11-09',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'NRFA',
    submittedAt: '2024-12-01',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'LFNW',
    submittedAt: '2024-10-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'LFNW',
    submittedAt: '2024-10-23',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'WVXJ',
    submittedAt: '2024-09-15',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'WVXJ',
    submittedAt: '2024-10-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'AFEY',
    submittedAt: '2024-06-07',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'AFEY',
    submittedAt: '2024-06-29',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'QHNA',
    submittedAt: '2024-07-06',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'QHNA',
    submittedAt: '2024-07-10',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'SXQI',
    submittedAt: '2024-09-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'SXQI',
    submittedAt: '2024-10-25',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'YZOR',
    submittedAt: '2024-11-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'YZOR',
    submittedAt: '2024-11-22',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'AOSN',
    submittedAt: '2024-11-24',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'AOSN',
    submittedAt: '2024-12-04',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'HGRZ',
    submittedAt: '2024-11-08',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'HGRZ',
    submittedAt: '2024-11-29',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'TZCC',
    submittedAt: '2024-08-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'TZCC',
    submittedAt: '2024-08-31',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'WAKT',
    submittedAt: '2024-07-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'WAKT',
    submittedAt: '2024-08-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'MBNZ',
    submittedAt: '2024-09-18',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'MBNZ',
    submittedAt: '2024-09-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'YIRG',
    submittedAt: '2024-08-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'YIRG',
    submittedAt: '2024-08-21',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'HYMA',
    submittedAt: '2024-09-19',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'HYMA',
    submittedAt: '2024-10-13',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'CZTA',
    submittedAt: '2024-07-28',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'CZTA',
    submittedAt: '2024-07-30',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'NKIX',
    submittedAt: '2024-08-18',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'NKIX',
    submittedAt: '2024-09-11',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'IRRF',
    submittedAt: '2024-08-05',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'IRRF',
    submittedAt: '2024-08-20',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'RXTR',
    submittedAt: '2024-09-21',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'RXTR',
    submittedAt: '2024-10-03',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'RVRR',
    submittedAt: '2024-11-07',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'RVRR',
    submittedAt: '2024-11-26',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'JJIK',
    submittedAt: '2024-06-01',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'JJIK',
    submittedAt: '2024-06-20',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'MAHM',
    submittedAt: '2024-06-26',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'MAHM',
    submittedAt: '2024-07-19',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'GKML',
    submittedAt: '2024-10-21',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'GKML',
    submittedAt: '2024-11-08',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'KQOA',
    submittedAt: '2024-09-07',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'KQOA',
    submittedAt: '2024-09-30',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'NYVY',
    submittedAt: '2024-09-26',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'NYVY',
    submittedAt: '2024-10-21',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'UUBH',
    submittedAt: '2024-06-02',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'UUBH',
    submittedAt: '2024-06-13',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Initial Simulation',
    patientId: 'PCPH',
    submittedAt: '2024-10-01',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'PCPH',
    submittedAt: '2024-10-29',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'PAAM',
    submittedAt: '2024-08-28',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'PAAM',
    submittedAt: '2024-09-18',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'AXBL',
    submittedAt: '2024-11-06',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'AXBL',
    submittedAt: '2024-11-16',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'ZVIB',
    submittedAt: '2024-09-23',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'ZVIB',
    submittedAt: '2024-10-09',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'YAFW',
    submittedAt: '2024-10-19',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'YAFW',
    submittedAt: '2024-10-25',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'EUAR',
    submittedAt: '2024-10-13',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'EUAR',
    submittedAt: '2024-11-03',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Adams',
    appointmentType: 'Initial Simulation',
    patientId: 'WMOW',
    submittedAt: '2024-06-16',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Adams',
    appointmentType: 'Treatment',
    patientId: 'WMOW',
    submittedAt: '2024-07-10',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'GWHD',
    submittedAt: '2024-11-22',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'GWHD',
    submittedAt: '2024-12-01',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'THRO',
    submittedAt: '2024-10-05',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Lopez',
    appointmentType: 'Treatment',
    patientId: 'THRO',
    submittedAt: '2024-10-27',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'MDVA',
    submittedAt: '2024-08-26',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'MDVA',
    submittedAt: '2024-09-20',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'LRKR',
    submittedAt: '2024-11-02',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'LRKR',
    submittedAt: '2024-11-26',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Lakeside',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'GKQD',
    submittedAt: '2024-06-01',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'GKQD',
    submittedAt: '2024-06-16',
    location: 'Machine 2',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Wong',
    appointmentType: 'Initial Simulation',
    patientId: 'QTCQ',
    submittedAt: '2024-07-07',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Wong',
    appointmentType: 'Treatment',
    patientId: 'QTCQ',
    submittedAt: '2024-07-20',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'OWDA',
    submittedAt: '2024-08-08',
    location: 'Machine 3',
    status: 'active',
  },
  {
    treatingDepartment: 'Rosebush',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'OWDA',
    submittedAt: '2024-09-02',
    location: 'Machine 4',
    status: 'active',
  },
  {
    treatingDepartment: 'Campsie',
    physician: 'Dr Lopez',
    appointmentType: 'Initial Simulation',
    patientId: 'AWQZ',
    submittedAt: '2024-07-25',
    location: 'Machine 1',
    status: 'active',
  },
  {
    treatingDepartment: 'Red Hills',
    physician: 'Dr Smith',
    appointmentType: 'Treatment',
    patientId: 'AWQZ',
    submittedAt: '2024-08-16',
    location: 'Machine 3',
    status: 'active',
  },
];

export default MockData;
