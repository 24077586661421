// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';

import { StepperLink } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';
import { CovidAssessmentItem } from 'op-classes';
import { OPUser } from 'op-interfaces';

import CovidNavigator from './CovidNavigator';
import { FormContext } from '../../../pages/OP/PatientNavigation/context';
import { FeatureOption } from 'op-interfaces/PatientInterfaces';
import { Query } from '@apollo/client/react/components';

const logger = new Logger('CovidNavigatorApollo');

const COVID_ASSESSMENT_QUERY = gql`
  query CovidScreening($id: ID!) {
    covidScreening(id: $id) {
      id
      hasSymptoms

      symptomDay
      symptomMonth
      symptomYear

      hasBeenDiagnosed
      hasBeenTested
      advisedToQuarantine
      hadContact
      travelledToHotspot
      status
    }
    user {
      id
      isPso
    }
    featureOptions {
      id
      name
      description
      active
    }
  }
`;

interface CovidAssessmentQueryData {
  covidScreening: {
    id: string;
    hasSymptoms: boolean;
    hasBeenDiagnosed: boolean;
    hasBeenTested: boolean;
    advisedToQuarantine: boolean;
    hadContact: boolean;
    travelledToHotspot: boolean;
    dateOfFeverSymptoms: Date;
    symptomDay: string;
    symptomMonth: string;
    symptomYear: string;
    status: string;
  };
  user: OPUser;
  featureOptions: FeatureOption[];
}

const COVID_SUBMISSION_MUTATION = gql`
  mutation CreateCovidSubmission($patientId: ID!) {
    createCovidSubmission(patientId: $patientId) {
      submissionCreated
    }
  }
`;

const COVID_SUBMISSION_MUTATION_PSO = gql`
  mutation submitCovidToMq($patientId: ID!) {
    submitCovidToMq(patientId: $patientId) {
      submissionCreated
      submission {
        id
        pdf
      }
    }
  }
`;

interface CreateSubmissionMutationResponse {
  createCovidSubmission: {
    submission: {
      id: string;
      pdf: string;
    };
  };
}

interface SubmitToMqMutationResponse {
  submitCovidToMq: {
    submission: {
      id: string;
      pdf: string;
    };
  };
}

interface Props extends WithApolloClient<{}>, RouteComponentProps<{ patientId: string }> {
  links: StepperLink[];
  covidScreening?: CovidAssessmentItem;
}

interface State {
  loadingMutation: boolean;
  mutationCalled: boolean;
}

class CovidNavigatorApollo extends Component<Props, State> {
  public static contextType = FormContext;

  public constructor(props: Props) {
    super(props);

    this.state = {
      loadingMutation: false,
      mutationCalled: false,
    };
  }

  public render(): JSX.Element {
    const {
      links,
      match: {
        params: { patientId },
      },
    } = this.props;
    const { loadingMutation, mutationCalled } = this.state;

    return (
      <Fragment>
        <Query<CovidAssessmentQueryData> query={COVID_ASSESSMENT_QUERY} variables={{ id: patientId }}>
          {({ data }): JSX.Element => {
            if (data && data.covidScreening && data.user) {
              const covidScreening = new CovidAssessmentItem();
              covidScreening.loadData(data.covidScreening);
              const submitted = covidScreening.status === 'filterSubmittedToMosaiq' ? true : mutationCalled;

              const activeNewRegoFeature = data?.featureOptions.find(
                (featureOption: FeatureOption) => featureOption.name === 'NewAusRego' && featureOption.active,
              );

              return (
                <CovidNavigator
                  covidScreening={covidScreening}
                  links={links}
                  loading={loadingMutation}
                  submitFunction={this.submitCovid}
                  submitCalled={submitted}
                  isPso={data.user.isPso}
                  showNewRego={!!activeNewRegoFeature}
                />
              );
            }
            return <div></div>;
          }}
        </Query>
      </Fragment>
    );
  }

  private submitCovid = (isPso = false): void => {
    const {
      client,
      match: {
        params: { patientId },
      },
      history,
    } = this.props;

    const { loadingMutation, mutationCalled } = this.state;

    if (!loadingMutation && !mutationCalled) {
      this.setState({ loadingMutation: true }, (): void => {
        // Submit covid as patient
        if (isPso) {
          client
            ?.mutate({
              mutation: COVID_SUBMISSION_MUTATION_PSO,
              variables: {
                patientId: patientId,
              },
            })
            .then((response): void => {
              if (response.data && response.data.submitCovidToMq && response.data.submitCovidToMq.submission) {
                logger.debug(
                  'submitCovid',
                  'The pdf has been loaded in: ',
                  `${window.location.origin}/server/media/${response.data.submitCovidToMq.submission.pdf}`,
                );
              }
              this.setState({ loadingMutation: false, mutationCalled: true });
              history.goBack();
            });
        } else {
          client
            ?.mutate({
              mutation: COVID_SUBMISSION_MUTATION,
              variables: {
                patientId: patientId,
              },
            })
            .then((_response): void => {
              this.setState({ loadingMutation: false, mutationCalled: true });
              //@ts-ignore
              this.context.setFormStatusDetails({
                //@ts-ignore
                ...this.context.formStatusDetails,
                covidScreening: { status: 'filterReviewRequired' },
              });
            });
        }

        // Submit covid as pso
      });
    }
  };
}

const routeComponent = withRouter(withApollo<Props>(CovidNavigatorApollo));
export default routeComponent;
