import { CareplanFilter, CareplanStatusFilter, RoDashboardTabs } from 'op-enums';
import { BaseConfigInterface } from './types';
import { BOOLEAN_OPTIONS, BOOLEAN_TBC_OPTIONS } from '../TreatmentInfoPage/Interface';

const demo_config: BaseConfigInterface = {
  SpecialPhysicsConsultVisible: true,
  RequestTemplateChangeActive: true,
  ErrorPopup: {
    Generic:
      "We encountered an error. Please refresh the page. If the issue persists, please contact Lumonus support: <a href='mailto:support@lumonus.com'>support@lumonus.com</a>",
    Practitioner:
      "We encountered an error. Please refresh the page. If the issue persists, please contact Lumonus support: <a href='mailto:support@lumonus.com'>support@lumonus.com</a>",
    Patient: 'We encountered an error. Please refresh the page. If the issue persists, please contact your clinic.',
  },
  ReferringPhysicians: {
    oncologist: 'Responsible Physician',
    generalPractitioner: 'Primary Physician (GP)',
    referringSurgeon: 'Referring Physician 1',
    referringSurgeon2: 'Referring Physician 2',
    referringSurgeon3: 'Referring Physician 3',
  },
  DefaultValue: {
    cied: '',
    patientStatus: 'unknown',
    previousRadioTreatment: '',
    treatmentRORequired: '',
    tumourName: 'tumor',
  },
  Clinex: {
    betaTesting: false,
  },
  Intake: {
    disabled: false,
    isProstateDisabled: false,
    breastMandatoryFields: ['grade', 'er', 'pgr', 'her2'],
    lungMandatoryFields: ['weightLoss', 'smokingHistory', 'egfr', 'alk', 'pdl1'],
    headNeckMandatoryFields: ['P16', 'smokingHistory'],
    lymphomaMandatoryFields: ['stage', 'aOrBSymptoms', 'extranodal', 'bulkDisease'],
    breastFormVersion: 2,
  },
  PatientSummary: {
    isVisible: true,
    labsEnabled: false,
    RODocumentUpload: false,
    documentFilterField: 'description',
  },
  OutcomesPage: {
    isVisible: true,
    DateFormat: 'DD-MMM-YYYY',
    DateTimeFormat: 'DD MMM YYYY h:mma',
  },
  OTRPage: {
    isVisible: true,
    DateFormat: 'DD-MMM-YYYY',
    DateTimeFormat: 'DD MMM YYYY h:mma',
  },
  DisplayButton: {
    displaySimButton: true,
  },
  DisplayModal: {
    submission: false,
  },
  TreatmentInfoPage: {
    mandatoryFields: ['intent', 'treatingDepartment', 'cied', 'previousRadioTreatment'],
    ReadyForCareDate: false,
    AdditionalBookingInfo: false,
    DelayCategory: false,
    MDT: false,
    PreviousTreatmentRetreatment: false,
    PreviousTreatmentRetreatmentType: false,
    AuthorizedICDTreatingDepartments: [],
    Hormones: false,
    Brachytherapy: false,
    ccip: true,
  },
  SimulationPage: {
    ContrastRequiredChildFields: true,
    SpacerGelWarning: false,
    imagingText: 'Add outside imaging request',
    imagingCountTitle: 'Outside imaging',
    disableExtentDefined: false,
    disablePositionSetup: false,
    immobilisationLabel: 'Immobilization',
  },
  PrescriptionPage: {
    MicrodosimetryTld: true,
    WeeklyPhysicsCheck: true,
    BasicDoseCalculation: true,
  },
  SubmissionPage: {
    PlanAimSubmissionType: 'Plan Aim',
    CPOTEnabled: false,
  },
  VolumingPage: {
    UsePrioritizedOARs: true,
  },
  RadiationDashboard: {
    dateTimeFormat: 'MM/DD/YYYY hh:mm A',
    active: RoDashboardTabs.APPOINTMENTS,
    careplanDashboard: {
      careplanStatusFilters: [
        { id: CareplanStatusFilter.DRAFT, name: CareplanStatusFilter.DRAFT, checked: true },
        { id: CareplanStatusFilter.SIMULATION, name: CareplanStatusFilter.SIMULATION, checked: true },
        { id: CareplanStatusFilter.PLAN_AIM, name: CareplanStatusFilter.PLAN_AIM, checked: true },
        { id: CareplanStatusFilter.PRESCRIPTION, name: CareplanStatusFilter.PRESCRIPTION, checked: true },
        { id: CareplanStatusFilter.CPOT, name: CareplanStatusFilter.CPOT, checked: true },
      ],
      careplanFilters: [{ id: CareplanFilter.UNSUBMITTED, name: CareplanFilter.UNSUBMITTED, checked: false }],
      isPatientSummaryLinkEnabled: true,
    },
    showFilters: false,
  },
  QuestionLabel: {
    CIED: 'Cardiac Implantable Electronic Device (CIED)',
    RoRequired: 'RO Required',
    GoldSeeds: 'Gold Seeds',
    phase: 'Sequence',
  },
  QuestionOptions: {
    CIED: BOOLEAN_TBC_OPTIONS,
    previousRadioTreatment: BOOLEAN_OPTIONS,
  },
  QuestionValidationChildren: {
    CIED: ['ciedProtocolRequired', 'preTreatmentCiedCheckRequired'],
    Staging: {
      Primary: {
        RO: ['laterality', 'diagnosisDate'],
        MO: ['laterality'],
      },
      Metastasis: {
        RO: ['laterality', 'diagnosisDate'],
        MO: [],
      },
    },
  },
  ResetMutationVariableDefaults: {
    CIED: {
      ciedProtocolRequired: '',
      preTreatmentCiedCheckRequired: '',
      referralToGcCardiologist: '',
      otherCiedInstructions: '',
    },
    CiedProtocolRequired: {
      preTreatmentCiedCheckRequired: '',
      referralToGcCardiologist: '',
      otherCiedInstructions: '',
    },
  },
  GetMutationVariableDefaults: {
    CIED: {
      ciedProtocolRequired: 'yes',
      preTreatmentCiedCheckRequired: 'yes',
      referralToGcCardiologist: 'no',
      otherCiedInstructions: '',
    },
    CiedProtocolRequired: {
      preTreatmentCiedCheckRequired: 'yes',
      referralToGcCardiologist: 'no',
      otherCiedInstructions: '',
    },
  },
  DoseSiteSummary: {
    enabled: true,
    DateFormat: 'MM/DD/YYYY',
  },
  SubmitDiagnosisOnlyAndExit: false,
  CardioReferralField: false,
  Tooltips: {
    hasPriority: false,
    hasClinicalPathologicalStaging: false,
  },
  DefaultTimezone: 'America/New_York',
  DateFormat: 'MM DD YYYY',
  CareplanVoidTimeRestriction: 168,
  CareplanVoidDescription: 'Documentation will be voided in your OIS.',
  EmailMaxLength: 40,
  Outage: {
    link: 'https://genesiscare.sharepoint.com/sites/Oncologyportal/SitePages/Horizon-Offline-weekend.aspx',
    showBanner: true,
    message: 'Horizon will be offline from 9 PM Friday, March 15th to Sunday, March 17th, 2024, AEST.',
  },
  Notes: {
    EnableSubmit: true,
    DateTimeFormat: 'MM/DD/YYYY hh:mm A',
  },
  DefaultLocale: 'en',
  SupportEmail: 'support-us@lumonus.com',
  ShowResetDemoDataMenuLink: true,
  Insights: {
    enabled: true,
    showInNavBar: true,
    mockData: true,
    defaultDateFilter: 'Past 6 months',
    doseUnit: 'Gy',
    Dashboards: {
      careplanAdherence: true,
      techniques: true,
      averageFractions: true,
      totalReferrals: true,
      totalDiagnosis: true,
      totalPlans: true,
      linacs: true,
      simToTreatment: true,
      averageDailyPlans: true,
      totalConsultations: false,
      totalSimulations: false,
      totalTreatments: true,
    },
  },
  MenuItems: {
    manageProtocols: true,
  },
};

export default demo_config;
