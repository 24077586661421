import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartStackedColumn from '../Charts/ChartStackedColumn';
import SubChartBar from '../Charts/SubCharts/SubChartBar';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import { DataFunctions } from '../../Utils/dataFunctions';

const seriesColorMapping: { [key: string]: string } = {
  Breast: 'rgba(170, 161, 253)',
  Metastasis: 'rgba(62, 167, 178)',
  Urogenital: 'rgba(255, 189, 91)',
  GI: 'rgba(251, 106, 118)',
  Brain: 'rgba(128, 0, 128)',
  Skin: 'rgba(89, 39, 218)',
  'H&N': 'rgba(0, 128, 128)',
  Lung: 'rgba(255, 99, 132)',
  Gynae: 'rgba(255, 159, 64)',
  Sarcoma: 'rgba(255, 205, 86)',
  Haematological: 'rgba(75, 192, 192)',
  Misc: 'rgba(54, 162, 235)',
  Benign: 'rgba(153, 102, 255)',
};

const Diagnosis = ({
  data,
  subChartData,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  ...rest
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight, dateRange),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartStackedColumn({
      selectedPoint: rest.filterOverride,
      aggregation: dateAggregationMap[dateRange!],
      dateRange: dateRange,
      leftSeriesTitle: '',
      rightSeriesTitle: '',
      leftSeriesFields: DataFunctions.aggregateStackedBarData(
        data,
        'tumourStream',
        dateAggregationMap[dateRange!],
        '',
        [
          'breast',
          'urogenital',
          'metastasis',
          'lung',
          'h&n',
          'brain',
          'gi',
          'gynae',
          'skin',
          'sarcoma',
          'haematological',
          'misc',
          'Unspecified',
        ],
        true,
      )[0],
      rightSeriesFields: ['submittedAt', 'Techniques'],
      seriesColorMapping,
      tooltipSuffix: 'cases',
      ...rest,
    }).components?.[0] ?? {},
    SubChartBar({
      seeMore: seeMoreBottomLeft,
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      yAxisMax: DataFunctions.aggregateDataMax(subChartData, 'diagnosisCode') * 2,
      dataLength: DataFunctions.aggregateData(subChartData, 'diagnosisCode').length - 1,
      dataTotal: DataFunctions.aggregateDataSum(subChartData, 'diagnosisCode'),
      ...rest,
    }).components?.[0] ?? {},
    SubChartBar({
      seeMore: seeMoreBottomRight,
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      yAxisMax: DataFunctions.aggregateDataMax(subChartData, 'createdBy') * 2,
      dataLength: DataFunctions.aggregateData(subChartData, 'createdBy').length - 1,
      dataTotal: DataFunctions.aggregateDataSum(subChartData, 'createdBy'),
      ...rest,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
  dateRange: string | undefined,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: true,
        data: DataFunctions.aggregateStackedBarData(data, 'tumourStream', dateAggregationMap[dateRange!], '', [], true),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(
          subChartData,
          'diagnosisCode',
          seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT,
        ),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(subChartData, 'createdBy', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default Diagnosis;
