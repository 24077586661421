import React, { createContext, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MultiSelectOptions } from '../MultiSelectWithSearch';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

type DashboardContextType = {
  filteredData: any;
  previousPeriodData: any;
  subChartFilteredData: any;
  dateFilterOverride: string;
  dateRange: string;
  endDate: Date;
  tumourStreams: MultiSelectOptions[];
  physicians: MultiSelectOptions[];
  sites: MultiSelectOptions[];
  tumourStreamOptions: MultiSelectOptions[];
  physicianOptions: MultiSelectOptions[];
  siteOptions: MultiSelectOptions[];
  isLoading: boolean;
  overviewChartsLoaded: boolean;
  isReloaded: boolean;
  selectedPointIndex: number;
  dataRefreshed: boolean;
  setOverviewChartsLoaded: Dispatch<SetStateAction<boolean>>;
  setDateRange: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<Date>>;
  setTumourStreams: Dispatch<SetStateAction<MultiSelectOptions[]>>;
  setPhysicians: Dispatch<SetStateAction<MultiSelectOptions[]>>;
  setSites: Dispatch<SetStateAction<MultiSelectOptions[]>>;
  handlePointClick: (pointName: string) => void;
  handleDateClick: (date: string, index: number) => void;
  navToPage: (page: string) => void;
  onChartClick: (override?: number) => void;
};

const DashboardContext = createContext<DashboardContextType>({
  filteredData: [],
  previousPeriodData: [],
  subChartFilteredData: [],
  dateFilterOverride: '',
  dateRange: '',
  endDate: new Date(),
  tumourStreams: [],
  physicians: [],
  sites: [],
  tumourStreamOptions: [],
  physicianOptions: [],
  siteOptions: [],
  isLoading: true,
  overviewChartsLoaded: false,
  isReloaded: false,
  selectedPointIndex: -1,
  dataRefreshed: false,
  setOverviewChartsLoaded: () => {},
  setDateRange: () => {},
  setEndDate: () => {},
  setTumourStreams: () => {},
  setPhysicians: () => {},
  setSites: () => {},
  handlePointClick: () => {},
  handleDateClick: () => {},
  navToPage: () => {},
  onChartClick: () => {},
});

interface FilterData {
  submittedAt: string;
  tumourStream: string;
  createdBy: string;
  treatingDepartment: string;
}

interface DataRecord {
  fractions: number;
  submittedAt: string;
  fraction_dose: number;
  tumourStream: string;
  createdBy: string;
  treatingDepartment: string;
}

const getLastDayOfMonth = (monthYear: string): string => {
  const [month, year] = monthYear.split(' ');
  const date = new Date(`${month} 1, ${year}`);
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);

  const lastDay = date.getDate().toString().padStart(2, '0');
  const monthNumber = (date.getMonth() + 1).toString().padStart(2, '0');

  return `${year}-${monthNumber}-${lastDay}`;
};

const getFirstDayOfMonth = (monthYear: string): string => {
  const [month, year] = monthYear.split(' ');
  const monthNumber = new Date(month + ' 1, 2021').getMonth() + 1;
  return `${year}-${monthNumber.toString().padStart(2, '0')}-01`;
};

const dateRangeMap: Record<string, number> = {
  'Past 7 days': 7,
  'Past 14 days': 14,
  'Past 30 days': 30,
  'Past 90 days': 90,
  'Past 6 months': 180,
  'Past 1 year': 365,
  'All time': 0,
};

const subDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

const DashboardContextProvider = ({
  children,
  data = [],
  dataRefreshed,
  setDataRefreshed,
  navToPage,
  onChartClick,
}: {
  children: React.ReactNode;
  data: DataRecord[];
  dataRefreshed: boolean;
  setDataRefreshed: (value: boolean) => void;
  navToPage: (page: string) => void;
  onChartClick: (override?: number) => void;
}): JSX.Element => {
  const tumourStreamOptions: MultiSelectOptions[] = Array.from(
    new Set(data.map((item: FilterData) => item.tumourStream)),
  )
    .sort((a, b) => a.localeCompare(b))
    .map((diagnosis: string) => ({
      label: diagnosis.charAt(0).toUpperCase() + diagnosis.slice(1),
      value: diagnosis,
    }));

  const physicianOptions: MultiSelectOptions[] = [...new Set(data.map((item: FilterData) => item.createdBy))].map(
    (physician: string) => ({
      label: physician,
      value: physician,
    }),
  );

  const siteOptions: MultiSelectOptions[] = [...new Set(data.map((item: FilterData) => item.treatingDepartment))].map(
    (site: string) => ({
      label: site,
      value: site,
    }),
  );

  const handlePointClick = (pointName: string) => {
    const newTumourStream: MultiSelectOptions = {
      label: pointName,
      value: pointName.toLowerCase(),
    };
    setTumourStreams([newTumourStream]);
  };

  const handleDateClick = (date: string, index: number) => {
    onChartClick();

    if (dateFilterOverride === date) {
      setSubChartFilteredData(filteredData);
      setDateFilterOverride('');
    } else {
      const endDate = new Date(getLastDayOfMonth(date));
      const startDate = new Date(getFirstDayOfMonth(date));
      setSubChartFilteredData(
        filteredData.filter(
          (item: DataRecord) => new Date(item.submittedAt) >= startDate && new Date(item.submittedAt) <= endDate,
        ),
      );
      setDateFilterOverride(date);

      if (index !== selectedPointIndex) {
        setSelectedPointIndex(index);
      } else {
        setSelectedPointIndex(-1);
      }
    }
  };

  const [dateRange, setDateRange] = useState<string>(CurrentAppConfig.Insights?.defaultDateFilter || 'Past 6 months');
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dateFilterOverride, setDateFilterOverride] = useState<string>('');
  const [tumourStreams, setTumourStreams] = useState<MultiSelectOptions[]>(tumourStreamOptions);
  const [physicians, setPhysicians] = useState<MultiSelectOptions[]>(physicianOptions);
  const [sites, setSites] = useState<MultiSelectOptions[]>(siteOptions);

  const [filteredData, setFilteredData] = useState<DataRecord[]>([]);
  const [subChartFilteredData, setSubChartFilteredData] = useState<DataRecord[]>(filteredData);
  const [previousPeriodData, setPreviousPeriodData] = useState<DataRecord[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [overviewChartsLoaded, setOverviewChartsLoaded] = useState(false);
  const [selectedPointIndex, setSelectedPointIndex] = useState<number>(-1);

  // determine if the page is reloaded. Needed for overview chart height calculation
  const [isReloaded, _] = useState(sessionStorage.getItem('reloaded') === '1');
  const history = useHistory();
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (history['location']['pathname'] === '/radiation/insights') {
        sessionStorage.setItem('reloaded', '1');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // clear out the selected point index when the location changes
  const location = useLocation();
  useEffect(() => {
    if (selectedPointIndex !== -1) {
      setSelectedPointIndex(-1);
      setDateFilterOverride('');
      setSubChartFilteredData(filteredData);
    }
  }, [location]);

  useEffect(() => {
    if (data.length > 0) {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (!dateRange) {
      setDateRange(CurrentAppConfig.Insights?.defaultDateFilter || 'Past 6 months');
    }

    if (sites.length === 0 || dataRefreshed) {
      setSites(siteOptions);
    }

    if (physicians.length === 0 || dataRefreshed) {
      setPhysicians(physicianOptions);
    }

    if (tumourStreams.length === 0 || dataRefreshed) {
      setTumourStreams(tumourStreamOptions);
    }

    const startDate = subDays(endDate, dateRangeMap[dateRange]);

    const filteredData: DataRecord[] = data.filter(
      (item: DataRecord) =>
        tumourStreams.some((tumourStream) => tumourStream.value === item.tumourStream) &&
        physicians.some((physician) => physician.value === item.createdBy) &&
        sites.some((site) => site.value === item.treatingDepartment) &&
        new Date(item.submittedAt) > (dateRange === 'All time' ? new Date(0) : startDate) &&
        new Date(item.submittedAt) <= endDate,
    );

    setFilteredData(filteredData);
    setSubChartFilteredData(filteredData);
    setDateFilterOverride('');

    const previousPeriodData: DataRecord[] = data.filter(
      (item: DataRecord) =>
        tumourStreams.some((tumourStream) => tumourStream.value === item.tumourStream) &&
        physicians.some((physician) => physician.value === item.createdBy) &&
        sites.some((site) => site.value === item.treatingDepartment) &&
        new Date(item.submittedAt) >
          (dateRange === 'All time' ? new Date(0) : subDays(startDate, dateRangeMap[dateRange])) &&
        new Date(item.submittedAt) <= startDate,
    );
    setPreviousPeriodData(previousPeriodData);

    sessionStorage.removeItem('reloaded');
    setDataRefreshed(false);
  }, [data, tumourStreams, physicians, sites, endDate, dateRange]);

  const value = {
    filteredData,
    previousPeriodData,
    subChartFilteredData,
    dateFilterOverride,
    dateRange,
    endDate,
    tumourStreams,
    physicians,
    sites,
    tumourStreamOptions,
    physicianOptions,
    siteOptions,
    isLoading,
    overviewChartsLoaded,
    isReloaded,
    selectedPointIndex,
    dataRefreshed,
    setOverviewChartsLoaded,
    setDateRange,
    setEndDate,
    setTumourStreams,
    setPhysicians,
    setSites,
    handlePointClick,
    handleDateClick,
    navToPage,
    onChartClick,
  };

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

export { DashboardContext, DashboardContextProvider };
