// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';

import { Redirect, useHistory } from 'react-router-dom';
import { RecentPatients } from 'op-components';
import { useErrorModalContext } from 'op-contexts';
import { RoleType, CareplanStatusFilter, CareplanFilter } from 'op-enums';
import { GET_RECENT_PATIENTS, GET_USER_PROFILE } from 'op-graphql/queries';
import { generateGreetingText } from 'op-utils/helpers';
import { styled } from '@mui/system';

import { Card, LoadingSpinner } from 'shared-components/components';
import ROProfilePhoto from 'shared-components/components/ROProfilePhoto/ROProfilePhoto';
import { Region } from 'shared-components/enums';

import { CREATE_PATIENT, GET_UNSUBMITTED_COUNT } from './DashboardQueries';
import { Divider, Typography, useTheme, Stack, Badge, CustomTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;
const isUkRegion = REACT_APP_REGION === Region.UK;

export const StyledTile = styled(Card)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  margin-left: 5px;
`;

interface Props {
  setStatusFilter: (statusFilter: string[]) => void;
  setCareplanFilter: (careplanFilter: string[]) => void;
}

const DashboardLHSPane = ({ setStatusFilter, setCareplanFilter }: Props): JSX.Element => {
  const { setError } = useErrorModalContext();
  const theme = useTheme<CustomTheme>();

  const history = useHistory();
  const { data: userProfile, loading: userLoading, error: userError } = useQuery(GET_USER_PROFILE);
  const { data: recentPatients } = useQuery(GET_RECENT_PATIENTS, {
    fetchPolicy: 'network-only',
    skip: userProfile?.user?.primaryRole === RoleType.CAREPLANVIEWER,
  });
  const { data: unsubmittedCareplansData, loading: unsubmittedCareplansLoading } = useQuery(GET_UNSUBMITTED_COUNT, {
    skip: userProfile?.user?.primaryRole === RoleType.CAREPLANVIEWER,
    fetchPolicy: 'cache-and-network',
  });

  const isRo = userProfile?.user?.isRo;

  useEffect(() => {
    if (userError || !userProfile?.user?.hasStaffId) {
      // TODO - use locallyHandledErrors context value to only apply this on the intended userError
      return setError('Account');
    }
  }, [userError, userProfile, userProfile?.user?.hasStaffId, setError]);

  const [createPatient, { loading: createPatientLoading }] = useMutation(CREATE_PATIENT, {
    onCompleted: (data: any) => {
      const { id } = data.createPatient.patient;
      history.push(`/radiation/registration/patient/${id}/basic`);
    },
  });
  const hasCareplanViewerRole = userProfile?.user?.primaryRole === RoleType.CAREPLANVIEWER;

  if (userLoading) return <LoadingSpinner />;

  if (!isRo && !hasCareplanViewerRole) {
    return <Redirect to="/error" />;
  }

  const userName = isRo ? `Dr. ${userProfile?.user?.name}` : userProfile?.user?.name;
  const greeting = generateGreetingText();
  const draftCareplanCount = !unsubmittedCareplansLoading ? unsubmittedCareplansData?.getUnsubmittedCount?.drafts : 0;
  const unsubmittedCareplanCount = !unsubmittedCareplansLoading
    ? unsubmittedCareplansData?.getUnsubmittedCount?.unsubmitted
    : 0;
  const text = createPatientLoading ? 'Loading' : 'Create new patient';

  return (
    <Stack
      data-test-id="dashboard-lhs-pane"
      height={1}
      borderRight={`1px solid ${theme.palette.divider}`}
      width="240px"
      minWidth="240px"
      alignItems="center"
      sx={{ backgroundColor: theme.palette.background.secondary }}>
      <Stack paddingX={2} paddingTop={3} paddingBottom={2} width={1}>
        <Typography variant="h6" whiteSpace="break-spaces" color={theme.palette.text.secondary}>
          {dayjs().format('ddd, DD MMMM YYYY')}
        </Typography>
        <Stack paddingY={2}>
          <ROProfilePhoto useStock={true} />
        </Stack>
        <Typography>{greeting}</Typography>
        <Typography variant="h6" sx={{ textWrap: 'wrap' }}>
          {userName}
        </Typography>
        <Divider sx={{ margin: '12px -16px 12px -16px' }} />
        {isRo && (
          <Stack gap={1}>
            <Typography variant="body2">OUTSTANDING CAREPLANS</Typography>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              id={'draft-careplan-count-button'}
              data-test-id={'draft-careplan-count-button'}
              onClick={() => {
                setStatusFilter([CareplanStatusFilter.DRAFT]);
                setCareplanFilter([]);
                history.push('/radiation/careplans');
              }}>
              <Typography
                variant="body1"
                color={theme.palette.primary.dark}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Draft
              </Typography>
              <Badge
                data-testid="draft-careplan-count"
                showZero
                badgeContent={draftCareplanCount}
                color={draftCareplanCount === 0 ? 'secondary' : 'error'}
              />
            </Stack>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              id={'unsubmitted-count-button'}
              data-test-id={'unsubmitted-count-button'}
              onClick={() => {
                setStatusFilter([]);
                setCareplanFilter([CareplanFilter.UNSUBMITTED]);
                history.push('/radiation/careplans');
              }}>
              <Typography
                variant="body1"
                color={theme.palette.primary.dark}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Unsubmitted changes
              </Typography>
              <Badge
                data-testid="unsubmitted-careplan-count"
                showZero
                badgeContent={unsubmittedCareplanCount}
                color={unsubmittedCareplanCount === 0 ? 'secondary' : 'error'}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
      {isUkRegion && (
        <LoadingButton
          id="new-patient-button"
          data-testid="new-patient-button"
          sx={{ width: 0.9, marginBottom: 2 }}
          variant="outlined"
          onClick={() => {
            createPatient();
          }}
          loading={createPatientLoading}>
          {text}
        </LoadingButton>
      )}
      <RecentPatients recentPatients={recentPatients?.getRecentPatients} isRo={isRo} />
    </Stack>
  );
};

export default DashboardLHSPane;
