// eslint-disable-next-line no-use-before-define
// TODO: Remove this and use switch in RO CareplanApollo component.
import React, { useEffect, useContext } from 'react';
import { useFormikContext, FormikProps } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { useRouteMatch, useLocation } from 'react-router-dom';

import { useErrorModalContext } from 'op-contexts';
import { intakePageMapping } from 'op-pages/RO/Careplan/DiagnosisPages/Intake/IntakePage';
import { CREATE_DIAGNOSIS_INTAKE, GET_DIAGNOSIS_INTAKE } from 'op-pages/RO/Careplan/DiagnosisPages/Intake/Queries';
import { codeToIntakePageMapping } from 'op-pages/RO/Careplan/DiagnosisPages/Utils';
import { CarePlanActionType } from 'op-pages/RO/Careplan/Interface';
import ROPatientCarePlanContext from 'op-pages/RO/Careplan/ROPatientCarePlanContext';
import { GET_INTAKE_CODE_MAPPING } from 'op-pages/RO/Careplan/Queries';
import { LOAD_TREATMENTINFO } from 'op-pages/RO/Careplan/TreatmentInfoPage/Queries';
import { GET_DIAGNOSIS_FROM_CAREPLAN } from 'op-pages/RO/Careplan/SidePanel/SidePanelQueries';
import {
  GET_ORDERED_DIAGNOSIS_LIST,
  GET_ICD10_CATEGORY_LIST,
  GET_CLINEX_CODES,
  GET_DIAGNOSIS,
} from 'op-pages/RO/Careplan/DiagnosisPages/Queries';
import { LOAD_TREATMENT_LIST_DATA } from 'op-pages/RO/Careplan/TreatmentInfoPage/Queries';
import { LIST_OPTIONS } from 'op-pages/MO/Careplan/Constants';
import MOPatientCarePlanPageRouter from './Router';

const CareplanApollo = (): JSX.Element => {
  const { setError } = useErrorModalContext();
  const match = useRouteMatch<any>();
  const location = useLocation();
  const { values, setFieldValue }: FormikProps<any> = useFormikContext();
  const [createIntake, { called: createIntakeCalled }] = useMutation(CREATE_DIAGNOSIS_INTAKE);
  const { diagnosis, intake, intakePage } = values;
  const { dispatch } = useContext(ROPatientCarePlanContext);
  const { id: patientId, careplanId } = match.params;

  // Below are list of useQuery that cache the query data. This cached data is used in the
  // diagnosis and careplan work flow.
  useQuery(GET_ORDERED_DIAGNOSIS_LIST, {
    variables: { isPrimaryDiagnosis: location.pathname.includes('primary'), patientId },
    skip: careplanId === 'create',
    fetchPolicy: 'cache-and-network',
  });

  useQuery(GET_ICD10_CATEGORY_LIST, {
    variables: { isPrimaryDiagnosis: location.pathname.includes('primary') },
    fetchPolicy: 'cache-and-network',
  });

  useQuery(GET_CLINEX_CODES, {
    variables: { patientId: patientId },
    fetchPolicy: 'cache-and-network',
  });

  useQuery(LOAD_TREATMENT_LIST_DATA, {
    variables: {
      listCategory: [LIST_OPTIONS.INTENT, LIST_OPTIONS.TREATING_DEPARTMENT, LIST_OPTIONS.DOSE_CHANGE_REASON],
      patientId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: dxFromCpData, error: dxFromCpError } = useQuery(GET_DIAGNOSIS_FROM_CAREPLAN, {
    fetchPolicy: 'cache-and-network',
    variables: { careplanId: careplanId },
    skip: !careplanId || careplanId === 'create',
  });

  const splitPathname = location.pathname.split('/');
  const diagnosisIndex = splitPathname.indexOf('diagnosis');
  const diagnosisIdFromPath = diagnosisIndex > 0 ? splitPathname[diagnosisIndex + 1] : null;

  const diagnosisId =
    diagnosisIdFromPath && diagnosisIdFromPath !== 'create'
      ? diagnosisIdFromPath
      : dxFromCpData
      ? dxFromCpData?.careplan?.diagnosis?.id
      : null;

  const { error: diagnosisError } = useQuery(GET_DIAGNOSIS, {
    variables: { diagnosisId },
    fetchPolicy: 'cache-and-network',
    skip: !diagnosisId || diagnosisId === 'create',
    onCompleted: (data: any) => setFieldValue('diagnosis', data?.diagnosis),
  });

  const { error: treatmentInfoError } = useQuery(LOAD_TREATMENTINFO, {
    variables: { id: careplanId },
    skip: careplanId === 'create',
    onCompleted: (data: any) => setFieldValue('treatmentInfo', data?.careplan),
  });

  const { error: dxIntakeError } = useQuery(GET_DIAGNOSIS_INTAKE, {
    variables: { diagnosisId: diagnosis?.id },
    skip: !diagnosis?.id || diagnosis?.id === 'create',
    fetchPolicy: 'cache-and-network',
    onCompleted: (data: any) => setFieldValue('intake', data?.intake),
  });

  const { data: intakeCodeMapping, error: intakeMappingError } = useQuery(GET_INTAKE_CODE_MAPPING, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (
      diagnosis &&
      intake &&
      diagnosis.id &&
      intakePage &&
      // @ts-ignore
      !intake[intakePageMapping[intakePage]?.key] &&
      !createIntakeCalled
    ) {
      // @ts-ignore
      const { createVariable } = intakePageMapping[intakePage];
      createIntake({
        variables: { diagnosisId: diagnosis.id, careplanId: careplanId, intakeData: createVariable },
      }).then((res: any) => {
        setFieldValue('intake', { ...res.data.createIntake.intake, id: diagnosis.id });
      });
    }
  }, [values.intake, values.diagnosis]);

  useEffect(() => {
    if (intakeCodeMapping?.intakeCodeMapping?.length > 0 && diagnosis) {
      const codeToStream = intakeCodeMapping.intakeCodeMapping.reduce((current: any, candidate: any): any => {
        const name = candidate.intake.name;
        const code = candidate.diagnosisCode.diagnosisCode;
        current[code] = name;
        return current;
      }, {});
      dispatch({ type: CarePlanActionType.SET_INTAKE_CODE_MAPPING, payload: { ...codeToStream } });
      const primaryDiagnosis = diagnosis?.isPrimaryDiagnosis ? diagnosis : diagnosis?.relatedPrimaryDiagnosis;
      const intakeNameMapped = codeToIntakePageMapping(
        primaryDiagnosis?.diagnosisCode ? primaryDiagnosis?.diagnosisCode?.diagnosisCode : '',
        {
          ...codeToStream,
        },
      );
      setFieldValue('intakePage', intakeNameMapped);
    }
  }, [diagnosis, intakeCodeMapping]);

  if (intakeMappingError || dxFromCpError || diagnosisError || treatmentInfoError || dxIntakeError) {
    return <></>;
  }
  return <MOPatientCarePlanPageRouter />;
};

export default CareplanApollo;
