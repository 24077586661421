// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';

import withDistressThermometer, { WithDistressThermometer } from '../../DistressThermometer';

import DTQuestionOtherProblems from './DTQuestionOtherProblems';

import { DTOtherProblemsItem } from 'op-classes';

import { Logger } from 'shared-components/utils';
import { LoadingSpinner } from 'shared-components/components';
import { useErrorModalContext } from 'op-contexts';

const logger = new Logger('DTQuestionOtherProblemsApollo');

interface DTQuestionOtherProblemsQueryData {
  distressThermometer: DTOtherProblemsItem;
}

interface Props
  extends WithApolloClient<{}>,
    WithDistressThermometer,
    RouteComponentProps<{ patientId: string; distressId: string }> {}

class DTQuestionOtherProblemsApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId, distressId },
      },
    } = this.props;

    return (
      <Fragment>
        <Query<DTQuestionOtherProblemsQueryData>
          query={this.createQueryGQL()}
          variables={{ patientId, id: distressId }}>
          {({ loading, data }): JSX.Element => {
            if (loading) return <LoadingSpinner relativeSpinner={true} />;

            if (data && data.distressThermometer) {
              return (
                <DTQuestionOtherProblems
                  autosave={this.autosave}
                  distressId={data.distressThermometer.id}
                  data={data.distressThermometer}
                  patientId={patientId}
                />
              );
            }

            return <div></div>;
          }}
        </Query>
      </Fragment>
    );
  }
  /**
   * This function will generate the graph ql query that is required for fetching the question data that will be displayed to the patient.
   * This is used instead of static gql export is because the query will need to include various variables for caching purposes.
   * @returns {any} The graph ql tag that will be used to fetch the data.
   */
  //@ts-ignore
  private createQueryGQL = (): any => {
    const gqlString = `query DTQuestions($patientId: ID!, $id: ID!) {
      distressThermometer(patientId: $patientId, id: $id) {
        id
        otherProblems
      }
    }`;

    logger.debug('createQueryGQL', 'The query string is: ', gqlString);

    return gql(gqlString);
  };

  private autosave = (updateObject: object): void => {
    const { client, getDTMutation } = this.props;

    // Call the client to mutate the distress thermometer
    //@ts-ignore
    client?.mutate(getDTMutation(updateObject)).catch((): void => {
      logger.debug('autosave', '**** FAILED TO SAVE ****', JSON.stringify(updateObject));
    });
  };
}

const apolloComponent = withApollo<Props>(DTQuestionOtherProblemsApollo);
const routedComponent = withRouter(apolloComponent);
const dtComponent = withDistressThermometer(routedComponent);
export default dtComponent;
