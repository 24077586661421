// eslint-disable-next-line no-use-before-define
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Component, Fragment } from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { BASE_REGISTRATION_PAGES } from 'op-enums';
import { OPUser, Patient, UKPatientAltContact } from 'op-interfaces';
import { SavingStatus } from 'shared-components/enums';
import { ListData } from 'shared-components/interfaces';

import { ExtendLock } from 'op-components';
import { LoadingSpinner } from 'shared-components/components';
import withRegistrationForm, { GET_APOLLO_CACHE, WithRegistrationForm } from '../RegistrationForm';
import UKRegistrationAltContact from './UKRegistrationAltContact';

const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    patient(id: $id) {
      id
      lock {
        lockedBy
        readOnly
        lockedByName
      }
      address {
        id
        ukFormattedAddress
      }
      firstName
      lastName
      ida
      idb
      gender
      dob
      isProd
      emergencyContact {
        id
        firstName
        lastName
        relationship
        mobilePhoneNumber
        homePhoneNumber
        email
      }
      nextOfKinContact {
        id
        firstName
        lastName
        relationship
        mobilePhoneNumber
        homePhoneNumber
        email
      }
      sameAsEmergency
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    relationships: listData(category: "relationships") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
`;

interface State {
  saveStatus: SavingStatus;
  pageViewed: boolean;
}

interface PatientAndUserDetailsQueryData {
  patient: UKPatientAltContact;
  genderRefData: ListData[];
  relationships: ListData[];
  user: OPUser;
}

interface Props extends WithApolloClient<{}>, WithRegistrationForm, RouteComponentProps<{ patientId?: string }> {}

class UKRegistrationAltContactApollo extends Component<Props, State> {
  private userIsPSO = false;

  public constructor(props: Props) {
    super(props);
    this.state = { saveStatus: SavingStatus.SAVED, pageViewed: false };
  }

  public componentDidMount(): void {
    const { client } = this.props;
    let viewedPages: string[] = [];

    // Get the apollo cache values
    this.getApolloCache().then((apolloCache): void => {
      const currentPendingSaveCount = apolloCache.currentPendingSaveCount;
      const saveErrorCount = apolloCache.saveErrorCount;
      const registrationPagesViewed = apolloCache.registrationPagesViewed;
      viewedPages = registrationPagesViewed;
      this.setState({
        saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount),
        pageViewed: registrationPagesViewed.includes(BASE_REGISTRATION_PAGES.ALT_CONTACT),
      });

      if (!viewedPages.includes(BASE_REGISTRATION_PAGES.ALT_CONTACT)) {
        viewedPages = [...viewedPages, BASE_REGISTRATION_PAGES.ALT_CONTACT];
      }

      client &&
        client.writeQuery({
          query: gql`
            query {
              registrationPagesViewed
            }
          `,
          data: {
            registrationPagesViewed: viewedPages,
          },
        });
    });
  }
  public render(): JSX.Element {
    const { match, showModalIfLocked } = this.props;
    const { patientId } = match.params;

    return (
      <Query<PatientAndUserDetailsQueryData>
        query={PATIENT_AND_USER_DETAILS_QUERY}
        variables={{ id: patientId }}
        onCompleted={(data: { patient: Patient }): void => {
          showModalIfLocked(data);
        }}>
        {({ loading, data }): JSX.Element => {
          if (loading) return <LoadingSpinner />;

          if (data && data.patient && data.relationships) {
            this.userIsPSO = data.user.isPso || false;

            return (
              <Fragment>
                <ExtendLock accessPatientId={data.patient.id} />
                <UKRegistrationAltContact
                  altContactInfo={data.patient}
                  autosave={this.autosave}
                  genderRefData={data.genderRefData}
                  relationshipsReferenceData={data.relationships}
                  saveStatus={this.state.saveStatus}
                  validateOnLoad={this.state.pageViewed}
                  isPso={this.userIsPSO}
                />
              </Fragment>
            );
          }

          return <></>;
        }}
      </Query>
    );
  }

  /**
   * Autosave a field and it's values to the database. The type is hardcoded to string since there will not be different types that are being written.
   * @param patient
   * @param key
   * @param value
   */
  private autosave = async (
    UKPatientAltContact: UKPatientAltContact,
    updateObject: object,
    forceFetch?: object[],
  ): Promise<void> => {
    const client = this.props.client;

    // Get the pending save count and increment it by 1
    const apolloCache = await this.getApolloCache();
    let currentPendingSaveCount = apolloCache.currentPendingSaveCount + 1;
    let saveErrorCount = apolloCache.saveErrorCount;

    client &&
      client.writeQuery({
        query: gql`
          query {
            pendingSaveCount
          }
        `,
        data: {
          pendingSaveCount: currentPendingSaveCount,
        },
      });
    this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });

    // Save the updated data. Once done deincrement the pending save count. If any erros saving increment the saveErrorCount
    client
      //@ts-ignore
      ?.mutate(this.props.getPatientMutation(UKPatientAltContact, [], forceFetch, true, updateObject))
      .then((result): void => {
        if (result.data.updatePatient.errors) {
          saveErrorCount++;
        }
      })
      .catch((): void => {
        this.props.showSavingErrorModal(this.userIsPSO, this.props.history.push);
        saveErrorCount++;
      })
      .finally(async (): Promise<void> => {
        // Reload the save count incase it was set elsewhere during the run of this function
        const apolloCache = await this.getApolloCache();
        currentPendingSaveCount = apolloCache.currentPendingSaveCount - 1;
        client &&
          client.writeQuery({
            query: gql`
              query {
                pendingSaveCount
                saveErrorCount
              }
            `,
            data: {
              pendingSaveCount: currentPendingSaveCount,
              saveErrorCount: saveErrorCount,
            },
          });
        this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });
      });
  };

  private getApolloCache = async (): Promise<{
    currentPendingSaveCount: number;
    saveErrorCount: number;
    registrationPagesViewed: string[];
  }> => {
    const { client } = this.props;
    try {
      const apolloCache = await client?.query({ query: GET_APOLLO_CACHE });
      const currentPendingSaveCount = apolloCache?.data.pendingSaveCount;
      const saveErrorCount = apolloCache?.data.saveErrorCount;
      const registrationPagesViewed = apolloCache?.data.registrationPagesViewed;
      return {
        currentPendingSaveCount: currentPendingSaveCount,
        saveErrorCount: saveErrorCount,
        registrationPagesViewed: registrationPagesViewed,
      };
    } catch (error) {
      throw error;
    }
  };
}

const apolloComponent = withApollo<Props>(UKRegistrationAltContactApollo);
const component = withRegistrationForm(apolloComponent);
export default component;
