import { useContext, useState, useEffect } from 'react';
import OverviewCard from './OverviewCard';
import { DataFunctions } from '../Utils/dataFunctions';
import DashboardHeader from './DashboardHeader';
import { LoadingSpinner } from 'shared-components/components';
import { DashboardContext } from '../DashboardContext';
import { Stack, useMediaQuery } from '@mui/material';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

const DashboardOverview = (props: { careplansLoading: boolean; appointmentsLoading: boolean }) => {
  const { careplansLoading, appointmentsLoading } = props;

  const { isLoading } = useContext(DashboardContext);

  const [loadedImages, setLoadedImages] = useState<{ [key: string]: string }>({});
  const images = import.meta.glob('../Assets/*.{png,jpg,jpeg,svg}');
  const isSmallScreen = useMediaQuery('(max-width:1600px)');

  useEffect(() => {
    const loadImages = async () => {
      const imgMap: { [key: string]: string } = {};
      for (const path in images) {
        const module: any = await images[path]();
        imgMap[path.replace('../Assets/', '')] = module.default;
      }
      setLoadedImages(imgMap);
    };

    loadImages();
  }, []);

  return (
    <Stack alignItems="center" height={1}>
      <DashboardHeader title="Insights Dashboard" />
      {isLoading ? (
        <LoadingSpinner loadingText={''} />
      ) : (
        <Stack direction={isSmallScreen ? 'row' : 'column'} flex={1} paddingBottom="80px">
          <Stack direction={isSmallScreen ? 'column' : 'row'} justifyContent="left" flex={1} maxHeight="272px">
            <OverviewCard
              title="Total Consultations"
              aggregateFunction={DataFunctions.aggregateAverageFractions}
              metricFunction={DataFunctions.calcAverageFractions}
              page="consultations"
              enabled={CurrentAppConfig.Insights?.Dashboards.totalConsultations}
              description="Track the volume of appointments, initial consults, and follow-ups to optimize scheduling"
              modalImage={loadedImages['total_consultations.png']}
              loading={careplansLoading}
            />
            <OverviewCard
              title="Careplan Adherence"
              aggregateFunction={DataFunctions.aggregateAdherencePercentage}
              metricFunction={DataFunctions.calcAdherencePercentage}
              page="carepathway"
              metricSuffix="%"
              enabled={CurrentAppConfig.Insights?.Dashboards.careplanAdherence}
              description="Identify gaps in careplan adherence to enhance patient safety and standardize quality care"
              modalImage={loadedImages['adherence.png']}
              loading={careplansLoading}
            />
            <OverviewCard
              title="Total Referrals"
              aggregateFunction={DataFunctions.aggregateTotalReferrals}
              metricFunction={DataFunctions.calcTotalReferrals}
              page="referrers"
              enabled={CurrentAppConfig.Insights?.Dashboards.totalReferrals}
              description="Track referral trends to align resources with patient demand + identify high-referral sources"
              modalImage={loadedImages['referrers.png']}
              loading={careplansLoading}
            />
            <OverviewCard
              title="Average Daily Treatments per Linac"
              aggregateFunction={DataFunctions.aggregateAverageDailyTreatmentsPerLinac}
              metricFunction={DataFunctions.calcAverageDailyTreatmentsPerLinac}
              page="machines"
              metricRound={1}
              enabled={CurrentAppConfig.Insights?.Dashboards.linacs}
              description="Monitor machine usage to optimize Linac scheduling and minimize patient wait times"
              modalImage={loadedImages['linacs.png']}
              dataType="AppointmentWithPractitionersType"
              appointmentType="treat"
              loading={appointmentsLoading}
            />
          </Stack>
          <Stack direction={isSmallScreen ? 'column' : 'row'} justifyContent="left" flex={1} maxHeight="272px">
            <OverviewCard
              title="Total Simulations"
              aggregateFunction={DataFunctions.aggregateTotalSimulations}
              metricFunction={DataFunctions.calcTotalSimAppointments}
              page="simulations"
              enabled={CurrentAppConfig.Insights?.Dashboards.totalSimulations}
              description="Track number of simulations to ensure adequate treatment prep + efficient resource allocation"
              dataType="AppointmentWithPractitionersType"
              appointmentType="sim"
              loading={appointmentsLoading}
            />
            <OverviewCard
              title="IMRT/VMAT/Stereotactic Plans"
              aggregateFunction={DataFunctions.aggregateTechniquePercentage}
              metricFunction={DataFunctions.calcComplexTechnique}
              page="techniques"
              metricSuffix="%"
              enabled={CurrentAppConfig.Insights?.Dashboards.techniques}
              description="Understand the frequency of complex techniques to streamline reporting and resource allocation"
              loading={careplansLoading}
            />
            <OverviewCard
              type="donut"
              title="Total Diagnoses"
              aggregateFunction={DataFunctions.aggregateData}
              metricFunction={DataFunctions.calcTotalDiagnosis}
              page="diagnosis"
              enabled={CurrentAppConfig.Insights?.Dashboards.totalDiagnosis}
              description="Gain insights into the most common diagnoses to better tailor treatment programs and plan resource allocation"
              loading={careplansLoading}
            />
            <OverviewCard
              title="Average Sim to Treat Time (Days)"
              aggregateFunction={DataFunctions.aggregateAverageSimToTreat}
              metricFunction={DataFunctions.calcAverageSimToTreat}
              page="wait-times"
              metricRound={1}
              enabled={CurrentAppConfig.Insights?.Dashboards.simToTreatment}
              description="Reduce wait times + improve patient experience by monitoring the time between SIM & treatment"
              modalImage={loadedImages['sim_to_treat.png']}
              dataType="AppointmentWithPractitionersType"
              loading={appointmentsLoading}
            />
          </Stack>
          <Stack direction={isSmallScreen ? 'column' : 'row'} justifyContent="left" flex={1} maxHeight="272px">
            <OverviewCard
              title="Total Treatments (Fractions)"
              aggregateFunction={DataFunctions.aggregateTotalTreatments}
              metricFunction={DataFunctions.calcTotalTreatmentAppointments}
              page="treatments"
              enabled={CurrentAppConfig.Insights?.Dashboards.totalTreatments}
              description="Ensure prescription accuracy and treatment consistency by analyzing trends in fractions"
              modalImage={loadedImages['total_treatments.png']}
              dataType="AppointmentWithPractitionersType"
              appointmentType="treat"
              loading={appointmentsLoading}
            />
            <OverviewCard
              title="Average Fractions"
              aggregateFunction={DataFunctions.aggregateAverageFractions}
              metricFunction={DataFunctions.calcAverageFractions}
              page="fractions"
              metricRound={1}
              enabled={CurrentAppConfig.Insights?.Dashboards.averageFractions}
              description="Evaluate the average number of fractions delivered to optimize treatment planning"
              loading={careplansLoading}
            />
            <OverviewCard
              title="Total Plans"
              aggregateFunction={DataFunctions.aggregateTotalPlans}
              metricFunction={DataFunctions.calcTotalPlans}
              page="activity"
              enabled={CurrentAppConfig.Insights?.Dashboards.totalPlans}
              description="Monitor the volume of treatment plans generated by physicians to evaluate treatment demand"
              modalImage={loadedImages['total_plans.png']}
              loading={careplansLoading}
            />
            <OverviewCard
              title="Average Daily Plans"
              aggregateFunction={DataFunctions.aggregateAverageDailyRecords}
              metricFunction={DataFunctions.calcAverageDailyPlans}
              page="productivity"
              metricRound={1}
              enabled={CurrentAppConfig.Insights?.Dashboards.averageDailyPlans}
              description="Evaluate the planning team's efficiency + track changes in volumes for workload adjustments"
              modalImage={loadedImages['daily_plans.png']}
              loading={careplansLoading}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default DashboardOverview;
