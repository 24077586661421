// eslint-disable-next-line no-use-before-define
import React, { Component, useEffect } from 'react';
import validate from 'validate.js';
import { gql } from '@apollo/client';

import { Query } from '@apollo/client/react/components';

import './UKRegistrationGP.scss';

import { UKPatientGP } from 'op-interfaces';
import { ListData } from 'shared-components/interfaces';
import { SavingStatus } from 'shared-components/enums';

import { RegistrationContainer } from 'op-components';
import { DropDownField, SectionField, AsyncAutocomplete, ReferralField } from 'shared-components/components/FormFields';
import { DocumentNode } from 'graphql';
import { styled } from '@mui/system';
const GP_OPTIONS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    choices: practitioners(url: "https://genesiscare.com/fhir/general-practice-surgery", patient: $id) {
      id
      name
    }
  }
`;

const gpHelpString = 'Select the GP surgery to filter the available GP names';

interface GpOptionsQueryData {
  choices: ListData[];
}

// Constants
const PLACEHOLDERS = {
  GP_SURGERY: 'Type to search',
  GP_NAME: '',
  SURGEON_LOCATION: 'Type to search',
  SURGEON_NAME: 'Type to search',
  ONCOLOGIST: 'Required',
};

const HEADINGS = {
  GP_SURGERY: 'GP surgery',
  GP_NAME: 'General practitioner name',
  SURGEON_LOCATION: 'Referring facility',
  SURGEON_NAME: 'Referring clinician name',
  ONCOLOGIST: 'Responsible clinician',
  PENDING_APPT: 'Pending Appointment',
};

const FIELD_NAMES: { [key: string]: string } = {
  GP_SURGERY: 'generalPractitionerLocation',
  GP_NAME: 'generalPractitioner',
  SURGEON_LOCATION: 'referringSurgeonLocation',
  SURGEON_NAME: 'referringSurgeon',
  ONCOLOGIST: 'oncologist',
  PENDING_APPT: 'pendingAppt',
};

const FORM_HEADING = 'Referring clinicians';

interface Props {
  gpInfo: UKPatientGP;
  gpRefData: ListData[];
  surgeonRefData: ListData[];
  oncologistRefData: ListData[];
  genderRefData: ListData[];
  autosave: (patient: UKPatientGP, key: string, value: string | boolean) => void;
  saveStatus: SavingStatus;
  validateOnLoad: boolean;
  isPso: boolean;
  gpList?: any;
  outerCallbackHandler?: any;
}

interface ChildProps {
  gpInfo: UKPatientGP;
  autosave: (patient: UKPatientGP, key: string, value: string | boolean) => void;
  query: DocumentNode;
  inputName: string;
  placeholder: string;
  errors: string[];
  initial: string;
  ref: any;
}
const StyledHelperDiv = styled('div')`
  margin-bottom: 8px;
  font-size: 14px;
  width: 100%;
  color: ${(props) => props.theme.palette.grey[600]};
`;

interface State {
  viewed: Set<string>;
}

class UpdatableDropDown extends Component<ChildProps, State> {
  private cleanInput(selected: string, options: ListData[]): string {
    if (options && options.length > 0) {
      // @ts-ignore
      const ids = options.map((opt) => opt.id);
      if (ids.indexOf(selected) > -1) {
        return selected;
      }
    }

    return '';
  }
  public render(): JSX.Element {
    const { gpInfo, autosave, errors, initial, query, inputName, placeholder } = this.props;
    return (
      <Query<GpOptionsQueryData> query={query} variables={{ id: gpInfo.id }}>
        {({ data, refetch }): JSX.Element => {
          // @ts-ignore
          this.refetch = refetch;

          if (data && data.choices) {
            return (
              <div style={{ width: '100%' }}>
                <DropDownField
                  disabled={gpInfo.lock && gpInfo.lock.readOnly}
                  inputName={inputName}
                  placeholder={placeholder}
                  defaultValue={this.cleanInput(initial, data && data.choices ? data.choices : [])}
                  options={data && data.choices ? data.choices : []}
                  onChange={async (e): Promise<void> => autosave(gpInfo, inputName, e.target.value)}
                  errors={errors}
                />
              </div>
            );
          }
          return <div>loading</div>;
        }}
      </Query>
    );
  }
}

class UKRegistrationGP extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      viewed: new Set(),
    };
  }

  public static defaultProps = {
    gpList: React.createRef(),
  };

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad && props.gpInfo.lock && !props.gpInfo.lock.readOnly) {
      // Add all the field's to viewed - this will trigger validation on the whole form
      const fields = Object.keys(FIELD_NAMES).map((keyName: string) => {
        return FIELD_NAMES[keyName];
      });
      const viewed = new Set(fields);
      return { viewed: viewed };
    }

    return state;
  }

  public render(): JSX.Element {
    const { isPso, saveStatus, gpInfo, genderRefData } = this.props;
    return (
      <RegistrationContainer patient={gpInfo} isPso={isPso} saveStatus={saveStatus} genderRefData={genderRefData}>
        {this.renderContents()}
      </RegistrationContainer>
    );
  }
  private renderContents = (): JSX.Element => {
    const { gpInfo, gpList, outerCallbackHandler } = this.props;

    const validationObject = this.validateObject(gpInfo);

    // @ts-ignore
    return (
      <div className="form-page">
        <form className="form-container">
          <div className="form-heading gp-form-heading">{FORM_HEADING}</div>
          <SectionField
            isValid={validationObject && validationObject.generalPractitionerLocation ? false : true}
            htmlFor={FIELD_NAMES.GP_SURGERY}
            title={HEADINGS.GP_SURGERY}>
            <StyledHelperDiv>{gpHelpString}</StyledHelperDiv>
            <AsyncAutocomplete
              disabled={gpInfo.lock && gpInfo.lock.readOnly}
              inputName={FIELD_NAMES.GP_SURGERY}
              placeholder={PLACEHOLDERS.GP_SURGERY}
              fullName={true}
              patient={gpInfo.id}
              grapheneField={'practitionerLocations'}
              onBlur={(value): void => {
                this.autosave(gpInfo, FIELD_NAMES.GP_SURGERY, value);
              }}
              defaultValue={gpInfo.generalPractitionerLocation}
              errors={
                validationObject && validationObject.generalPractitionerLocation
                  ? validationObject.generalPractitionerLocation
                  : undefined
              }
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.generalPractitioner ? false : true}
            htmlFor={FIELD_NAMES.GP_NAME}
            title={HEADINGS.GP_NAME}>
            <UpdatableDropDown
              inputName={FIELD_NAMES.GP_NAME}
              placeholder={PLACEHOLDERS.GP_NAME}
              initial={gpInfo.generalPractitioner}
              gpInfo={gpInfo}
              query={GP_OPTIONS_QUERY}
              autosave={this.autosave}
              errors={
                validationObject && validationObject.generalPractitioner
                  ? validationObject.generalPractitioner
                  : undefined
              }
              ref={gpList}
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.referringSurgeon ? false : true}
            htmlFor={FIELD_NAMES.SURGEON_NAME}
            title={HEADINGS.SURGEON_NAME}>
            <AsyncAutocomplete
              disabled={gpInfo.lock && gpInfo.lock.readOnly}
              inputName={FIELD_NAMES.SURGEON_NAME}
              placeholder={PLACEHOLDERS.SURGEON_NAME}
              grapheneField={'extPractitioners'}
              fullName={false}
              patient={gpInfo.id}
              onBlur={(value): void => {
                this.autosave(gpInfo, FIELD_NAMES.SURGEON_NAME, value);
              }}
              defaultValue={gpInfo.referringSurgeon}
              errors={
                validationObject && validationObject.referringSurgeon ? validationObject.referringSurgeon : undefined
              }
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.referringSurgeonLocation ? false : true}
            htmlFor={FIELD_NAMES.SURGEON_LOCATION}
            title={HEADINGS.SURGEON_LOCATION}>
            <AsyncAutocomplete
              disabled={gpInfo.lock && gpInfo.lock.readOnly}
              inputName={FIELD_NAMES.SURGEON_LOCATION}
              placeholder={PLACEHOLDERS.SURGEON_LOCATION}
              grapheneField={'practitionerLocations'}
              fullName={true}
              patient={gpInfo.id}
              onBlur={(value): void => {
                this.autosave(gpInfo, FIELD_NAMES.SURGEON_LOCATION, value);
              }}
              defaultValue={gpInfo.referringSurgeonLocation}
              errors={
                validationObject && validationObject.referringSurgeonLocation
                  ? validationObject.referringSurgeonLocation
                  : undefined
              }
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.oncologist ? false : true}
            htmlFor={FIELD_NAMES.ONCOLOGIST}
            title={HEADINGS.ONCOLOGIST}>
            <AsyncAutocomplete
              inputName={FIELD_NAMES.ONCOLOGIST}
              placeholder={PLACEHOLDERS.ONCOLOGIST}
              grapheneField={'oncologists'}
              fullName={false}
              patient={gpInfo.id}
              onBlur={(value): void => {
                this.autosave(gpInfo, FIELD_NAMES.ONCOLOGIST, value);
              }}
              defaultValue={gpInfo.oncologist}
              errors={validationObject && validationObject.oncologist ? validationObject.oncologist : undefined}
            />
          </SectionField>
          <ReferralField patient={gpInfo} callbackHandler={outerCallbackHandler} />
        </form>
      </div>
    );
  };

  /**
   * Autosave functionality that will call the props auto save function.
   */
  private autosave = async (patient: UKPatientGP, key: string, value: string | boolean): Promise<void> => {
    let viewed = this.state.viewed.add(key);
    const { gpList } = this.props;

    await this.props.autosave(patient, key, value);

    if (key === 'generalPractitionerLocation') {
      if (!!value) viewed = viewed.add('generalPractitioner');
      setTimeout(function () {
        if (gpList.current) {
          gpList.current.refetch();
        }
        // @ts-ignore
      }, 500);
    }

    await this.setState({ viewed });
  };

  private validateObject = (patient: UKPatientGP): any => {
    // Global rules for the validator, must match the patient interface keys
    const globalValidationRules: { [key: string]: object } = {
      generalPractitionerLocation: {
        presence: {
          allowEmpty: true,
        },
      },
      generalPractitioner: {
        presence: {
          allowEmpty: !patient.generalPractitionerLocation,
          message: 'Please select a practitioner',
        },
      },
      referringSurgeonLocation: {
        presence: {
          allowEmpty: true,
        },
      },
      referringSurgeon: {
        presence: {
          allowEmpty: true,
          message: 'Please select a surgeon',
        },
      },
      oncologist: {
        presence: {
          allowEmpty: false,
          message: 'Please select oncologist',
        },
      },
    };

    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    for (const viewed of this.state.viewed.keys()) {
      specificValidationRules[viewed] = globalValidationRules[viewed];
    }

    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };
    // Run validation on all the fields
    return validate(patient, specificValidationRules, disableFullMessages);
  };
}
// @ts-ignore
export default UKRegistrationGP;
