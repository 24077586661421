import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartColumn from '../Charts/ChartColumn';
import SubChartBar from '../Charts/SubCharts/SubChartBar';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import { DataFunctions } from '../../Utils/dataFunctions';

const PlanningProductivity = ({
  data,
  subChartData,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  ...rest
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight, dateRange),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartColumn({
      selectedPoint: rest.filterOverride,
      leftSeriesTitle: 'Average daily plans',
      rightSeriesTitle: 'Automation rate',
      leftSeriesFields: ['Date', 'Plans'],
      rightSeriesFields: ['Date', 'Automation Rate'],
      aggregation: dateAggregationMap[dateRange],
      dateRange: dateRange,
      rightSeriesEnabled: true,
      ...rest,
    }).components?.[0] ?? {},
    SubChartBar({
      seeMore: seeMoreBottomLeft,
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      dataTotal: DataFunctions.aggregateDataSum(subChartData, 'tumourStream'),
      yAxisMax: DataFunctions.aggregateDataMax(subChartData, 'tumourStream') * 2,
      dataLength: DataFunctions.aggregateData(subChartData, 'tumourStream').length - 1,
      ...rest,
    }).components?.[0] ?? {},
    SubChartBar({
      seeMore: seeMoreBottomRight,
      title: 'Techniques',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      dataTotal: DataFunctions.aggregateDataSum(subChartData, 'technique'),
      yAxisMax: DataFunctions.aggregateDataMax(subChartData, 'technique') * 2,
      dataLength: DataFunctions.aggregateData(subChartData, 'technique').length - 1,
      ...rest,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
  dateRange: string | undefined,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['Date', 'Plans', 'Automation Rate'],
        data: DataFunctions.aggregateAverageDailyRecords(data, dateAggregationMap[dateRange!]),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(
          subChartData,
          'tumourStream',
          seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT,
        ),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(subChartData, 'technique', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default PlanningProductivity;
