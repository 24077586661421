// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';

import withCovidScreening, { WithCovidScreening } from '../CovidScreening';

import CovidAssessment from './CovidAssessment';

import { Logger } from 'shared-components/utils';
import { LoadingSpinner } from 'shared-components/components';
import { CovidAssessmentItem } from 'op-classes';
import { useErrorModalContext } from 'op-contexts';

const logger = new Logger('CovidAssessmentApollo');

const COVID_ASSESSMENT_QUERY = gql`
  query CovidScreening($id: ID!) {
    covidScreening(id: $id) {
      id
      hasSymptoms
      dateOfFeverSymptoms
      hasBeenDiagnosed
      hasBeenTested
      advisedToQuarantine
      hadContact
      travelledToHotspot
    }
  }
`;

interface CovidAssessmentQueryData {
  covidScreening: {
    id: string;
  };
}

interface Props extends WithApolloClient<{}>, WithCovidScreening, RouteComponentProps<{ patientId: string }> {}

class CovidAssessmentApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
    } = this.props;

    return (
      <Fragment>
        <Query<CovidAssessmentQueryData> query={COVID_ASSESSMENT_QUERY} variables={{ id: patientId }}>
          {({ loading, data }): JSX.Element => {
            if (loading) {
              return <LoadingSpinner relativeSpinner={true} />;
            }

            if (data && data.covidScreening) {
              const covidScreening = new CovidAssessmentItem();
              covidScreening.loadData(data.covidScreening);

              return (
                <CovidAssessment
                  autosave={this.autosave}
                  patientId={patientId}
                  covidScreening={covidScreening}
                  validateOnLoad={false}
                />
              );
            }
            return <div>Error!</div>;
          }}
        </Query>
      </Fragment>
    );
  }

  private autosave = (updateObject: object, replaceId?: string): void => {
    const { client, getCovidMutation } = this.props;

    // Call the client to mutate the distress thermometer
    //@ts-ignore
    client?.mutate(getCovidMutation(updateObject, replaceId)).catch(() => {
      logger.debug('autosave', '**** FAILED TO SAVE ****', JSON.stringify(updateObject));
    });
  };
}

const apolloComponent = withApollo<Props>(CovidAssessmentApollo);
const routedComponent = withRouter(apolloComponent);
const covidComponent = withCovidScreening(routedComponent);
export default covidComponent;
