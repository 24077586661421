import { useContext } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DashboardContext } from '../../../DashboardContext';
import { findParentById, groupSmallSlicesIntoOther } from '../../../Utils/utils';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const OverviewChartDonut = (props: { aggregatedData: any[] }) => {
  const theme = useTheme();

  const { aggregatedData } = props;
  const { overviewChartsLoaded, isReloaded, setOverviewChartsLoaded } = useContext(DashboardContext);

  const sortOrder = [
    'Breast',
    'Urogenital',
    'Metastasis',
    'Lung',
    'H&N',
    'Brain',
    'GI',
    'Gynae',
    'Skin',
    'Sarcoma',
    'Haematological',
    'Misc',
    'Unspecified',
  ];

  const existingKeys = new Set(aggregatedData.map((item) => item[0]));

  sortOrder.forEach((key) => {
    if (!existingKeys.has(key)) {
      aggregatedData.push([key, 0]);
    }
  });

  const seriesColorMapping: { [key: string]: string } = {
    Breast: 'rgba(170, 161, 253)',
    Metastasis: 'rgba(62, 167, 178)',
    Urogenital: 'rgba(255, 189, 91)',
    GI: 'rgba(251, 106, 118)',
    Brain: 'rgba(128, 0, 128)',
    Skin: 'rgba(89, 39, 218)',
    'H&N': 'rgba(0, 128, 128)',
    Lung: 'rgba(255, 99, 132)',
    Gynae: 'rgba(255, 159, 64)',
    Sarcoma: 'rgba(255, 205, 86)',
    Haematological: 'rgba(75, 192, 192)',
    Misc: 'rgba(54, 162, 235)',
    Benign: 'rgba(153, 102, 255)',
    Other: 'rgba(200, 200, 200)',
  };

  const sortedData = aggregatedData
    .sort((a, b) => {
      const indexA = sortOrder.indexOf(a[0]);
      const indexB = sortOrder.indexOf(b[0]);

      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    })
    .map((item) => ({
      name: item[0],
      y: item[1],
      colorIndex: 999,
      color: seriesColorMapping[item[0]] || 'rgba(158, 158, 158)',
    }));

  const chartData = groupSmallSlicesIntoOther(sortedData);

  return (
    <Stack height={1} className="overview-card-chart">
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { height: '100%' } }}
        options={{
          chart: {
            type: 'pie',
            backgroundColor: theme.palette.background.paper,
            spacing: [-10, 0, -5, -20],
            styledMode: false,
            events: {
              // hacking the chart height to fit the container, because highcharts doesnt play well with flexbox
              load: function (this: Highcharts.Chart) {
                setOverviewChartsLoaded(true);
                const currentElement = findParentById(this.container, 'overview-chart-container');
                const parentSize = currentElement?.getBoundingClientRect().height;

                // for some reason chart heights are different when the charts are first loaded, and also when the page is reloaded
                const offset = overviewChartsLoaded || !isReloaded ? 110 : 130;

                if (parentSize) {
                  this.setSize(undefined, Number((parentSize - offset).toFixed(0)));
                  sessionStorage.removeItem('reloaded');
                }
              },
            },
          },
          exporting: {
            enabled: false,
          },
          title: {
            text: '',
          },
          legend: {
            enabled: true,
            navigation: {
              enabled: false,
            },
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'right',
            itemStyle: {
              fontSize: '10px',
              width: '85px',
            },
            width: 140,
            itemWidth: 80,
            margin: 0,
            padding: 0,
            itemMarginTop: 2,
            itemMarginBottom: 0,
            x: 40,
            y: 30,
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false,
              },
              showInLegend: true,
            },
            series: {
              innerSize: '60%',
              marker: {
                enabled: false,
              },
              states: {
                hover: {
                  enabled: false,
                },
              },
              enableMouseTracking: false,
            },
          },
          series: [
            {
              data: chartData,
            },
          ],
        }}
      />
    </Stack>
  );
};

export default OverviewChartDonut;
