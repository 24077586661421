import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartColumn from '../Charts/ChartColumn';
import SubChartBar from '../Charts/SubCharts/SubChartBar';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import { DataFunctions } from '../../Utils/dataFunctions';

const TotalTreatments = ({
  data,
  subChartData,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  ...rest
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight, dateRange),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartColumn({
      selectedPoint: rest.filterOverride,
      aggregation: dateAggregationMap[dateRange],
      dateRange: dateRange,
      leftSeriesTitle: 'Treatments',
      rightSeriesTitle: '',
      leftSeriesFields: ['submittedAt', 'Treatments'],
      rightSeriesFields: ['submittedAt', 'Target'],
      tooltipSuffix: 'fractions',
      ...rest,
    }).components?.[0] ?? {},
    SubChartBar({
      seeMore: seeMoreBottomLeft,
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-left',
      yAxisMax: DataFunctions.aggregateDataMax(subChartData, 'createdBy') * 2,
      dataLength: DataFunctions.aggregateData(subChartData, 'createdBy').length - 1,
      dataTotal: DataFunctions.aggregateDataSum(subChartData, 'createdBy'),
      ...rest,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
  dateRange: string | undefined,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['submittedAt', 'Treatments', 'Target'],
        data: DataFunctions.aggregateTotalTreatments(data, dateAggregationMap[dateRange!]),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(
          subChartData,
          'tumourStream',
          seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT,
        ),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(subChartData, 'createdBy', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default TotalTreatments;
