import React, { useState, useEffect, useRef, useContext } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { styled } from '@mui/system';
import useInterval from 'shared-components/utils/useInterval';
import ROPatientProfilePopup from './ROPatientProfilePopup';
import { DOCTOR_APPOINTMENT_QUERY } from './RODailyAppointmentQueries';
import { DateTimeWidget } from './DateTimeWidget';
import { UserContext } from 'op-contexts';
import { CurrentAppConfig } from '../../Careplan/AppConfig';
import dayjs from 'dayjs';
import { Tooltip, Stack, IconButton, useTheme, Typography, Card } from '@mui/material';
import { BaseDatePicker } from 'shared-components/components/FormFields';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { AppointmentWithPractitionersType } from './interfaces';

interface StyledTableRowProps {
  readonly $highlight: boolean;
  readonly $highlightTop?: boolean;
  readonly $highlightBottom?: boolean;
  readonly ref: any;
}

const MY_APPOINTMENTS = 'My Appointments';
const ALL_APPOINTMENTS = 'All Appointments';

type AppointmentFilter = typeof MY_APPOINTMENTS | typeof ALL_APPOINTMENTS;

export const StyledTimeAppCol = styled(Stack)`
  display: flex;
  align-items: center;
`;

export const StyledTotalAppointments = styled('div')`
  font-weight: 400;
  background: ${(props) => props.theme.palette.grey[100]};
  border-radius: 128px;
  padding: 2px 16px;
  height: 24px;
  line-height: 24px;
`;

export const StyledTable = styled('table')`
  border-collapse: separate;
  width: 100%;
  & td {
    padding: 7px 12px;
    border: 1px solid lightgray;
    text-align: left;
  }

  & thead {
    & th {
      position: sticky;
      top: 0;
      text-align: left;
      padding: 8px 8px;
      z-index: 1000;
      border: 1px solid lightgray;
      background-color: ${(props) => props.theme.palette.action.selected};
    }
  }
`;

export const StyledTableBody = styled('tbody')`
  color: black;
`;

export const HighlightArrow = styled('div')`
  float: left;
  border-right: solid 7px transparent;
  border-left: solid 7px transparent;
  border-top: solid 7px ${(props) => props.theme.palette.warning.main};
  transform: rotate(-90deg);
  width: 12px;
  height: 12px;
  z-index: 0;
`;

export const StyledTableRow = styled('tr')<StyledTableRowProps>`
  & td:first-child {
    border-left: ${(props) =>
      props.$highlight ? `2px solid ${props.theme.palette.warning.main}` : '1px solid lightgray'};
  }
  & td:last-child {
    border-right: ${(props) =>
      props.$highlight ? `2px solid ${props.theme.palette.warning.main}` : '1px solid lightgray'};
  }
  & td {
    border-top: ${(props) =>
      props.$highlight && props.$highlightTop
        ? `2px solid ${props.theme.palette.warning.main}`
        : '1px solid lightgray'};
    border-bottom: ${(props) =>
      props.$highlight && props.$highlightBottom
        ? `2px solid ${props.theme.palette.warning.main}`
        : '1px solid lightgray'};
    cursor: default;
    background: white;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  top: 12px !important;
  pointer-events: none;

  .arrow {
    border: none !important;
    z-index: 1181;
    overflow: hidden;
    padding: 0;
    height: 32px;
    width: 32px;
    top: -32px;
    transform: none !important;

    &::before {
      height: 16px;
      position: absolute;
      width: 16px;
      background-color: white;
      transform: rotate(45deg);
      box-shadow: 0px 4px 8px rgba(113, 110, 106, 0.5);
      left: 16px;
      bottom: -8px;
    }
  }

  .tooltip-inner {
    background: transparent;
  }
`;

export const StyledPatientName = styled(Link)`
  color: ${(props) => props.theme.palette.primary.dark} !important;
  text-decoration: underline !important;
`;

interface OverlapStyleProps {
  readonly $overlap: boolean;
}

export const OverlappedStyle = styled('td')<OverlapStyleProps>`
  background: ${(props) => (props.$overlap ? props.theme.palette.warning.light : 'white')} !important;
`;

export const TodayButton = styled('div')`
  width: 55px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  margin-right: 16px;
`;

const filterAppointments = (
  appointments: Array<AppointmentWithPractitionersType>,
  appliedfilter: AppointmentFilter,
): Array<AppointmentWithPractitionersType> => {
  return appointments.filter((apptData: AppointmentWithPractitionersType) => {
    if (apptData?.appointment == null) {
      return false;
    }
    if (CurrentAppConfig.RadiationDashboard.showFilters && appliedfilter === MY_APPOINTMENTS) {
      // Check if the logged in user is part of the relatedPractitioners
      // Use the first and last name to match the practitioner, as the User ID is different to the Practitioner ID
      const loggedInPracFirstName = apptData?.practitioner?.firstName;
      const loggedInPracLastName = apptData?.practitioner?.lastName;

      for (let i = 0; i < apptData?.relatedPractitioners?.length; i++) {
        const practitioner = apptData.relatedPractitioners[i].practitioner;
        if (practitioner.firstName === loggedInPracFirstName && practitioner.lastName === loggedInPracLastName) {
          return true;
        }
      }
      return false;
    }
    return true;
  });
};

const prepareDailyScheduleData = (
  startDate: any,
  data: any,
  practitionerTimezone: string,
  appliedfilter: AppointmentFilter,
): any => {
  // Prepare schedule from 6am to 6pm in 15min interval
  const startTime = moment(startDate).tz(practitionerTimezone)?.startOf('date').add(6, 'hours').subtract(15, 'minutes');
  const defaultDailySchedule = [...new Array(48)].map(() => {
    return {
      startTime: startTime?.add(15, 'minutes').clone(),
      duration: 15,
      id: '',
      patient: '',
      activity: '',
      description: '',
      status: '',
      comment: '',
      department: '',
      location: '',
      overlap: false,
      showTime: true,
    };
  });

  if (!data || data.doctorAppointmentsByDateWithPractitioners.length === 0) return defaultDailySchedule;

  const dailySchedule = defaultDailySchedule;
  const filteredAppointments = filterAppointments(data.doctorAppointmentsByDateWithPractitioners, appliedfilter);
  const primedDoctorAppointments = filteredAppointments.map((apptData: any) => {
    return {
      id: apptData.id,
      startTime: moment(apptData.appointment.startTime).tz(practitionerTimezone),
      duration: apptData.appointment.duration ? apptData.appointment.duration / 60 : 0, // seconds to minutes
      patient: apptData.appointment.patient,
      activity: apptData.appointment.activity,
      description: apptData.appointment.description,
      status: apptData.appointment.statusAbbreviation,
      comment: apptData.appointment.comment,
      department: apptData.appointment.department,
      location: apptData.appointment.location,
      overlap: false,
      showTime: true,
    };
  });

  primedDoctorAppointments.forEach((appointment: any) => {
    //find the index in the dailySchedule and replace/insert this appointment
    const index = dailySchedule.findIndex((existingAppointment: any): any => {
      return (
        moment(appointment.startTime).tz(practitionerTimezone) <=
        moment(existingAppointment.startTime).tz(practitionerTimezone)
      );
    });

    if (index === -1) {
      dailySchedule.push(appointment);
      return;
    }
    // if they are same and no appointment exist then replace dummy
    if (appointment.startTime.isSame(dailySchedule[index].startTime)) {
      if (!dailySchedule[index].id) {
        dailySchedule.splice(index, 1, appointment);
      } else {
        appointment.overlap = true;
        dailySchedule[index].overlap = true;
        dailySchedule[index].showTime = false;
        dailySchedule.splice(index, 0, appointment);
      }
    } else {
      if (index !== 0) {
        // appointment doesn't start at the 15' boundary
        dailySchedule[index - 1].duration = moment
          .duration(appointment.startTime.diff(dailySchedule[index - 1].startTime))
          .asMinutes();
      }
      // insert new record
      dailySchedule.splice(index, 0, appointment);
    }
  });
  // update overlapping appointment
  const maxIndex = dailySchedule.length;
  dailySchedule.forEach((appointment: any, index: number) => {
    let innerIndex = index + 1;
    const totalAppointment = appointment.startTime.clone().add(appointment.duration, 'minutes');
    while (innerIndex < maxIndex && totalAppointment > dailySchedule[innerIndex].startTime) {
      if (dailySchedule[innerIndex].id) appointment.overlap = true;
      dailySchedule[innerIndex].overlap = true;
      innerIndex += 1;
    }
  });
  return dailySchedule;
};

const renderTime = (schedule: any, highlight: boolean): JSX.Element => {
  const time = schedule['startTime'];
  const formatString = time.minutes() === 0 ? 'hA' : 'h:mm';

  return (
    <OverlappedStyle data-testid="appointment-time" $overlap={schedule['overlap']}>
      <Stack direction="row">
        {highlight && <HighlightArrow />}
        {schedule['showTime'] && time.format(formatString)}
      </Stack>
    </OverlappedStyle>
  );
};

const renderDuration = (schedule: any): JSX.Element => {
  return (
    <OverlappedStyle $overlap={schedule['overlap']}>
      {schedule['id'] ? `${schedule['duration']}'` : '-'}
    </OverlappedStyle>
  );
};

const renderPatient = (schedule: any): JSX.Element => {
  const id = schedule['patient'] ? schedule['patient']['id'] : 0;
  if (!id) return <td>-</td>;
  return (
    <td>
      <Tooltip
        title={
          <React.Fragment>
            <StyledTooltip id="patient-profile" title="">
              <ROPatientProfilePopup {...schedule['patient']} />
            </StyledTooltip>
          </React.Fragment>
        }
        placement="bottom-start">
        <StyledPatientName to={`/radiation/patient/${id}/summary`}>{schedule['patient']['fullName']}</StyledPatientName>
      </Tooltip>
    </td>
  );
};

const ScheduleTableBody = (props: any): JSX.Element => {
  const [currentTime, setCurrentTime] = useState(moment());
  const scheduleList = props.schedule;
  const myRef = useRef<HTMLDivElement>(null);
  const firstAppointment = scheduleList.find((schedule: any) => schedule.id);
  const currentlyRunningAppointments = scheduleList.filter(
    (schedule: any): any =>
      schedule.startTime <= currentTime && schedule.startTime.clone().add(schedule.duration, 'minutes') > currentTime,
  );
  // If there are no currently running appointments not even the placeholders then need
  // to find the closest/last finished
  if (
    currentlyRunningAppointments &&
    currentlyRunningAppointments.filter((schedule: any): boolean => !(schedule.overlap && !schedule.id)).length === 0
  ) {
    const foundIndex = scheduleList.findIndex((schedule: any): boolean => schedule === currentlyRunningAppointments[0]);
    if (foundIndex !== -1 && foundIndex !== 0) {
      currentlyRunningAppointments.push(scheduleList[foundIndex - 1]);
    }
  }

  const updatedAppointmentList = scheduleList
    .map((schedule: any) => {
      // is the schedule overlapping with currently running appointment
      const overlapList = currentlyRunningAppointments.filter(
        (appointment: any) =>
          schedule === appointment ||
          (schedule.startTime >= appointment.startTime &&
            schedule.startTime < appointment.startTime.clone().add(appointment.duration, 'minutes')),
      );
      return { ...schedule, currentAppointmentOverlap: overlapList && overlapList.length !== 0 };
    })
    .filter((schedule: any) => !(schedule.overlap && !schedule.id)); // Remove overlapping placeholder

  useInterval(() => {
    setCurrentTime(moment());
  }, 10000);
  useEffect(() => {
    if (myRef && myRef.current) {
      myRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [myRef.current]);
  return (
    <StyledTableBody data-testid="daily-appointments-table">
      {updatedAppointmentList.map((schedule: any, index: number): JSX.Element => {
        const prevSchedule = index !== 0 ? updatedAppointmentList[index - 1] : undefined;
        const nextSchedule =
          index !== updatedAppointmentList.length - 1 ? updatedAppointmentList[index + 1] : undefined;
        const currentEndSchedule = schedule.startTime?.clone().add(schedule.duration, 'minutes');
        const timeHighlight = currentTime >= schedule.startTime && currentTime < currentEndSchedule && schedule.id;
        const rowHighlight = schedule.currentAppointmentOverlap;
        // If next appointment is overlapping then don't draw bottom line
        // If previous appointment is overlapping then don't draw top line
        const rowBottomHighlight =
          schedule.currentAppointmentOverlap && !(nextSchedule && nextSchedule.currentAppointmentOverlap);
        const rowTopHighlight =
          schedule.currentAppointmentOverlap &&
          !(typeof prevSchedule !== 'undefined' && prevSchedule.currentAppointmentOverlap);
        return (
          <StyledTableRow
            key={`appointment-row-${index}`}
            ref={
              (firstAppointment && firstAppointment.id === schedule.id) || (!firstAppointment && index === 0)
                ? myRef
                : (null as any)
            }
            $highlight={rowHighlight}
            $highlightBottom={rowBottomHighlight}
            $highlightTop={rowTopHighlight}>
            {renderTime(schedule, timeHighlight)}
            {renderDuration(schedule)}
            {renderPatient(schedule)}
            <td>{schedule['description'] ? schedule['description'] : '-'} </td>
            <td>{schedule['status'] && schedule['status'] !== 'None' ? schedule['status'] : '-'}</td>
            <td>{schedule['comment'] ? schedule['comment'] : '-'}</td>
            <td>{schedule['department'] ? schedule['department']['alias'] : '-'}</td>
            <td>{schedule['location'] ? schedule['location']['name'] : '-'}</td>
          </StyledTableRow>
        );
      })}
    </StyledTableBody>
  );
};

const RODailyAppointment = (): JSX.Element => {
  const theme = useTheme();
  const userContext = useContext(UserContext);
  const practitionerTimezone = userContext.state.timezone;
  const [appliedfilter, setAppliedFilter] = useState<AppointmentFilter>(MY_APPOINTMENTS);
  const [totalAppointmentCount, setTotalAppointmentCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    setSelectedDate(moment());
  }, [practitionerTimezone]);

  const { data, refetch } = useQuery(DOCTOR_APPOINTMENT_QUERY, {
    variables: {
      startDate: moment(selectedDate).tz(practitionerTimezone)?.startOf('day').toDate(),
      endDate: moment(selectedDate).tz(practitionerTimezone)?.endOf('day').toDate(),
    },
    skip: !selectedDate,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.doctorAppointmentsByDateWithPractitioners?.length > 0) {
      const filteredAppointments = filterAppointments(data.doctorAppointmentsByDateWithPractitioners, appliedfilter);
      setTotalAppointmentCount(filteredAppointments.length);
    }
  }, [data, appliedfilter]);

  const header = [
    { name: 'Time', variable: 'startTime', width: '5%' },
    { name: 'Dur', variable: 'duration', width: '5%' },
    { name: 'Patient', variable: 'patient', width: '15%' },
    { name: 'Activity', variable: 'activity', width: '15%' },
    { name: 'Status', variable: 'status', width: '5%' },
    { name: 'Comment', variable: 'comment', width: '30%' },
    { name: 'Department', variable: 'department', width: '10%' },
    { name: 'Location', variable: 'location', width: '15%' },
  ];

  const schedule = prepareDailyScheduleData(selectedDate.toDate(), data, practitionerTimezone, appliedfilter);

  useInterval(() => {
    refetch();
  }, 60000);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppliedFilter((event.target as HTMLInputElement).value as AppointmentFilter);
  };

  return (
    <Stack width={1} padding={2} direction="column" height={'calc(100vh - 50px)'}>
      <Card>
        <Stack direction="column" height={1}>
          <Stack alignItems="baseline" direction="row" padding={2}>
            <Typography variant="h5" paddingRight={1}>
              <DateTimeWidget date={selectedDate} practitionerTimezone={practitionerTimezone} />
            </Typography>
            <StyledTotalAppointments data-testid="total-daily-appointments">
              Total appointments: {totalAppointmentCount}
            </StyledTotalAppointments>
            <Stack direction="row" alignItems="center" sx={{ marginLeft: 'auto', marginBottom: '6px' }} gap="2px">
              <TodayButton
                onClick={() => {
                  setSelectedDate(moment());
                }}>
                Today
              </TodayButton>
              <IconButton
                onClick={() => setSelectedDate(selectedDate ? moment(selectedDate).subtract(1, 'days') : moment())}>
                <ArrowBackIosNew color="primary" />
              </IconButton>
              <BaseDatePicker
                id="dailyAppointmentDatePicker"
                value={dayjs(selectedDate.toString())}
                minDate={dayjs().subtract(5, 'year')}
                maxDate={dayjs().add(5, 'year')}
                onChange={(date, context) => {
                  if (context.validationError || !date) return;
                  setSelectedDate(moment(date && date.toString()));
                }}
              />
              <IconButton
                onClick={() => setSelectedDate(selectedDate ? moment(selectedDate).add(1, 'days') : moment())}>
                <ArrowForwardIos color="primary" />
              </IconButton>
            </Stack>
          </Stack>

          {CurrentAppConfig.RadiationDashboard.showFilters && (
            <>
              <Divider />
              <Stack alignItems="baseline" direction="row" paddingTop={2} paddingLeft={2}>
                <Typography variant="subtitle1" color={theme.palette.text.secondary}>
                  Filters
                </Typography>
              </Stack>
              <Stack alignItems="baseline" direction="row" paddingLeft={2} paddingBottom={2}>
                <Stack marginLeft="15px">
                  <RadioGroup
                    row
                    aria-labelledby="template-change-type-label"
                    name="template-change-type-group"
                    value={appliedfilter}
                    onChange={handleChangeFilter}>
                    <FormControlLabel value={MY_APPOINTMENTS} control={<Radio />} label={MY_APPOINTMENTS} />
                    <FormControlLabel value={ALL_APPOINTMENTS} control={<Radio />} label={ALL_APPOINTMENTS} />
                  </RadioGroup>
                </Stack>
              </Stack>
            </>
          )}

          <Stack width={1} flexGrow={1} overflow="auto" paddingLeft={2} paddingRight={2}>
            <StyledTable>
              <thead>
                <tr>
                  {header.map((head, index) => (
                    <th key={`header-${index}`} style={{ width: head['width'] }}>
                      {head['name']}
                    </th>
                  ))}
                </tr>
              </thead>
              <ScheduleTableBody schedule={schedule} />
            </StyledTable>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default RODailyAppointment;
