import { GraphQLErrorMessage } from 'shared-components/interfaces';
import { GraphQLError, GraphQLFormattedError } from 'graphql';

export const djangoJSONConversion = (djangoMsg: string): string => {
  // Escape out of double quotation marks if there is already double quotation marks
  const message = djangoMsg.replace(/\"/g, '\\"');

  // Replace all the message string from ' to ", otherwise invalid JSON
  return message.replace(/\'/g, '"');
};

// Determine if a string is valid json
export const isValidJson = (jsonString: any): boolean => {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === 'object') {
      return true;
    }
  } catch (e) {}
  return false;
};

export const determineError = (graphQLError?: GraphQLFormattedError): GraphQLErrorMessage | null => {
  /*
    Certain graphqQL Errors are being returned by the backend like this
      "{\"message\": \"You must be logged in\", \"statusCode\": 403}"
    so we need to convert it to a valid object

    A normal GraphQLError will just have a message and no status code
      {message: 'Patient matching query does not exist.', locations: Array(1), path: Array(1)}

    // TODO this is still jank and should be replaced
  */

  if (!graphQLError) return null;

  let message = '';
  if (isValidJson(graphQLError.message)) {
    message = graphQLError.message;
  } else {
    message = djangoJSONConversion(graphQLError.message);
  }

  try {
    const errorObject = JSON.parse(message);
    return errorObject;
  } catch {
    // An error was detected when trying to convert the error object from graph ql, so return a 500 error
    // A normal GraphQLError will just have a string message and be returned here
    return {
      message: graphQLError.message,
      statusCode: 500,
    };
  }
};

export const NEXT_SEARCH_PARAM = 'next';
